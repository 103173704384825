import { tour } from "./tour";

export const tasks = {
  // general tour
  ...tour,
  moduleElement: "작업",
  // ADD - guide me steps
  task: "작업",
  tasks: "작업들",
  high: "높음",
  medium: "중간",
  low: "낮음",
  taskDeletedSuccessfully: "작업이 성공적으로 삭제되었습니다",
  taskRestoredSuccessfully: "작업이 성공적으로 복원되었습니다",
  noTasksCreated: "현재 작업이 생성되지 않았습니다",
  noTasksContent:
    "작업을 추가하려면 추가 버튼을 클릭하여 프로세스를 시작하십시오",
  addTask: "작업 추가",
  backlog: "백로그",
  doing: "진행 중",
  done: "완료",
  creationDate: "생성 날짜",
  deliveryDate: "납기일",
  closeDate: "마감일",
  category: "카테고리",
  priority: "우선순위",
  location: "위치",
  startTask: "작업 시작",
  closeTask: "작업 마감",
  days: "일",
  tasksRecycleBin: "작업 휴지통",
  editTask: "작업 편집",
  addCategory: "카테고리 추가",
  // ADD - guide me steps
  step1_addtask_title: "1. 작업 추가",
  step1_addtask_text: "새 작업을 추가하려면 여기를 클릭하세요",
  step2_addtask_title: "2. 제목",
  step2_addtask_text: "새 작업을 설명할 수 있는 제목을 작성하세요",
  step3_addtask_title: "3. 마감일",
  step3_addtask_text: "작업을 완료해야하는 마감일을 선택하세요",
  step4_addtask_title: "4. 상태",
  step4_addtask_text1_1: "상태를 선택하세요",
  step4_addtask_text1_2: "OPEN을 선택하여 작업을 즉시 게시합니다.",
  step4_addtask_text2_1: "다음을 선택하세요",
  step4_addtask_text2_2:
    "DRAFT 상태를 선택하여 작업을 게시하지 않고 임시 저장합니다.",
  step5_addtask_title: "5. 개인 정보",
  step5_addtask_text:
    "모든 사람에게 보이도록하거나 관련된 사람들에게만 보이도록 설정하려면 여기를 클릭하세요",
  step5_addtask_text_1: "작업 개인 정보 유형을 선택하세요",
  step5_addtask_text_2:
    "모듈에 액세스 할 수있는 모든 사람이 작업을 볼 수 있습니다",
  step5_addtask_text_3: "참가자 만이이 작업을 볼 수 있습니다",
  step6_addtask_title: "6. 담당자",
  step6_addtask_text: "작업을 완료해야하는 사람들을 선택하세요",
  step7_addtask_title: "7. 배포 목록",
  step7_addtask_text: "이 작업에 대해 알릴 사람들을 선택하세요",
  step8_addtask_title: "8. 설명",
  step8_addtask_text: "작업에 대한 설명을 작성하세요",
  step9_addtask_title: "9. 파일 업로드",
  step9_addtask_text:
    "여기를 클릭하거나 이미지 또는 문서와 같은 파일을 끌어다 놓으세요",
  step10_addtask_title: "10. 카테고리",
  step10_addtask_text: "작업의 카테고리를 선택하세요",
  step11_addtask_title: "11. 우선순위",
  step11_addtask_text: "높음, 중간, 낮음 중에서 선택하세요",
  step12_addtask_title: "12. 위치",
  step12_addtask_text: "작업을 수행해야하는 프로젝트 내의 위치를 선택하세요",
  step13_addtask_title: "13. 게시",
  step13_addtask_text:
    "작업을 공개하려면 여기를 클릭하거나 해당 사항이있는 경우 초안으로 저장하려면 저장하세요",
  stepFinal_addtask: "새 작업을 만드는 방법을 배웠습니다",
  // VIEW - guide me steps
  step1_viewtask_title: "1. 작업 보기",
  step1_viewtask_text: "작업을 클릭하여 내용을 확인하세요.",
  step2_viewtask_title: "2. 참여자",
  step2_viewtask_text: "이 섹션에서 담당자와 작성자를 확인할 수 있습니다.",
  step3_viewtask_title: "3. 작업 데이터",
  step3_viewtask_text:
    "이 섹션에서 마감일, 상태, 우선순위 등과 같은 작업의 주요 데이터를 확인할 수 있습니다.",
  step4_viewtask_title: "4. 설명",
  step4_viewtask_text: "작업 소유자가 작성한 작업 설명을 확인할 수 있습니다.",
  step6_viewtask_title: "6. 답장",
  step6_viewtask_text:
    "주요 설명에 대한 답장을 작성하고 파일을 첨부하세요. 다른 답장은 설명 아래에 표시됩니다.",
  step7_viewtask_title: "7. 댓글",
  step7_viewtask_text:
    "응답에 댓글을 남기고 필요한 경우 파일을 첨부하세요. 댓글은 응답 메시지 아래에 표시됩니다.",
  step8_viewtask_title: "8. 작업 시작/마감",
  step8_viewtask_text:
    "작업을 시작으로 표시하려면 여기를 클릭하세요. 이미 시작되었으면 작업을 마감으로 표시하려면 클릭하세요.",
  stepFinal_viewtask: "작업 보기 방법을 배웠습니다",
  // EDIT - guide me steps
  step1_edittask_title: "1. 작업 편집",
  step1_edittask_text:
    "세 개의 점을 클릭하여 다른 옵션을 엽니다. '편집'을 클릭하세요.",
  step2_edittask_title: "2. 정보",
  step2_edittask_text:
    "이 섹션에서 작업의 제목, 마감일, 상태 및 개인 정보를 편집할 수 있습니다.",
  step3_edittask_title: "3. 설명 및 첨부 파일",
  step3_edittask_text:
    "이 섹션에서 작업 설명을 편집하고 첨부된 파일을 삭제할 수 있습니다.",
  step4_edittask_title: "4. 추가 정보",
  step4_edittask_text:
    "이 섹션에서 카테고리, 우선순위 및 위치와 같은 추가 정보를 편집할 수 있습니다.",
  step4_edittask_text1_1: "상태를 선택하세요",
  step4_edittask_text1_2: "응답 대기 중인 작업을 게시하려면 OPEN을 선택하세요.",
  step4_edittask_text2_1: "다음을 선택하세요",
  step4_edittask_text2_2:
    "DRAFT 상태를 선택하여 작업을 게시하지 않고 임시 저장합니다.",
  step5_edittask_title: "5. 담당자",
  step5_edittask_text: "작업 담당자를 추가하거나 제거하세요",
  step5_edittask_text_1: "작업 개인 정보 유형을 선택하세요",
  step5_edittask_text_2:
    "모듈에 액세스 할 수있는 모든 사람이 작업을 볼 수 있습니다",
  step5_edittask_text_3: "참가자 만이이 작업을 볼 수 있습니다",
  step6_edittask_title: "6. 배포 목록",
  step6_edittask_text: "배포 목록에 사람을 추가하거나 제거하세요",
  step7_edittask_title: "7. 변경 사항 저장",
  step7_edittask_text:
    "작업에 대한 변경 사항을 저장하고 업데이트하려면 여기를 클릭하세요",
  stepFinal_edittask: "작업 편집 방법을 배웠습니다",
  // RECYCLE - guide me steps
  step1_recycletask_title: "2. 작업 휴지통",
  step1_recycletask_text: "프로젝트에서 삭제된 작업을 확인할 수 있습니다.",
  step2_recycletask_title: "3. 작업 선택",
  step2_recycletask_text: "복원하려는 작업을 선택하세요.",
  step3_recycletask_title: "4. 작업 복원",
  step3_recycletask_text: "선택한 작업을 복원하려면 여기를 클릭하세요.",
  stepFinal_recycletask: "휴지통과 상호 작용하는 방법을 배웠습니다",
  // EXPORT - guide me steps
  step1_exporttask_title: "세 개의 점을 클릭하여 다른 옵션을 엽니다:",
  step1_exporttask_text1_1: "PDF로 내보내기",
  step1_exporttask_text1_2:
    "선택한 작업을 PDF 파일로 내보내려면 여기를 클릭하세요.",
  step1_exporttask_text2_1: "이메일로 보내기",
  step1_exporttask_text2_2:
    "선택한 작업을 이메일로 보내려면 여기를 클릭하세요.",
  stepFinal_exporttask: "작업 내보내기 방법을 배웠습니다",
  // SETTINGS - guide me steps
  step1_settingstask_title: "2. 작업 설정",
  step1_settingstask_text: "설정을 수정하려면 여기를 클릭하세요",
  step3_settingstask_title: "3. 배포 목록",
  step3_settingstask_text: "생성 된 작업의 기본 배포 목록 사람을 선택하세요.",
  step4_settingstask_title: "4. 설정 저장",
  step4_settingstask_text: "설정을 저장하려면 여기를 클릭하세요",
  stepFinal_settingstask: "작업 설정과 상호 작용하는 방법을 배웠습니다",
  // other
  obligatory: "의무적",
  public: "공개",
  private: "비공개",
  congratulations: "축하합니다!",
  rfi_title_module: "RFIs",
  no_rfis_title: "현재 RFI가 생성되지 않았습니다",
  no_rfis_text: "하나를 추가하려면 RFI 추가 버튼을 클릭하십시오",
  meeting: "미팅",
  workflow: "워크플로우",
  guideMe: "가이드",
  support: "지원",
  closeTaskMessage: "이 작업을 닫으시겠습니까?",
  subtasks: "하위 작업",
  addSubtask: "하위 작업 추가",
  noSubtasks: "하위 작업이 생성되지 않았습니다",
  inList: "목록에",
};
