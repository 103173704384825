// @ts-check
/* eslint react/prop-types: 0 */

import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// eslint-disable-next-line no-unused-vars
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Swal from "sweetalert2";

import TransitionComponent from "../TransitionComponent";

export default function Milestone(props) {
  const { client, setDataSource, object, open, setOpen } = props;

  const [loading, setLoading] = useState(false);

  const { t: tGeneral } = useTranslation("general");
  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  const formContext = useForm({
    defaultValues: {
      title: object.title || "",
      date: object.date?.slice(0, 10) || new Date().toISOString().slice(0, 10),
    },
  });
  const { control, formState, handleSubmit, reset } = formContext;

  useEffect(() => {
    reset({
      title: object.title || "",
      date: object.date?.slice(0, 10) || new Date().toISOString().slice(0, 10),
    });
  }, [object, reset]);

  const handleClose = () => {
    setOpen(false);
  };

  const process = async (data) => {
    setLoading(true);
    try {
      if (!object.id) {
        const response = await client.request({
          data: {
            idGroup: object.idGroup,
            title: data.title,
            date: data.date,
            metadata: {},
          },
          method: "POST",
          url: "/milestones",
        });
        setDataSource(response.data);
      } else {
        const response = await client.request({
          data: {
            idGroup: object.idGroup,
            title: data.title,
            date: data.date,
            metadata: data.metadata,
          },
          method: "PUT",
          url: `/milestones/${object.id}`,
        });
        setDataSource(response.data);
      }
      const Toast = Swal.mixin({
        position: "center",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        toast: true,
      });
      Toast.fire({
        icon: "success",
        title: tProjectSchedule("Success"),
      });
    } catch (error) {
      const Toast = Swal.mixin({
        position: "center",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        toast: true,
      });
      Toast.fire({
        icon: "error",
        title: tProjectSchedule(error.response.data.error ? error.response.data.error : error),
      });
      // eslint-disable-next-line no-console
      console.error(error);
    }
    setLoading(false);
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={handleClose}
      open={open}
      PaperProps={{
        className: "project-schedule",
        sx: {
          borderRadius: "20px",
        },
      }}
      scroll="body"
      // @ts-ignore
      TransitionComponent={TransitionComponent}
    >
      <FormProvider {...formContext}>
        <form onSubmit={handleSubmit(process)}>
          <DialogTitle component="div">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4">{tProjectSchedule("Milestone")}</Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent
            dividers
            sx={{
              padding: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                gap: "30px",
              }}
            >
              <Controller
                control={control}
                name="title"
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      error={formState.errors.title !== undefined}
                      fullWidth
                      helperText={formState.errors.title && tGeneral("fieldRequired")}
                      label={tProjectSchedule("Title")}
                      type="text"
                    />
                  );
                }}
                rules={{ required: true }}
              />
              <Controller
                control={control}
                name="date"
                render={({ field }) => {
                  return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        {...field}
                        format={object.dateFormat}
                        onChange={(newValue) => field.onChange(dayjs(newValue).format("YYYY-MM-DD"))}
                        value={field.value ? dayjs(field.value) : null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={formState.errors.date !== undefined}
                            fullWidth
                            helperText={formState.errors.date && tGeneral("fieldRequired")}
                            label={tProjectSchedule("Due Date")}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  );
                }}
                rules={{ required: true }}
              />
            </Box>
          </DialogContent>
          <DialogActions
            disableSpacing
            sx={{
              gap: "30px",
              padding: "30px",
            }}
          >
            {loading && <CircularProgress size={20} />}
            <Button color="secondary" onClick={handleClose} variant="outlined">
              {tProjectSchedule("Cancel")}
            </Button>
            <Button color="primary" type="submit" variant="contained">
              {tProjectSchedule("Submit")}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}
