import { tour } from "./tour";

const inspections = {
  // general tour
  ...tour,
  moduleElement: "검사",
  subModuleElement: "템플릿",
  // ADD - guide me steps
  step1_inspectionsAdd_title: "1. 추가하기",
  step1_inspectionsAdd_text:
    "새로운 템플릿을 추가하려면 + 아이콘을 클릭하세요.",
  step2_inspectionsAdd_title: "2. 제목",
  step2_inspectionsAdd_text: "검사의 제목을 입력하세요.",
  step3_inspectionsAdd_title: "3. 납기일",
  step3_inspectionsAdd_text: "검사의 납기일을 선택하세요.",
  step4_inspectionsAdd_title: "4. 상태",
  step4_inspectionsAdd_text_1_1: "상태를 선택하세요.",
  step4_inspectionsAdd_text_1_2: "해당 상태의 검사는 수정 중입니다.",
  step4_inspectionsAdd_text_2_1: "상태를 선택하세요.",
  step4_inspectionsAdd_text_2_2: "검사의 초안 상태를 유지합니다.",
  step5_inspectionsAdd_title: "5. 개인 정보",
  step5_inspectionsAdd_text: "검사의 개인 정보 유형을 선택하세요.",
  step5_inspectionsAdd_text_1:
    "모듈에 액세스할 수 있는 모든 사용자가 검사를 볼 수 있습니다.",
  step5_inspectionsAdd_text_2: "참가자만이 이 검사를 볼 수 있습니다.",
  step6_inspectionsAdd_title: "6. 위치",
  step6_inspectionsAdd_text: "검사의 위치를 선택하세요.",
  step7_inspectionsAdd_title: "7. 템플릿 선택",
  step7_inspectionsAdd_text: "검사에 사용할 템플릿을 선택하세요.",
  step8_inspectionsAdd_title: "8. 생성자",
  step8_inspectionsAdd_text: "본인과 동일한 권한을 가진 사용자를 선택하세요.",
  step9_inspectionsAdd_title: "9. 담당자",
  step9_inspectionsAdd_text: "검사를 승인할 사람을 선택하세요.",
  step10_inspectionsAdd_title: "10. 배포 목록",
  step10_inspectionsAdd_text: "이 검사에 대해 알림을 받을 사람을 선택하세요.",
  step11_inspectionsAdd_title: "11. 생성",
  step11_inspectionsAdd_text: "검사를 생성하려면 여기를 클릭하세요.",
  step_final_inspectionsAdd: "검사를 생성하는 방법을 배웠습니다.",
  // ADD TEMPLATES - guide me steps
  step1_inspectionTemplatesAdd_title: "1. 검사 모듈 메뉴",
  step1_inspectionTemplatesAdd_text: "옵션을 보려면 여기를 클릭하세요.",
  step2_inspectionTemplatesAdd_title: "2. 템플릿",
  step2_inspectionTemplatesAdd_text: "템플릿 섹션을 열려면 여기를 클릭하세요.",
  step3_inspectionTemplatesAdd_title: "3. 추가",
  step3_inspectionTemplatesAdd_text: "+ 아이콘을 클릭하세요.",
  step4_inspectionTemplatesAdd_title: "4. 제목",
  step4_inspectionTemplatesAdd_text: "검사 템플릿의 제목을 입력하세요.",
  step5_inspectionTemplatesAdd_title: "5. 코드",
  step5_inspectionTemplatesAdd_text: "검사 템플릿의 코드를 입력하세요.",
  step6_inspectionTemplatesAdd_title: "6. 카테고리",
  step6_inspectionTemplatesAdd_text: "카테고리를 선택하세요.",
  step7_inspectionTemplatesAdd_title: "7. 설명",
  step7_inspectionTemplatesAdd_text: "설명을 입력하세요.",
  step8_inspectionTemplatesAdd_title: "8. 검사 목록",
  step8_inspectionTemplatesAdd_text: "추가할 새로운 검사 항목을 클릭하세요.",
  step8_1_inspectionTemplatesAdd_title: "8.1 제목",
  step8_1_inspectionTemplatesAdd_text: "섹션의 제목을 입력하세요.",
  step8_1_1_inspectionTemplatesAdd_title: "8.1.1 요소 추가",
  step8_1_1_inspectionTemplatesAdd_text: "검사할 요소를 추가하려면 클릭하세요.",
  step8_1_2_inspectionTemplatesAdd_title: "8.1.2 제목",
  step8_1_2_inspectionTemplatesAdd_text: "제목을 입력하세요.",
  step8_1_3_inspectionTemplatesAdd_title: "8.1.3 설명",
  step8_1_3_inspectionTemplatesAdd_text: "요소에 대한 설명을 입력하세요.",
  step9_inspectionTemplatesAdd_title: "9. 추가",
  step9_inspectionTemplatesAdd_text: "요소를 추가하려면 클릭하세요.",
  step10_inspectionTemplatesAdd_title: "10. 저장",
  step10_inspectionTemplatesAdd_text: "템플릿을 저장하려면 클릭하세요.",
  step_final_inspectionTemplatesAdd: "템플릿을 생성하는 방법을 배웠습니다.",
  add: "검사 추가",
  edit: "검사 편집",
  guideMe: "가이드",
  inspection_recycle_bin: "검사 - 휴지통",
  inspections: "검사",
  meeting: "미팅",
  no_inspections_text: "추가하려면 검사 추가 버튼을 클릭하세요.",
  no_inspections_title: "현재까지 검사가 생성되지 않았습니다.",
  title: "검사",
  workflow: "워크플로우",
  itemsInspected: "검사된 항목",
  pass: "통과",
  fail: "실패",
  na: "해당 없음",
  sections: "섹션",
  items: "항목",
  createTask: "작업 생성",
  add_button_tour: "새로운 검사 생성",
  select_template: "템플릿 선택",
  add_section: "섹션 추가",
  no_sections: "이 검사에는 섹션이 없습니다.",
  approve: "승인",
  checkAndSign: "확인 및 서명",
  createREvision: "개정 생성",
  observation: "관찰",
  addItem: "항목 추가",
  deleteInspection: "이 검사를 삭제하시겠습니까?",
  Revision: "개정",
};

export { inspections };
