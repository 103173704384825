import { createSlice } from "@reduxjs/toolkit";

export const CONST_DEFAULT_TASK_FILTERS = {
  assignees: [],
  creator: [],
  priority: [],
  status: [],
  category: [],
  location: [],
  createdAfter: null,
  createdBefore: null,
};

const initialState = {
  editingTask: null,
  selected: [],
  filters: {
    assignees: [],
    creator: [],
    priority: [],
    status: [],
    category: [],
    location: [],
    createdAfter: null,
    createdBefore: null,
  },
};

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setEditingTask: (state, action) => {
      state.editingTask = action.payload;
    },
    addSelectedTask: (state, action) => {
      if (!state.selected.includes(action.payload)) {
        state.selected = [...state.selected, action.payload];
      }
    },
    removeSelectedTask: (state, action) => {
      state.selected = state.selected.filter((task) => task !== action.payload);
    },
    resetSelectedTasks: (state) => {
      state.selected = [];
    },
    setCurrentFiltersTasks: (state, action) => {
      state.filters = action.payload;
    },
    resetTasks: () => initialState,
  },
});

export const selectEditingTask = (state) => state.tasks.editingTask;
export const selectSelectedTasks = (state) => state.tasks.selected;
export const selectCurrentFiltersTasks = (state) => state.tasks.filters;

export const {
  setEditingTask,
  addSelectedTask,
  removeSelectedTask,
  resetSelectedTasks,
  setCurrentFiltersTasks,
  resetTasks,
} = tasksSlice.actions;

export default tasksSlice.reducer;
