// @ts-check

import { Box, Divider, Drawer } from "@mui/material";
import { registerLicense } from "@syncfusion/ej2-base";
import axios from "axios";
import { Fullscreen, FullscreenExit } from "@mui/icons-material";
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import Close from "../../components/projectDashboard/ProjectSchedule/Close";
import Colors from "../../components/projectDashboard/ProjectSchedule/Colors";
import Columns from "../../components/projectDashboard/ProjectSchedule/Columns";
import DateFormat from "../../components/projectDashboard/ProjectSchedule/DateFormat";
import ExpandAllGroups from "../../components/projectDashboard/ProjectSchedule/ExpandAllGroups";
import FilterAssignees from "../../components/projectDashboard/ProjectSchedule/FilterAssignees";
import FilterColors from "../../components/projectDashboard/ProjectSchedule/FilterColors";
import FilterTimestamp from "../../components/projectDashboard/ProjectSchedule/FilterTimestamp";
import Gantt from "../../components/projectDashboard/ProjectSchedule/Gantt";
import Menu from "../../components/projectDashboard/ProjectSchedule/Menu";
import Activity from "../../components/projectDashboard/ProjectSchedule/Modals/Activity";
import ExportPDF from "../../components/projectDashboard/ProjectSchedule/Modals/ExportPDF";
import Group from "../../components/projectDashboard/ProjectSchedule/Modals/Group";
import InvitePeople from "../../components/projectDashboard/ProjectSchedule/Modals/InvitePeople";
import Milestone from "../../components/projectDashboard/ProjectSchedule/Modals/Milestone";
import Roadblock from "../../components/projectDashboard/ProjectSchedule/Modals/Roadblock";
import People from "../../components/projectDashboard/ProjectSchedule/People";
import Refresh from "../../components/projectDashboard/ProjectSchedule/Refresh";
import Settings from "../../components/projectDashboard/ProjectSchedule/Settings";
import Share from "../../components/projectDashboard/ProjectSchedule/Share";
import ShowHideCompleted from "../../components/projectDashboard/ProjectSchedule/ShowHideCompleted";
import Timeline from "../../components/projectDashboard/ProjectSchedule/Timeline";
import Title from "../../components/projectDashboard/ProjectSchedule/Title";
import View from "../../components/projectDashboard/ProjectSchedule/View";
import Zoom from "../../components/projectDashboard/ProjectSchedule/Zoom";
import { TooltipIconButton } from "../../components/shared";
import { selectCurrentToken } from "../../features/auth/authSlice";
import { useGetProjectUsersQuery } from "../../features/project/projectApiSlice";
import {
  buildAssignees,
  colors,
  computeSplitterSettings,
  computeTimelineSettings,
  dateFormats,
  prepareDataSource,
  timestamps,
} from "../../utils/projectSchedule";

registerLicense("Ngo9BigBOggjHTQxAR8/V1NAaF5cWWRCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWX1edXZXQ2RdVEBzXUI=");

function ProjectSchedule() {
  const { idEnterprise, idProject } = useParams();

  const url = `${process.env.REACT_APP_BACKEND_URL}project/projectSchedule/${idProject}`;

  const ref = useRef(null);

  const token = useSelector(selectCurrentToken);

  const [dataSource, setDataSource] = useState([]);

  const [color, setColor] = useState(colors[0]);

  const [view, setView] = useState("Chart");

  const [fullscreenMode, setFullscreenMode] = useState(false);

  const [filterAssignees, setFilterAssignees] = useState([]);
  const [filterColors, setFilterColors] = useState([]);
  const [filterTimestamp, setFilterTimestamp] = useState(timestamps[0].options[0]);
  const [filterCompleted, setFilterCompleted] = useState(false);

  const [dateFormat, setDateFormat] = useState(dateFormats[0].options[0]);
  const [expandAllGroups, setExpandAllGroups] = useState(true);
  const [columns, setColumns] = useState([]);
  const [menu, setMenu] = useState(false);
  const [timelineViewMode, setTimelineViewMode] = useState("Day");

  const [invitePeople, setInvitePeople] = useState(false);
  const [exportPDF, setExportPDF] = useState(false);

  const [groupOpen, setGroupOpen] = useState(false);
  const [groupObject, setGroupObject] = useState({});
  const [activityOpen, setActivityOpen] = useState(false);
  const [activityObject, setActivityObject] = useState({});
  const [milestoneOpen, setMilestoneOpen] = useState(false);
  const [milestoneObject, setMilestoneObject] = useState({});
  const [roadblockOpen, setRoadblockOpen] = useState(false);
  const [roadblockObject, setRoadblockObject] = useState({});

  const { t: tGeneral } = useTranslation("general");
  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  const { data: users } = useGetProjectUsersQuery(idProject);

  const client = axios.create({
    baseURL: url,
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await client.request({
          method: "GET",
          url: "get",
        });
        setDataSource(response.data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
      try {
        const gantt = ref?.current;
        if (gantt) {
          // @ts-ignore
          gantt.refresh();
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      const gantt = ref?.current;
      if (gantt) {
        // @ts-ignore
        gantt.dataSource = prepareDataSource(
          dataSource,
          filterAssignees,
          filterColors,
          filterTimestamp,
          filterCompleted,
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    if (activityObject) {
      const fetch = (groups) => {
        const activities = [];

        const traverse = (group) => {
          if (group.type === "activity") {
            activities.push(group);
          }
          if (group.child && group.child.length) {
            group.child.forEach(traverse);
          }
        };

        groups.forEach(traverse);

        return activities;
      };

      const activities = fetch(dataSource);

      activities.sort((a, b) => {
        if (a.updatedAt > b.updatedAt) {
          return -1;
        }
        if (a.updatedAt < b.updatedAt) {
          return 1;
        }
        return 0;
      });

      // @ts-ignore
      const activity = activities.find((activity) => activity.id === activityObject.id);
      if (!activity) {
        setActivityOpen(false);
      }
      setActivityObject(activity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, filterAssignees, filterColors, filterTimestamp, filterCompleted]);

  useEffect(() => {
    try {
      const gantt = ref?.current;
      if (gantt) {
        // @ts-ignore
        if (gantt.treeGrid.getRows() !== undefined) {
          if (expandAllGroups) {
            // @ts-ignore
            gantt.expandAll();
          } else {
            // @ts-ignore
            gantt.collapseAll();
          }
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [expandAllGroups]);

  useEffect(() => {
    try {
      const gantt = ref?.current;
      if (gantt) {
        // @ts-ignore
        gantt.splitterSettings = computeSplitterSettings(fullscreenMode, columns, view);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [fullscreenMode, columns, view]);

  useEffect(() => {
    try {
      const gantt = ref?.current;
      if (gantt) {
        gantt.refresh();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [dateFormat]);

  const setSplitterPosition = (gantt, columns, view) => {
    // eslint-disable-next-line no-param-reassign
    gantt.splitterSettings = computeSplitterSettings(fullscreenMode, columns, view);
  };

  const assignees = buildAssignees(dataSource);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        ...(fullscreenMode && {
          left: 0,
          position: "absolute",
          top: 0,
          width: "100vw",
          zIndex: 1201,
        }),
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "white",
          borderBottom: "1px solid #FBCB04",
          display: "flex",
          gap: "20px",
          padding: "10px 20px",
          width: "100%",
        }}
      >
        <Title />
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            gap: "20px",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <div className="project-schedule-full">
            <Refresh
              handleClick={() => {
                try {
                  const gantt = ref?.current;
                  if (gantt) {
                    // @ts-ignore
                    gantt.refresh();
                    setSplitterPosition(gantt, columns, view);
                  }
                } catch (error) {
                  // eslint-disable-next-line no-console
                  console.error(error);
                }
              }}
            />
          </div>
          <div className="project-schedule-full">
            <Colors caption={tProjectSchedule("Default Color")} color={color} options={colors} setColor={setColor} />
          </div>
          <div className="project-schedule-full">
            <People options={assignees} />
          </div>
          <div className="project-schedule-full">
            <Share
              invitePeople={invitePeople}
              setInvitePeople={setInvitePeople}
              exportPDF={exportPDF}
              setExportPDF={setExportPDF}
            />
          </div>
          {fullscreenMode ? (
            <TooltipIconButton
              // @ts-ignore
              color="black"
              icon={<FullscreenExit />}
              label={`${tGeneral("close")} ${tGeneral("fullscreen").toLowerCase()}`}
              onClick={() => setFullscreenMode(false)}
            />
          ) : (
            <TooltipIconButton
              // @ts-ignore
              color="black"
              icon={<Fullscreen />}
              label={tGeneral("fullscreen")}
              onClick={() => setFullscreenMode(true)}
            />
          )}
          <div className="project-schedule-full">
            <Settings client={client} dataSource={dataSource} setDataSource={setDataSource} />
          </div>
          {fullscreenMode ? null : (
            <div className="project-schedule-mini">
              <View
                fullscreenMode={fullscreenMode}
                view={view}
                setView={(view) => {
                  setView(view);
                  try {
                    const gantt = ref?.current;
                    if (gantt) {
                      setSplitterPosition(gantt, columns, view);
                    }
                  } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error(error);
                  }
                }}
              />
            </div>
          )}
          <div className="project-schedule-mini">
            <Menu
              setMenu={(menu) => {
                setMenu(menu);
              }}
            />
            <Drawer
              anchor="right"
              onClose={() =>
                setMenu((menu) => {
                  return !menu;
                })
              }
              open={menu}
              sx={{
                zIndex: 1202,
              }}
            >
              <Box
                sx={{
                  alignItems: "flex-start",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  padding: "20px",
                }}
              >
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Refresh
                    handleClick={() => {
                      try {
                        const gantt = ref?.current;
                        if (gantt) {
                          // @ts-ignore
                          gantt.refresh();
                          setSplitterPosition(gantt, columns, view);
                        }
                      } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error(error);
                      }
                    }}
                  />
                  <Colors
                    caption={tProjectSchedule("Default Color")}
                    color={color}
                    options={colors}
                    setColor={setColor}
                  />
                  <People options={assignees} />
                  <Share
                    invitePeople={invitePeople}
                    setInvitePeople={setInvitePeople}
                    exportPDF={exportPDF}
                    setExportPDF={setExportPDF}
                  />
                  <Settings client={client} dataSource={dataSource} setDataSource={setDataSource} />
                  <Close
                    handleClick={() => {
                      setMenu(false);
                    }}
                  />
                </Box>
                <Divider
                  sx={{
                    height: "1px",
                    width: "100%",
                  }}
                />
                <FilterAssignees
                  assignees={filterAssignees}
                  options={assignees}
                  setAssignees={(assignees) => {
                    setFilterAssignees(assignees);
                  }}
                />
                <FilterColors
                  colors={filterColors}
                  options={colors}
                  setColors={(colors) => {
                    setFilterColors(colors);
                  }}
                />
                <FilterTimestamp
                  timestamp={filterTimestamp}
                  options={timestamps}
                  setTimestamp={(timestamp) => {
                    setFilterTimestamp(timestamp);
                  }}
                />
                <ShowHideCompleted
                  completed={filterCompleted}
                  setCompleted={(isCompleted) => {
                    setFilterCompleted(isCompleted);
                  }}
                />
                <DateFormat dateFormat={dateFormat} options={dateFormats} setDateFormat={setDateFormat} />
                <ExpandAllGroups expandAllGroups={expandAllGroups} setExpandAllGroups={setExpandAllGroups} />
                <Divider
                  sx={{
                    height: "1px",
                    width: "100%",
                  }}
                />
                <Columns
                  columns={columns}
                  handleChange={(columnsNew) => {
                    const columnsOld = columns;
                    setColumns(columnsNew);
                    const insert = columnsNew.filter((column) => !columnsOld.includes(column));
                    try {
                      const gantt = ref?.current;
                      if (gantt) {
                        setSplitterPosition(gantt, columnsNew, view);
                        if (insert.includes("projectCost")) {
                          // @ts-ignore
                          gantt.showColumn(["projectCostBudget", "projectCostExpenses", "projectCostDifference"]);
                        }
                        if (insert.includes("scheduledTimes")) {
                          // @ts-ignore
                          gantt.showColumn(["scheduledTimesDuration", "scheduledTimesStart", "scheduledTimesEnd"]);
                        }
                        if (insert.includes("realTimes")) {
                          // @ts-ignore
                          gantt.showColumn(["realTimesDuration", "realTimesStart", "realTimesEnd"]);
                        }
                        const remove = columnsOld.filter((column) => !columnsNew.includes(column));
                        if (remove.includes("projectCost")) {
                          // @ts-ignore
                          gantt.hideColumn(["projectCostBudget", "projectCostExpenses", "projectCostDifference"]);
                        }
                        if (remove.includes("scheduledTimes")) {
                          // @ts-ignore
                          gantt.hideColumn(["scheduledTimesDuration", "scheduledTimesStart", "scheduledTimesEnd"]);
                        }
                        if (remove.includes("realTimes")) {
                          // @ts-ignore
                          gantt.hideColumn(["realTimesDuration", "realTimesStart", "realTimesEnd"]);
                        }
                      }
                    } catch (error) {
                      // eslint-disable-next-line no-console
                      console.error(error);
                    }
                  }}
                />
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Timeline
                    setTimelineViewMode={(timelineViewMode) => {
                      setTimelineViewMode(timelineViewMode);
                      try {
                        const gantt = ref?.current;
                        if (gantt) {
                          // @ts-ignore
                          gantt.timelineSettings = computeTimelineSettings(timelineViewMode);
                        }
                      } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error(error);
                      }
                    }}
                    timelineViewMode={timelineViewMode}
                  />
                  <Zoom
                    fitToProject={() => {
                      setTimelineViewMode("");
                      try {
                        const gantt = ref?.current;
                        if (gantt) {
                          // @ts-ignore
                          gantt.fitToProject();
                        }
                      } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error(error);
                      }
                    }}
                    zoomIn={() => {
                      setTimelineViewMode("");
                      try {
                        const gantt = ref?.current;
                        if (gantt) {
                          // @ts-ignore
                          gantt.zoomIn();
                        }
                      } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error(error);
                      }
                    }}
                    zoomOut={() => {
                      setTimelineViewMode("");
                      try {
                        const gantt = ref?.current;
                        if (gantt) {
                          // @ts-ignore
                          gantt.zoomOut();
                        }
                      } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error(error);
                      }
                    }}
                  />
                </Box>
              </Box>
            </Drawer>
          </div>
        </Box>
      </Box>
      <div className="project-schedule-full">
        <Box
          sx={{
            alignItems: "center",
            backgroundColor: "white",
            display: "flex",
            gap: "20px",
            justifyContent: "space-between",
            padding: "10px 20px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              gap: "20px",
              justifyContent: "flex-start",
            }}
          >
            <FilterAssignees
              assignees={filterAssignees}
              options={assignees}
              setAssignees={(assignees) => {
                setFilterAssignees(assignees);
              }}
            />
            <FilterColors
              colors={filterColors}
              options={colors}
              setColors={(colors) => {
                setFilterColors(colors);
              }}
            />
            <FilterTimestamp
              timestamp={filterTimestamp}
              options={timestamps}
              setTimestamp={(timestamp) => {
                setFilterTimestamp(timestamp);
              }}
            />
            <ShowHideCompleted
              completed={filterCompleted}
              setCompleted={(isCompleted) => {
                setFilterCompleted(isCompleted);
              }}
            />
          </Box>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              gap: "20px",
              justifyContent: "flex-start",
            }}
          >
            <DateFormat dateFormat={dateFormat} options={dateFormats} setDateFormat={setDateFormat} />
            <ExpandAllGroups expandAllGroups={expandAllGroups} setExpandAllGroups={setExpandAllGroups} />
            <Columns
              columns={columns}
              handleChange={(columnsNew) => {
                const columnsOld = columns;
                setColumns(columnsNew);
                const insert = columnsNew.filter((column) => !columnsOld.includes(column));
                try {
                  const gantt = ref?.current;
                  if (gantt) {
                    setSplitterPosition(gantt, columnsNew, view);
                    if (insert.includes("projectCost")) {
                      // @ts-ignore
                      gantt.showColumn(["projectCostBudget", "projectCostExpenses", "projectCostDifference"]);
                    }
                    if (insert.includes("scheduledTimes")) {
                      // @ts-ignore
                      gantt.showColumn(["scheduledTimesDuration", "scheduledTimesStart", "scheduledTimesEnd"]);
                    }
                    if (insert.includes("realTimes")) {
                      // @ts-ignore
                      gantt.showColumn(["realTimesDuration", "realTimesStart", "realTimesEnd"]);
                    }
                    const remove = columnsOld.filter((column) => !columnsNew.includes(column));
                    if (remove.includes("projectCost")) {
                      // @ts-ignore
                      gantt.hideColumn(["projectCostBudget", "projectCostExpenses", "projectCostDifference"]);
                    }
                    if (remove.includes("scheduledTimes")) {
                      // @ts-ignore
                      gantt.hideColumn(["scheduledTimesDuration", "scheduledTimesStart", "scheduledTimesEnd"]);
                    }
                    if (remove.includes("realTimes")) {
                      // @ts-ignore
                      gantt.hideColumn(["realTimesDuration", "realTimesStart", "realTimesEnd"]);
                    }
                  }
                } catch (error) {
                  // eslint-disable-next-line no-console
                  console.error(error);
                }
              }}
            />
            <Timeline
              setTimelineViewMode={(timelineViewMode) => {
                setTimelineViewMode(timelineViewMode);
                try {
                  const gantt = ref?.current;
                  if (gantt) {
                    // @ts-ignore
                    gantt.timelineSettings = computeTimelineSettings(timelineViewMode);
                  }
                } catch (error) {
                  // eslint-disable-next-line no-console
                  console.error(error);
                }
              }}
              timelineViewMode={timelineViewMode}
            />
            <Zoom
              fitToProject={() => {
                setTimelineViewMode("");
                try {
                  const gantt = ref?.current;
                  if (gantt) {
                    // @ts-ignore
                    gantt.fitToProject();
                  }
                } catch (error) {
                  // eslint-disable-next-line no-console
                  console.error(error);
                }
              }}
              zoomIn={() => {
                setTimelineViewMode("");
                try {
                  const gantt = ref?.current;
                  if (gantt) {
                    // @ts-ignore
                    gantt.zoomIn();
                  }
                } catch (error) {
                  // eslint-disable-next-line no-console
                  console.error(error);
                }
              }}
              zoomOut={() => {
                setTimelineViewMode("");
                try {
                  const gantt = ref?.current;
                  if (gantt) {
                    // @ts-ignore
                    gantt.zoomOut();
                  }
                } catch (error) {
                  // eslint-disable-next-line no-console
                  console.error(error);
                }
              }}
            />
          </Box>
        </Box>
      </div>
      <Gantt
        // @ts-ignore
        client={client}
        setDataSource={setDataSource}
        columns={columns}
        dateFormat={dateFormat}
        fullscreenMode={fullscreenMode}
        ref={ref}
        setActivityObject={setActivityObject}
        setActivityOpen={setActivityOpen}
        setGroupObject={setGroupObject}
        setGroupOpen={setGroupOpen}
        setMilestoneObject={setMilestoneObject}
        setMilestoneOpen={setMilestoneOpen}
        setRoadblockObject={setRoadblockObject}
        setRoadblockOpen={setRoadblockOpen}
        timelineViewMode={timelineViewMode}
        view={view}
      />
      {invitePeople && <InvitePeople client={client} open={invitePeople} setOpen={setInvitePeople} />}
      {exportPDF && <ExportPDF open={exportPDF} setOpen={setExportPDF} />}
      {groupOpen && (
        <Group
          assignees={assignees}
          client={client}
          dateFormat={dateFormat}
          object={groupObject}
          open={groupOpen}
          setDataSource={setDataSource}
          setGroupObject={setGroupObject}
          setOpen={setGroupOpen}
          users={users}
        />
      )}
      {activityOpen && (
        <Activity
          client={client}
          color={color}
          dataSource={dataSource}
          dateFormat={dateFormat}
          idEnterprise={idEnterprise}
          idProject={idProject}
          object={activityObject}
          open={activityOpen}
          setActivityObject={setActivityObject}
          setDataSource={setDataSource}
          setOpen={setActivityOpen}
          setRoadblockObject={setRoadblockObject}
          setRoadblockOpen={setRoadblockOpen}
          users={users}
        />
      )}
      {milestoneOpen && (
        <Milestone
          client={client}
          object={milestoneObject}
          open={milestoneOpen}
          setDataSource={setDataSource}
          setObject={setMilestoneObject}
          setOpen={setMilestoneOpen}
        />
      )}
      {roadblockOpen && (
        <Roadblock
          client={client}
          object={roadblockObject}
          open={roadblockOpen}
          setDataSource={setDataSource}
          setObject={setRoadblockObject}
          setOpen={setRoadblockOpen}
          users={users}
        />
      )}
    </Box>
  );
}

export default ProjectSchedule;
