import * as XLSX from "xlsx";

const EXCEL_FILE_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8";
const EXCEL_FILE_EXTENSION = ".xlsx";

const buildAndExportExcel = (fileName, data) => {
  const ws = XLSX.utils.aoa_to_sheet(data);
  const excelBuffer = XLSX.write(
    { Sheets: { [fileName]: ws }, SheetNames: [fileName] },
    { bookType: "xlsx", type: "array" },
  );
  const file = new Blob([excelBuffer], { type: EXCEL_FILE_TYPE });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(file);
  link.download = `${fileName}${EXCEL_FILE_EXTENSION}`;
  link.click();
};

export { buildAndExportExcel };
