import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { selectCurrentUser } from "../../../../features/userSettings/userSettingsSlice";
import { selectCurrentProject } from "../../../../features/project/projectSlice";
import CaslContext from "../../../../utils/caslContext";
import { ReportLocationComments } from "./ReportLocationComments";

function ReportLocationDetailsTable({ report }) {
  const currentProject = useSelector(selectCurrentProject);
  const { idProject } = useParams();
  const isShared = useMemo(() => {
    if (report && idProject) {
      return report.sharedWith.indexOf(idProject) > -1;
    }
    return false;
  }, [report, idProject]);
  const locationItems = useMemo(() => {
    if (report) {
      return report.agendas;
    }
    return [];
  }, [report]);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedReportItem, setSelectedReportItem] = useState({});

  const { t: tGeneral } = useTranslation("general");
  const { t: tDailyReports } = useTranslation("dailyReports");

  const currentUser = useSelector(selectCurrentUser);
  const ability = useContext(CaslContext);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    if (locationItems?.[tabIndex]?.topics?.length > 0) {
      setSelectedReportItem(locationItems?.[tabIndex]?.topics?.[0]);
    }
  }, [tabIndex]);

  const disableChat = useMemo(() => {
    if (isShared) return true;
    if (report && currentUser) {
      const isDisabled = report.status === "close";
      if (!isDisabled && !ability.can("superAdmin", "dailyReports")) {
        const canComment =
          report.creators.indexOf(currentUser.id) > -1 ||
          report.assignedTo.indexOf(currentUser.id) > -1;
        if (canComment) {
          return false;
        }
        return true;
      }
      return isDisabled;
    }
    return false;
  }, [report, currentUser, ability.rules, isShared]);

  function getRisk(selectedRisk) {
    const risks = [
      {
        label: tDailyReports("highRisk"),
        value: "0",
        color: "#EC2D30",
      },
      {
        label: tDailyReports("mediumRisk"),
        value: "1",
        color: "#FE9B0E",
      },
      {
        label: tDailyReports("lowRisk"),
        value: "2",
        color: "#0C9D61",
      },
    ];
    return risks.find((risk) => risk.value === selectedRisk)?.label;
  }

  return (
    <Box>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={tabIndex}
        onChange={handleTabChange}
        data-tour="view-dailyReport-3"
      >
        {locationItems.map((location, index) => {
          let locationLabel = location?.locationName;
          if (location?.locationType === "tier 2")
            locationLabel = `>${locationLabel}`;
          if (location?.locationType === "tier 3")
            locationLabel = `>>${locationLabel}`;
          return (
            <Tab
              key={`tab-location-${index}`}
              label={locationLabel}
              sx={{
                textTransform: "none",
                minWidth: 0,
                fontWeight: "regular",
                "&.Mui-selected": {
                  color: "black",
                  fontWeight: "medium",
                },
              }}
            />
          );
        })}
      </Tabs>
      {locationItems.map((location, index) => (
        <div
          key={`tabpanel-location-${location.id}`}
          role="tabpanel"
          hidden={tabIndex !== index}
        >
          {tabIndex === index && (
            <Paper elevation={3} sx={{ display: "flex", maxHeight: "48vh" }}>
              {/* Left side */}
              <Box flex={1} overflow="hidden">
                {/* Title */}
                <Box px={4}>
                  <Box display="flex" columnGap={1} alignItems="center" py={1}>
                    <Typography fontSize={14} fontWeight="bold">{`${tGeneral(
                      "location",
                    )}`}</Typography>
                    <Typography
                      fontSize={14}
                      color="#5F5F5F"
                    >{`${location?.path}`}</Typography>
                  </Box>
                  {report?.category === 3 && (
                    <Box
                      display="flex"
                      columnGap={1}
                      alignItems="center"
                      py={1}
                      borderBottom="1px solid black"
                    >
                      <Typography
                        fontSize={14}
                        fontWeight="bold"
                      >{`${tDailyReports("risk")}`}</Typography>
                      <Typography fontSize={14} color="#5F5F5F">{`${
                        location.risk !== null ? getRisk(location.risk) : "N/A"
                      }`}</Typography>
                    </Box>
                  )}
                  {report?.category === 2 && (
                    <Box
                      display="flex"
                      columnGap={1}
                      alignItems="center"
                      py={1}
                      borderBottom="1px solid black"
                      data-tour="view-report-progress"
                    >
                      <Typography
                        fontSize={14}
                        fontWeight="bold"
                      >{`${tDailyReports("advance")}`}</Typography>
                      <Typography fontSize={14} color="#5F5F5F">
                        {location?.advance > 0
                          ? `${location?.advance} ` +
                            (location?.unitObject
                              ? `${location?.unitObject.name}`
                              : "")
                          : "N/A"}
                      </Typography>
                      <Typography fontSize={14} fontWeight="bold">{`${tGeneral(
                        "cost",
                      )}`}</Typography>
                      <Typography fontSize={14} color="#5F5F5F">
                        {location?.cost > 0
                          ? `$${
                              location?.cost
                            } ${location?.currency.toUpperCase()}`
                          : "N/A"}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box
                  maxHeight="40vh"
                  overflow="auto"
                  data-tour="view-report-description"
                >
                  {location?.topics?.map((topic, index) => (
                    <Box
                      key={topic?.id}
                      bgcolor={
                        selectedReportItem?.id === topic?.id
                          ? "#FFEBBD"
                          : "white"
                      }
                      onClick={() => setSelectedReportItem(topic)}
                      sx={{
                        ":hover": {
                          cursor: "pointer",
                        },
                      }}
                    >
                      <Box px={4} pt={2}>
                        <Typography
                          fontSize={14}
                          color={
                            selectedReportItem?.id === topic?.id
                              ? "#B58101"
                              : "black"
                          }
                          sx={{
                            whiteSpace: "pre-wrap",
                          }}
                        >{`${index + 1}. ${topic?.title}`}</Typography>
                        <Typography
                          fontSize={12}
                          color={
                            selectedReportItem?.id === topic?.id
                              ? "#B58101"
                              : "#888888"
                          }
                          textAlign="end"
                          marginTop={1}
                          pb={1}
                          borderBottom="1px solid #DEDEDE"
                        >
                          {moment(new Date(topic?.createDate))
                            .tz(currentProject?.projectTimezone)
                            .format(
                              "D MMMM YYYY\xa0\xa0\xa0\xa0 |\xa0\xa0\xa0\xa0 h:mmA",
                            )}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
              {/* Right side */}
              <Box flex={1} bgcolor="#FFEBBC" data-tour="view-dailyReport-5" overflow="auto" pb="20px">
                <ReportLocationComments
                  disabled={disableChat}
                  topic={selectedReportItem}
                />
              </Box>
            </Paper>
          )}
        </div>
      ))}
    </Box>
  );
}

export default ReportLocationDetailsTable;
