import {
  Alert,
  AlertTitle,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";

function SuccessToast({ title, message }) {
  return (
    <Alert
      severity="success"
      icon={<CheckIcon sx={{ color: "#4B4B4B" }} />}
      sx={{
        background:
          "linear-gradient(90deg, rgba(251,203,4,1) 0%, rgba(229,233,0,1) 100%)",
        borderRadius: "16px",
      }}
    >
      <AlertTitle fontSize="16px">{title}</AlertTitle>
      <Typography>{message}</Typography>
    </Alert>
  );
}

function ErrorToast({ title, message, handleClose }) {
  return (
    <Alert
      severity="error"
      icon={false}
      sx={{
        background: "#FFCCD2",
        borderRadius: "16px",
      }}
    >
      <AlertTitle fontSize="16px" color="#EC2D30">
        {title}
        <IconButton onClick={handleClose}>
          <ClearIcon />
        </IconButton>
      </AlertTitle>
      <Typography
        fontSize="14px"
        color="#4B4B4B"
        width="100%"
        display="flex"
        justifyContent="center"
      >
        {message}
      </Typography>
    </Alert>
  );
}

function Toast({ message, onExited, autoHideDuration, ...props }) {
  const [open, setOpen] = useState(true);

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      key={message.key}
      open={open}
      onClose={handleClose}
      TransitionProps={{ onExited }}
      sx={{ height: "100%" }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      autoHideDuration={autoHideDuration ?? 3000}
      {...props}
    >
      <div>
        {message.severity === "success" && <SuccessToast {...message} />}
        {message.severity === "error" && (
          <ErrorToast {...message} handleClose={handleClose} />
        )}
      </div>
    </Snackbar>
  );
}

export { Toast };
