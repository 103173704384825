import { tour } from "./tour";

export const photos = {
  // general tour
  ...tour,
  moduleElement: "Fotos",
  editButtonTour: "Add Album",
  // ADD - guide me steps
  addPhoto: "Add photo",
  timeline: "Timeline",
  albums: "Albums",
  album: "Album",
  photos: "Photos",
  addAlbum: "Add album",
  photosPerPage: "Photos per page",
  deleteImagesTitle: "Delete images",
  deleteImagesContent: "Are you sure you want to delete these images?",
  deleteAlbumsContent: "Are you sure you want to delete these albums?",
  deleteImagetTitle: "Delete image",
  deleteImageContent: "Are you sure you want to delete this image?",
  setLocation: "Set location",
  setAlbumCover: "Set as album cover",
  setAlbumCoverConfirm:
    "Are you sure you want to set this image as album cover?",
  movePhotoAlbumTitle: "Change album",
  movePhotoLocationTitle: "Change location",
  movePhotoLocationSubtitle: "Move photo to location",
  movePhotoAlbumSubtitle: "Move photo to album",
  movePhotoLocationSuccess: "Image location updated successfully",
  movePhotoAlbumSuccess: "Image album updated successfully",
  selectedPhotos: "Selected photos",
  perPage: "Per page",
  noImagesCreated: "No images created at this moment",
  noAlbumsCreated: "No albums created at this moment",
  addImagesValidation:
    "If you want to add one, we invite you to click the add Image button to start the process",
  addAlbumsValidation:
    "If you want to add one, we invite you to click the add Album button to start the process",
  projectConfigurationUpdate: "Project configuration updated successfully",
  newPhotos: "New photos",
  images: "images",
  youHaveUploaded: "You have uploaded",
  restoreAlbumsQuestion: "Are you sure you want to restore this album?",
  restoreImagesQuestion: "Are you sure you want to restore these images?",
  deletePermanentlyQuestion:
    "Are you sure you want to delete this permanently?",
  albumName: "Album name",
  imagesDeletedSuccessfully: "Images deleted successfully",
  imageCoverSetSuccessfully: "Image cover has been set successfully",
  albumDeletedSuccessfully: "Album deleted successfully",
  imagesRestoredSuccessfully: "Images restored successfully",
  // ADD Photo - guide me steps
  step1_addPhotos_title: "1. Add photo",
  step1_addPhotos_text:
    "On the timeline view, click here to add one or multiple media files",
  step2_addPhotos_title: "2. Location",
  step2_addPhotos_text: "Select a location that has been pre established",
  step3_addPhotos_title: "3. Album",
  step3_addPhotos_text: "Select an album for the files",
  step4_addPhotos_title: "4. Add files",
  step4_addPhotos_text:
    "Attach any type of photo and video files, or drag and drop them inside the dotted box",
  step5_addPhotos_title: "5. Confirm",
  step5_addPhotos_text:
    "Click here to confirm the information of the uploaded media files and publish them",
  stepFinal_addPhotos: "You have learned how to add a photo",
  // ADD Album - guide me steps
  step1_addAlbums_title: "1. Add album",
  step1_addAlbums_text: "On the album view, click here to add an album.",
  step2_addAlbums_title: "2. Album name",
  step2_addAlbums_text: "Give the album a name",
  step3_addAlbums_title: "3. Add",
  step3_addAlbums_text: "Click here to add the album to the album dashboard",
  stepFinal_addAlbums: "You have learned how to add an album",
  // VIEW - guide me steps
  step1_viewPhotos_title: "1. View media file",
  step1_viewPhotos_text: "Click on a media file or video to visualize it",
  step2_viewPhotos_title: "2. Visualize media file",
  step2_viewPhotos_text:
    "Once you clicked on a file, it will appear full scaled",
  step3_viewPhotos_title: "3. Information",
  step3_viewPhotos_text:
    "To the top right of the media file preview, you will see the date it was taken, the date and who uploaded it, and the location",
  step4_viewPhotos_title: "4. Comments",
  step4_viewPhotos_text:
    "Comment on the media file for other people to see your observations",
  step5_viewPhotos_title: "5. Download photo/video",
  step5_viewPhotos_text:
    "Click here to download the media file you selected to your device",
  step6_viewPhotos_title: "6. Delete image/video",
  step6_viewPhotos_text:
    "If you're a super admin or admin, you can delete uploaded media files",
  step7_viewPhotos_title: "7. More options",
  step7_viewPhotos_text:
    "Hover the cursor over the three dots and a pop up menu will appear with the following options:",
  step7_viewPhotos_text1_1: "Change album",
  step7_viewPhotos_text1_2: "Move the selected media file to a different album",
  step7_viewPhotos_text2_1: "Change location",
  step7_viewPhotos_text2_2: "Change the location the media file is referred to",
  step7_viewPhotos_text3_1: "Set as album cover",
  step7_viewPhotos_text3_2:
    "Set the selected photo as the album cover it belongs to (this only works with photo)",
  stepFinal_viewPhotos: "You have learned how to view a photo",
  // RECYCLE - guide me steps
  step1_recyclePhotos_title: "2. Recycle Bin",
  step1_recyclePhotos_text:
    "Click here to open the recycle bin menu. This will take you to the photos / albums menu depending on your current view.",
  step2_recyclePhotos_title: "3. Select files / albums to restore",
  step2_recyclePhotos_text: "This is the menu fot the recycle bin",
  step3_recyclePhotos_title: "4. Restore",
  step3_recyclePhotos_text: "Click here to restore the selected files / albums",
  step4_recyclePhotos_title: "5. Delete",
  step4_recyclePhotos_text:
    "Click here to permanently delete the selected files / albums",
  stepFinal_recyclePhotos:
    "You have learned how to interact with the recycle bin",
  // EXPORT - guide me steps
  step1_exportPhotos_title: "1. Select media files",
  step1_exportPhotos_text:
    "Select the checkbox of all the media files that want to be exported. You can also select the files of a whole day, week, or month if needed",
  step2_exportPhotos_title: "2. Export PDF",
  step2_exportPhotos_text:
    "Click here and a pop-up menu will appear with the options to export media files in PDF format (1, 2 or 4 per page).",
  stepFinal_exportPhotos: "You have learned how to export a media file.",
  // SETTINGS - guide me steps
  step1_settingsPhotos_title: "2. Settings",
  step1_settingsPhotos_text: "Click here to open the settings pop up menu",
  step2_settingsPhotos_title: "3. Distribution list",
  step2_settingsPhotos_text:
    "Select the people that will have permission to see all the media files and albums",
  step3_settingsPhotos_title: "4. Save",
  step3_settingsPhotos_text: "Save the configuration",
  stepFinal_settingsPhotos:
    "You have learned how to interact with the module settings",
  // other
  obligatory: "Obligatory",
  public: "Public",
  private: "Private",
  congratulations: "Congratulations!",
  rfi_title_module: "RFIs",
  no_rfis_title: "No RFIs have been created at the moment",
  no_rfis_text:
    "If you want to add one, we invite you to click the add RFI button to start the process",
  meeting: "Meeting",
  workflow: "Workflow",
  guideMe: "Guide me",
  support: "Support",
  days: "Days",
  rfi_recycle_bin: "RFI Recycle Bin",
  addSpec: "Add specification",
  imageUploadedMessage: "Image(s) uploaded succesfully.",
  captureSite: "Capture site",
  uploadedOn: "Uploaded On",
  dateUploaded: "Date uploaded",
  dateTaken: "Date taken",
  coordinates: "Coordinates",
};
