import { Add, Check, Close, Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  createFilterOptions,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  useEditAssignedProjectsFromUserMutation,
  useGetAssignedProjectsFromUserQuery,
  useGetRolesFromEnterprisesQuery,
  useGetUserProjectsByEnterpriseQuery,
} from "../../../features/enterprise/enterpriseApiSlice";
import { PopUpAlert } from "../../../components/PopUpAlert";
import { TooltipIconButton } from "../../../components/shared";
import { useCreateCompanyMutation, useGetAllCompaniesQuery } from "../../../features/company";

const filter = createFilterOptions();

function AssignedProjects({ projects, onDelete }) {
    const { t: tGeneral } = useTranslation("general");
  
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent={projects.length > 0 ? "flex-start" : "center"}
        height="300px"
        maxHeight="300px"
        sx={{ overflowY: "auto" }}
      >
        {projects.length === 0 ? (
          tGeneral("noAssignedProjects")
        ) : (
          <>
            <Box display="flex" width="100%" height="40px" alignItems="center">
              <Box width="100px" />
              <Typography sx={{ flex: 1 }} fontWeight="bold">
                {tGeneral("projectName")}
              </Typography>
              <Typography sx={{ flex: 1 }} fontWeight="bold">
                {tGeneral("roleName")}
              </Typography>
              <Typography sx={{ flex: 1 }} fontWeight="bold">
                {tGeneral("position")}
              </Typography>
              <Box width="50px" />
            </Box>
            <Box overflow="auto" width="100%">
              {projects.map((project) => (
                <Box
                  display="flex"
                  key={project.idProject}
                  height="50px"
                  width="100%"
                  alignItems="center"
                >
                  <Box width="100px" display="flex" justifyContent="center">
                    {project.image ? (
                      <img
                        src={project.image}
                        alt={project.projectName}
                        height="40px"
                        width="auto"
                      />
                    ) : (
                      "N/A"
                    )}
                  </Box>
                  <Typography sx={{ flex: 1 }}>{project.projectName}</Typography>
                  <Typography sx={{ flex: 1 }}>{project.roleName}</Typography>
                  <Typography sx={{ flex: 1 }}>
                    {project.position?.length > 0 ? project.position : "N/A"}
                  </Typography>
                  <Box width="50px">
                    <TooltipIconButton
                      icon={<Delete />}
                      onClick={() => onDelete(project.idProject, project.id ? false : true)}
                      label={tGeneral("delete")}
                      color="red"
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </>
        )}
      </Box>
    );
}

function ModalEditEnterpriseUser({ user, isOpen, onClose }) {
  const { idEnterprise } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { t: tError } = useTranslation("error");
  const [idProject, setIdProject] = useState(null);
  const [idRole, setIdRole] = useState(null);
  const [position, setPosition] = useState(null);
  const formData = useForm({
    defaultValues: {
      projects: [],
      firstName: user?.firstName,
      lastName: user?.lastName,
      phone: user?.phone,
      company: user?.idCompany,
      deletedProjects: [],
    },
  });

  const { data: projectsData } =
    useGetUserProjectsByEnterpriseQuery(idEnterprise);
  const { data: rolesData } = useGetRolesFromEnterprisesQuery(idEnterprise);
  const { data: assignedProjectsData } = useGetAssignedProjectsFromUserQuery({ idEnterprise, idUser: user.id });
  const { data: companiesData } = useGetAllCompaniesQuery(idEnterprise);
  const [
    editAssignedProjects,
    { isSuccess, isError, error, reset: resetMutation },
  ] = useEditAssignedProjectsFromUserMutation();
  const [createCompany] = useCreateCompanyMutation();
  const { handleSubmit, control, register, watch, setValue, reset } = formData;

  const handleClose = useCallback(() => {
    setIdProject(null);
    setIdRole(null);
    setPosition(null);
    reset();
    onClose();
  }, [onClose, reset]);

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert("success", tGeneral("done"), tGeneral("userEditedSuccessfully"));
      resetMutation();
      handleClose();
    }

    if (isError) {
      PopUpAlert("error", "Error", tError(error.data.message));
      resetMutation();
      handleClose();
    }
  }, [isSuccess, isError, error, tGeneral, handleClose, tError, resetMutation]);

  const onSubmit = (data) => {
    const body = {
      ...data,
      idEnterprise,
      idUser: user.id,
      projects: data.projects?.filter((project) => !project.id), // Only new projects
    };

    editAssignedProjects(body);
  };

  const projects = watch("projects");
  const enterpriseProjects = projectsData ? projectsData.projects : [];
  const enterpriseRoles = rolesData ?? [];
  const idCompany = watch("company");
  const deletedProjects = watch("deletedProjects");

  const companies = useMemo(() => companiesData ?? [], [companiesData]);
  const selectedCompany = useMemo(() => {
    if (companies && idCompany) {
      return companies.find((company) => company.id === idCompany);
    }
    return null;
  }, [companies, idCompany]);

  useEffect(() => {
    if (assignedProjectsData) {
        setValue("projects", [
            ...projects,
            ...assignedProjectsData.map((project) => ({
                id: project.id,
                idProject: project.idProject,
                projectName: project.projectName,
                idRole: project.idRole,
                roleName: project.roleName,
                image: project.image,
                position: project.position,
            })),
        ]);
    }
    }, [assignedProjectsData]);

  const handleAddProject = () => {
    const project = enterpriseProjects.find((proj) => proj.id === idProject);
    const role = enterpriseRoles.find((role) => role.id === idRole);
    setValue("projects", [
      ...projects,
      {
        idProject: project.id,
        projectName: project.projectName,
        idRole: role.id,
        roleName: role.name,
        image: project.image,
        position,
      },
    ]);
    setIdProject(null);
    setIdRole(null);
    setPosition("");
  };

  const handleAddCompany = async (newCompany) => {
    const createdCompany = await createCompany({
      name: newCompany,
      idEnterprise,
    }).unwrap();
    setValue("company", createdCompany.id);
  };

  const handleDeleteAssignedProject = (idProject, bNew) => {
    if (!bNew) { // Old projects
      let deletedP = [...deletedProjects];
      deletedP.push(idProject);
      setValue("deletedProjects", deletedP);
    }
    setValue(
      "projects",
      projects.filter((project) => project.idProject !== idProject),
    );
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card sx={{ width: "800px", padding: "10px" }}>
        <FormProvider {...formData}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex" alignItems="center" my="10px">
              <Typography sx={{ flex: 1 }} variant="h4">
                {tGeneral("editUser")}
              </Typography>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
              <IconButton color="primary" type="submit">
                <Check />
              </IconButton>
            </Box>
            <Divider />
            <Box display="flex" flexDirection="column" rowGap="10px" mt="10px">
              <Box display="flex" columnGap="10px">
                    <TextField
                      label={tGeneral("email")}
                      type="email"
                      sx={{ flex: 1 }}
                      size="small"
                      value={user?.email}
                      disabled
                    />
              </Box>
              <Box display="flex" columnGap="10px">
                    <TextField
                      label={tGeneral("firstName")}
                      sx={{ flex: 1 }}
                      size="small"
                      required
                      {...register("firstName")}
                      InputLabelProps={{ shrink: watch("firstName").length > 0 }}
                    />
                    <TextField
                      label={tGeneral("lastName")}
                      sx={{ flex: 1 }}
                      size="small"
                      required
                      {...register("lastName")}
                      InputLabelProps={{ shrink: watch("lastName").length > 0 }}
                    />
                    <TextField
                      label={tGeneral("phone")}
                      sx={{ flex: 1 }}
                      size="small"
                      required
                      {...register("phone")}
                      type="number"
                    />
                    <Autocomplete
                      size="small"
                      value={selectedCompany?.name}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          setValue("company", newValue);
                        } else if (newValue && newValue.inputValue) {
                          handleAddCompany(newValue.inputValue);
                        } else {
                          setValue("company", newValue ? newValue.id : newValue);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        const isExisting = options.some(
                          (option) => inputValue === option.title,
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            name: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="free-solo-with-text-demo"
                      options={companies}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.name;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.name}</li>
                      )}
                      sx={{ flex: 1 }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField {...params} label={tGeneral("company")} />
                      )}
                    />
              </Box>
              <Divider />
              <Typography>{tGeneral("assignedProjects")}</Typography>
              <Box display="flex" columnGap="10px">
                <FormControl sx={{ flex: 1 }} size="small">
                  <InputLabel>{tGeneral("project")}</InputLabel>
                  <Select
                    label={tGeneral("project")}
                    value={idProject}
                    onChange={(e) => setIdProject(e.target.value)}
                    MenuProps={{ sx: { maxHeight: "400px" } }}
                  >
                    {enterpriseProjects
                      .filter(
                        (project) =>
                          projects.findIndex(
                            (proj) => proj.idProject === project.id,
                          ) < 0,
                      )
                      .map((project) => (
                        <MenuItem value={project.id}>
                          {project.projectName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ flex: 1 }} size="small">
                  <InputLabel>{tGeneral("role")}</InputLabel>
                  <Select
                    label={tGeneral("role")}
                    value={idRole}
                    onChange={(e) => setIdRole(e.target.value)}
                    MenuProps={{
                      sx: { maxHeight: "400px" },
                    }}
                  >
                    {enterpriseRoles.map((role) => (
                      <MenuItem value={role.id}>{role.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label={tGeneral("position")}
                  onChange={(e) => setPosition(e.target.value)}
                  value={position}
                  size="small"
                  variant="outlined"
                />
                <Button
                  variant="text"
                  disabled={idProject === null || idRole === null}
                  onClick={handleAddProject}
                >
                  <Add />
                </Button>
              </Box>
              <AssignedProjects
                projects={projects}
                onDelete={handleDeleteAssignedProject}
              />
            </Box>
          </form>
        </FormProvider>
      </Card>
    </Modal>
  );
}

export { ModalEditEnterpriseUser };
