export const photos = {
  addPhoto: "添加照片",
  timeline: "图库",
  albums: "相册",
  album: "相册",
  photos: "照片",
  addAlbum: "添加相册",
  photosPerPage: "每页照片数",
  deleteImagesTitle: "删除图片",
  deleteImagesContent: "确定要删除这些图片吗？",
  deleteAlbumsContent: "确定要删除这些相册吗？",
  deleteImagetTitle: "删除图片",
  deleteImageContent: "确定要删除这张图片吗？",
  setLocation: "设置位置",
  setAlbumCover: "设置为相册封面",
  setAlbumCoverConfirm: "确定要将此图片设置为相册封面吗？",
  movePhotoLocationTitle: "更改位置",
  movePhotoAlbumTitle: "更改相册",
  movePhotoLocationSubtitle: "将照片移动到位置",
  movePhotoAlbumSubtitle: "将照片移动到相册",
  movePhotoLocationSuccess: "图片位置编辑成功",
  movePhotoAlbumSuccess: "图片相册编辑成功",
  selectedPhotos: "已选择的照片",
  perPage: "每页",
  noImagesCreated: "当前没有创建任何图片",
  noAlbumsCreated: "当前没有创建任何相册",
  addImagesValidation: "如果要添加图片，请点击“添加照片”按钮开始该过程",
  addAlbumsValidation: "如果要添加相册，请点击“添加相册”按钮开始该过程",
  projectConfigurationUpdate: "项目配置已成功更新",
  newPhotos: "新照片",
  images: "图片",
  youHaveUploaded: "您已上传",
  restoreAlbumsQuestion: "确定要恢复此相册吗？",
  restoreImagesQuestion: "确定要恢复这些图片吗？",
  deletePermanentlyQuestion: "确定要永久删除此内容吗？",
  albumName: "相册名称",
  imagesDeletedSuccessfully: "图片删除成功",
  imageCoverSetSuccessfully: "相册封面设置成功",
  albumDeletedSuccessfully: "相册删除成功",
  // ADD Photo - guide me steps
  step1_addPhotos_title: "1. 添加照片",
  step1_addPhotos_text:
    "在时间轴视图中，点击添加按钮以添加一个或多个多媒体文件。",
  step2_addPhotos_title: "2. 位置",
  step2_addPhotos_text: "选择一个预设的位置",
  step3_addPhotos_title: "3. 相册",
  step3_addPhotos_text: "选择一个相册用于文件。",
  step4_addPhotos_title: "4. 添加文件",
  step4_addPhotos_text: "附加任何类型的照片或视频，或将其拖放到虚线框内。",
  step5_addPhotos_title: "5. 确认",
  step5_addPhotos_text: "点击此处确认上传的多媒体文件的信息并发布。",
  stepFinal_addPhotos: "您已学会如何添加照片",
  // ADD Album - guide me steps
  step1_addAlbums_title: "1. 添加相册",
  step1_addAlbums_text: "在相册视图中，点击添加按钮以添加一个相册。",
  step2_addAlbums_title: "2. 相册名称",
  step2_addAlbums_text: "为相册输入一个名称。",
  step3_addAlbums_title: "3. 添加",
  step3_addAlbums_text: "点击此处将相册添加到相册面板。",
  stepFinal_addAlbums: "您已学会如何添加相册",
  // VIEW - guide me steps
  step1_viewPhotos_title: "1. 查看多媒体文件",
  step1_viewPhotos_text: "点击照片或视频以查看它。",
  step2_viewPhotos_title: "2. 查看多媒体文件",
  step2_viewPhotos_text: "一旦您点击了文件，它将以全屏显示。",
  step3_viewPhotos_title: "3. 信息",
  step3_viewPhotos_text:
    "在多媒体文件预览的右上角，将显示拍摄日期、上传日期、上传者和位置。",
  step4_viewPhotos_title: "4. 评论",
  step4_viewPhotos_text: "对多媒体文件进行评论，以便其他人可以看到您的观察。",
  step5_viewPhotos_title: "5. 下载多媒体文件",
  step5_viewPhotos_text: "点击此处将所选的多媒体文件下载到您的设备。",
  step6_viewPhotos_title: "6. 删除图片/视频",
  step6_viewPhotos_text:
    "如果您是超级管理员或管理员，可以删除已上传的媒体文件。",
  step7_viewPhotos_title: "7. 更多选项",
  step7_viewPhotos_text:
    "将鼠标悬停在三个点上，将显示一个弹出菜单，其中包含以下选项：",
  step7_viewPhotos_text1_1: "更改相册",
  step7_viewPhotos_text1_2: "将所选的多媒体文件移动到其他相册。",
  step7_viewPhotos_text2_1: "更改位置",
  step7_viewPhotos_text2_2: "更改多媒体文件引用的位置。",
  step7_viewPhotos_text3_1: "设置为相册封面",
  step7_viewPhotos_text3_2:
    "将所选的照片设置为其所属的相册封面（仅适用于照片）。",
  stepFinal_viewPhotos: "您已学会如何查看多媒体文件",
  // RECYCLE - guide me steps
  step1_recyclePhotos_title: "1. 回收站",
  step1_recyclePhotos_text:
    "点击此处打开回收站菜单。这将根据您当前的视图带您进入照片/相册菜单。",
  step2_recyclePhotos_title: "2. 选择要恢复的文件/相册",
  step2_recyclePhotos_text:
    "选择要恢复或永久删除的文件/相册的复选框。如果需要，还可以选择整天、整周或整月的文件。",
  step3_recyclePhotos_title: "3. 恢复",
  step3_recyclePhotos_text: "点击此处恢复所选的文件。",
  step4_recyclePhotos_title: "4. 删除",
  step4_recyclePhotos_text: "点击此处永久删除所选的文件。",
  stepFinal_recyclePhotos: "您已学会如何与照片回收站交互",
  // EXPORT - guide me steps
  step1_exportPhotos_title: "1. 选择多媒体文件",
  step1_exportPhotos_text:
    "选择要导出的所有多媒体文件的复选框。如果需要，还可以选择整天、整周或整月的文件。",
  step2_exportPhotos_title: "2. PDFにエクスポート",
  step2_exportPhotos_text:
    "点击此处，将显示一个弹出菜单，其中包含将多媒体文件导出为PDF格式（每页1、2或4个）的选项。",
  stepFinal_exportPhotos: "您已学会如何导出多媒体文件",
  // SETTINGS - guide me steps
  step1_settingsPhotos_title: "1. 设置",
  step1_settingsPhotos_text: "点击此处打开设置弹出菜单。",
  step2_settingsPhotos_title: "2. 分发列表",
  step2_settingsPhotos_text: "选择有权限查看所有多媒体文件和相册的人员。",
  step3_settingsPhotos_title: "3. 保存",
  step3_settingsPhotos_text: "保存设置。",
  stepFinal_settingsPhotos: "您已学会如何与模块设置交互",
  // other
  obligatory: "必填",
  public: "公开",
  private: "私密",
  congratulations: "恭喜！",
  rfi_title_module: "RFIs（疑问）",
  no_rfis_title: "目前尚未创建RFIs（疑问）",
  no_rfis_text: "如果要添加一个，请点击添加按钮开始该过程",
  meeting: "支持",
  workflow: "流程",
  guideMe: "引导",
  support: "支持",
  days: "天",
  rfi_recycle_bin: "回收站（RFIs）",
  addSpec: "添加规范",
  imageUploadedMessage: "图片添加成功。",
  captureSite: "拍摄地点",
  dateUploaded: "上传日期",
  dateTaken: "拍摄日期",
  coordinates: "坐标",
};
