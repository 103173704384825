import { Add, CheckBox, CloudDone } from "@mui/icons-material";
import { Box, Button, Card, Modal } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  CheckListDrawer,
  DeleteDrawingsButton,
  ModalAddDrawing,
  ModalCompareDrawings,
  ModalReviseDrawings,
} from "../../../components/drawings";
import { ModalEditDrawing } from "../../../components/drawings/ModalEditDrawing";
import {
  EmptyState,
  TitleWidgets,
  TooltipIconButton,
} from "../../../components/shared";
import { useGetDrawingsQuery } from "../../../features/drawings";
import {
  selectCurrentGuideMe,
  selectCurrentRole,
} from "../../../features/project/projectSlice";
import Can from "../../../utils/can";

import { ModalFileViewer } from "../../../components/shared/ModalFileViewer";
import { useAppTourContext } from "../../../components/supportButton/context/context";
import { DISTRIBUTION_LIST_NAMES } from "../../../constants";
import CaslContext from "../../../utils/caslContext";
import { DrawingView } from "./DrawingView";
import { DrawingsTable } from "./DrawingsTable";

function Drawings() {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");

  const userRole = useSelector(selectCurrentRole);
  const currentGuideMe = useSelector(selectCurrentGuideMe);
  const navigate = useNavigate();
  const { idProject } = useParams();
  const ability = useContext(CaslContext);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [filters, setFilters] = useState({
    sets: null,
    disciplines: null,
    search: "",
    createdOn: null,
  });
  const [deleteAnchorEl, setDeleteAnchorEl] = useState(null);
  const [editingDrawingId, setEditingDrawingId] = useState(null);
  const [selectedDrawings, setSelectedDrawings] = useState({});
  const [currentDrawing, setCurrentDrawing] = useState(null);
  const [isReviseOpen, setIsReviseOpen] = useState(false);
  const [compareDrawing, setCompareDrawing] = useState(null);
  const [isCheckListOpen, setIsCheckListOpen] = useState(false);

  const isEditOpen = Boolean(editingDrawingId);
  const params = useMemo(() => {
    const filterObject = { idProject };
    if (Boolean(filters.disciplines) && filters.disciplines.length > 0) {
      filterObject.disciplineIds = filters.disciplines
        .map((discipline) => discipline.id)
        .join(",");
    }
    if (Boolean(filters.sets) && filters.sets.length > 0) {
      filterObject.setIds = filters.sets.map((set) => set.id).join(",");
    }
    if (filters.search.length > 0) {
      filterObject.search = filters.search;
    }
    if (filters.createdOn !== null && filters.createdOn !== "") {
      filterObject.startDate = filters.createdOn[0];
      filterObject.endDate = filters.createdOn[1];
    }
    return filterObject;
  }, [filters, idProject]);
  const {
    data: drawings,
    isLoading: isDrawingsLoading,
    isFetching: isDrawingsFetching,
  } = useGetDrawingsQuery(params, {
    refetchOnMountOrArgChange: true,
  });

  const [pdfUrl, setPdfUrl] = React.useState(null);

  const hasFilters =
    filters.disciplines !== null ||
    filters.sets !== null ||
    filters.search !== "" ||
    (filters.createdOn !== null && filters.createdOn !== "");
  const {
    state: { tourActive, stepIndex, steps },
    setState,
  } = useAppTourContext();
  useEffect(() => {
    if (
      tourActive &&
      (stepIndex === 1 || stepIndex === steps.length - 2) &&
      currentGuideMe === "drawings-add"
    ) {
      setIsAddOpen(true);
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }

    if (
      tourActive &&
      (stepIndex === steps.length - 1 || stepIndex === 0) &&
      currentGuideMe === "drawings-add"
    ) {
      setIsAddOpen(false);
    }
  }, [tourActive, stepIndex, steps, currentGuideMe]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box display="flex" alignItems="flex-start" columnGap="12px">
        <TitleWidgets
          title={tGeneral("drawings")}
          searchEnabled={false}
          actions={[]}
          moduleName="drawings"
          distributionListName={DISTRIBUTION_LIST_NAMES.drawings}
          showDelete={
            Object.keys(selectedDrawings).length > 0 &&
            ability.can("delete", "drawings")
          }
          addFunction={() => navigate("./upload")}
          onDelete={(e) => setDeleteAnchorEl(e.currentTarget)}
          enableSupport
          addNameTour="add-drawings-1"
          customElement={
            <TooltipIconButton
              icon={<CheckBox />}
              label={tDrawings("checklist")}
              onClick={() => setIsCheckListOpen(true)}
            />
          }
        />
        {deleteAnchorEl && (
          <DeleteDrawingsButton
            onDelete={() => {
              setSelectedDrawings({});
              setDeleteAnchorEl(null);
            }}
            selectedDrawings={Object.keys(selectedDrawings)}
            anchorEl={deleteAnchorEl}
            setAnchorEl={(val) => setDeleteAnchorEl(val)}
          />
        )}
      </Box>
      <Box display="flex" flexDirection="column" rowGap="10px">
        <DrawingsTable
          isLoading={isDrawingsLoading || isDrawingsFetching}
          drawings={drawings || []}
          onCompare={(revision) => setCompareDrawing(revision)}
          onEdit={(id) => setEditingDrawingId(id)}
          onSelect={setSelectedDrawings}
          onViewDrawing={(drawing) => setCurrentDrawing(drawing)}
          selectedRows={selectedDrawings}
          onFiltersChange={(val) => setFilters(val)}
          filters={filters}
        />
        {/* {drawings?.length === 0 && !hasFilters && !tourActive && (
          <EmptyState
            title={tDrawings("noDrawingsCreated")}
            content={
              userRole?.drawings === 3 || userRole?.drawings === 2
                ? tDrawings("addDrawingsValidation")
                : ""
            }
            module="drawings"
            button={
              <Can I="add" a="drawings">
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsAddOpen(true);
                  }}
                >
                  <Add /> {tDrawings("addDrawing")}
                </Button>
              </Can>
            }
          />
        )} */}
      </Box>
      {Boolean(pdfUrl) && (
        <ModalFileViewer
          file={{ url: pdfUrl }}
          isOpen={Boolean(pdfUrl)}
          onClose={() => setPdfUrl(null)}
        />
      )}
      {currentDrawing && (
        <Modal
          open={Boolean(currentDrawing)}
          onClose={() => setCurrentDrawing(null)}
        >
          <Card sx={{ p: "1%", height: "100%" }}>
            <DrawingView
              drawing={currentDrawing}
              onClose={() => setCurrentDrawing(null)}
            />
          </Card>
        </Modal>
      )}
      <ModalReviseDrawings
        isOpen={isReviseOpen}
        onClose={() => setIsReviseOpen(false)}
      />
      {isAddOpen && (
        <ModalAddDrawing
          isOpen={isAddOpen}
          onClose={() => setIsAddOpen(false)}
          onSuccess={() => {
            setIsAddOpen(false);
            setIsReviseOpen(true);
          }}
        />
      )}
      {isEditOpen && (
        <ModalEditDrawing
          idDrawing={editingDrawingId}
          isOpen={isEditOpen}
          onClose={() => setEditingDrawingId(null)}
        />
      )}
      {!!compareDrawing && (
        <ModalCompareDrawings
          isOpen={!!compareDrawing}
          onClose={() => setCompareDrawing(null)}
          comparingDrawing={compareDrawing}
        />
      )}
      {isCheckListOpen && (
        <CheckListDrawer
          isOpen={isCheckListOpen}
          onClose={() => setIsCheckListOpen(false)}
        />
      )}
    </Box>
  );
}

export { Drawings };
