// @ts-check
/* eslint react/prop-types: 0 */
/* eslint jsx-a11y/control-has-associated-label: 0 */

import CloseIcon from "@mui/icons-material/Close";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ShareIcon from "@mui/icons-material/Share";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import copy from "copy-to-clipboard";
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import { AssignedUsersTooltip } from "../../../shared/AssignedUsersTooltip";
import TransitionComponent from "../TransitionComponent";

export default function InvitePeople(props) {
  const { client, open, setOpen } = props;

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState("");

  const { t: tGeneral } = useTranslation("general");
  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await client.request({
          method: "GET",
          url: "/users",
        });
        setUsers(response.data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    fetch();
  }, [client]);

  // @ts-ignore
  const usersY = users.filter((user) => user.projectSchedule !== 0);
  // @ts-ignore
  const usersN = users.filter((user) => user.projectSchedule === 0);

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={handleClose}
      open={open}
      PaperProps={{
        className: "project-schedule",
        sx: {
          borderRadius: "20px",
        },
      }}
      scroll="body"
      // @ts-ignore
      TransitionComponent={TransitionComponent}
    >
      <DialogContent
        dividers
        sx={{
          padding: "30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: "10px",
                }}
              >
                <ShareIcon />
                <strong>{tProjectSchedule("Share")}</strong>
              </Box>
              <Box
                onClick={handleClose}
                sx={{
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <CloseIcon />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap: "10px",
              }}
            >
              <TextField
                disabled
                sx={{
                  alignSelf: "stretch",
                  flexGrow: 1,
                }}
                value={window.location.href}
              />
              <Button
                color="primary"
                onClick={() => {
                  copy(window.location.href);
                  const Toast = Swal.mixin({
                    position: "center",
                    showConfirmButton: false,
                    timer: 1500,
                    timerProgressBar: true,
                    toast: true,
                  });
                  Toast.fire({
                    icon: "success",
                    title: tProjectSchedule("Success"),
                  });
                }}
                sx={{
                  width: "200px",
                }}
                type="button"
                variant="contained"
              >
                {tProjectSchedule("Copy Link")}
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap: "10px",
              }}
            >
              <GroupAddIcon />
              <strong>{tProjectSchedule("Invite people")}</strong>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap: "10px",
              }}
            >
              <Select
                onChange={(event) => {
                  // @ts-ignore
                  setUser(event.target.value);
                }}
                sx={{
                  alignSelf: "stretch",
                  flexGrow: 1,
                }}
                type="text"
                variant="outlined"
                value={user}
              >
                {usersN.map((user) => {
                  return (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <Button
                color="primary"
                disabled={!user}
                onClick={async (event) => {
                  if (!user) {
                    return;
                  }
                  setLoading(true);
                  try {
                    const response = await client.request({
                      data: {
                        // @ts-ignore
                        idUser: user,
                        projectSchedule: 1,
                      },
                      method: "POST",
                      url: "/users",
                    });
                    setUsers(response.data);
                    const Toast = Swal.mixin({
                      position: "center",
                      showConfirmButton: false,
                      timer: 1500,
                      timerProgressBar: true,
                      toast: true,
                    });
                    Toast.fire({
                      icon: "success",
                      title: tProjectSchedule("Success"),
                    });
                  } catch (error) {
                    const Toast = Swal.mixin({
                      position: "center",
                      showConfirmButton: false,
                      timer: 1500,
                      timerProgressBar: true,
                      toast: true,
                    });
                    Toast.fire({
                      icon: "error",
                      title: tProjectSchedule(error.response.data.error ? error.response.data.error : error),
                    });
                    // eslint-disable-next-line no-console
                    console.error(error);
                  }
                  setLoading(false);
                }}
                sx={{
                  width: "200px",
                }}
                type="button"
                variant="contained"
              >
                {loading ? <CircularProgress color="secondary" size="1.5rem" /> : tProjectSchedule("Invite")}
              </Button>
            </Box>
          </Box>
          {usersY.length === 0 ? (
            <Alert severity="info">{tProjectSchedule("You have not invited anyone yet.")}</Alert>
          ) : (
            <table className="project-schedule project-schedule-invite-people">
              <colgroup>
                <col width="75" />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th />
                  <th>{tProjectSchedule("Name")}</th>
                  <th>{tProjectSchedule("Email")}</th>
                  <th>{tProjectSchedule("Permissions")}</th>
                </tr>
              </thead>
              <tbody>
                {usersY.map((user) => {
                  const users = [user];
                  return (
                    <tr key={user.id}>
                      <td>
                        <AssignedUsersTooltip users={users} />
                      </td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>
                        <Select
                          fullWidth
                          onChange={async (event) => {
                            setLoading(true);
                            try {
                              const response = await client.request({
                                data: {
                                  idUser: user.id,
                                  projectSchedule: event.target.value,
                                },
                                method: "POST",
                                url: "/users",
                              });
                              setUsers(response.data);
                              const Toast = Swal.mixin({
                                position: "center",
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                                toast: true,
                              });
                              Toast.fire({
                                icon: "success",
                                title: tProjectSchedule("Success"),
                              });
                            } catch (error) {
                              const Toast = Swal.mixin({
                                position: "center",
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                                toast: true,
                              });
                              Toast.fire({
                                icon: "error",
                                title: tProjectSchedule(error.response.data.error ? error.response.data.error : error),
                              });
                              // eslint-disable-next-line no-console
                              console.error(error);
                            }
                            setLoading(false);
                          }}
                          size="small"
                          value={user.projectSchedule}
                          variant="outlined"
                        >
                          <MenuItem key="superAdmin" value={3}>
                            {tGeneral("superAdmin")}
                          </MenuItem>
                          <MenuItem key="admin" value={2}>
                            {tGeneral("admin")}
                          </MenuItem>
                          <MenuItem key="viewOnly" value={1}>
                            {tGeneral("viewOnly")}
                          </MenuItem>
                        </Select>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
