/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import { Cancel } from "@mui/icons-material";
import { Box, Card, IconButton, Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";
import Heic from "../../assets/documents/heic.png";
import Zip from "../../assets/documents/zip.png";
import Docx from "../../assets/files/docx.png";
import Dwg from "../../assets/files/dwg.png";
import Pdf from "../../assets/files/pdf.png";
import Ppt from "../../assets/files/ppt.png";
import Rvt from "../../assets/files/rvt.png";
import Xlsx from "../../assets/files/xlsx.png";
import Video from "../../assets/icons/sharedModules/video.png";
import { isVideo } from "../../utils/video";
import AudioPlayer from "../AudioPlayer";
import { ModalFileViewer } from "./ModalFileViewer";
import { ModalVideoViewer } from "./ModalVideoViewer";

function FilePreviewVideo({ height, file }) {
  const videoRef = React.useRef(null);
  const canvasRef = React.useRef(null);
  const [thumbnail, setThumbnail] = useState(null);

  React.useEffect(() => {
    if (!file.url) return;

    const video = videoRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Load the video
    video.src = file.url;
    video.addEventListener("loadeddata", () => {
      if (!video.videoWidth || !video.videoHeight) return;

      // Set canvas dimensions
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Seek to a specific time to capture the thumbnail (e.g., 1 second)
      video.currentTime = 1;

      video.addEventListener(
        "seeked",
        () => {
          // Draw the video frame on the canvas
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

          // Convert the canvas to a data URL and update the thumbnail state
          const dataUrl = canvas.toDataURL("image/png");
          setThumbnail(dataUrl);

          // Clean up
          video.src = ""; // Stop loading the video
        },
        { once: true },
      );
    });

    // Clean up event listeners
    return () => {
      video.removeEventListener("loadeddata", () => {});
      video.removeEventListener("seeked", () => {});
    };
  }, [file.url]);

  return (
    <Card
      sx={{
        height: `calc(${height} - 15px)`,
        display: "flex",
        p: "12px",
        columnGap: "10px",
        alignItems: "center",
        overflowY: "hidden",
      }}
    >
      <video ref={videoRef} style={{ display: "none" }} />
      <canvas ref={canvasRef} style={{ display: "none" }} />
      <img
        src={thumbnail ?? Video}
        alt={file.name}
        width="100px"
        height="100px"
        style={{ borderRadius: "15px" }}
      />
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography fontSize="0.825rem">{file.name}</Typography>
        <Typography fontSize="0.75rem" color="#B0B7C1">
          {file.size}
        </Typography>
      </Box>
    </Card>
  );
}

function FilePreviewHorizontalScroll({
  onDelete,
  onAddPhotos,
  files = [],
  urls = [],
  accept = "all",
  height = "155px",
  multiple = true,
  allowFolders = false,
}) {
  // Translations
  const { t: tGeneral } = useTranslation("general");
  const [currentFile, setCurrentFile] = useState(null);
  const renderFiles = React.useMemo(() => {
    if (files.length > 0) {
      const tempFiles = files.map((file) => {
        if (file.id) {
          return {
            ...file,
            name: file.name,
            type: file.extension,
            size: file.size,
            url: file.url,
            thumbnail: file.thumbnailUrl,
          };
        }
        let url = file.src ?? file.path ?? file.name;
        let type = "pdf";
        if (!url || !url.match(/http/)) {
          const path = url.split(".");
          type = path[path.length - 1];
          url = URL.createObjectURL(file);
        } else {
          const path = url.split(".");
          type = path[path.length - 1];
        }
        const fileSize = file.size
          ? `${(file.size / 1048576).toFixed(2)}MB`
          : null;
        return { name: file.name, type, size: fileSize, url };
      });
      return [...urls, ...tempFiles];
    }
    return [];
  }, [files, urls]);

  const handleAddFiles = (newFiles) => {
    onAddPhotos([...files, ...newFiles]);
  };

  const renderFileType = (file) => {
    const fileType = (
      file.type ?? file.name.substring(file.name.lastIndexOf(".") + 1)
    ).toLowerCase();
    if (
      fileType.match(
        /pdf|doc|xls|csv|dwg|cad|ppt|pptx|zip|rar|heic|mp4|mov|avi|aac|wav|rvt/,
      )
    ) {
      let sourceImage = Pdf;
      if (fileType.match("doc")) {
        sourceImage = Docx;
      }

      if (fileType.match(/xls|csv/)) {
        sourceImage = Xlsx;
      }

      if (fileType.match(/dwg|cad/)) {
        sourceImage = Dwg;
      }

      if (fileType.match(/ppt|pptx/)) {
        sourceImage = Ppt;
      }

      if (fileType.match(/zip|rar/)) {
        sourceImage = Zip;
      }
      if (fileType.match(/heic/)) {
        sourceImage = Heic;
      }
      if (fileType.match(/mp4|mov|avi/)) {
        return <FilePreviewVideo height={height} file={file} />;
      }
      if (fileType.match(/rvt/)) {
        sourceImage = Rvt;
      }
      if (fileType.match(/aac|wav|mp4/)) {
        return (
          <Box
            sx={{
              alignItems: "center",
              columnGap: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              overflowY: "hidden",
              p: "10px",
            }}
          >
            <AudioPlayer url={file.url} />
            <Typography fontSize="0.825rem">{file.name}</Typography>
            <Typography fontSize="0.75rem" color="#B0B7C1">
              {file.size}
            </Typography>
          </Box>
        );
      }
      return (
        <Card
          sx={{
            height: `calc(${height} - 15px)`,
            display: "flex",
            p: "12px",
            columnGap: "10px",
            alignItems: "center",
            overflowY: "hidden",
          }}
        >
          <img src={sourceImage} alt="Pdf" style={{ height: "60px" }} />
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography fontSize="0.825rem">{file.name}</Typography>
            <Typography fontSize="0.75rem" color="#B0B7C1">
              {file.size}
            </Typography>
          </Box>
        </Card>
      );
    }

    return (
      <img
        src={file.thumbnail ?? file.url}
        alt={file.name}
        width="100px"
        height="100px"
        style={{ borderRadius: "15px" }}
      />
    );
  };

  const { getInputProps, getRootProps } = useDropzone({
    accept:
      // eslint-disable-next-line no-nested-ternary
      accept === "all"
        ? "*"
        : accept === "image-videos"
          ? {
              "image/*": [],
              "video/*": [],
            }
          : {
              "image/*": [],
            },
    multiple,
    onDrop: (files) => {
      const maxSize = 5 * 1024 * 1024 * 1024; // 5GB in bytes
      const validFiles = files.filter((file) => file.size <= maxSize);
      const invalidFiles = files.filter((file) => file.size > maxSize);

      if (invalidFiles.length > 0) {
        Swal.fire({
          icon: "error",
          title: tGeneral("errorFileUploadSizeTitle"),
          text: tGeneral("errorFileUploadSizeDescription"),
          showConfirmButton: false,
          timer: 2000,
        });
      }

      handleAddFiles(validFiles);
    },
  });

  const handleViewDocument = (file) => {
    if (!file.type === "aac" && !file.type === "wav" && !file.type === "mp4") {
      setCurrentFile(file);
    }
  };

  const handleCloseViewer = () => {
    setCurrentFile(null);
  };

  const isViewOpen = Boolean(currentFile);

  return (
    <Box
      display="flex"
      sx={{
        flexWrap: "wrap",
        height,
        overflowX: multiple ? "auto" : "hidden",
        p: "10px",
      }}
      columnGap="10px"
      data-tour="docs-add-file-3"
    >
      {renderFiles.map((file, i) => {
        return (
          <Box
            position="relative"
            width={multiple ? "auto" : "100%"}
            key={file.name}
            component="div"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleViewDocument(file);
            }}
          >
            <IconButton
              sx={{ position: "absolute", top: -15, right: -15 }}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(file, i);
              }}
            >
              <Cancel color="error" />
            </IconButton>
            {renderFileType(file)}
          </Box>
        );
      })}
      {(multiple || renderFiles.length === 0) && (
        <Box
          component="div"
          onClick={(e) => {
            e.stopPropagation();
            getInputProps().onClick(e);
          }}
          flex={1}
        >
          <Box
            sx={{
              border: "3px dashed #707070",
              backgroundColor: "#DDDDDD",
              cursor: "pointer",
              mb: "20px",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: renderFiles.length > 0 ? "250px" : "100%",
              minWidth: renderFiles.length > 0 ? "250px" : "100%",
              flexDirection: "column",
            }}
            {...getRootProps({
              className: "dropzone",
            })}
          >
            <input
              {...getInputProps()}
              {...(allowFolders ? { directory: "", webkitdirectory: "" } : "")}
            />
            <Typography
              component="a"
              sx={{
                color: "#377DF7",
                textDecoration: "underline",
              }}
              textAlign="center"
            >
              {tGeneral("attachFiles")}
            </Typography>
            <Typography
              component="a"
              sx={{
                color: "red",
                textDecoration: "bold",
                fontSize: "0.75rem",
                paddingTop: "5px",
              }}
              textAlign="center"
            >
              {tGeneral("fileUploadMaxSizeWarning")}
            </Typography>
          </Box>
        </Box>
      )}
      {isViewOpen && isVideo(currentFile) ? (
        <ModalVideoViewer
          isOpen={isViewOpen}
          onClose={handleCloseViewer}
          file={currentFile}
        />
      ) : (
        <ModalFileViewer
          isOpen={isViewOpen}
          onClose={handleCloseViewer}
          file={currentFile}
        />
      )}
    </Box>
  );
}

export { FilePreviewHorizontalScroll };
