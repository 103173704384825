import { Box } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import {
  useDeleteBimMutation,
  useGetBimsByProjectQuery,
} from "../../../features/project/modules/bim";
import { ModalWithAction, TitleWidgets } from "../../shared";
import { BimTable } from "./BimTable";
import { ModalAddBim } from "./ModalAddBim";
import { PopUpAlert } from "../../PopUpAlert";
import { useGetProjectByIdQuery } from "../../../features/project/projectApiSlice";

function Bim() {
  const { idProject } = useParams();

  const { t: tBim } = useTranslation("bim");
  const { t: tGeneral } = useTranslation("general");
  const [showAdd, setShowAdd] = useState(false);
  const [selectedBims, setSelectedBims] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);

  const { data: project, isLoading: isLoadingProject } = useGetProjectByIdQuery(
    idProject,
    {
      skip: idProject === null,
    },
  );

  const [deleteBim] = useDeleteBimMutation();

  const {
    data: bimData,
    isLoading: isLoadingBim,
    isFetching,
  } = useGetBimsByProjectQuery(idProject, {
    skip: !idProject,
  });

  const isLoading = useMemo(
    () => isLoadingBim || isLoadingProject,
    [isLoadingBim, isLoadingProject],
  );

  const handleDeleteBim = async () => {
    await deleteBim({ ids: Object.keys(selectedBims) });
    setIsDeleting(false);
    setSelectedBims({});
    PopUpAlert("success", tGeneral("deletedSuccessfully"), tGeneral("success"));
  };

  return (
    <Box>
      <TitleWidgets
        title="BIM"
        moduleName="bim"
        addFunction={() => {
          if (
            (!isLoading || !isFetching) &&
            project?.bimLimit > bimData.length
          ) {
            setShowAdd(true);
          } else {
            Swal.fire({
              icon: "warning",
              title: tGeneral("errorBimUploadLimitTitle"),
              text: tGeneral("errorBimUploadLimitDescription"),
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }}
        showAdd
        showDelete={Object.keys(selectedBims).length > 0}
        onDelete={() => setIsDeleting(true)}
        showRestore={false}
      />
      <BimTable
        bimData={bimData}
        isLoading={isLoading}
        isFetching={isFetching}
        selectedBims={selectedBims}
        setSelectedBims={setSelectedBims}
      />
      <ModalAddBim isOpen={showAdd} onClose={() => setShowAdd(false)} />
      <ModalWithAction
        title={tBim("deleteBim")}
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        actionLabel={tGeneral("delete")}
        content={tBim("deleteBimConfirmation")}
        action={handleDeleteBim}
      />
    </Box>
  );
}

export { Bim };