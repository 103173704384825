export const bim = {
  addBim: "Agregar BIM",
  progressHelp:
    "Los archivos deben ser procesador para su visualización, este proceso puede tomar varios minutos.",
  errorUpload:
    "Hubo un error al subir el archivo, por favor contacta a soporte.",
  pendingUpload:
    "El archivo se está subiendo, esto puede tomar unos minutos, por favor regresa más tarde.",
  successUpload: "El archivo se subió exitosamente.",
  deleteBim: "Eliminar BIM",
  deleteBimConfirmation: "¿Estás seguro de que quieres eliminar el BIM?",
};
