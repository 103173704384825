import { Edit, Info } from "@mui/icons-material";
import { Box, IconButton, Switch, Tooltip, Typography } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_EN as MRTLocalizationEn } from "material-react-table/locales/en";
import { MRT_Localization_ES as MRTLocalizationEs } from "material-react-table/locales/es";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useAbility } from "@casl/react";
import { selectLocale } from "../../../features/preferences/preferencesSlice";
import { useChangeProjectUserStatusMutation } from "../../../features/project/modules/directory/projectDirectoryApiSlice";
import Can from "../../../utils/can";
import stringUtil from "../../../utils/string";
import { PopUpAlert } from "../../PopUpAlert";
import { ModalWithAction } from "../../shared";
import UserAvatar from "../../userAvatars/UserAvatar";
import { ProjectDirectoryFilters } from "./ProjectDirectoryFilters";
import CaslContext from "../../../utils/caslContext";

const TABLE_FONT_SIZE = 14;

function ProjectDirectoryTable({
  data = [],
  onInfoClick = () => {},
  onEditClick = () => {},
  setFilters,
  isLoading,
}) {
  const ability = useAbility(CaslContext);
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const id = "material-react-table";

  const [height, setHeight] = useState("600px");
  const [changeStatusUserId, setChangeStatusUserId] = useState(null);

  const updateHeight = () => {
    const table = document.getElementById(id);
    if (table) {
      const parent = table.parentNode.parentNode;
      const parentTop = parent.getBoundingClientRect().top;
      const parentBottom = parentTop + parent.offsetHeight;
      setHeight(`${table.offsetHeight + window.innerHeight - parentBottom}px`);
    }
  };

  const users = useMemo(() => {
    return data.map((user) => ({
      ...user,
      companyName: user?.idCompany ? user?.companyName : "N/A",
    }));
  }, [data]);

  console.log("users", users);

  useEffect(() => {
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const [
    changeUserStatus,
    { isLoading: isChangeStatusLoading, isSuccess, isError, error },
  ] = useChangeProjectUserStatusMutation();

  const handleUpdateUserStatus = () => {
    changeUserStatus({ idProject, idUser: changeStatusUserId });
  };

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert("success", tGeneral("Done"), tGeneral("updatedSuccessfully"));
      setChangeStatusUserId(null);
    }

    if (isError) {
      PopUpAlert("error", "Error", error.message);
      setChangeStatusUserId(null);
    }
  }, [isSuccess, isError, error, tGeneral]);

  const locale = useSelector(selectLocale);
  const columns = useMemo(() => {
    const cols = [
      {
        header: tGeneral("name"),
        id: "name",
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        enableGrouping: false,
        size: 220,
        Cell: ({ cell, row }) => {
          const user = row.original;
          return (
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              gap={1}
            >
              <UserAvatar
                sx={{ m: 10 }}
                key={user?.id}
                name={`${stringUtil.capitalizeFirstLetter(
                  user?.firstName?.trim(),
                )} ${stringUtil.capitalizeFirstLetter(user?.lastName?.trim())}`}
                size="Normal"
                url={user?.urlAvatar}
              />
              <Typography
                fontSize={TABLE_FONT_SIZE}
              >{`${stringUtil.capitalizeFirstLetter(
                cell.getValue(),
              )}`}</Typography>
            </Box>
          );
        },
      },
      {
        header: tGeneral("position"),
        accessorKey: "position",
        enableGrouping: false,
        size: 100,
        Cell: ({ cell, row }) => {
          const position = cell.getValue() || "N/A";
          return (
            <Typography
              fontSize={TABLE_FONT_SIZE}
              noWrap
              textOverflow="ellipsis"
            >{`${stringUtil.capitalizeFirstLetter(position)}`}</Typography>
          );
        },
      },
      {
        header: tGeneral("email"),
        accessorKey: "email",
        enableGrouping: false,
        size: 250,
        Cell: ({ cell, row }) => {
          return (
            <a href={`mailto:${cell.getValue()}`}>
              <Typography
                fontSize={TABLE_FONT_SIZE}
                sx={{
                  textDecoration: "underline",
                  textTransform: "lowercase",
                  color: "#009DFF",
                }}
              >
                {`${cell.getValue()}`}
              </Typography>
            </a>
          );
        },
      },
      {
        header: tGeneral("phone"),
        accessorKey: "phone",
        enableGrouping: false,
        Cell: ({ cell, row }) => {
          return (
            <a href={`tel:${cell.getValue()}`}>
              <Typography
                fontSize={TABLE_FONT_SIZE}
              >{`${cell.getValue()}`}</Typography>
            </a>
          );
        },
        size: 120,
        minSize: 0,
      },
      {
        header: tGeneral("company"),
        accessorKey: "idCompany",
        size: 140,
        Cell: ({ cell, row }) => {
          return (
            <Typography fontSize={TABLE_FONT_SIZE}>
              {`${stringUtil.capitalizeFirstLetter(row.original.companyName)}`}
            </Typography>
          );
        },
        GroupedCell: ({ cell, row }) => (
          <Box>
            <Typography
              fontSize={TABLE_FONT_SIZE}
              color="black"
              fontWeight="bold"
            >{`${stringUtil.capitalizeFirstLetter(row.original.companyName)} (${
              row.subRows?.length
            })`}</Typography>
          </Box>
        ),
      },
    ];

    if (ability.can("editUser", "directory")) {
      cols.push({
        header: tGeneral("active"),
        accessorKey: "bActive",
        enableGrouping: false,
        Cell: ({ cell, row }) => (
          <Switch
            checked={cell.getValue()}
            onChange={() => setChangeStatusUserId(row.original.id)}
          />
        ),
        size: 100,
      });
    }

    return cols;
  }, [ability.rules, tGeneral]);

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={users}
        enableRowActions
        enableColumnResizing
        enableGrouping
        enableHiding
        enablePagination
        enableBottomToolbar
        enableStickyHeader
        enableDensityToggle={false}
        state={{
          isLoading,
        }}
        renderTopToolbar={() => (
          <ProjectDirectoryFilters setFilters={(val) => setFilters(val)} />
        )}
        localization={locale === "es" ? MRTLocalizationEs : MRTLocalizationEn}
        initialState={{
          density: "comfortable",
          expanded: true, // expand all groups by default
          grouping: ["idCompany"], // an array of columns to group by by default (can be multiple)
          sorting: [{ id: "name", desc: false }], // sort by firstName by default
          pagination: { pageSize: 100 },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
            size: 120,
            header: tGeneral("actions"),
          },
        }}
        positionActionsColumn="last"
        renderRowActions={({ row }) => (
          <Box sx={{ display: "flex", gap: "5px", justifyContent: "center" }}>
            <Can I="view" a="directory">
              <Tooltip arrow placement="left" title={tGeneral("info")}>
                <IconButton
                  onClick={() => {
                    onInfoClick(row.original);
                  }}
                  sx={{
                    color: "black",
                  }}
                  data-tour="view-user-1"
                >
                  <Info />
                </IconButton>
              </Tooltip>
            </Can>
            <Can I="editUser" a="directory">
              <Tooltip arrow placement="right" title={tGeneral("edit")}>
                <IconButton
                  onClick={() => {
                    onEditClick(row.original);
                  }}
                  sx={{
                    color: "black",
                  }}
                  data-tour="edit-user-1"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            </Can>
          </Box>
        )}
        muiTableBodyProps={{ sx: { display: "block" } }}
        muiTableHeadCellProps={{
          sx: { fontSize: 12, textTransform: "uppercase" },
        }}
        muiTableProps={{
          id,
          sx: { height },
        }}
        layoutMode="grid"
      />
      <ModalWithAction
        title={tGeneral("changeStatus")}
        actionLabel={tGeneral("accept")}
        content={tGeneral("changeStatusContent")}
        isOpen={Boolean(changeStatusUserId)}
        onClose={() => setChangeStatusUserId(null)}
        action={handleUpdateUserStatus}
        isLoading={isChangeStatusLoading}
      />
    </>
  );
}

export { ProjectDirectoryTable };
