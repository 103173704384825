import { Add, Check, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import {
  useCreateAlbumMutation,
  useGetAllProjectAlbumsQuery,
} from "../../../../features/project/imageApiSlice";
import {
  FilePreviewHorizontalScroll,
  TooltipIconButton,
} from "../../../shared";
import { UploadPhotoLocationSelect } from "./UploadPhotoLocationSelect";
import { setUploadingFiles } from "../../../../features/drawings/drawingsSlice";

function UploadPhoto({ isOpen, onClose, album = null }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tPhotos } = useTranslation("photos");
  const [selectedLocation, setSelectedLocation] = React.useState(null);
  const [selectedAlbum, setSelectedAlbum] = React.useState(album);
  const [selectedFiles, setSelectedFiles] = React.useState();
  const [newAlbum, setNewAlbum] = React.useState(null);
  const isAlbumAdding = newAlbum !== null;

  const { idProject } = useParams();
  const dispatch = useDispatch();

  const { data: albums, isLoading: isAlbumsLoading } =
    useGetAllProjectAlbumsQuery({ projectId: idProject });

  const [createAlbum] = useCreateAlbumMutation();

  const upload = async () => {
    dispatch(
      setUploadingFiles(
        selectedFiles.map((file) => ({
          file,
          idProject,
          selectedAlbum,
          selectedLocation,
          type: "image",
        })),
      ),
    );
    onClose();
  };

  const handleRemoveSelectedFile = (file) => {
    setSelectedFiles((prev) =>
      prev.filter((f) => f.name !== file.name && f.url !== file.url),
    );
  };

  const handleAddAlbum = async () => {
    await createAlbum({
      name: newAlbum,
      idProject,
      bPrivate: false,
    }).unwrap();
    setNewAlbum(null);
  };

  const handleSelectAlbum = (e) => {
    setSelectedAlbum(e.target.value);
  };

  const handleSelectLocation = (value) => {
    setSelectedLocation(value);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          width: "45%",
          height: "fit-content",
          borderRadius: "20px",
          p: "25px 40px",
        }}
      >
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="space-between"
          mb="20px"
        >
          <Typography fontSize="1.5625rem" fontWeight="bold" flex={1}>
            {tPhotos("newPhotos")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
          <TooltipIconButton
            icon={<Check />}
            label={tGeneral("add")}
            onClick={upload}
            disabled={!selectedFiles}
            data-tour="add-photos-5"
          />
        </Box>
        <Divider />
        <Box mt="35px" height="80%">
          <Box data-tour="add-photos-2">
            <UploadPhotoLocationSelect
              onChange={handleSelectLocation}
              selectedLocation={selectedLocation}
            />
          </Box>
          <FormControl fullWidth sx={{ mb: "20px" }} data-tour="add-photos-3">
            <InputLabel
              id="album-select-label"
              sx={{ color: "black", fontWeight: "bold" }}
            >
              {tPhotos("album")}
            </InputLabel>
            {isAlbumsLoading ? (
              <CircularProgress color="primary" />
            ) : (
              <Select
                labelId="album-select-label"
                id="album-select"
                value={selectedAlbum}
                label="Album"
                onChange={handleSelectAlbum}
                onClose={() => {
                  setNewAlbum(null);
                }}
              >
                {albums?.results.map((album) => (
                  <MenuItem
                    onClick={() => {
                      if (album.id === selectedAlbum) {
                        setSelectedAlbum(null);
                      }
                    }}
                    key={album.id}
                    value={album.id}
                  >
                    {album.name}
                  </MenuItem>
                ))}
                <Box display="flex" columnGap="10px" px="10px">
                  {isAlbumAdding ? (
                    <OutlinedInput
                      fullWidth
                      value={newAlbum}
                      onKeyDown={(e) => {
                        e.stopPropagation();
                      }}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => setNewAlbum(e.target.value)}
                      size="small"
                      endAdornment={
                        <Button
                          onClick={handleAddAlbum}
                          variant="contained"
                          size="small"
                        >
                          {tGeneral("add")}
                        </Button>
                      }
                    />
                  ) : (
                    <Button
                      variant="text"
                      startIcon={<Add />}
                      fullWidth
                      style={{
                        justifyContent: "flex-start",
                        paddingLeft: "3%",
                        color: "black",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setNewAlbum("");
                      }}
                    >
                      {tPhotos("addAlbum")}
                    </Button>
                  )}
                </Box>
              </Select>
            )}
          </FormControl>
          <Box data-tour="add-photos-4">
            <FilePreviewHorizontalScroll
              files={selectedFiles}
              onDelete={handleRemoveSelectedFile}
              onAddPhotos={(files) => setSelectedFiles(files)}
              accept="image-videos"
            />
          </Box>
        </Box>
      </Card>
    </Modal>
  );
}

export { UploadPhoto };
