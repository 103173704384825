/* eslint-disable react/prop-types */
import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@mui/material";
import heic2any from "heic2any";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { selectLocale } from "../../../features/preferences/preferencesSlice";
import {
  selectImageViewerOpen,
  selectSampleImage,
} from "../../../features/project/modules/rfiSlice";
import { selectCurrentGuideMe } from "../../../features/project/projectSlice";
import Can from "../../../utils/can";
import { EmptyState } from "../../shared";
import { useAppTourContext } from "../../supportButton/context/context";
import { PhotoViewer } from "./PhotoViewer";

function GalleryImage({
  isSelected,
  image,
  index,
  setSelectedImages,
  setSelectedImage,
  date,
  allIdsImage = [],
}) {
  const [isHover, setIsHover] = React.useState(false);
  const [imageSrc, setImageSrc] = React.useState("");
  const [isError, setIsError] = useState(false);

  const fileType = useMemo(() => {
    if (image) {
      return image.name
        .substring(image.name.lastIndexOf(".") + 1)
        .toLowerCase();
    }
    return null;
  }, [image]);

  const isVideo = useMemo(() => {
    if (fileType) {
      return (
        ["flv", "avi", "mp4", "mov", "wmv", "avchd", "3gp", "mpeg"].indexOf(
          fileType,
        ) > -1
      );
    }

    return false;
  }, [fileType]);

  const setHeicImage = useCallback(async () => {
    const blobImage = await (await fetch(image.src)).blob();
    const heicBlob = await heic2any({
      blob: blobImage,
      toType: "image/jpeg",
      quality: 1,
    });
    const heicSrc = URL.createObjectURL(heicBlob);
    setImageSrc(heicSrc);
  }, [image]);

  useEffect(() => {
    if (image && fileType) {
      if (fileType === "heic" || fileType === "heif") {
        setHeicImage();
      } else {
        setImageSrc(image.src);
      }
    }
  }, [image, setHeicImage, fileType]);

  if (isError) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        borderRadius: "1rem",
      }}
      component="div"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      data-tour="view-photos-1"
    >
      <Checkbox
        data-tour="recycle-photos-2"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 10,
        }}
        checked={isSelected}
        value={isSelected}
        onChange={(e) => {
          e.stopPropagation();
          setSelectedImages(image.id, date, allIdsImage);
        }}
      />
      <LazyLoad>
        <Box
          onClick={() => {
            setSelectedImage({
              id: index,
              image,
              date,
            });
          }}
        >
          {isVideo ? (
            <video
              style={{ height: "180px", width: "200px", cursor: "pointer" }}
              src={`${image.url}#t=0.1`}
              preload="auto"
            />
          ) : (
            <img
              className="bp-image-gallery-container"
              src={image.thumbnailUrl ?? image.url}
              alt={image.name}
              onError={() => setIsError(true)}
              style={{
                cursor: "pointer",
                maxWidth: "contain",
                width: "200px",
                height: "180px",
                marginLeft: "0px",
                marginTop: "0px",
                transition: "transform .2s",
                objectFit: "cover",
                borderRadius: "1rem",
              }}
            />
          )}
        </Box>
      </LazyLoad>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          height: "14%",
          display: isHover ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
          transition: "display 0.5s",
        }}
      >
        <Typography fontSize="0.875rem" color="white">
          {moment(image.dateUploaded).format("DD MMM YYYY")}
        </Typography>
      </Box>
    </Box>
  );
}

function PhotoGallery({
  images,
  selectedImages,
  setSelectedImages,
  setSelectedAllImages,
  selectedDates,
  add,
  userRole,
  hasFilters = false,
  isRecycle = false,
  showSelectAll = false,
}) {
  const { idPhoto } = useParams();
  const { t: tPhotos } = useTranslation("photos");
  const { t: tGeneral } = useTranslation("general");
  const [selectedImageIndex, setSelectedImageIndex] = React.useState(null);
  const [selectedImageGuideMe, setSelectedImageGuideMe] = React.useState(null);
  const [sortedImages, setSortedImages] = React.useState({});

  const currentGuideMe = useSelector(selectCurrentGuideMe);
  const {
    state: { tourActive, stepIndex, steps },
    setState,
  } = useAppTourContext();

  const sampleImage = useSelector(selectSampleImage);
  const imageViewerOpen = useSelector(selectImageViewerOpen);
  const allImages = useMemo(() => {
    if (images) {
      return images.results.reduce((prev, curr) => {
        return [...prev, ...curr.photos];
      }, []);
    }
    return [];
  }, [images]);

  useEffect(() => {
    if (imageViewerOpen === true) {
      setSelectedImageGuideMe(sampleImage);
    } else {
      setSelectedImageGuideMe(null);
    }
  }, [imageViewerOpen, sampleImage]);

  const previousImage = () => {
    if (selectedImageIndex === 0) return;
    setSelectedImageIndex((prev) => prev - 1);
  };

  const nextImage = () => {
    if (selectedImageIndex === allImages.length - 1) return;
    setSelectedImageIndex((prev) => prev + 1);
  };

  const handleSelectImage = (value) => {
    const newIndex = allImages.findIndex((img) => img.id === value.image.id);
    setSelectedImageIndex(newIndex);
  };

  useEffect(() => {
    if (
      tourActive &&
      (stepIndex === 1 || stepIndex === steps.length - 2) &&
      currentGuideMe === "photos-view"
    ) {
      setSelectedImageIndex(0);
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }

    if (
      tourActive &&
      (stepIndex === steps.length - 1 || stepIndex === 0) &&
      currentGuideMe === "photos-view"
    ) {
      setSelectedImageIndex(null);
    }
  }, [tourActive, stepIndex, steps, currentGuideMe]);

  const renderGalleryImage = (image, index, date, idsImg) => {
    const isSelected = selectedImages?.indexOf(image.id) > -1;
    return (
      <GalleryImage
        key={image.id}
        image={image}
        index={index}
        setSelectedImages={setSelectedImages}
        setSelectedImage={handleSelectImage}
        isSelected={isSelected}
        date={date}
        allIdsImage={idsImg}
      />
    );
  };

  useEffect(() => {
    if (idPhoto && allImages && allImages.length > 0) {
      setSelectedImageIndex(
        allImages.findIndex((image) => image.id === idPhoto),
      );
    }
  }, [idPhoto, allImages]);

  if (images?.results?.length === 0) {
    return (
      <EmptyState
        title={hasFilters ? tGeneral("noResults") : tPhotos("noImagesCreated")}
        content={
          hasFilters ? (
            tGeneral("noResultsMessage")
          ) : (
            <Can I="add" a="photos">
              {tPhotos("addImagesValidation")}
            </Can>
          )
        }
        module="photos"
        button={
          hasFilters ? null : (
            <Can I="add" a="photos">
              <Button variant="contained" onClick={add}>
                <Add /> {tPhotos("addPhoto")}
              </Button>
            </Can>
          )
        }
      />
    );
  }

  return (
    <Box width="100%" height="74vh">
      <Box height="100%" width="100%" sx={{ overflowY: "auto" }}>
        {showSelectAll && (
          <Box display="flex" sx={{ marginLeft: "5px", marginBottom: "10px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={images?.results?.length === 0}
                  checked={selectedImages.length === allImages.length}
                  value={selectedImages.length === allImages.length}
                  onChange={(e) => {
                    setSelectedAllImages(e.target.checked, images.results);
                  }}
                />
              }
              label={tGeneral("selectAll")}
            />
          </Box>
        )}

        {images?.results?.length > 0 ? (
          images.results.map(({ date, photos }) => (
            <Box key={date} width="100%">
              {date && (
                <Box
                  display="flex"
                  sx={{ marginLeft: "5px", marginBottom: "5px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          selectedDates?.indexOf(date) > -1 &&
                          photos
                            .map((img) => img.id)
                            .every((idImg) => selectedImages.includes(idImg))
                        }
                        value={selectedDates?.indexOf(date) > -1}
                        onChange={() => {
                          const ids = photos.map((img) => img.id);
                          if (date === "") {
                            date = "X";
                          }
                          setSelectedImages(ids, date);
                        }}
                      />
                    }
                    label={date}
                  />
                </Box>
              )}

              <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fill, 200px)"
                sx={{ justifyContent: "space-around" }}
              >
                {photos?.map((image, index) =>
                  renderGalleryImage(
                    image,
                    index,
                    date,
                    photos.map((img) => img.id),
                  ),
                )}
              </Box>
            </Box>
          ))
        ) : (
          <Box
            display="flex"
            width="100%"
            justifyContent="center"
            alignSelf="center"
          >
            <CircularProgress color="primary" />
          </Box>
        )}
        {selectedImageIndex !== null && (
          <PhotoViewer
            isOpen={selectedImageIndex !== null}
            onClose={() => {
              setSelectedImageIndex(null);
            }}
            imageId={selectedImageIndex}
            image={allImages[selectedImageIndex]}
            total={allImages.length}
            nextImage={nextImage}
            previousImage={previousImage}
            isRecycle={isRecycle}
          />
        )}
        {selectedImageGuideMe && (
          <PhotoViewer
            isOpen={selectedImageGuideMe !== null}
            onClose={() => {
              setSelectedImageGuideMe(null);
            }}
            imageId={selectedImageGuideMe.id}
            image={selectedImageGuideMe}
            total={sortedImages[selectedImageGuideMe?.date]?.images?.length}
            nextImage={nextImage}
            previousImage={previousImage}
          />
        )}
      </Box>
    </Box>
  );
}

export { PhotoGallery };
