import { tour } from "./tour";

const inspections = {
  // general tour
  ...tour,
  moduleElement: "Inspecciones",
  subModuleElement: "Plantillas",
  // ADD - guide me steps
  step1_inspectionsAdd_title: "1. Agregar.",
  step1_inspectionsAdd_text: "Haz click en el icono de +",
  step2_inspectionsAdd_title: "2. Título.",
  step2_inspectionsAdd_text: "Escribe un titulo para la inspección.",
  step3_inspectionsAdd_title: "3. Fecha de entrega.",
  step3_inspectionsAdd_text: "Determina la fecha de entrega de la inspección",
  step4_inspectionsAdd_title: "4. Estado.",
  step4_inspectionsAdd_text_1_1: "Elige el estado",
  step4_inspectionsAdd_text_1_2:
    "EN REVISIÓN para inspecciones en dicho proceso.",
  step4_inspectionsAdd_text_2_1: "Elige el estado",
  step4_inspectionsAdd_text_2_2: "BORRADOR para guardar como borrador.",
  step5_inspectionsAdd_title: "5. Privacidad.",
  step5_inspectionsAdd_text:
    "Selecciona si la inspección será visible para todos o no.",
  step5_inspectionsAdd_text_1:
    "Cualquier persona con acceso al módulo verá tu inspección.",
  step5_inspectionsAdd_text_2:
    "Solo los participantes podrán ver esta inspección",
  step6_inspectionsAdd_title: "6. Ubicación.",
  step6_inspectionsAdd_text: "Determina cuál es la ubicación de la inspección.",
  step7_inspectionsAdd_title: "7. Seleccionar plantilla.",
  step7_inspectionsAdd_text: "Selecciona la plantilla para la inspección.",
  step8_inspectionsAdd_title: "8. Creadores.",
  step8_inspectionsAdd_text:
    "Selecciona los usuarios que tendrán los mismos permisos que usted.",
  step9_inspectionsAdd_title: "9. Asignados.",
  step9_inspectionsAdd_text:
    "Selecciona a las personas que aprobarán la inspección.",
  step10_inspectionsAdd_title: "10. Lista de distribución.",
  step10_inspectionsAdd_text:
    "Selecciona las personas que recibirán notificación de la inspección.",
  step11_inspectionsAdd_title: "11. Crear",
  step11_inspectionsAdd_text: "Haz click aqui para crear la inspección.",
  step_final_inspectionsAdd: "Haz aprendido cómo crear una inspección.",
  // ADD TEMPLATES - guide me steps
  step1_inspectionTemplatesAdd_title: "1. Menú del modulo de Inspecciones.",
  step1_inspectionTemplatesAdd_text: "Haz click aquí para ver las opciones.",
  step2_inspectionTemplatesAdd_title: "2. Plantillas.",
  step2_inspectionTemplatesAdd_text:
    "Haz clic aquí para abrir la sección Plantillas.",
  step3_inspectionTemplatesAdd_title: "3. Agregar.",
  step3_inspectionTemplatesAdd_text: "Haz click en el icono de +",
  step4_inspectionTemplatesAdd_title: "4. Título.",
  step4_inspectionTemplatesAdd_text: "Escribe un título para la plantilla.",
  step5_inspectionTemplatesAdd_title: "5. Código.",
  step5_inspectionTemplatesAdd_text: "Define un código para la plantilla.",
  step6_inspectionTemplatesAdd_title: "6. Categoría.",
  step6_inspectionTemplatesAdd_text:
    "Selecciona una categoría para la plantilla.",
  step7_inspectionTemplatesAdd_title: "7. Descripción.",
  step7_inspectionTemplatesAdd_text: "Agrega una descripción.",
  step8_inspectionTemplatesAdd_title: "8. Lista de inspecciones.",
  step8_inspectionTemplatesAdd_text:
    "Agrega un nuevo elemento ha inspeccionar haciendo click en Agregar Sección.",
  step8_1_inspectionTemplatesAdd_title: "8.1 Título.",
  step8_1_inspectionTemplatesAdd_text:
    "Escribe un título para la sección ha inspeccionar.",
  step8_1_1_inspectionTemplatesAdd_title: "8.1.1 Agregar elemento.",
  step8_1_1_inspectionTemplatesAdd_text:
    "Haz click para agregar un elemento ha inspeccionar.",
  step8_1_2_inspectionTemplatesAdd_title: "8.1.2 Título.",
  step8_1_2_inspectionTemplatesAdd_text: "Escribe un título para el elemento.",
  step8_1_3_inspectionTemplatesAdd_title: "8.1.3 Descripción.",
  step8_1_3_inspectionTemplatesAdd_text:
    "Escribe una descripción para el elemento.",
  step9_inspectionTemplatesAdd_title: "9. Añadir.",
  step9_inspectionTemplatesAdd_text: "Haz click aqui para añadir el elemento.",
  step10_inspectionTemplatesAdd_title: "10. Guardar.",
  step10_inspectionTemplatesAdd_text:
    "Haz click aqui para guardar la plantilla.",
  step_final_inspectionTemplatesAdd: "Haz aprendido cómo crear una plantilla.",

  add: "Agregar Inspección",
  edit: "Editar Inspección",
  guideMe: "Guíame",
  inspection_recycle_bin: "Papelera (Inspecciones)",
  inspections: "Inspecciones",
  meeting: "Reunión",
  no_inspections_text:
    "Si quieres agregar una, te invitamos a hacer clic en el botón Agregar Inspección para comenzar el proceso",
  no_inspections_title: "No hay inspecciones creadas en este momento",
  title: "Inspecciones",
  workflow: "Flujo de trabajo",
  itemsInspected: "Elementos Inspeccionados",
  pass: "Pasa",
  fail: "Falla",
  na: "N/A",
  sections: "Secciones",
  items: "Elementos",
  createTask: "Crear Tarea",
  add_button_tour: "Crear una nueva inspección",
  select_template: "Seleccionar Plantilla",
  add_section: "Agregar Sección",
  no_sections: "No hay secciones en esta inspección.",
  approve: "Aprobar",
  checkAndSign: "Revisar y firmar",
  createRevision: "Crear revisión",
  observation: "Observación",
  addItem: "Agregar elemento",
  deleteInspection: "¿Está seguro que desea borrar esta inspección?",
  Revision: "Revisión",
};

export { inspections };
