import { apiSlice } from "../../api/apiSlice";

export const sesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendToEmail: builder.mutation({
      query: (body) => ({
        url: "/ses/sendMail",
        method: "POST",
        body,
      }),
    }),
    sendToEmailFile: builder.mutation({
      query: (body) => ({
        url: "/ses/sendMailFile",
        method: "POST",
        body,
      }),
    }),
    sendToEmailExport: builder.mutation({
      query: ({ idEnterprise, idProject, idObject, body }) => {
        return {
          url: `/ses/sendMail/${idEnterprise}/${idProject}/${idObject}`,
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useSendToEmailMutation,
  useSendToEmailFileMutation,
  useSendToEmailExportMutation,
} = sesApiSlice;
