import { tour } from "./tour";

const inspections = {
  // general tour
  ...tour,
  moduleElement: "Inspections",
  subModuleElement: "Templates",
  // ADD - guide me steps
  step1_inspectionsAdd_title: "1. Add.",
  step1_inspectionsAdd_text: "Click on the + icon to add a new template.",
  step2_inspectionsAdd_title: "2. Title.",
  step2_inspectionsAdd_text: "Enter inspections's title.",
  step3_inspectionsAdd_title: "3. Delivery date.",
  step3_inspectionsAdd_text: "Choose the delivery date for the inspection.",
  step4_inspectionsAdd_title: "4. Status.",
  step4_inspectionsAdd_text_1_1: "Choose the status",
  step4_inspectionsAdd_text_1_2: "In Revision for inspections in that status.",
  step4_inspectionsAdd_text_2_1: "Choose the status",
  step4_inspectionsAdd_text_2_2:
    "DRAFT status to keep a draft of the the inspection.",
  step5_inspectionsAdd_title: "5. Privacy.",
  step5_inspectionsAdd_text: "Choose the type of inspection privacy",
  step5_inspectionsAdd_text_1:
    "Anyone with access to the module will see the inspection.",
  step5_inspectionsAdd_text_2:
    "Only participants will be able to see this inspection.",
  step6_inspectionsAdd_title: "6. Location.",
  step6_inspectionsAdd_text: "Select the location for the inspection.",
  step7_inspectionsAdd_title: "7. Choose the template.",
  step7_inspectionsAdd_text:
    "Select the template to be used by the inspection.",
  step8_inspectionsAdd_title: "8. Creators.",
  step8_inspectionsAdd_text:
    "Choose the users who will have the same permissions as you",
  step9_inspectionsAdd_title: "9. Assigned.",
  step9_inspectionsAdd_text:
    "Select the people who will approve the inspection.",
  step10_inspectionsAdd_title: "10. Distribution list.",
  step10_inspectionsAdd_text:
    "Select the people you want to notify about this inspection.",
  step11_inspectionsAdd_title: "11. Create.",
  step11_inspectionsAdd_text: "Click here to create the inspection.",
  step_final_inspectionsAdd: "You have learned how to create a inspection.",
  // ADD TEMPLATES - guide me steps
  step1_inspectionTemplatesAdd_title: "1. Inspection Module Menu.",
  step1_inspectionTemplatesAdd_text: "Click here to see the options.",
  step2_inspectionTemplatesAdd_title: "2. Templates.",
  step2_inspectionTemplatesAdd_text:
    "Click here to open the section templates.",
  step3_inspectionTemplatesAdd_title: "3. Add.",
  step3_inspectionTemplatesAdd_text: "Click in the icon +",
  step4_inspectionTemplatesAdd_title: "4. Title.",
  step4_inspectionTemplatesAdd_text: "Enter the inspection template title",
  step5_inspectionTemplatesAdd_title: "5. Code.",
  step5_inspectionTemplatesAdd_text: "Enter the inspection template code.",
  step6_inspectionTemplatesAdd_title: "6. Category.",
  step6_inspectionTemplatesAdd_text: "Select the category.",
  step7_inspectionTemplatesAdd_title: "7. Description.",
  step7_inspectionTemplatesAdd_text: "Enter the description.",
  step8_inspectionTemplatesAdd_title: "8. Inspection's list",
  step8_inspectionTemplatesAdd_text:
    "Add a new item to be inspected making click in Add section.",
  step8_1_inspectionTemplatesAdd_title: "8.1 Title.",
  step8_1_inspectionTemplatesAdd_text: "Enter the title for the section.",
  step8_1_1_inspectionTemplatesAdd_title: "8.1.1 Add element.",
  step8_1_1_inspectionTemplatesAdd_text:
    "Click to add a element to be inspected.",
  step8_1_2_inspectionTemplatesAdd_title: "8.1.2 Title.",
  step8_1_2_inspectionTemplatesAdd_text: "Enter the title.",
  step8_1_3_inspectionTemplatesAdd_title: "8.1.3 Description.",
  step8_1_3_inspectionTemplatesAdd_text:
    "Enter the description for the element.",
  step9_inspectionTemplatesAdd_title: "9. Add.",
  step9_inspectionTemplatesAdd_text: "Click to add the element.",
  step10_inspectionTemplatesAdd_title: "10. Save.",
  step10_inspectionTemplatesAdd_text: "Click to save the template.",
  step_final_inspectionTemplatesAdd:
    "You have learned how to create a template.",

  add: "Add Inspection",
  edit: "Edit Inspection",
  guideMe: "Guide me",
  inspection_recycle_bin: "Inspections - Recycle Bin",
  inspections: "Inspections",
  meeting: "Meeting",
  no_inspections_text:
    "If you want to add one, we invite you to click the add Inspection button to start the process",
  no_inspections_title: "No Inspections have been created at the moment",
  title: "Inspections",
  workflow: "Workflow",
  itemsInspected: "Items Inspected",
  pass: "Pass",
  fail: "Fail",
  na: "N/A",
  sections: "Sections",
  items: "Items",
  createTask: "Create Task",
  add_button_tour: "Create a new inspection",
  select_template: "Select Template",
  add_section: "Add Section",
  no_sections: "There are no sections in this inspection.",
  approve: "Approve",
  checkAndSign: "Check and Sign",
  createREvision: "Create revision",
  observation: "Observation",
  addItem: "Add item",
  deleteInspection: "Are you sure you want to delete this inspection?",
  Revision: "Revision",
};

export { inspections };
