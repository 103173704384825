export const bim = {
  addBim: "BIM を追加",
  progressHelp:
    "ファイルを表示するには処理する必要があります。このプロセスには数分かかる場合があります。",
  errorUpload:
    "ファイルのアップロード中にエラーが発生しました。サポートに連絡してください。",
  pendingUpload:
    "ファイルがアップロードされています。これには数分かかる場合があります。後で戻ってください。",
  successUpload: "ファイルが正常にアップロードされました。",
  deleteBim: "BIMを削除",
  deleteBimConfirmation: "BIMを削除してもよろしいですか？",
};
