import * as React from "react";
import { useTranslation } from "react-i18next";
import { PopUpAlert } from "../components/PopUpAlert";
import {
  useDeleteImageMutation,
  useRestoreImagesMutation,
} from "../features/project/imageApiSlice";

function useImageGallery(restore = false) {
  const [selectedImages, setSelectedImages] = React.useState([]);
  const [selectedDates, setSelectedDates] = React.useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

  const { t: tPhotos } = useTranslation("photos");
  const { t: tGeneral } = useTranslation("general");

  const [
    deleteImages,
    {
      isLoading: isDeleteLoading,
      isError: isDeleteError,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteImageMutation();

  const [
    restoreImages,
    {
      isLoading: isRestoreLoading,
      isError: isRestoreError,
      isSuccess: isRestoreSuccess,
    },
  ] = useRestoreImagesMutation();

  const onOpenDelete = () => setIsDeleteOpen(true);
  const onCloseDelete = () => setIsDeleteOpen(false);

  React.useEffect(() => {
    if (isDeleteSuccess || isRestoreSuccess) {
      PopUpAlert(
        "success",
        restore
          ? tPhotos("imagesRestoredSuccessfully")
          : tPhotos("imagesDeletedSuccessfully"),
      );
      onCloseDelete();
      handleClearImageGallery();
    }
    if (isDeleteError || isRestoreError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      onCloseDelete();
      handleClearImageGallery();
    }
  }, [isDeleteError, isDeleteSuccess, isRestoreError, isRestoreSuccess]);

  const handleSelectImage = (value, date, arrayImageByDate) => {
    /* NOTES
      - value is the ID of the selected image(s)
      - date is the date on which it is grouped
      - arrayImageByDate is the arrangement of ids of the images of that date,
        it is used to verify if they are all selected and to mark or unmark
        the date checkbox automatically
    */
    if (date) {
      // For gallery view (case 1)
      if (arrayImageByDate) {
        // To select images one by one
        const allImagesByDateSelected = arrayImageByDate.every((idImg) =>
          selectedImages.includes(idImg),
        );
        if (allImagesByDateSelected) {
          // All images from that date were already selected
          setSelectedImages((prev) => prev.filter((p) => p !== value)); // The image is deselected
          if (selectedDates.indexOf(date) > -1) {
            // The date was already selected
            setSelectedDates((prev) => prev.filter((p) => p !== date)); // The date is deselected
          }
        } else {
          // All images from that date are NOT YET selected
          if (selectedImages.indexOf(value) > -1) {
            // The image was already selected
            setSelectedImages((prev) => prev.filter((p) => p !== value));
          } else {
            // Select the new image and check if all the images from that date are now selected to mark it
            const newSelectedImages = [...selectedImages, value];
            setSelectedImages(newSelectedImages);
            if (
              arrayImageByDate.every((idImg) =>
                newSelectedImages.includes(idImg),
              )
            ) {
              setSelectedDates((prev) => [...prev, date]);
            }
          }
        }
      } else {
        // The date check is selected
        if (selectedDates.indexOf(date) > -1) {
          // The date was already selected
          setSelectedDates((prev) => prev.filter((p) => p !== date));
          const filteredIds = selectedImages.filter(
            (v) => value.indexOf(v) < 0,
          );
          setSelectedImages(filteredIds);
        } else {
          // The date has not yet been selected
          setSelectedDates((prev) => [...prev, date]);
          const filteredIds = value.filter(
            (v) => selectedImages.indexOf(v) < 0,
          );
          setSelectedImages((prev) => [...prev, ...filteredIds]);
        }
      }
    } else if (selectedImages.indexOf(value) > -1) {
      // For view within the album
      // An image is DESELECTED from its checkbox (case 2)
      setSelectedImages((prev) => prev.filter((p) => p !== value));
    } else {
      // For view within the album
      // An image is SELECTED from its checkbox (case 3)
      setSelectedImages((prev) => [...prev, value]);
    }
  };

  const handleSelectAllImages = (value, results) => {
    if (value) {
      setSelectedDates(results.map((result) => result.date));
      setSelectedImages(
        results.map((res) => res.photos.map((photo) => photo.id)).flat(),
      );
    } else {
      setSelectedDates([]);
      setSelectedImages([]);
    }
  };

  const handleDeleteImages = async () => {
    if (restore) {
      await restoreImages({ ids: selectedImages });
    } else {
      await deleteImages({ ids: selectedImages });
    }
  };

  const handleClearImageGallery = () => {
    setSelectedDates([]);
    setSelectedImages([]);
  };

  return {
    selectedImages,
    selectedDates,
    handleSelectImage,
    handleSelectAllImages,
    handleDeleteImages,
    isDeleteLoading,
    isDeleteOpen,
    isRestoreLoading,
    isRestoreError,
    isRestoreSuccess,
    onOpenDelete,
    onCloseDelete,
    handleClearImageGallery,
  };
}

export { useImageGallery };
