import { apiSlice } from "../../../../api/apiSlice";
import { objectToQueryString } from "../../../../utils";

export const tasksApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query({
      query: ({ idProject, bActive = true }) =>
        `/project/task/getTasks/${idProject}?bActive=${bActive}`,
      keepUnusedDataFor: 60,
      providesTags: ["Tasks"],
    }),
    getTasksCounters: builder.query({
      query: ({ idProject, ...params }) =>
        `/project/task/getTasksCounters/${idProject}${objectToQueryString({
          ...params,
        })}`,
      keepUnusedDataFor: 60,
      providesTags: ["Tasks"],
    }),
    getTasksPaginated: builder.query({
      query: ({ idProject, search, ...params }) =>
        `/project/task/getTasksPaginated/${idProject}${objectToQueryString({
          ...params,
          search,
        })}`,
      keepUnusedDataFor: 60,
      providesTags: ["Tasks"],
    }),
    createTask: builder.mutation({
      query: (body) => ({
        url: "/project/task/createTask",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Tasks", "Meetings", "Dashboard"],
    }),
    editTask: builder.mutation({
      query: (body) => ({
        url: "/project/task/editTask",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Tasks", "Meetings"],
    }),
    deleteTask: builder.mutation({
      query: (body) => ({
        url: "/project/task/deleteTask",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Tasks", "Meetings"],
    }),
    setBacklogTask: builder.mutation({
      query: (body) => ({
        url: "/project/task/setBacklogTask",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Tasks", "Dashboard"],
    }),
    setDoingTask: builder.mutation({
      query: (body) => ({
        url: "/project/task/setDoingTask",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Tasks", "Dashboard"],
    }),
    setDoneTask: builder.mutation({
      query: (body) => ({
        url: "/project/task/setDoneTask",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Tasks", "Dashboard"],
    }),
    getTaskById: builder.query({
      query: (idTask) => `/project/task/${idTask}`,
      providesTags: ["Tasks"],
    }),
    restoreTask: builder.mutation({
      query: (body) => ({
        url: "/project/task/restoreTask",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Tasks"],
    }),
    checkSubtask: builder.mutation({
      query: (body) => ({
        url: "/project/task/checkSubtask",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Tasks", "Dashboard"],
    }),
  }),
});

export const {
  useGetTasksQuery,
  useGetTasksCountersQuery,
  useGetTasksPaginatedQuery,
  useCreateTaskMutation,
  useEditTaskMutation,
  useDeleteTaskMutation,
  useSetBacklogTaskMutation,
  useSetDoingTaskMutation,
  useSetDoneTaskMutation,
  useGetTaskByIdQuery,
  useRestoreTaskMutation,
  useCheckSubtaskMutation,
} = tasksApiSlice;
