// Third-party library imports
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// Icon imports
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import RefreshIcon from "@mui/icons-material/Refresh";

// Project-specific absolute imports
import { useGetProjectSchedulesQuery } from "../../../../features/project/analyticsApiSlice";
import { selectCurrentProject } from "../../../../features/project/projectSlice";

function ProjectSchedule({ size, isMock }) {
  const { t: tAnalytics } = useTranslation("analytics");
  const currentProject = useSelector(selectCurrentProject);

  const {
    data: projectScheduleData,
    isError,
    isLoading,
    refetch,
  } = useGetProjectSchedulesQuery(currentProject?.id, {
    skip: isMock,
  });

  const handleRefetch = () => {
    refetch(); // Call refetch to reload data
  };

  // Function to transform projectScheduleData into the desired format
  const chartData = [
    {
      id: tAnalytics("progress"), // Unique identifier for each slice
      label: tAnalytics("progress"),
      value: isMock ? 78 : projectScheduleData?.totalProgress,
      color: "url(#progress-gradient)",
    },
    {
      id: tAnalytics("remaining"), // Unique identifier for each slice
      label: tAnalytics("remaining"),
      value: isMock ? 22 : 100 - projectScheduleData?.totalProgress,
      color: "#EEEEEE",
    },
  ];
  // Process remaininDays data
  const remainingDays = isMock ? 148 : projectScheduleData?.remainingDays;

  let content;
  switch (size) {
    case "sm":
    case "md":
      content = (
        <Box
          sx={{
            position: "relative",
            height: { xs: "85%", lg: "100%" },
            width: "100%",
            py: "16px",
          }}
        >
          {/* Remaining Days Box */}
          <Box sx={{ py: "8px" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
                color: "var(--Neutral-Neutral-500, #8E8E8E)",
              }}
            >
              {tAnalytics("remainingDays")}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 700,
                color: "var(--Neutral-Neutral-600, #4B4B4B)",
                mt: "6px",
              }}
            >
              {remainingDays} {tAnalytics("day", { count: remainingDays })}
            </Typography>
          </Box>

          {/* Divider */}
          <Divider sx={{ borderBottomWidth: 2 }} />

          {/* Progress Box */}
          <Box sx={{ py: "8px" }}>
            <Typography
              sx={{
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                color: "var(--Neutral-Neutral-500, #8E8E8E)",
              }}
            >
              {tAnalytics("projectAdvancement")}
            </Typography>

            {/* Progress Details Box */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* Percentage */}
              <Typography
                sx={{
                  fontSize: "48px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  color: "var(--Neutral-Neutral-700, #1F1F1F)",
                }}
              >
                {chartData[0].value}%
              </Typography>

              {/* Nivo Graph Placeholder */}
              <Box
                sx={{
                  width: 45,
                  height: 45,
                }}
              >
                <ResponsivePie
                  data={chartData}
                  margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                  innerRadius={0.7}
                  cornerRadius={2}
                  borderWidth={0}
                  startAngle={0}
                  padAngle={0}
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.2]],
                  }}
                  colors={{ datum: "data.color" }}
                  enableArcLabels={false}
                  enableArcLinkLabels={false}
                  arcLabelsSkipAngle={10}
                  defs={[
                    {
                      id: "progress-gradient",
                      type: "linearGradient",
                      colors: [
                        { offset: 0, color: "#E5E900" },
                        { offset: 100, color: "#FBCB04" },
                      ],
                    },
                  ]}
                  fill={[
                    {
                      match: {
                        id: "Progress",
                      },
                      id: "progress-gradient",
                    },
                  ]}
                />
              </Box>
            </Box>

            {/* Horizontal Graph Placeholder */}
            <Box>
              <Box
                sx={{
                  height: 8,
                  backgroundColor: "#eee",
                  borderRadius: 2,
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    width: `${chartData[0].value}%`,
                    height: "100%",
                    background: `linear-gradient(90deg, #FBCB04 0%, #E5E900 100%)`,
                    borderRadius: 2,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "var(--Neutral-Neutral-500, #8E8E8E)",
                  }}
                >
                  0%
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    color: "var(--Neutral-Neutral-500, #8E8E8E)",
                  }}
                >
                  100%
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      );
      break;
    case "lg":
    default:
      content = <></>;
  }

  if (isLoading && !isError && !isMock)
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (isError && !isLoading && !isMock)
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography
          sx={{
            color: "#8E8E8E",
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22px",
          }}
        >
          {tAnalytics("somethingWentWrong")}
        </Typography>
        <IconButton onClick={handleRefetch}>
          <RefreshIcon />
        </IconButton>
      </Box>
    );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <Box sx={{ display: "flex", gap: "8px" }}>
        <TrendingUpIcon sx={{ color: "#E5E900" }} />
        <Typography
          sx={{ fontSize: "16px", fontStyle: "normal", fontWeight: "500" }}
        >
          {tAnalytics("projectProgress")}
        </Typography>
      </Box>
      {content}
    </Box>
  );
}

export { ProjectSchedule };
