import {
  Close,
  Info,
  LockOutlined,
  LockOpen,
  MoreVert,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import moment from "moment-timezone";
import {
  useGetEnterpriseProjectsQuery,
  useGetEnterprisesQuery,
} from "../../../features/enterprise/enterpriseApiSlice";
import {
  useGetCitiesQuery,
  useGetCountriesQuery,
  useGetStatesQuery,
} from "../../../features/geolocation";
import {
  useGetProjectAdminPanelInfoQuery,
  useUpdateProjectMutation,
} from "../../../features/project/projectApiSlice";
import { setCurrentTranslation } from "../../../features/project/projectSlice";
import { useCreatePresignedUrlMutation } from "../../../features/s3/s3ApiSlice";
import Can from "../../../utils/can";
import { PopUpAlert } from "../../PopUpAlert";
import { IOSSwitch, ImgInline } from "../../shared";

const Warning = require("../../../assets/icons/warning.png");

const styles = {
  iconButtonClose: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  cancelButton: {
    width: "50%",
    color: "black",
    backgroundColor: "white",
    borderColor: "black",
    border: 1,
    ":hover": {
      color: "white",
      backgroundColor: "#b4b4b4",
    },
  },
  saveButton: {
    width: "50%",
    color: "black",
    backgroundColor: "#ffc900",
    ":hover": {
      color: "black",
      backgroundColor: "#d9ab00",
    },
  },
};

const timezones = moment.tz.names();

const formattedTimezones = timezones
  .map((timezone) => {
    const offset = moment.tz(timezone).format("Z");
    const slashIndex = timezone.indexOf("/");
    const location =
      slashIndex !== -1 ? timezone.slice(slashIndex + 1) : timezone;
    const formattedValue = `(GMT${offset}) ${location.replace(/_/g, " ")}`;
    return { timezone, offset, formattedValue };
  })
  .sort((a, b) => {
    if (a.offset !== b.offset) {
      return parseFloat(a.offset) - parseFloat(b.offset); // Sort by GMT offset
    }
    return a.timezone.localeCompare(b.timezone); // Sort alphabetically if offset is equal
  })
  .map(({ formattedValue, timezone }) => ({ formattedValue, timezone }));

function SectionTitle({ title }) {
  return [
    <Divider key={`${title}divider`} />,
    <Typography
      key={title}
      fontSize="1.125rem"
      sx={{
        bgcolor: "#FFC42D",
        display: "inline-block",
        p: "1px 15px",
        borderBottomRightRadius: "10PX",
        mb: "40px",
      }}
      width="auto"
    >
      {title}
    </Typography>,
  ];
}

function SectionDataCell({
  label,
  content,
  isEditing,
  value,
  values,
  onChange,
  icons,
  type = "text",
}) {
  const { t: tGeneral } = useTranslation("general");

  const renderInput = () => {
    if (type === "switch") {
      return (
        <Box>
          <Typography
            fontSize="1rem"
            fontWeight="500"
            color="#8E8E8E"
            paddingBottom={1}
            marginLeft="2px"
          >
            {label}
          </Typography>
          <Box
            sx={{
              borderRadius: "8px",
              border: "2px solid #E1E1E1",
              padding: "8px 16px",
            }}
          >
            <Box display="flex" alignItems="center">
              {value ? icons?.trueIcon : icons?.falseIcon}
              <Typography sx={{ ml: "12px" }}>{content}</Typography>
              <Box flex="1" />
              <IOSSwitch
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
              />
            </Box>
          </Box>
        </Box>
      );
    }

    if (type === "select") {
      return (
        <Box>
          <Typography
            fontSize="1rem"
            fontWeight="500"
            color="#8E8E8E"
            paddingBottom={1}
            marginLeft="2px"
          >
            {label}
          </Typography>
          <FormControl
            fullWidth
            variant="outlined"
            sx={{
              borderRadius: "8px",
              border: "1.6px solid #E1E1E1",
              "&:hover": {
                backgroundColor: "#E1E1E1",
              },
            }}
          >
            <Select
              value={value}
              onChange={(e) => onChange(e.target.value)}
              fullWidth
              sx={{
                "& .MuiSelect-select": {
                  padding: "8px 16px",
                },
              }}
            >
              {values.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      );
    }

    if (type === "timezones") {
      return (
        <Box>
          <Typography
            fontSize="1rem"
            fontWeight="500"
            color="#8E8E8E"
            paddingBottom={1}
            marginLeft="2px"
          >
            {label}
          </Typography>
          <FormControl
            fullWidth
            variant="outlined"
            sx={{
              borderRadius: "8px",
              border: "1.6px solid #E1E1E1",
              "&:hover": {
                backgroundColor: "#E1E1E1",
              },
            }}
          >
            <Select
              value={value}
              onChange={(e) => onChange(e.target.value)}
              fullWidth
              sx={{
                "& .MuiSelect-select": {
                  padding: "8px 16px",
                },
              }}
            >
              {values.map((item) => (
                <MenuItem key={item.timezone} value={item.timezone}>
                  {item.formattedValue}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      );
    }

    if (type === "multiline") {
      return (
        <Box minHeight="100px">
          <TextField
            value={value}
            onChange={(e) => onChange(e.target.value)}
            fullWidth
            multiline
            minRows={1}
            placeholder={tGeneral("clickToWrite")}
            sx={{
              borderRadius: "8px",
              border: "1.6px solid #E1E1E1",
              "&:hover": {
                backgroundColor: "#E1E1E1",
              },
              mb: "16px",
            }}
          />
        </Box>
      );
    }

    return (
      <Box>
        <Typography
          fontSize="1rem"
          fontWeight="500"
          color="#8E8E8E"
          paddingBottom={1}
          marginLeft="2px"
        >
          {label}
        </Typography>
        <TextField
          value={value}
          onChange={(e) => onChange(e.target.value)}
          fullWidth
          sx={{
            borderRadius: "8px",
            border: "1.6px solid #E1E1E1",
            "&:hover": {
              backgroundColor: "#E1E1E1",
            },
          }}
          inputProps={{
            style: { padding: "8px 16px" },
          }}
        />
      </Box>
    );
  };

  return (
    <Box flex={1} sx={{ maxWidth: type === "select" ? "25%" : "100%" }}>
      {isEditing ? (
        renderInput()
      ) : (
        <>
          <Typography fontSize="0.875rem" fontWeight="bold">
            {label}
          </Typography>
          <Typography fontSize="1rem" sx={{ whiteSpace: "pre-wrap" }}>
            {typeof content === "string"
              ? content
              : content?.join(", ") ?? "N/A"}
          </Typography>
        </>
      )}
    </Box>
  );
}

function ImageEdit({ src, file, onChange, alt, isEditing, help = null }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const inputRef = React.useRef(null);
  const open = Boolean(anchorEl);

  const [imageSrc, setImageSrc] = React.useState("");

  useEffect(() => {
    if (src || file) {
      let fileName = src;

      // By default the file attribute is an string until we upload an image while editing
      if (typeof file === "object") {
        fileName = file.name;
      }

      setImageSrc(fileName);
    }
  }, [src, file]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const newUrl = URL.createObjectURL(file);
    onChange(newUrl, file);
    setAnchorEl(null);
  };

  return (
    <>
      {isEditing && (
        <>
          <IconButton
            id="more-button"
            aria-controls={open ? "image-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: "rgba(200, 200, 200, 0.8)",
            }}
          >
            <MoreVert sx={{ color: "white" }} />
          </IconButton>
          {help ? (
            <Tooltip
              title={help}
              sx={{ position: "absolute", bottom: 10, right: 10 }}
            >
              <Info color="info" />
            </Tooltip>
          ) : null}
          <Menu
            id="image-menu"
            MenuListProps={{
              "aria-labelledby": "more-button",
            }}
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                inputRef.current.click();
              }}
            >
              Reemplazar imagen
            </MenuItem>
          </Menu>
          <input
            type="file"
            style={{ display: "none" }}
            ref={inputRef}
            multiple={false}
            accept="image/*"
            onChange={handleImageChange}
          />
        </>
      )}

      <ImgInline
        alt={alt}
        src={imageSrc}
        imgStyle={{ objectFit: "cover", height: "100%", width: "100%" }}
        svgStyle={{ width: "100px", height: "100px" }}
      />
    </>
  );
}

const mapItem = (item) => ({
  label: item.name,
  value: item.iso2,
});

function AdminPanelProject() {
  const { t: tGeneral } = useTranslation("general");
  const { idProject, idEnterprise } = useParams();
  const [projectAdminForm, setProjectAdminForm] = React.useState({});

  const [isEditing, setIsEditing] = React.useState(false);
  const { data: project } = useGetProjectAdminPanelInfoQuery(idProject);

  const [
    updateAdminPanelProject,
    {
      isLoading: isUpdateProjectLoading,
      isSuccess: isUpdateProjectSuccess,
      isError: isUpdateProjectError,
    },
  ] = useUpdateProjectMutation();

  // Fetching projects from get projects query
  const { data: projects } = useGetEnterpriseProjectsQuery(idEnterprise);

  const { data: enterprises } = useGetEnterprisesQuery(null);

  const [createPresignedUrl] = useCreatePresignedUrlMutation();

  const [newProjectLogo, setNewProjectLogo] = useState(null);
  const [newProjectImage, setNewProjectImage] = useState(null);

  const [originallyActive, setOriginallyActive] = useState(false);
  const [activeProjects, setActiveProjects] = useState(0);
  const [totalProjectLimit, setTotalProjectLimit] = useState(0);

  React.useEffect(() => {
    if (isUpdateProjectSuccess) {
      PopUpAlert("success", tGeneral("done"), tGeneral("updatedProject"));
      setIsEditing(false);
      setNewProjectLogo(null);
      setNewProjectImage(null);
    }
    if (isUpdateProjectError) {
      PopUpAlert("error", tGeneral("errorBig"), tGeneral("errorOccurred"));
      setIsEditing(false);
    }
  }, [isUpdateProjectError, isUpdateProjectSuccess, tGeneral]);

  const defaultProjectInfo = React.useMemo(
    () => ({
      projectId: project?.id,
      usersAvailable: project?.usersAvailable,
      projectName: project?.projectName || "",
      idEnterprise: project?.idEnterprise,
      bActive: project?.bActive,
      city: project?.city || "",
      country: project?.country || "",
      projectImage: project?.image || null,
      projectLogo: project?.logo || null,
      state: project?.state || "",
      postalCode: project?.postalCode || "",
      street: project?.street || "",
      streetNumber: project?.streetNumber || "",
      meta: project?.meta,
      architect: project?.architect,
      projectEngineer: project?.projectEngineer,
      constructionDirector: project?.constructionDirector,
      constructionManager: project?.constructionManager,
      translation: project?.translation,
      projectTimezone: project?.projectTimezone,
      description: project?.description,
    }),
    [project],
  );

  const { data: countries } = useGetCountriesQuery({}, { skip: !isEditing });
  const { data: states } = useGetStatesQuery(projectAdminForm.country, {
    skip: projectAdminForm.country === undefined || !isEditing,
  });
  const { data: cities } = useGetCitiesQuery(
    { country: projectAdminForm.country, state: projectAdminForm.state },
    {
      skip:
        projectAdminForm.state === undefined ||
        projectAdminForm.state === "" ||
        !isEditing,
    },
  );
  const countryItems = useMemo(() => {
    if (countries?.length) {
      return countries.map(mapItem);
    }
    return [];
  }, [countries]);
  const stateItems = useMemo(() => {
    if (states?.length) {
      return states.map(mapItem);
    }
    return [];
  }, [states]);

  const cityItems = useMemo(() => {
    if (cities?.length) {
      return cities.map((city) => ({ label: city.name, value: city.name }));
    }
    return [];
  }, [cities]);

  React.useEffect(() => {
    setProjectAdminForm(defaultProjectInfo);
    setOriginallyActive(project?.bActive);

    if (projects && enterprises) {
      // only get active projects
      const active = projects.filter((item) => item.bActive === true);
      setActiveProjects(active.length);
      setTotalProjectLimit(enterprises[0].projectsLimit);
    }
  }, [defaultProjectInfo, project, projects, enterprises]);

  const handleCancelEdit = () => {
    setProjectAdminForm(defaultProjectInfo);
    setIsEditing(false);
  };

  // project limit warning
  const [openProjectsLimit, setOpenProjectsLimit] = useState(false);

  // adding one more project to limit
  const [addToLimit, setAddToLimit] = useState(false);

  const dispatch = useDispatch();

  const handleUpdateBucket = async (idEnterprise, idProject, projectFile) => {
    let projectFileUrl = null;

    if (projectFile) {
      try {
        // for await (let file of messageFiles) {
        const bodyLogoFiles = {
          idEnterprise,
          idProject,
          moduleName: "admin panel",
          name: projectFile.name,
        };

        const presignedLogoUrl =
          await createPresignedUrl(bodyLogoFiles).unwrap();

        await fetch(presignedLogoUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: projectFile,
        });

        const filesUrl = presignedLogoUrl.split("?")[0];
        projectFileUrl = filesUrl;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
    return projectFileUrl;
  };

  const handleSave = async () => {
    const filesLogoUrl = await handleUpdateBucket(
      projectAdminForm.idEnterprise,
      projectAdminForm.projectId,
      projectAdminForm.projectLogoFile,
    );

    const filesCoverUrl = await handleUpdateBucket(
      projectAdminForm.idEnterprise,
      projectAdminForm.projectId,
      projectAdminForm.projectImageFile,
    );

    const country = countryItems.find(
      (ci) => ci.value === projectAdminForm.country,
    )?.label;
    const state = stateItems.find(
      (si) => si.value === projectAdminForm.state,
    )?.label;

    const body = {
      idProject: projectAdminForm.projectId,
      idEnterprise,
      country,
      state,
    };

    if (projectAdminForm.projectName) {
      body.projectName = projectAdminForm.projectName;
    }
    if (projectAdminForm.projectTimezone) {
      body.projectTimezone = projectAdminForm.projectTimezone;
    }
    // if (projectAdminForm.country) {
    //   body.country = projectAdminForm.country;
    // }
    // if (projectAdminForm.state) {
    //   body.state = projectAdminForm.state;
    // }
    if (projectAdminForm.city) {
      body.city = projectAdminForm.city;
    }
    if (projectAdminForm.street) {
      body.street = projectAdminForm.street;
    }
    if (projectAdminForm.streetNumber) {
      body.streetNumber = projectAdminForm.streetNumber;
    }
    if (projectAdminForm.postalCode) {
      body.postalCode = projectAdminForm.postalCode;
    }
    if (projectAdminForm.idEnterprise) {
      body.idEnterprise = projectAdminForm.idEnterprise;
    }
    if (projectAdminForm.bActive) {
      body.bActive = projectAdminForm.bActive;
      if (addToLimit === true) {
        body.addOneToProjectLimit = true;
      } else {
        body.addOneToProjectLimit = false;
      }
    } else {
      body.bActive = false;
      body.addOneToProjectLimit = false;
    }
    if (projectAdminForm.architect) {
      body.architect = projectAdminForm.architect;
    }
    if (projectAdminForm.projectEngineer) {
      body.projectEngineer = projectAdminForm.projectEngineer;
    }
    if (projectAdminForm.constructionDirector) {
      body.constructionDirector = projectAdminForm.constructionDirector;
    }
    if (projectAdminForm.constructionManager) {
      body.constructionManager = projectAdminForm.constructionManager;
    }
    if (newProjectImage) {
      body.image = filesCoverUrl;
    }
    if (newProjectLogo) {
      body.logo = filesLogoUrl;
    }
    if (projectAdminForm.translation) {
      body.translation = projectAdminForm.translation;
    } else {
      body.translation = false;
    }
    if (projectAdminForm.description) {
      body.description = projectAdminForm.description;
    }

    dispatch(setCurrentTranslation(projectAdminForm.translation));
    await updateAdminPanelProject(body).unwrap();
  };

  useEffect(() => {
    if (!isEditing) {
      return;
    }
    if (Boolean(projectAdminForm.country) && countryItems.length > 0) {
      const country = countryItems.find(
        (ci) => ci.label === projectAdminForm.country,
      )?.value;
      if (country) {
        setProjectAdminForm((prev) => ({
          ...prev,
          country,
        }));
      }
    }
    if (Boolean(projectAdminForm.state) && stateItems.length > 0) {
      const state = stateItems.find(
        (si) => si.label === projectAdminForm.state,
      )?.value;
      if (state) {
        setProjectAdminForm((prev) => ({
          ...prev,
          state,
        }));
      }
    }
  }, [countryItems, cityItems, stateItems, projectAdminForm, isEditing]);

  return (
    <Box>
      <Grid
        container
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Grid item xs={12} md={8} sx={{ paddingRight: 2 }}>
          {/* GENERAL */}
          <SectionTitle title="General" />

          <Box display="flex" mb="60px" columnGap="20px">
            {/* Project name */}
            <SectionDataCell
              label={tGeneral("projectName")}
              content={projectAdminForm?.projectName}
              value={projectAdminForm.projectName}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({ ...prev, projectName: value }))
              }
              isEditing={isEditing}
            />

            {/* timezone */}
            <SectionDataCell
              label={tGeneral("timezone")}
              content={projectAdminForm?.projectTimezone}
              value={projectAdminForm.projectTimezone}
              isEditing={isEditing}
              type="timezones"
              values={formattedTimezones}
              onChange={(value) => {
                setProjectAdminForm((prev) => ({
                  ...prev,
                  projectTimezone: value,
                }));
              }}
            />
          </Box>

          <Box display="flex" mb="60px" columnGap="20px">
            {/* Status */}
            <SectionDataCell
              label={tGeneral("status")}
              content={tGeneral(
                projectAdminForm?.bActive ? "active" : "inactive",
              )}
              isEditing={isEditing}
              value={projectAdminForm.bActive}
              type="switch"
              onChange={(value) =>
                setProjectAdminForm((prev) => ({ ...prev, bActive: value }))
              }
              icons={{ trueIcon: <LockOpen />, falseIcon: <LockOutlined /> }}
            />

            {/* Translation */}
            <SectionDataCell
              label={tGeneral("translation")}
              content={tGeneral(projectAdminForm.translation ? "on" : "off")}
              isEditing={isEditing}
              value={projectAdminForm.translation}
              type="switch"
              onChange={(value) =>
                setProjectAdminForm((prev) => ({ ...prev, translation: value }))
              }
              icons={{ trueIcon: <LockOpen />, falseIcon: <LockOutlined /> }}
            />
          </Box>

          {/* ADDRESS */}
          <SectionTitle title={tGeneral("address")} />
          <Box display="flex" columnGap="20px">
            {/* street */}
            <SectionDataCell
              label={tGeneral("street")}
              content={projectAdminForm?.street}
              value={projectAdminForm.street}
              isEditing={isEditing}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({ ...prev, street: value }))
              }
            />

            {/* number */}
            <SectionDataCell
              label={tGeneral("number")}
              content={projectAdminForm?.streetNumber}
              value={projectAdminForm.streetNumber}
              isEditing={isEditing}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({
                  ...prev,
                  streetNumber: value,
                }))
              }
            />

            {/* country */}
            <SectionDataCell
              label={tGeneral("country")}
              content={projectAdminForm?.country}
              value={projectAdminForm.country}
              isEditing={isEditing}
              type="select"
              values={countryItems}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({ ...prev, country: value }))
              }
            />
          </Box>
          <Box display="flex" mt="60px" columnGap="20px">
            {/* state */}
            <SectionDataCell
              label={tGeneral("state")}
              value={projectAdminForm.state}
              content={projectAdminForm?.state}
              isEditing={isEditing}
              type="select"
              values={stateItems}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({ ...prev, state: value }))
              }
            />

            {/* city */}
            <SectionDataCell
              label={tGeneral("city")}
              content={projectAdminForm?.city}
              value={projectAdminForm.city}
              isEditing={isEditing}
              type="select"
              values={cityItems}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({ ...prev, city: value }))
              }
            />

            {/* zip code */}
            <SectionDataCell
              label={tGeneral("zipCode")}
              content={projectAdminForm?.postalCode}
              value={projectAdminForm.postalCode}
              isEditing={isEditing}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({ ...prev, postalCode: value }))
              }
            />
          </Box>
        </Grid>

        {/* IMAGES */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: 400,
            width: "100%",
          }}
        >
          {/* Project Image */}
          <Box
            flex={1}
            position="relative"
            justifyContent="center"
            display="flex"
            marginBottom={2}
            maxHeight="280px"
          >
            {/* Project IMAGE */}
            <ImageEdit
              src={projectAdminForm?.projectImage}
              file={projectAdminForm?.projectImageFile}
              alt="Project image"
              isEditing={isEditing}
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
              onChange={(value, file) => {
                setProjectAdminForm((prev) => ({
                  ...prev,
                  projectImage: value,
                  projectImageFile: file,
                }));
                setNewProjectImage(true);
              }}
            />
          </Box>

          {/* Project Logo */}
          <Box
            position="relative"
            maxHeight="100px"
            justifyContent="center"
            display="flex"
            sx={{ border: "1px solid #B0B7C1" }}
          >
            {/* Project LOGO */}
            <ImageEdit
              src={projectAdminForm?.projectLogo}
              file={projectAdminForm?.projectLogoFile}
              alt="Enterprise logo"
              isEditing={isEditing}
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
              help="Medidas recomendadas 230px x 50px .png"
              onChange={(value, file) => {
                setProjectAdminForm((prev) => ({
                  ...prev,
                  projectLogo: value,
                  projectLogoFile: file,
                }));
                setNewProjectLogo(true);
              }}
            />
          </Box>
        </Grid>
      </Grid>

      {/* MANAGERS */}
      <Grid display="flex" mt="24px">
        <Box flex={1}>
          <SectionTitle title={tGeneral("managers")} />
          <Box display="flex" columnGap="20px">
            {/* Construction director */}
            <SectionDataCell
              label={tGeneral("constructionDirector")}
              content={projectAdminForm?.constructionDirector}
              value={projectAdminForm.constructionDirector}
              isEditing={isEditing}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({
                  ...prev,
                  constructionDirector: value,
                }))
              }
            />

            {/* Project manager */}
            <SectionDataCell
              label={tGeneral("constructionManager")}
              content={projectAdminForm?.constructionManager}
              value={projectAdminForm.constructionManager}
              isEditing={isEditing}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({
                  ...prev,
                  constructionManager: value,
                }))
              }
            />

            {/* architect */}
            <SectionDataCell
              label={tGeneral("architect")}
              content={projectAdminForm?.architect}
              value={projectAdminForm.architect}
              isEditing={isEditing}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({ ...prev, architect: value }))
              }
            />

            {/* engineer */}
            <SectionDataCell
              label={tGeneral("projectEngineer")}
              content={projectAdminForm?.projectEngineer}
              value={projectAdminForm.projectEngineer}
              isEditing={isEditing}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({
                  ...prev,
                  projectEngineer: value,
                }))
              }
            />
          </Box>
        </Box>
      </Grid>

      {/* DESCRIPTION */}
      <Grid display="flex" mt="24px">
        <Box flex={1}>
          <SectionTitle title={tGeneral("projectDescription")} />
          <Box display="flex">
            <SectionDataCell
              type="multiline"
              label=""
              content={projectAdminForm?.description}
              value={projectAdminForm.description}
              isEditing={isEditing}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({
                  ...prev,
                  description: value,
                }))
              }
            />
          </Box>

          {/* Buttons */}
          <Can I="edit" a="adminPanel">
            <Box display="flex" justifyContent="flex-end" columnGap="16px">
              {isEditing ? (
                <>
                  {/* cancel button */}
                  <Button
                    variant="contained"
                    sx={{
                      p: "8px 16px",
                      backgroundColor: "white",
                      color: "black",
                      border: "1px solid yellow",
                      textTransform: "capitalize",
                      borderRadius: "16px",
                      minWidth: "122px",
                    }}
                    disabled={isUpdateProjectLoading}
                    onClick={() => {
                      setAddToLimit(false);
                      handleCancelEdit();
                    }}
                  >
                    <Typography fontWeight="medium" fontSize="1rem">
                      {tGeneral("cancel")}
                    </Typography>
                  </Button>

                  {/* Save Button */}
                  <Button
                    variant="contained"
                    sx={{
                      p: "8px 16px",
                      textTransform: "capitalize",
                      borderRadius: "16px",
                      minWidth: "122px",
                    }}
                    onClick={() => {
                      if (
                        !originallyActive &&
                        projectAdminForm.bActive &&
                        activeProjects >= totalProjectLimit &&
                        enterprises[0].planType === "project"
                      ) {
                        setOpenProjectsLimit(true);
                        setAddToLimit(true);
                      } else {
                        setAddToLimit(false);
                        handleSave();
                      }
                    }}
                    disabled={isUpdateProjectLoading}
                  >
                    {isUpdateProjectLoading ? (
                      <CircularProgress color="secondary" />
                    ) : (
                      <Typography fontWeight="medium" fontSize="1rem">
                        {tGeneral("save")}
                      </Typography>
                    )}
                  </Button>
                </>
              ) : (
                // Edit button
                <Button
                  variant="contained"
                  sx={{
                    p: "8px 16px",
                    width: "184px",
                    textTransform: "capitalize",
                    borderRadius: "16px",
                  }}
                  onClick={() => setIsEditing(true)}
                >
                  <Typography fontWeight="500" fontSize="1rem">
                    {tGeneral("editInfo")}
                  </Typography>
                </Button>
              )}
            </Box>
          </Can>
        </Box>
      </Grid>

      {/* Modal - projects limit warning */}
      <Dialog
        open={openProjectsLimit}
        onClose={() => {
          setOpenProjectsLimit(false);
          setAddToLimit(false);
        }}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ mx: "auto" }}
        PaperProps={{ sx: { borderRadius: "10px", width: "50%" } }}
      >
        <DialogTitle
          id="scroll-dialog-title"
          // sx={[
          //   styles.dialogTitle,
          //   { padding: "16px 40px" },
          //   { marginBottom: "10px" },
          // ]}
        >
          <IconButton
            aria-label="close"
            onClick={() => setOpenProjectsLimit(false)}
            sx={[styles.iconButtonClose, { marginRight: "20px" }]}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <Box style={styles.closeAlert}>
          <Box
            style={{
              width: "100%",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={Warning}
              style={{ width: 100, height: 100, margin: "auto" }}
              alt=""
            />

            <Typography
              style={{ fontSize: 24, textAlign: "center", fontWeight: "600" }}
            >
              {"Project limit reached".toUpperCase()}
            </Typography>
          </Box>
        </Box>

        <DialogContent>
          <Typography style={{ fontSize: 16, textAlign: "center" }}>
            Are you sure you want to proceed with activating this project? When
            performing this action, an extra project will be charged to your
            next invoice.
          </Typography>
        </DialogContent>

        <DialogActions
          sx={[
            { marginBottom: "10px" },
            { marginRight: "30px" },
            { marginLeft: "30px" },
          ]}
        >
          <Button
            sx={styles.cancelButton}
            type="submit"
            variant="contained"
            size="large"
            onClick={() => {
              setOpenProjectsLimit(false);
              setAddToLimit(false);
            }}
          >
            CANCEL
          </Button>

          <Button
            sx={styles.saveButton}
            type="submit"
            variant="contained"
            size="large"
            onClick={() => {
              setOpenProjectsLimit(false);
              setAddToLimit(true);
              handleSave();
            }}
          >
            ACCEPT
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export { AdminPanelProject };
