export const general = {
  addFilter: "Agregar filtro",
  settings: "Configuraciones",
  restore: "Restaurar",
  location: "Ubicación",
  locations: "Ubicaciones",
  uploadedBy: "Subido por",
  attendance: "Asistencia",
  assignees: "Asignados",
  assigned: "Asignado",
  category: "Categoría",
  priority: "Prioridad",
  createdBy: "Creado por",
  distributionList: "Lista de distribución",
  noUsersSelected: "No se han seleccionado usuarios",
  noAttendanceUsers: "No hay usuarios de asistencia",
  actions: "Acciones",
  day: "Día",
  month: "Mes",
  year: "Año",
  week: "Semana",
  rename: "Renombrar",
  delete: "Eliminar",
  deleteAll: "Eliminar todo",
  cancel: "Cancelar",
  confirm: "Confirmar",
  edit: "Editar",
  information: "Información",
  comments: "Comentarios",
  takenOn: "Tomado el",
  uploadedOn: "Subido el",
  errorMessage: "Ocurrió un error. Por favor, inténtelo de nuevo más tarde.",
  errorUploadMessage:
    "No se pudo subir el archivo. Verifique su conexión a Internet e inténtelo de nuevo más tarde.",
  errorDownloadMessage:
    "No se pudo descargar el archivo. Verifique su conexión a Internet e inténtelo de nuevo más tarde.",
  close: "Cerrar",
  save: "Guardar",
  logOut: "Cerrar sesión",
  view: "Ver",
  seeDetails: "Ver detalles",
  download: "Descargar",
  add: "Agregar",
  update: "Actualizar",
  accept: "Aceptar",
  loading: "Cargando",
  loadingDots: "Cargando...",
  email: "Correo electrónico",
  phone: "Teléfono",
  name: "Nombre",
  lastName: "Apellido",
  position: "Posición",
  enterprise: "Empresa",
  role: "Rol",
  module: "Módulo",
  superAdmin: "Superadministrador",
  admin: "Administrador",
  viewOnly: "Solo vista",
  none: "Ninguno",
  invalid: "Inválido",
  search: "Buscar",
  projectSearch: "Búsqueda en todo el proyecto",
  open: "Abrir",
  open_action: "Abrir",
  still_open: "Aún abierto",
  public: "Público",
  private: "Privado",
  privacy: "Privacidad",
  date: "Fecha",
  closedDate: "Fecha de cierre",
  status: "Estado",
  title: "Título",
  draft: "Borrador",
  favorite: "Favorito",
  back: "Atrás",
  configUpdated: "Configuración actualizada correctamente",
  errorConfigUpdated: "No se pudo actualizar la configuración",
  hour: "Hora",
  selectStatus: "Seleccionar estado",
  publish: "Publicar",
  attendanceConfirmed: "Asistencia confirmada correctamente",
  errorAttendanceConfirmed: "No se pudo confirmar la asistencia",
  copy: "Copiar",
  confirmAttendance: "Confirmar asistencia",
  pdf: "PDF",
  creator: "Creador",
  lastSeen: "Última vista",
  lastEdit: "Última edición",
  systemLanguage: "Idioma del sistema",
  address: "Dirección",
  street: "Calle",
  number: "Número",
  country: "País",
  state: "Estado",
  city: "Ciudad",
  zipCode: "Código postal",
  next: "Siguiente",
  en: "Inglés",
  es: "Español",
  file: "Archivo",
  files: "Archivos",
  dateReceived: "Fecha de recepción",
  features: "Características",
  drawings: "Dibujos",
  drawing: "Dibujo",
  submittals: "Envíos",
  rfis: "RFI",
  dailyReports: "Informes diarios",
  issues: "Problemas",
  photos: "Fotos",
  tasks: "Tareas",
  inspections: "Inspecciones",
  documents: "Documentos",
  meetings: "ミーティング",
  delay: "遅延",
  directory: "ディレクトリ",
  hours: "時間",
  deliveries: "納品",
  announcements: "お知らせ",
  user: "ユーザー",
  project: "プロジェクト",
  projects: "プロジェクト",
  subscribe: "購読",
  notFound: "プロジェクトが見つかりません",
  noProjects: "現在、プロジェクトは作成されていません",
  createButton:
    "追加する場合は、プロジェクト作成ボタンをクリックしてプロセスを開始してください",
  createProject: "プロジェクトを作成",
  loadingEnterprises: "企業を読み込んでいます...",
  generalDirectory: "一般ディレクトリ",
  superAdmins: "スーパー管理者",
  roles: "役割",
  manager: "マネージャー",
  constructionDirector: "建設ディレクター",
  architect: "建築家",
  engineer: "エンジニア",
  active: "アクティブ",
  inactive: "非アクティブ",
  generalInfo: "一般情報",
  changePlan: "プラン変更",
  billing: "請求",
  paymentMethod: "支払い方法",
  updateProfile: "プロフィールを更新",
  youSureEnterprise: "このデータでプロフィール企業を更新してもよろしいですか？",
  enterpriseName: "企業名",
  enterpriseType: "企業タイプ",
  enterpriseImage: "企業ロゴ",
  success: "成功",
  done: "完了！",
  enterpriseUpdated: "企業情報が正常に更新されました。",
  error: "エラー",
  errorBig: "エラー！",
  errorOccurred: "エラーが発生しました。後でもう一度やり直してください。",
  logout: "ログアウト",
  profile: "プロフィール",
  currentPlan: "現在のプラン",
  per: "毎",
  activeProjects: "アクティブなプロジェクト",
  activeUsers: "アクティブなユーザー",
  annual: "年間",
  monthly: "月間",
  allTheFeatures: "すべての機能",
  unlimitedProjects: "無制限のプロジェクト",
  unlimitedUsers: "無制限のユーザー",
  unlimitedStorage: "無制限のストレージ",
  translationSpanglish: "スペイン語-英語の翻訳",
  companyReports: "企業ブランドのレポート",
  customizableReports: "カスタマイズ可能なレポートテンプレート",
  home: "ホーム",
  changePassword: "パスワードの変更",
  tickets: "チケット",
  preferences: "設定",
  userManual: "ユーザーマニュアル",
  notifications: "通知",
  male: "男性",
  female: "女性",
  editProfile: "プロフィールの編集",
  firstName: "名",
  birthDate: "生年月日",
  gender: "性別",
  inProcess: "進行中",
  currentPassword: "現在のパスワード",
  newPassword: "新しいパスワード",
  repeatPassword: "パスワードの再入力",
  passwordNotice:
    "パスワードは大文字1文字、小文字1文字、数字1文字、特殊文字1文字（_, #, ?, !, @, $, %, ^, &, *または-）を含み、8文字である必要があります。",
  passwordYouSure: "パスワードを変更してもよろしいですか？",
  enterPassword: "パスワードを入力",
  changedPassword: "パスワードが正常に変更されました",
  welcomeTickets:
    "ようこそ！以下に見つかったインシデントのカテゴリーを選択してください。",
  mobile: "モバイル",
  browser: "ブラウザ",
  device: "デバイス",
  details: "詳細",
  send: "送信",
  mode: "モード",
  dark: "ダーク",
  light: "ライト",
  exportCvs: "CSVエクスポート",
  exportPdf: "PDFエクスポート",
  newSuperAdmin: "新しいスーパー管理者",
  dashboard: "ダッシュボード",
  adminPanel: "管理パネル",
  visibleModules: "表示モジュール",
  orderModules: "モジュールの順序",
  projectAdmin: "プロジェクト管理",
  specification: "仕様",
  specifications: "仕様",
  costCodes: "コストコード",
  connections: "接続",
  projectName: "プロジェクト名",
  translation: "翻訳",
  managers: "マネージャー",
  constructionManager: "建設マネージャー",
  projectEngineer: "プロジェクトエンジニア",
  editInfo: "情報の編集",
  on: "オン",
  off: "オフ",
  addSuperAdmin: "スーパー管理者を追加",
  deleteSuperAdmin: "スーパー管理者を削除",
  areYouAdmin:
    "このスーパー管理者を削除してもよろしいですか？この操作を実行すると、元に戻すことはできません",
  writeDetail: "見つかったバグの詳細を書いてください。",
  dragFiles:
    "ここにファイルをドラッグ＆ドロップするか、クリックしてファイルを選択します",
  dragFile:
    "ここにファイルをドラッグ＆ドロップするか、クリックしてファイルを選択します",
  emails: "メール",
  subject: "件名",
  to: "宛先",
  createdOn: "作成日",
  info: "情報",
  move: "移動",
  favorites: "お気に入り",
  generalInformation: "一般情報",
  permissions: "権限",
  managePermissions: "権限の管理",
  changeHistory: "変更履歴",
  published: "公開済み",
  deleted: "削除済み",
  noPermission: "このページを表示する権限がありません",
  recycleBin: "ごみ箱",
  not_defined: "未定義",
  closed: "クローズ",
  no_assinged_users: "担当ユーザーがいません",
  due_date: "期限日",
  closed_date: "クローズ日",
  approve: "確認して署名",
  emptyDataSourceMessage: "表示するレコードがありません",
  of: "/",
  deletedPermanently: "完全に削除されました",
  deletedSuccessfully: "正常に削除されました",
  updatedSuccessfully: "正常に更新されました",
  createdSuccessfully: "正常に作成されました",
  recoveredSuccessfully: "正常に回復されました",
  closedSuccessfully: "正常にクローズされました",
  approvedSuccessfully: "正常に承認されました",
  cantDelete: "削除できません",
  cantEdit: "編集できません",
  deleteRFI: "RFIを削除してもよろしいですか？",
  question: "質問",
  startWriting: "書き始める...",
  additionalInformation: "追加情報",
  receivedFrom: "受信元",
  company: "会社",
  cost: "コスト",
  price: "価格",
  currency: "通貨",
  mxn: "MXN - メキシコペソ",
  usd: "USD - アメリカドル",
  emptyQuestion: "質問は空にできません",
  createDate: "作成日",
  newResponse: "新しい応答...",
  newComment: "新しいコメント...",
  newTopic: "新しいトピック...",
  topics: "トピック",
  showTranslation: "翻訳を表示",
  closeTranslation: "翻訳を閉じる",
  viewFiles: "ファイルを表示",
  images: "画像",
  noImages: "画像なし",
  noDocuments: "ドキュメントなし",
  attachFiles: "ファイルを添付するか、ドラッグして選択します",
  january: "1月",
  february: "2月",
  march: "3月",
  april: "4月",
  may: "5月",
  june: "6月",
  july: "7月",
  august: "8月",
  september: "9月",
  october: "10月",
  november: "11月",
  december: "12月",
  noEnterprises: "まだ企業がありません！",
  pendingItems: "保留中のアイテム",
  weather: "天気",
  todoList: "ToDoリスト",
  ensureThat:
    "プロジェクトに有効な住所があることを確認して天気を取得するか、後でもう一度やり直してください。",
  addItemList: "ToDoリストにアイテムを追加",
  noAnno: "表示するお知らせはありません",
  daysLeft: "残り日数",
  congratulations: "おめでとうございます",
  noPending: "保留中のアイテムはありません。",
  noFilterableElements: "検索条件に一致する要素が見つかりません",
  addList: "ToDoリストを追加",
  activityDesc: "アクティビティの説明",
  expirationDate: "有効期限",
  onTime: "期限内",
  overdue: "期限超過",
  areYouTask: "このタスクを完了済みとしてマークしてもよろしいですか？",
  trashCan: "ゴミ箱",
  newAnnouncement: "新しいお知らせ",
  startDate: "開始日",
  time: "時間",
  limitDate: "期限日",

  cancelled: "キャンセルされました",
  operations: "操作がキャンセルされました",
  task: "タスク",
  dailyReport: "日報",
  deletedBy: "削除者",
  deletedAt: "削除日時",
  publishedDate: "公開日",
  moved: "移動しました",
  selectAll: "すべて選択",
  pleaseMakeSureAllDataIsCorrect:
    "すべてのデータが正しいことを確認してください",
  addLocation: "場所を追加",
  new: "新規",
  sendSomeoneComment: "@someoneにコメントを送信",
  endDate: "終了日",
  recent: "最近",
  thisWeek: "今週",
  thisMonth: "今月",
  lastWeek: "先週",
  projectConfigurationUpdateSuccess: "プロジェクトの設定が正常に更新されました",
  description: "説明",
  enterValidTitle: "有効なタイトルを入力してください",
  enterValidDescription: "有効な説明を入力してください",
  enterValidDate: "有効な日付を入力してください",
  enterValidStatus: "有効なステータスを入力してください",
  enterValidPriority: "有効な優先度を入力してください",
  enterValidLocation: "有効な場所を入力してください",
  enterValidCategory: "有効なカテゴリを入力してください",
  fieldRequired: "このフィールドは必須です",
  cantUndone: "この操作は元に戻せません",
  users: "ユーザー",
  minutes: "分",
  noOne: "誰もいません",
  noComments: "コメントはありません",
  noTasks: "タスクはありません",
  updated: "更新されました",
  restored: "復元されました",
  created: "作成されました",
  successfully: "正常に",
  labelRowsPerPage: "ページごとの行数：",
  labelRowsSelect: "行",
  firstAriaLabel: "最初のページ",
  firstTooltip: "最初のページ",
  previousAriaLabel: "前のページ",
  previousTooltip: "前のページ",
  nextAriaLabel: "次のページ",
  nextTooltip: "次のページ",
  lastAriaLabel: "最後のページ",
  lastTooltip: "最後のページ",
  adminEnabledSuccessfully: "管理者ユーザーが正常に有効になりました",
  cantEnableAdmin: "管理者ユーザーを有効にできません",
  adminDisabledSuccessfully: "管理者ユーザーが正常に無効になりました",
  cantDisableAdmin: "管理者ユーザーを無効にできません",
  emptyFields: "空のフィールド",
  cantCreateUser: "ユーザーを作成できません",
  userCreatedSuccessfully: "ユーザーが正常に作成されました",
  userEditedSuccessfully: "ユーザーが正常に編集されました",
  cantEditUser: "ユーザーを編集できません",
  userDeletedSuccessfully: "ユーザーが正常に削除されました",
  cantDeleteUser: "ユーザーを削除できません",
  companyCreatedSuccessfully: "企業が正常に作成されました",
  cantCreateCompany: "企業を作成できません",
  totalEnterprises: "総企業数",
  totalContacts: "総連絡先数",
  enterpriseRoleUpdated: "企業の役割が正常に更新されました",
  cantUpdateEnterpriseRole: "企業の役割を更新できません",
  enterpriseRoleDeleted: "企業の役割が正常に削除されました",
  cantDeleteEnterpriseRole: "企業の役割を削除できません",
  newRole: "新しい役割",
  cantEditUnclassifiedRole: "未分類の役割を編集できません",
  cantDeleteUnclassifiedRole: "未分類の役割を削除できません",
  noUsersRole: "この役割を持つユーザーはいません",
  deleteRole: "役割を削除",
  deleteRoleMessage:
    "この役割を削除してもよろしいですか？この操作は元に戻せません。",
  editRole: "役割を編集",
  roleName: "役割名",
  invalidName: "無効な名前",
  cantCreateRole: "役割を作成できません",
  roleCreatedSuccessfully: "役割が正常に作成されました",
  roleEditedSuccessfully: "役割が正常に編集されました",
  cantEditRole: "役割を編集できません",
  addRole: "役割を追加",
  roleNameEmpty: "役割名は空にできません",
  projectCreated: "プロジェクトが正常に作成されました",
  cantCreateProject: "プロジェクトを作成できません",
  userName: "ユーザー名",
  selectUser: "ユーザーを選択",
  userAlreadyAdded: "ユーザーは既に追加されています",
  newProject: "新しいプロジェクト",
  skip: "スキップ",
  fillRequiredFields: "必須フィールドを入力してください",
  exportInfo: "情報をエクスポート",
  deleteImage: "画像を削除",
  clickAddProject: "プロジェクトを追加するにはクリックしてください",
  other: "その他",
  projectTypeCreated: "プロジェクトタイプが正常に作成されました",
  cantCreateProjectType: "プロジェクトタイプを作成できません",
  projectType: "プロジェクトタイプ",
  addProjectType: "プロジェクトタイプを追加",
  cover: "カバー",
  logo: "ロゴ",
  recommendedSize: "推奨サイズ",
  projectManager: "プロジェクトマネージャー",
  mexico: "メキシコ",
  unitedStates: "アメリカ合衆国",
  guatemala: "グアテマラ",
  colombia: "コロンビア",
  chile: "チリ",
  argentina: "アルゼンチン",
  projectLimitReached: "プロジェクト制限に達しました",
  projectsLimitReached: "プロジェクト制限に達しました",
  projectAddWarning:
    "さらに1つのプロジェクトを作成してもよろしいですか？この操作により、次の請求書に追加のプロジェクトが請求されます。",
  billingInformation: "請求情報",
  enterpriseInfoUpdated: "企業情報が正常に更新されました",
  cantUpdateEnterpriseInfo: "企業情報を更新できません",
  reference: "参照",
  amount: "金額",
  paymentDate: "支払日",
  emailRecipient: "メールの受信者",
  cardNumber: "カード番号",
  mmaa: "MM/YY",
  addCard: "カードを追加",
  selectAvatar: "アバターを選択",
  userUpdate: "ユーザーが正常に更新されました",
  cantUpdateUser: "ユーザーを更新できません",
  avatarUpdateError: "アバターの更新エラー",
  feelsLike: "体感気温",
  weatherValidation:
    "天気を取得するには、プロジェクトに郵便番号と国があることを確認するか、後でもう一度試してください",
  clear: "クリア",
  cloudy: "曇り",
  mostlyClear: "ほとんど晴れ",
  partlyCloudy: "一部曇り",
  mostlyCloudy: "ほとんど曇り",
  fog: "霧",
  lightFog: "薄い霧",
  drizzle: "霧雨",
  rain: "雨",
  lightRain: "小雨",
  heavyRain: "大雨",
  snow: "雪",
  flurries: "吹雪",
  lightSnow: "小雪",
  heavySnow: "大雪",
  drizzleFreezing: "霧雨凍結",
  freezingRain: "凍雨",
  freezingLightRain: "軽い凍雨",
  freezingHeavyRain: "激しい凍雨",
  icePellets: "氷のペレット",
  heavyIcePellets: "大きな氷のペレット",
  lightIcePellets: "小さな氷のペレット",
  thunderstorm: "雷雨",
  Sun: "日",
  Mon: "月",
  Tue: "火",
  Wed: "水",
  Thu: "木",
  Fri: "金",
  Sat: "土",
  cantUpdateConfig: "設定を更新できません",
  drawingsAdded: "図面が正常に追加されました",
  modified: "変更されました",
  modification: "変更",
  from: "から",
  moveFolderTo: "フォルダを移動",
  moveFileTo: "ファイルを移動",
  locationCreated: "場所が正常に作成されました",
  cantCreateLocation: "場所を作成できません",
  locationUpdated: "場所が正常に更新されました",
  cantUpdateLocation: "場所を更新できません",
  locationDeleted: "場所が正常に削除されました",
  cantDeleteLocation: "場所を削除できません",
  type: "タイプ",
  addType: "タイプを追加",
  typeAdded: "タイプが正常に追加されました",
  editType: "タイプを編集",
  typeUpdated: "タイプが正常に更新されました",
  deleteType: "タイプを削除",
  typeDeleted: "タイプが正常に削除されました",
  superLocation: "スーパー場所",
  sureDelete: "削除してもよろしいですか",
  specificationAdded: "仕様が正常に追加されました",
  costCodeAdded: "コストコードが正常に追加されました",
  specificationUpdated: "仕様が正常に更新されました",
  costCodeUpdated: "コストコードが正常に更新されました",
  specificationDeleted: "仕様が正常に削除されました",
  costCodeDeleted: "コストコードが正常に削除されました",
  addSpecification: "仕様を追加",
  addCostCode: "コストコードを追加",
  editSpecification: "仕様を編集",
  editCostCode: "コストコードを編集",
  code: "コード",
  deleteSpecification: "仕様を削除",
  deleteCostCode: "コストコードを削除",
  deleteGeneric: "この要素を削除してもよろしいですか？この操作は元に戻せません",
  seen: "閲覧済み",
  create: "作成",
  rfi: "RFI",
  meeting: "ミーティング",
  project_directory: "プロジェクトディレクトリ",
  createuser: "ユーザーを作成",
  deleteAgendaItem: "議題を削除",
  deleteAgendaItemMessage:
    "この議題を削除してもよろしいですか？この操作は元に戻せません。",
  viewCurrentFiles: "現在のファイルを表示",
  reportAnIssue: "問題を報告",
  message: "メッセージ",
  sendToEmail: "メールに送信",
  sent: "送信済み",
  sentMessage: "メールが正常に送信されました",
  timezone: "タイムゾーン",
  agenda: "議題",
  newPasswordTitle: "新しいパスワードを入力してください",
  assignTo: "ユーザーに割り当て",
  comment: "コメント",
  linkTo: "リンク先",
  deliveryDate: "納品日",
  responsible: "担当者",
  invalidEmail: "無効なメールアドレス",
  publishedBy: "公開者",
  privacyOptions: "プライバシーオプション",
  person: "人",
  companies: "企業",
  onlyFavorites: "お気に入りのみ",
  approvedBy: "承認者",
  approvalDate: "承認日",
  externalContacts: "外部連絡先",
  retry: "再試行",
  generalErrorMessage: "エラーが発生しました。もう一度お試しください",
  yesDeleteIt: "はい、削除します",
  creators: "作成者",
  deleteInspection: "検査を削除してもよろしいですか？",
  deleteInspectionTemplate: "検査テンプレートを削除してもよろしいですか？",
  sections: "セクション",
  items: "アイテム",
  cancelPrompt: "キャンセルしてもよろしいですか？",
  graph: "グラフ",
  quickTemplate: "+ クイックテンプレート",
  saveAsTemplate: "テンプレートとして保存",
  createRevision: "リビジョンを作成",
  generate: "生成",
  videos: "ビデオ",
  noVideos: "ビデオはありません",
  audios: "オーディオ",
  noAudios: "オーディオはありません",
  in_revision: "リビジョン中",
  logoutMessage: "ログアウトしてもよろしいですか？",
  exportCsv: "CSVをエクスポート",
  compare: "比較",
  approved: "承認済み",
  comparing: "比較中",
  more: "詳細",
  noCCSelected: "CC受信者が選択されていません",
  and: "および",
  removeSuperAdminTitle: "スーパー管理者ロールを削除",
  removeSuperAdminDescription:
    "この操作はユーザーからスーパー管理者ロールを削除しますが、ユーザー自体は削除しません。",
  noResults: "結果がありません",
  noResultsMessage: "異なるフィルタを使用してみてください",
  assignedProjects: "割り当てられたプロジェクト",
  noAssignedProjects: "割り当てられたプロジェクトはありません",
  userAddedSuccessfully: "ユーザーが正常に追加されました",
  projectSchedule: "プロジェクトスケジュール",
  noOptions: "オプションなし",
  noData: "データが見つかりませんでした",
  order: "順序",
  changeStatus: "ステータスを変更",
  changeStatusContent: "ユーザーステータスを変更してもよろしいですか？",
  select: "選択",
  sign: "署名",
  useExisting: "既存のものを使用",
  reject: "拒否",
  rejectedSuccessfully: "正常に拒否されました",
  reviewedBy: "レビュー者：",
  timeAgo: "{{time}} {{unit}}前",
  seconds: "秒",
  onlyUnread: "未読のみ表示",
  days: "日",
  image: "画像",
  reviewDate: "レビュー日",
  publicationDate: "公開日",
  inspection: "検査",
  announcement: "お知らせ",
  updatedProject: "プロジェクト情報が正常に更新されました。",
  draw: "描く",
  history: "履歴",
  templates: "テンプレート",
  useFileName: "ファイル名を使用する",
  progress: "進捗",
  bim: "BIM",
  reports: "レポート",
  report: "レポート",
  constants: "定数",
  importInfoMessage:
    "ここでは、作成プロセスの時間を節約するために、別のプロジェクトから情報をインポートできます。",
  importInfo: "情報をインポート",
  imported: "インポート済み",
  importedSuccessfully: "正常にインポートされました",
  createdDate: "作成日",
  acceptedBy: "承認者",
  acceptedDate: "承認日",
  projectNetwork: "プロジェクトネットワーク",
  share: "共有",
  shared: "共有済み",
  sharedSuccessfully: "正常に共有されました",
  sharedOnly: "共有のみ",
  altitude: "高度",
  fullscreen: "全画面表示",
  metric: "メートル法",
  imperial: "ヤード・ポンド法",
  meters: "メートル",
  feet: "フィート",
  inches: "インチ",
  daily_reports: "レポート",
  upload: "アップロード",
  enterprises: "企業",
  all: "すべて",
  total: "合計",
  modules: "モジュール",
  filters: "フィルタ",
  filterBy: "フィルター",
  clearFilter: "掃除する",
  typeToAdd: "追加するタイプ",
  saveChanges: "変更を保存",
  format: "フォーマット",
  showResults: "結果を表示",
  activity: "アクティビティ",
  allProjects: "すべてのプロジェクト",
  allModules: "すべてのモジュール",
  analytics: "分析",
  uploadingQtyFiles: "{{qty}} ファイルをアップロード中",
  technicalIssues: "現在、技術的な問題が発生しています",
  comeBackLater: "数分後にもう一度お試しください。",
  noInternetConnection: "インターネットに接続されていません",
  checkConnection: "接続を確認するか、Wi-Fiに接続してください。",
  charactersLimitError: "文字数の上限に達しました",
  maxCharacters: "最大 {{max}} 文字",
  sharedBy: "共有者",
  ccInstruction:
    "有効なメールアドレスの後にEnterキーまたは';'キーを押して追加してください",
  exportExcel: "Excel にエクスポート",
  excelSuccessfullyDownloaded:
    "Excel ドキュメントが正常にダウンロードされました",
  noRfiSelected: "エクスポートする RFI が選択されていません。",
  selectRfiToContinue: "続行するには RFI を選択してください。",
  noSubmittalSelected: "エクスポートする提出物が選択されていません。",
  selectSubmittalToContinue: "続行するには提出物を選択してください。",
  noMoreResults: "これ以上の結果はありません",
  projectDescription: "プロジェクトの説明",
  clickToWrite: "ここをクリックして書き込みます",
  fileUploadMaxSizeWarning: "ファイルのアップロードサイズ制限は5GBです",
  errorFileUploadSizeTitle: "ファイルの一つが5GBの制限を超えています",
  errorFileUploadSizeDescription:
    "ファイルを確認し、より小さいファイルで再試行してください",
  errorBimUploadLimitTitle: "プロジェクトのBIMアップロード制限に達しました",
  errorBimUploadLimitDescription:
    "You have reached the limit of Archives for this project, please contact support if you want to add more",
};
