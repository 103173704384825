export const bim = {
  addBim: "Adicionar BIM",
  progressHelp:
    "Os arquivos devem ser processados para visualização, este processo pode levar alguns minutos.",
  errorUpload:
    "Houve um erro ao enviar o arquivo, entre em contato com o suporte.",
  pendingUpload:
    "O arquivo está sendo enviado, isso pode levar alguns minutos, por favor volte mais tarde.",
  successUpload: "O arquivo foi enviado com sucesso.",
  deleteBim: "Excluir BIM",
  deleteBimConfirmation: "Tem certeza de que deseja excluir o BIM?",
};
