export const analytics = {
  projectProgress: "Progresso do projeto",
  hi: "Oi",
  realProgress: "Progresso real",
  welcomeBack: "Bem-vindo",
  moduleStatistics: "Estatísticas do módulo",
  onTime: "No Tempo",
  overdue: "Atrasado",
  constructionPercentage: "Porcentagem de construção",
  completed: "Concluído",
  thisWeek: "Esta semana",
  workForce: "Força de trabalho",
  hours: "Horas",
  completedTasks: "Tarefas concluídas",
  analytics: "Analítica",
  addWidgets: "Adicione seus widgets para exibir",
  projectStatistics: "Estatísticas do projeto",
  rfis: "RFIs",
  tasks: "Tarefas",
  submittals: "Submissões",
  drawingsProgress: "Progresso dos Desenhos",
  drawingsCount_one: "Desenho",
  drawingsCount_other: "Desenhos",
  inspections: "Inspeções",
  inspectionCount_one: "Inspeção",
  inspectionCount_other: "Inspeções",
  reports: "Relatórios",
  reportCount_one: "Relatório",
  reportCount_other: "Relatórios",
  delayed: "Atrasado",
  closed: "Fechado",
  open: "Aberto",
  pending: "Pendente",
  inProgress: "Em Progresso",
  done: "Concluído",
  revision: "Revisão",
  total: "Total",
  selectWidget: "Selecione o widget de sua escolha",
  widgetDisplayed: "O widget selecionado será exibido em seu painel",
  somethingWentWrong: "Algo deu errado",
  basic: "Básico",
  manpower: "Mão de obra",
  workProgress: "Progresso do Trabalho",
  problem: "Problema",
  delay: "Atraso",
  categoryReports: "Categoria de Relatórios",
  projectAdvancement: "Avanço do projeto",
  remainingDays: "Dias restantes para conclusão do projeto",
  day_one: "dia",
  day_other: "dias",
  progress: "Progresso",
  remaining: "Restante",
  error: "Erro",
  notEnoughSpace: "Não há espaço suficiente para este widget.",
};
