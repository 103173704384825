import {
  CloudDownload,
  Email,
  InsertDriveFile,
  Lock,
  Star,
} from "@mui/icons-material";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import {
  Box,
  ButtonGroup,
  Grid,
  IconButton,
  SvgIcon,
  Typography,
} from "@mui/material";
import MaterialTable, { MTableToolbar } from "material-table";
import { createRef, forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { ReactComponent as Icon_dwg } from "../../../assets/documents/dwg.svg";
import { ReactComponent as Icon_folder } from "../../../assets/documents/folder.svg";
import { ReactComponent as Icon_jpg } from "../../../assets/documents/jpg.svg";
import { ReactComponent as Icon_pdf } from "../../../assets/documents/pdf.svg";
import { ReactComponent as Icon_ppt } from "../../../assets/documents/ppt.svg";
import { ReactComponent as Icon_svg } from "../../../assets/documents/svg.svg";
import { ReactComponent as Icon_xls } from "../../../assets/documents/xls.svg";
import { PopUpAlert } from "../../../components/PopUpAlert";
import { selectTheme } from "../../../features/preferences/preferencesSlice";
import {
  useEditDocumentMutation,
  useToggleFavoriteMutation,
} from "../../../features/project/modules/documents/documentsApiSlice";
import {
  useEditProjectFolderMutation,
  useGetFavoriteProjectFoldersAndDocumentsQuery,
} from "../../../features/project/modules/documents/projectFolderApiSlice";
import {
  setNeedReload,
  setSelectedFolder,
  setSelectedFolderId,
} from "../../../features/project/modules/documents/projectFolderSlice";
import { useGetProjectConfigurationQuery } from "../../../features/project/projectApiSlice";
import {
  selectCurrentProject,
  selectCurrentRole,
} from "../../../features/project/projectSlice";
import { useDistributionList } from "../../../hooks";
import { formatWithTimeZoneOffset } from "../../../utils/timeZoneOffset";
import DocumentFilters from "./components/DocumentFilters";
import ActionMenu from "./components/Menus/ActionMenu";
import Can from "../../../utils/can";
import { EmptyState, FileIcon, TitleWidgets } from "../../../components/shared";

function FavoriteDocuments() {
  // Theme / Styling
  const darkMode = useSelector(selectTheme);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Translations
  const { t: tGeneral } = useTranslation("general");
  const { t: tDocuments } = useTranslation("documents");

  function formatDateNew(utcTime) {
    const months = [
      tGeneral("january"),
      tGeneral("february"),
      tGeneral("march"),
      tGeneral("april"),
      tGeneral("may"),
      tGeneral("june"),
      tGeneral("july"),
      tGeneral("august"),
      tGeneral("september"),
      tGeneral("october"),
      tGeneral("november"),
      tGeneral("december"),
    ];

    const temp = new Date(utcTime);
    // Get the day, month, and year from the date object
    const day = temp.getDate();
    const month = months[temp.getMonth()];
    const year = temp.getFullYear();

    // Get the hour and minute from the date object
    let hour = temp.getHours();
    let minute = temp.getMinutes();

    // Determine whether it is AM or PM
    const ampm = hour >= 12 ? "pm" : "am";

    // Convert to 12-hour time format
    hour = hour % 12;
    hour = hour ? hour : 12; // 0 should be converted to 12

    // Add leading zero to minutes if necessary
    minute = minute < 10 ? "0" + minute : minute;

    // Format the date string
    // const formattedDate = `${day} ${month} ${year}, ${hour}:${minute} ${ampm}`;
    const formattedDate = `${day} ${month} ${year}`;

    // Return the formatted date string
    return formattedDate;
  }

  // Project and user settings
  const [loadingData, setLoadingData] = useState(true);
  const [userList, setUserList] = useState([]);
  const currentProject = useSelector(selectCurrentProject);
  const {
    getSelectedUsers,
    projectUsers,
    handleCloseDistributionList,
    handleUpdateConfig,
    handleOpenDistributionList,
    isDistributionListOpen,
  } = useDistributionList(currentProject?.id);
  // Filters
  const [paramsDocs, setParamsDocs] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const customSearchFunction = (value) => {
    setSearchValue(value);
  };
  // Set project config and project users
  useEffect(() => {
    setLoadingData(true);
    if (paramsDocs === null) {
      setParamsDocs({ idProject: currentProject?.id });
    }
    // if (projectConfiguration) {
    //   dispatch(setCurrentProjectConfig(projectConfiguration));
    // }
    if (projectUsers) {
      const usersList = projectUsers
        .filter(function (obj) {
          return !obj.bExternal; // only intern users
        })
        .map((user) => ({
          id: user.id,
          name: `${user.user.firstName} ${user.user.lastName}`,
          urlAvatar: user.user.urlAvatar,
          idUser: user.user.id,
        }));
      setUserList(usersList);
    }
    setLoadingData(false);
    return () => {};
  }, [projectUsers]);

  // Table
  const tableRef = createRef(null);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const titleWidgetsActions = [
    // {
    // name: "favorite",
    // icon: tGeneral("favorite"),
    // onClick: () => handleFavorite(),
    // },
  ];
  const columns = [
    {
      title: tGeneral("name"),
      field: "name",
      render: (rowData) => (
        <Box display="flex" alignItems="center" columnGap="10px">
          <FileIcon
            extension={
              rowData.isFolder
                ? "folder"
                : rowData.name.substring(rowData.name.lastIndexOf(".") + 1)
            }
          />
          <Typography>{rowData.name}</Typography>
        </Box>
      ),
    },
    {
      title: tGeneral("createdOn"),
      field: "createDate",
      type: "date",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
        overflow: "hidden",
      },
    },
    {
      title: tDocuments("lastEdit"),
      field: "lastEdit",
      type: "date",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
        overflow: "hidden",
      },
    },
    {
      title: tGeneral("actions"),
      editable: "never",
      headerStyle: {
        textAlign: "center",
      },
      cellStyle: {
        textAlign: "center",
        overflow: "hidden",
      },
      editComponent: (props) => (
        <div>
          <h1>test edit</h1>
        </div>
      ),
      render: (rowData) => (
        <ButtonGroup>
          <Box sx={{ minWidth: "200px" }}>
            {/* Favorite */}
            <IconButton
              type="submit"
              title="Favortie"
              onClick={() => handleToggleFavorite(rowData)}
              sx={{
                color: rowData.favorite
                  ? "#FBCB04"
                  : darkMode
                  ? "white"
                  : "black",
                opacity: rowData.favorite ? 1 : 0.1,
                ":hover": {
                  color: "#FBCB04",
                  opacity: 1,
                },
                m: [0, 0, 0, 0],
              }}
            >
              <Star />
            </IconButton>
            {/* Download */}
            <IconButton
              type="submit"
              title="Download"
              onClick={() => {
                alert("Download");
              }}
              sx={{
                color: darkMode ? "white" : "black",
                opacity: 0.1,
                ":hover": {
                  color: "#FBCB04",
                  opacity: 1,
                },
                m: [0, 0, 0, 0],
              }}
            >
              <CloudDownload />
            </IconButton>
            {/* Email */}
            <IconButton
              type="submit"
              title="Email"
              onClick={() => {
                alert("Email");
              }}
              sx={{
                color: darkMode ? "white" : "black",
                opacity: 0.1,
                ":hover": {
                  color: "#FBCB04",
                  opacity: 1,
                },
                m: [0, 0, 0, 0],
              }}
            >
              <Email />
            </IconButton>
            <Can I="edit" a="documents">
              {/* Edit */}
              <IconButton
                type="submit"
                title="Edit"
                onClick={() => {
                  alert("Edit");
                }}
                sx={{
                  color: darkMode ? "white" : "black",
                  opacity: 0.1,
                  ":hover": {
                    color: "#FBCB04",
                    opacity: 1,
                  },
                  m: [0, 0, 0, 0],
                }}
              >
                <Edit />
              </IconButton>
            </Can>
          </Box>
          <ActionMenu rowData={rowData} view="favorites" />
        </ButtonGroup>
      ),
    },
  ];

  // Fetch Documents
  const [dataDocuments, setDataDocuments] = useState([]);
  const {
    data: documents,
    isSuccess: isSuccessDocuments,
    isLoading: isLoadingDocuments,
    isFetching: isFetchingDocuments,
    refetch: refetchDocuments,
  } = useGetFavoriteProjectFoldersAndDocumentsQuery(currentProject?.id);
  useEffect(() => {
    // Set Documents
    let folders = null;
    let files = null;
    if (isSuccessDocuments && documents.length > 0) {
      folders = documents[0];
      files = documents[1];
      folders = folders.map((dir) => ({
        ...folders,
        id: dir.id,
        idProject: currentProject?.id,
        name: dir.name,
        createDate: formatDateNew(dir.createDate),
        lock: dir.bPrivate,
        active: dir.bActive,
        lastEdit: formatDateNew(dir.lastModified),
        isFolder: dir.idParentFolder !== undefined,
        favorite: dir.favorite,
      }));
      files = files.map((doc) => ({
        ...files,
        id: doc.id,
        idProject: currentProject?.id,
        name: doc.name,
        createDate: formatDateNew(doc.dateUploaded),
        lock: doc.bPrivate,
        active: doc.bActive,
        lastEdit: formatDateNew(doc.lastEdit),
        isFolder: doc.idParentFolder !== undefined,
        favorite: doc.favorite,
      }));
      setDataDocuments([...folders, ...files]);
    } else if (!isSuccessDocuments || documents.length === 0) {
      refetchDocuments();
    }
    return () => {
      setDataDocuments([]);
    };
  }, [isSuccessDocuments, documents]);

  // Handle toggle favorite
  const [
    toggleFavorite,
    {
      isSuccess: isEditDocumentSuccess,
      isError: isEditDocumentError,
      isLoading: isEditDocumentLoading,
    },
  ] = useToggleFavoriteMutation();
  useEffect(() => {
    if (isEditDocumentSuccess) {
      PopUpAlert("success", tGeneral("done"), "File marked successfully");
    }
  }, [isEditDocumentSuccess]);
  useEffect(() => {
    if (isEditDocumentError) {
      PopUpAlert(
        "error",
        tGeneral("error"),
        tDocuments("fileMarkedSuccessfully"),
      );
    }
  }, [isEditDocumentError]);

  const handleToggleFavorite = (rowData) => {
    const body = {
      idObject: rowData.id,
      module: "documents",
    };
    toggleFavorite(body);
  };

  const tableLocalization = {
    pagination: {
      labelRowsPerPage: `${tGeneral("labelRowsPerPage")}`,
      labelDisplayedRows: `{from}-{to} ${tGeneral("of")} {count}`,
      labelRowsSelect: `${tGeneral("labelRowsSelect")}`,
      firstAriaLabel: `${tGeneral("firstAriaLabel")}`,
      firstTooltip: `${tGeneral("firstTooltip")}`,
      previousAriaLabel: `${tGeneral("previousAriaLabel")}`,
      previousTooltip: `${tGeneral("previousTooltip")}`,
      nextAriaLabel: `${tGeneral("nextAriaLabel")}`,
      nextTooltip: `${tGeneral("nextTooltip")}`,
      lastAriaLabel: `${tGeneral("lastAriaLabel")}`,
      lastTooltip: `${tGeneral("lastTooltip")}`,
    },
    toolbar: {
      searchPlaceholder: tGeneral("search"),
    },
    body: {
      emptyDataSourceMessage: tGeneral("emptyDataSourceMessage"),
    },
  };

  return (
    <>
      <TitleWidgets
        title={tGeneral("favorites")}
        actions={titleWidgetsActions}
        searchEnabled
        customSearchFunction={customSearchFunction}
        backEnabled
        supportDisabled
        customBackFunctionEnabled
        customBackFunction={() => {
          dispatch(setSelectedFolderId(""));
          dispatch(setSelectedFolder(null));
          dispatch(setNeedReload(true));
          navigate(-1);
        }}
      />
      {/* File Manager */}
      {dataDocuments.length > 0 ? (
        <>
          {/* Filters */}
          <DocumentFilters
            paramsDocs={paramsDocs}
            setParamsDocs={setParamsDocs}
            userList={userList}
          />

          <Grid container spacing={1} sx={{ mt: 1 }}>
            {/* File Table */}
            <Grid item xs={12}>
              <MaterialTable
                tableRef={tableRef}
                components={{
                  Toolbar: (props) => (
                    <div
                      style={{
                        height: "0px",
                      }}
                    >
                      <MTableToolbar {...props} />
                    </div>
                  ),
                }}
                icons={tableIcons}
                columns={columns}
                data={dataDocuments.filter(function (obj) {
                  return (
                    obj.name
                      .toLowerCase()
                      .includes(searchValue.toLowerCase()) && obj.active
                  );
                })}
                localization={tableLocalization}
                options={{
                  toolbar: false,
                  selection: false,
                  showTitle: false,
                  actionsColumnIndex: -1,
                  search: false,
                  pageSize: 30,
                  pageSizeOptions: [10, 20, 30],
                  emptyRowsWhenPaging: false,
                  headerStyle: {
                    height: "60.5px",
                  },
                  rowStyle: {
                    height: "60.5px",
                    width: "auto",
                  },
                  padding: "dense",
                  overflowY: "auto",
                }}
                style={{ borderRadius: "10px", padding: 0, margin: 0 }}
                isLoading={isLoadingDocuments || isFetchingDocuments}
                onSelectionChange={(rows) => {}}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <EmptyState
            title={tDocuments("noFilesOnFavorites")}
            // content={""}
            module="documents"
            button={<></>}
          />
        </>
      )}
    </>
  );
}

export default FavoriteDocuments;
