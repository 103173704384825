import { ChevronRight, Search } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetCompanyUsersQuery,
  useGetProjectCompaniesQuery,
  useGetProjectDirectoryUsersQuery,
} from "../../features/project/modules/directory/projectDirectoryApiSlice";

function CompanySelector({
  idEnterprise,
  idProject,
  selectedCompanies,
  selectedUsers,
  onChangeCompanies,
  onChangeUsers,
}) {
  const { t: tGeneral } = useTranslation("general");
  const [idCompany, setIdCompany] = useState(null);
  const [userSearch, setUserSearch] = useState("");
  const [companySearch, setCompanySearch] = useState("");

  const { data: companiesData } = useGetProjectCompaniesQuery({
    idEnterprise,
    idProject,
  });
  const { data: companyUsersData } = useGetCompanyUsersQuery(
    { idCompany, idProject, bActive: true },
    {
      skip: !idCompany,
    },
  );

  const companyUsers = useMemo(() => {
    if (companyUsersData) {
      return companyUsersData.filter(
        (user) =>
          (user.name ?? "")
            .toLowerCase()
            .match(new RegExp(userSearch.toLowerCase(), "g")) ||
          userSearch === "",
      );
    }
    return [];
  }, [companyUsersData, userSearch]);

  const companies = useMemo(() => {
    if (companiesData) {
      return companiesData.filter(
        (company) =>
          company.name.toLowerCase().includes(companySearch.toLowerCase()) ||
          companySearch === "",
      );
    }
    return [];
  }, [companiesData, companySearch]);

  const isAllSelected = useMemo(() => {
    if (selectedUsers && companyUsers) {
      return companyUsers.every((user) =>
        selectedUsers.some((usr) => usr === user.id),
      );
    }
    return false;
  }, [selectedUsers, companyUsers]);

  const handleSelectAll = () => {
    const selUsers = selectedUsers ?? [];
    if (isAllSelected) {
      onChangeUsers(
        selectedUsers.filter(
          (user) => companyUsers.map((user) => user.id).indexOf(user) === -1,
        ),
      );
    } else {
      const allUsersSelected = [
        ...new Set([...selUsers, ...companyUsers.map((user) => user.id)]),
      ];
      onChangeUsers(allUsersSelected);
    }
  };

  const handleSelectCompany = (newIdCompany, select) => {
    const newCompanies = selectedCompanies ?? [];
    if (select) {
      onChangeCompanies([...newCompanies, newIdCompany]);
    } else {
      onChangeCompanies(
        [...newCompanies].filter((idC) => idC !== newIdCompany),
      );
    }
  };

  const handleSelectUser = (idUser, select) => {
    const newUsers = selectedUsers ?? [];

    if (select) {
      onChangeUsers([...newUsers, idUser]);
    } else {
      onChangeUsers([...newUsers].filter((idC) => idC !== idUser));
    }
  };

  return (
    <Box
      overflow="scroll"
      width="100%"
      height="100%"
      maxHeight="calc(100vh - 600px)"
    >
      <Box display="flex" columnGap="0.5rem" height="100%">
        <Box flex={1}>
          <Typography textAlign="center" fontWeight="500">
            {tGeneral("companies")}{" "}
            {selectedCompanies?.length > 0 && `(${selectedCompanies.length})`}
          </Typography>
          <Box>
            <TextField
              value={companySearch}
              onChange={(e) => setCompanySearch(e.target.value)}
              fullWidth
              size="small"
              InputProps={{ startAdornment: <Search /> }}
            />
          </Box>
          <Box sx={{ overflowY: "auto", height: "300px", maxHeight: "300px" }}>
            {companies.map((company) => (
              <Box display="flex" key={company.id} alignItems="center">
                <Checkbox
                  checked={selectedCompanies?.indexOf(company.id) > -1}
                  onChange={(e) =>
                    handleSelectCompany(company.id, e.target.checked)
                  }
                />
                <Button
                  variant="text"
                  fullWidth
                  sx={{
                    justifyContent: "space-between",
                    textTransform: "none",
                    color: "black",
                  }}
                  onClick={() =>
                    setIdCompany((prev) =>
                      prev === company.id ? null : company.id,
                    )
                  }
                >
                  {company.name}
                  <ChevronRight />
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
        <Divider flexItem orientation="vertical" />
        <Box flex={1}>
          <Typography textAlign="center" fontWeight="500">
            {tGeneral("users")}{" "}
            {selectedUsers?.length > 0 && `(${selectedUsers.length})`}
          </Typography>
          {idCompany !== null && (
            <Box>
              <TextField
                value={userSearch}
                onChange={(e) => setUserSearch(e.target.value)}
                fullWidth
                size="small"
                InputProps={{ startAdornment: <Search /> }}
              />
            </Box>
          )}
          <Box sx={{ overflowY: "auto", maxHeight: "270px" }}>
            {idCompany !== null && (
              <Box display="flex" key="select-all-users">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAllSelected}
                      onChange={handleSelectAll}
                    />
                  }
                  label={<Typography>{tGeneral("selectAll")}</Typography>}
                />
              </Box>
            )}
            {idCompany !== null &&
              companyUsers.map((user) => (
                <Box display="flex" key={user.id} sx={{ margin: "5px 2px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedUsers?.indexOf(user.id) > -1}
                        onChange={(e) =>
                          handleSelectUser(user.id, e.target.checked)
                        }
                      />
                    }
                    label={
                      <Box
                        display="flex"
                        alignItems="center"
                        columnGap="0.5rem"
                      >
                        <Avatar src={user.urlAvatar}>
                          {user.name
                            ? `${user.name.split(" ")[0][0]}${
                                user.name.split(" ")[1][0]
                              }`
                            : `${user.firstName[0]}${user.lastName[0]}`}
                        </Avatar>
                        <Typography>
                          {user.name ?? `${user.firstName} ${user.lastName}`}
                        </Typography>
                      </Box>
                    }
                  />
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export { CompanySelector };
