import { createSlice } from "@reduxjs/toolkit";
import { PopUpAlert } from "../../components/PopUpAlert";

const initialState = {
  widgets: {},
  activeWidgetId: null,
  isEditing: false,
  draggedWidget: null,
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    // Reducer to add a new widget
    addNewWidget: (state, action) => {
      const { type, size, rowIndex, colIndex, occupiedIndices } =
        action.payload;
      const id = type; // Generate a unique ID based on its type
      const index = `${rowIndex}-${colIndex}`;

      const newWidgets = { ...state.widgets };

      // Update the new widget positions
      occupiedIndices.forEach((idx) => {
        newWidgets[idx] = { id, type, size, mainIndex: index };
      });

      state.widgets = newWidgets;
      state.activeWidgetId = id; // Set the new widget as active
    },

    // Reducer to delete a widget by its ID
    deleteWidget: (state, action) => {
      const id = action.payload;
      const newWidgets = { ...state.widgets };

      // Iterate through all widgets and delete the one with the matching ID
      Object.keys(newWidgets).forEach((key) => {
        if (newWidgets[key].id === id) {
          delete newWidgets[key];
        }
      });

      state.widgets = newWidgets;
    },

    // Reducer to set the editing state
    setEditing: (state, action) => {
      state.isEditing = action.payload;
    },

    // Reducer to set the active widget ID
    setActiveWidget: (state, action) => {
      state.activeWidgetId = action.payload;
    },

    // Reducer to set the dragged widget data
    setDraggedWidget: (state, action) => {
      state.draggedWidget = action.payload;
    },

    // Reducer to set the entire widgets state
    setWidgets: (state, action) => {
      state.widgets = action.payload;
    },
  },
});

export const {
  addNewWidget,
  deleteWidget,
  setEditing,
  setActiveWidget,
  setDraggedWidget,
  setWidgets,
} = analyticsSlice.actions;

export const selectAllWidgets = (state) => state.analytics.widgets;
export const selectActiveWidgetId = (state) => state.analytics.activeWidgetId;
export const selectIsEditing = (state) => state.analytics.isEditing;
export const selectDraggedWidget = (state) => state.analytics.draggedWidget;

export default analyticsSlice.reducer;
