import { datadogRum } from "@datadog/browser-rum";
import { Delete } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActions,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { PopUpAlert } from "../../../components/PopUpAlert";
import { FilterableUserDropdown, FormHeader } from "../../../components/shared";
import { useAppTourContext } from "../../../components/supportButton/context/context";
import UserAvatar from "../../../components/userAvatars/UserAvatar";
import { selectTheme } from "../../../features/preferences/preferencesSlice";
import {
  useCreateAnnouncementMutation,
  useEditAnnouncementMutation,
  useGetAnnouncementQuery,
} from "../../../features/project/modules/announcements/announcementApiSlice";
import {
  selectCurrentProject,
  selectCurrentProjectUsers,
} from "../../../features/project/projectSlice";

const label = { inputProps: { "aria-label": "Switch demo" } };

function AnnouncementForm(props) {
  const { idAnnouncement } = useParams();
  const navigate = useNavigate();
  const { isEddit, isModal = false, onClose } = props;
  const darkMode = useSelector(selectTheme);
  const theme = useTheme();
  // Translations
  const { t: tAnnouncements } = useTranslation("announcements");
  const { t: tGeneral } = useTranslation("general");

  const {
    state: { tourActive },
    setState: setAppTourState,
  } = useAppTourContext();

  const [createAnnouncement] = useCreateAnnouncementMutation();
  const [editAnnouncement] = useEditAnnouncementMutation();
  const currentProject = useSelector(selectCurrentProject);
  const currentProjectUsers = useSelector(selectCurrentProjectUsers);

  const { data: announcement } = useGetAnnouncementQuery(idAnnouncement, {
    skip: !idAnnouncement,
  });

  const repeatOptions = [
    {
      id: "never",
      value: tAnnouncements("never"),
    },
    {
      id: "everyDay",
      value: tAnnouncements("everyDay"),
    },
    {
      id: "everyWeek",
      value: tAnnouncements("everyWeek"),
    },
    {
      id: "everyTwoWeeks",
      value: tAnnouncements("everyTwoWeeks"),
    },
    {
      id: "everyMonth",
      value: tAnnouncements("everyMonth"),
    },
    {
      id: "everyYear",
      value: tAnnouncements("everyYear"),
    },
  ];
  const dateNow = new Date();
  const [distributionList, setDistributionList] = useState(currentProjectUsers);
  const [selectedDistList, setSelectedDistList] = useState([]);
  const [formState, setState] = useState({
    scheduleForLater: !(
      announcement?.repeatRate === null ||
      announcement?.repeatRate === tAnnouncements("never")
    ),
    repeatRate: tAnnouncements("never"),
    startDate: new Date(),
    endDate: new Date(),
    description: "",
  });
  const [formisValid, setFormisValid] = useState(false);

  function handleChange(event, name) {
    const { value } = event.target;
    if (name === "scheduleForLater") {
      setState({
        ...formState,
        scheduleForLater: event.target.checked,
        repeatRate: "Never",
      });
    } else {
      setState({
        ...formState,
        [name]: value,
      });
    }
  }

  function handleInitDate(d, type) {
    let date = new Date(d);
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);

    if (type === "date") {
      return date.toISOString().split("T")[0];
    }
    const hour = date.toISOString().split("T")[1];
    const formatHour = hour.substring(0, hour.lastIndexOf(":"));
    return formatHour;
  }

  function handleDateChange(date, name, type) {
    const lastDate = formState[name];
    let newDate;
    if (type === "date") {
      newDate = new Date(date);
      newDate.setDate(newDate.getDate() + 1);
      newDate.setHours(lastDate.getHours(), lastDate.getMinutes());
    } else {
      newDate = new Date(formState[name]);
      newDate.setHours(
        date.substring(0, date.indexOf(":")),
        date.substring(date.indexOf(":") + 1, date.length + 1),
      );
    }
    setState({
      ...formState,
      [name]: newDate,
    });
  }

  function handleCancel() {
    PopUpAlert(
      tGeneral("warning"),
      `${tGeneral("cancelled")}!`,
      tGeneral("operations"),
    );
    if (isModal) {
      onClose();
    } else {
      navigate(-1);
    }
  }

  const handleAdd = async () => {
    try {
      const publishDateTemp = new Date();
      const body = {
        idProject: currentProject?.id,
        expirationDate: formState.endDate,
        description: formState.description,
        distributionList: selectedDistList,
        bActive: true,
        bPrivate: true, // TODO: check, not in front
        repeatRate: formState.repeatRate,
        publishDate: formState.startDate,
        bScheduled: formState.scheduleForLater,
      };

      // if (formState.scheduleForLater) {
      //   body.publishDate = new Date(formState.startDate);
      // }
      let success;
      if (formisValid) {
        if (announcement) {
          body.id = announcement.id;
          success = await editAnnouncement(body);
        } else {
          success = await createAnnouncement(body);
        }
        if (success) {
          PopUpAlert(
            "success",
            tGeneral("done"),
            announcement
              ? `${tAnnouncements("announcement")} ${tGeneral(
                  "updatedSuccessfully",
                )}`
              : `${tAnnouncements("announcement")} ${tGeneral(
                  "createdSuccessfully",
                )}`,
          );
          navigate("../announcements");
        }
      } else {
        PopUpAlert("error", "Oh!", tGeneral("pleaseMakeSureAllDataIsCorrect"));
      }
    } catch (error) {
      PopUpAlert("error", "Oh!", tGeneral("errorMessage"));
      datadogRum.addError({
        message: "Announcements - Create/Update - Error",
        stack: error.stack,
        custom: {
          app: "buildpeer-web",
          enterprise: currentProject?.idEnterprise,
          module: "Announcements",
          project: currentProject?.id,
        },
        timestamp: Date.now(),
      });
    }
  };

  const changeDistributionList = (value) => {
    if (value[value.length - 1] === "all") {
      setSelectedDistList(
        selectedDistList.length === distributionList.length
          ? []
          : distributionList.map((user) => user.id),
      );
    } else {
      setSelectedDistList(value);
    }
  };

  const handleDelete = (toDelete) => {
    setSelectedDistList(
      selectedDistList.filter(function (item) {
        return item !== toDelete.id;
      }),
    );
  };

  useEffect(() => {
    // Eddintg form
    if (announcement) {
      const annDistList = announcement.distributionList.map((item) => item.id);
      setState({
        ...formState,
        repeatRate: announcement.repeatRate
          ? announcement.repeatRate
          : formState.repeatRate,
        startDate: announcement.publishDate,
        endDate: announcement.expirationDate,
        description: announcement.description,
      });
      setSelectedDistList(annDistList);
      // Valid description and dist list check (On Edit Announcement)
      if (annDistList.length <= 0 || formState.description.length <= 0) {
        setFormisValid(false);
      } else {
        setFormisValid(true);
      }

      if (tourActive) {
        setTimeout(() => {
          setAppTourState({ run: true, stepIndex: 1 });
        }, 500);
      }
    }
    return () => {};
  }, [announcement]);

  // Valid description and dist list check (On Add Announcement)
  useEffect(() => {
    if (selectedDistList.length <= 0 || formState.description.length <= 0) {
      setFormisValid(false);
    } else {
      setFormisValid(true);
    }
    return () => {};
  }, [formState, selectedDistList]);

  useLayoutEffect(() => {
    if (tourActive) {
      setTimeout(() => {
        setAppTourState({ run: true, stepIndex: 1 });
      }, 500);
    }
  }, []);

  useEffect(() => {
    return () => {
      setState({ run: false, tourActive: false, stepIndex: 0 });
    };
  }, []);

  return (
    <>
      <FormHeader
        isEditing={Boolean(announcement)}
        title={
          announcement
            ? `${tGeneral("edit")} ${tGeneral("announcement")}`
            : tGeneral("newAnnouncement")
        }
        onBack={isModal ? () => onClose() : () => navigate(-1)}
        onDone={handleAdd}
        dataTourNameButton={
          announcement ? "edit-announcement-7" : "add-announcement-7"
        }
      />
      <Grid container spacing={0} alignItems="flex-start" height="90vh">
        {/* Left form */}
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          spacing={0}
          md={8}
          sx={{ padding: 2 }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{ padding: 1 }}
            data-tour={
              announcement ? "edit-announcement-2" : "add-announcement-2"
            }
          >
            <Typography
              sx={{ fontWeight: "bold", fontSize: 12, marginLeft: 2 }}
            >
              {tAnnouncements("programForLater")}
            </Typography>
            <Card sx={{ width: "100%", height: "100%" }}>
              <CardActions sx={{ justifyContent: "space-between" }}>
                <Typography variant="caption">
                  {formState.scheduleForLater
                    ? tAnnouncements("programAnnouncementLater")
                    : tAnnouncements("programAnnouncementNow")}
                </Typography>
                <Switch
                  {...label}
                  checked={formState.scheduleForLater}
                  onChange={(e) => handleChange(e, "scheduleForLater")}
                  color="primary"
                />
              </CardActions>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{ padding: 1 }}
            data-tour={
              announcement ? "edit-announcement-2_1" : "add-announcement-2_1"
            }
          >
            <Box sx={{ margin: 2 }} />
            {formState.scheduleForLater ? (
              <FormControl fullWidth>
                <InputLabel id="repeat-frequency-select">
                  {tAnnouncements("repeat")}
                </InputLabel>
                <Select
                  labelId="repeat-frequency-select"
                  id="repeat-frequency-select"
                  value={formState.repeatRate}
                  label={tAnnouncements("repeat")}
                  onChange={(e) => handleChange(e, "repeatRate")}
                  disabled={!formState.scheduleForLater}
                >
                  {repeatOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ padding: 1 }}
            data-tour={
              announcement ? "edit-announcement-3" : "add-announcement-3"
            }
          >
            <TextField
              fullWidth
              required
              id="description-multiline"
              label={tAnnouncements("description")}
              multiline
              rows={6}
              value={formState.description}
              onChange={(e) => handleChange(e, "description")}
            />
          </Grid>
          {formState.scheduleForLater ? (
            <Grid
              item
              xs={6}
              // marginTop={2}
              alignItems="center"
              justifyContent="center"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  m: 1,
                }}
              >
                <TextField
                  required
                  margin="dense"
                  variant="outlined"
                  type="date"
                  label={tGeneral("startDate")}
                  InputLabelProps={{ shrink: true }}
                  // helpertext="Expiration date *"
                  defaultValue={
                    announcement
                      ? handleInitDate(announcement.publishDate, "date")
                      : dateNow
                  }
                  onChange={(e) =>
                    handleDateChange(e.target.value, "startDate", "date")
                  }
                  sx={{ width: "65%", margin: 1 }}
                  data-tour={
                    announcement
                      ? "edit-announcement-2_2"
                      : "add-announcement-2_2"
                  }
                />
                <TextField
                  required
                  margin="dense"
                  variant="outlined"
                  type="time"
                  label={tGeneral("time")}
                  InputLabelProps={{ shrink: true }}
                  // helpertext="Time *"
                  defaultValue={
                    announcement
                      ? handleInitDate(announcement.publishDate, "time")
                      : null
                  }
                  onChange={(e) =>
                    handleDateChange(e.target.value, "startDate", "time")
                  }
                  sx={{ width: "34%", margin: 1 }}
                  data-tour={
                    announcement
                      ? "edit-announcement-2_3"
                      : "add-announcement-2_3"
                  }
                />
              </Box>
            </Grid>
          ) : null}
          {/* <Grid item xs={12} sm={6} md={6} sx={{ padding: 1 }}>
            <MobileDateTimePicker
              label={tAnnouncements("expirationDate")}
              value={formState.endDate}
              onChange={(e) => handleDateChange(e, "endDate")}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid> */}
          <Grid
            item
            xs={6}
            // marginTop={2}
            alignItems="center"
            justifyContent="center"
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                m: 1,
              }}
            >
              <TextField
                required
                margin="dense"
                variant="outlined"
                type="date"
                label={tGeneral("expirationDate")}
                InputLabelProps={{ shrink: true }}
                // helpertext="Expiration date *"
                defaultValue={
                  announcement
                    ? handleInitDate(announcement.expirationDate, "date")
                    : dateNow
                }
                onChange={(e) =>
                  handleDateChange(e.target.value, "endDate", "date")
                }
                sx={{ width: "65%", margin: 1 }}
                data-tour={
                  announcement ? "edit-announcement-5" : "add-announcement-5"
                }
              />
              <TextField
                required
                margin="dense"
                variant="outlined"
                type="time"
                label={tGeneral("time")}
                InputLabelProps={{ shrink: true }}
                // helpertext="Time *"
                defaultValue={
                  announcement
                    ? handleInitDate(announcement.expirationDate, "time")
                    : dateNow
                }
                onChange={(e) =>
                  handleDateChange(e.target.value, "endDate", "time")
                }
                sx={{ width: "34%", margin: 1 }}
                data-tour={
                  announcement ? "edit-announcement-6" : "add-announcement-6"
                }
              />
            </Box>
          </Grid>
        </Grid>
        {/* Right Form */}
        <Grid
          item
          container
          direction="row"
          justifyContent="space-evenly"
          spacing={0}
          md={4}
          sx={{
            padding: 2,
            borderRadius: 1,
            minHeight: 600,
            backgroundColor: darkMode
              ? "rgba(153, 153, 153, 0.5)"
              : "rgba(153, 153, 153, 0.2)",
            color: darkMode ? "white" : "black",
            height: "100%",
          }}
        >
          <Grid item sx={{ padding: 1, width: "100%" }}>
            <FormControl
              sx={{ width: "100%" }}
              required
              data-tour={
                announcement ? "edit-announcement-4" : "add-announcement-4"
              }
            >
              <FilterableUserDropdown
                title={tGeneral("distributionList")}
                userList={distributionList}
                selectedUsersList={distributionList.filter(
                  (user) =>
                    selectedDistList.findIndex(
                      (creator) => creator === user.id,
                    ) > -1,
                )}
                selectedUsersFormatted={selectedDistList}
                handleUpdateUsersList={(list) => {
                  changeDistributionList(list.map((user) => user.id));
                }}
              />

              {distributionList.map((person) => {
                if (selectedDistList.indexOf(person.id) > -1) {
                  return (
                    <Chip
                      key={person.id}
                      sx={{
                        width: "100%",
                        height: 40,
                        bgcolor: darkMode ? "" : "#f7e4b2",
                        fontSize: "0.875rem",
                        justifyContent: "left",
                        mt: 1,
                        padding: 2,
                        borderRadius: "23px",
                      }}
                      avatar={
                        <UserAvatar
                          sx={{ m: 10 }}
                          key={person.id}
                          name={person.name}
                          size="Small"
                          url={person?.urlAvatar}
                        />
                      }
                      clickable={false}
                      label={person.name}
                      deleteIcon={
                        <Delete style={{ color: "red", marginLeft: "auto" }} />
                      }
                      onDelete={() => handleDelete(person)}
                    />
                  );
                }
                return null;
              })}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default AnnouncementForm;
