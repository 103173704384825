import { datadogRum } from "@datadog/browser-rum";
import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
// Theme
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ReactJoyride from "react-joyride";
import { useDispatch, useSelector } from "react-redux";

import darkTheme from "./assets/theme/darkTheme";
import defaultTheme from "./assets/theme/defaultTheme";
import { selectTheme } from "./features/preferences/preferencesSlice";

import EnterpriseRoutes from "./EnterpriseRoutes";
import PublicRoutes from "./PublicRoutes";
import UserRoutes from "./UserRoutes";

// Redux selectors
import { Tooltip } from "./components/supportButton/components/Tooltip/Tooltip";
import {
  AppTourProvider,
  useAppTourContext,
} from "./components/supportButton/context/context";
import { useGetUserQuery } from "./features/enterprise/enterpriseApiSlice";
import { useGetActiveAnnouncementsQuery } from "./features/project/modules/announcements/announcementApiSlice";
import { useGetActiveInspectionsTemplatesQuery } from "./features/project/modules/inspectionsTemplates/apiSlice";
import { useGetMeetingsQuery } from "./features/project/modules/meetings/meetingApiSlice";
import { useGetActiveRfisQuery } from "./features/project/modules/rfiApiSlice";
import { resetSelectedTasks } from "./features/project/modules/tasks/tasksSlice";
import {
  selectCurrentGuideMe,
  selectCurrentProject,
} from "./features/project/projectSlice";
import {
  selectCurrentUser,
  setCurrentUser,
} from "./features/userSettings/userSettingsSlice";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { ToastProvider } from "./contexts/ToastContext";

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  defaultPrivacyLevel: "mask-user-input",
  env: process.env.REACT_APP_DATADOG_ENV,
  service: "buildpeer-web",
  sessionReplaySampleRate: 100,
  sessionSampleRate: 100,
  site: "us5.datadoghq.com",
  trackLongTasks: true,
  trackResources: true,
  trackUserInteractions: true,
});
datadogRum.startSessionReplayRecording();

function AppTourWrapper() {
  const dispatch = useDispatch();
  const { state, setState } = useAppTourContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const currentGuideMe = useSelector(selectCurrentGuideMe);
  const [rfi, setRfi] = useState({ id: null });
  const [announcement, setAnnouncement] = useState({ id: null });
  const [meeting, setMeeting] = useState({ id: null });
  const [inspectionsTemplate, setInspectionsTemplates] = useState({ id: null });

  const currentProject = useSelector(selectCurrentProject);
  const { data: records, isSuccess } = useGetActiveRfisQuery({
    idProject: currentProject?.id,
  });
  const { data: announcements, isSuccess: getAnnouncementIsSuccess } =
    useGetActiveAnnouncementsQuery(currentProject?.id);

  const { data: meetings, isSuccess: getMeetingsIsSuccess } =
    useGetMeetingsQuery({ idProject: currentProject?.id });

  const params = { idProject: currentProject?.id };

  const {
    data: inspectionsTemplates,
    isSuccess: getActiveInspectionsTemplateIsSuccess,
  } = useGetActiveInspectionsTemplatesQuery(params, { skip: params === null });

  // JoyRide
  const handler = ({ settings }) => {
    return (setState) => {
      setState(settings);
    };
  };
  const run = handler({ settings: { run: false } });
  // -------

  useEffect(() => {
    if (records && isSuccess) {
      if (records.length > 0) {
        setRfi(records[0]);
      }
    }
  }, [records, isSuccess]);

  useEffect(() => {
    if (announcements && getAnnouncementIsSuccess) {
      if (announcements.length > 0) {
        setAnnouncement(announcements[0]);
      }
    }
  }, [announcements, getAnnouncementIsSuccess]);

  useEffect(() => {
    if (meetings && getMeetingsIsSuccess) {
      if (meetings.length > 0) {
        setMeeting(meetings[0]);
      }
    }
  }, [meetings, getMeetingsIsSuccess]);

  useEffect(() => {
    if (inspectionsTemplates && getActiveInspectionsTemplateIsSuccess) {
      if (inspectionsTemplates.length > 0) {
        setInspectionsTemplates(inspectionsTemplates[0]);
      }
    }
  }, [inspectionsTemplates, getActiveInspectionsTemplateIsSuccess]);

  const handleTourCallback = useCallback(
    (data) => {
      const {
        action, // : Actions;
        controlled, // : boolean;
        index, // : number;
        lifecycle, // : Lifecycle;
        origin, // : Origin | null;
        size, // : number;
        status, // : Status;
        step, // : Step;
        type, // : Events;
      } = data;

      if (action === "close" && origin === "keyboard") {
        // do something
      }

      const isStep1 = index === 0;
      const isStep2 = index === 1;
      const isStep3 = index === 2;
      const isStep4 = index === 3;
      const isStep5 = index === 4;
      const isStep6 = index === 5;
      const isStep7 = index === 6;
      const isStep8 = index === 7;
      const isStep9 = index === 8;
      const isStep10 = index === 9;
      const isStep11 = index === 10;

      if (
        type === "tour:start" &&
        isStep1 &&
        (currentGuideMe === "external-contact-edit" ||
          currentGuideMe === "roles-settings" ||
          currentGuideMe === "photos-add" ||
          currentGuideMe === "photos-edit" ||
          currentGuideMe === "photos-view" ||
          currentGuideMe === "photos-export" ||
          currentGuideMe === "tasks-view" ||
          currentGuideMe === "tasks-edit")
      ) {
        run(setState);
      }

      if (type === "step:after" && isStep1 && action === "next") {
        switch (currentGuideMe) {
          case "rfi-view":
            run(setState);
            navigate(`${pathname}/${rfi.id}`, { relative: "path" });
            break;
          case "rfis-add":
            run(setState);
            navigate(`${pathname}/form`);
            break;
          case "rfi-edit":
            run(setState);
            navigate(`${pathname}/form/${rfi.id}`);
            break;
          case "announcement-add":
            run(setState);
            navigate(`${pathname}/form`);
            break;
          case "announcement-edit":
            run(setState);
            navigate(`${pathname}/form/${announcement.id}`);
            break;
          case "meetings-add":
            run(setState);
            navigate(`${pathname}/form`);
            break;
          case "meetings-view":
            run(setState);
            navigate(`${pathname}/${meeting.id}`);
            break;
          case "meetings-edit":
            run(setState);
            navigate(`${pathname}/form/${meeting.id}`);
            break;
          case "roles-add":
            run(setState);
            navigate(`${pathname}/../AddRoles`);
            break;
          case "roles-edit":
            run(setState);
            navigate(`${pathname}/../editRole`);
            break;
          case "inspections-add":
            run(setState);
            navigate(`${pathname}/form`);
            break;
          case "inspections-templates-edit":
            run(setState);
            navigate(`${pathname}/form`);
            break;
          case "daily-reports-add":
            run(setState);
            navigate(`${pathname}/form`);
            break;
          case "photos-add":
          case "photos-edit":
          case "photos-view":
          case "photos-recycle":
          case "drawings-view":
          case "tasks-edit":
            run(setState);
            break;
          case "tasks-add":
            run(setState);
            navigate(`${pathname}/form`);
            break;
          default:
            break;
        }
      }

      if (type === "step:after" && action === "next") {
        if (isStep1) {
          switch (currentGuideMe) {
            case "announcement-view":
            case "directory-users-add":
            case "roles-settings":
            case "external-contact-add":
            case "external-contact-edit":
            case "external-contact-delete":
            case "directory-users-edit":
            case "directory-users-view":
            case "roles-delete":
            case "inspections-add-template":
            case "drawings-add":
            case "drawings-review-and-publish":
            case "drawings-edit":
            case "drawings-recycle":
            case "drawings-export":
            case "drawings-settings":
            case "daily-reports-view":
            case "reports-view-work-progress":
            case "reports-view-problem":
            case "reports-view-delay":
            case "daily-reports-edit":
            case "daily-reports-recycle":
            case "photos-settings":
            case "tasks-view":
            case "tasks-recycle":
            case "tasks-settings":
              run(setState);
              break;
            default:
              break;
          }
        }

        if (isStep2) {
          switch (currentGuideMe) {
            case "docs-add-file":
            case "docs-add-folder":
            case "docs-file-info":
            case "docs-move-to":
            case "docs-settings":
            case "meetings-settings":
            case "drawings-export":
            case "drawings-settings":
            case "daily-reports-settings":
            case "photos-settings":
            case "rfi-settings":
            case "tasks-settings":
              run(setState);
              break;
            case "rfi-recycle":
            case "announcement-recycle":
            case "meetings-recycle":
            case "drawings-recycle":
            case "daily-reports-recycle":
            case "photos-recycle":
            case "tasks-recycle":
              run(setState);
              navigate(`${pathname}/restore`);
              break;
            case "inspections-add-template":
              run(setState);
              navigate(`${pathname}/templates`);
              break;
            case "drawings-review-and-publish":
              run(setState);
              navigate(`${pathname}/review`);
              break;
            default:
              break;
          }
        }

        if (isStep3) {
          switch (currentGuideMe) {
            case "inspections-add-template":
              run(setState);
              navigate(`${pathname}/form`);
              break;
            case "drawings-review-and-publish":
              run(setState);
              break;
            default:
              break;
          }
        }

        if (isStep4) {
          switch (currentGuideMe) {
            case "drawings-settings":
              run(setState);
              break;
            default:
              break;
          }
        }

        if (isStep5) {
          switch (currentGuideMe) {
            case "docs-file-info":
            case "meetings-view":
              run(setState);
              break;
            default:
              break;
          }
        }

        if (isStep6) {
          switch (currentGuideMe) {
            case "meetings-view":
            case "drawings-review-and-publish":
              run(setState);
              break;
            default:
              break;
          }
        }

        if (isStep7) {
          switch (currentGuideMe) {
            case "drawings-review-and-publish":
              run(setState);
              break;
            default:
              break;
          }
        }

        if (isStep8 || isStep9 || isStep10) {
          switch (currentGuideMe) {
            case "inspections-add-template":
              run(setState);
              break;
            default:
              break;
          }
        }

        if (isStep10) {
          switch (currentGuideMe) {
            case "meetings-add":
              run(setState);
              break;
            default:
              break;
          }
        }
      }

      if (type === "tour:end" && action === "next") {
        switch (currentGuideMe) {
          case "meetings-view":
          case "meetings-edit":
          case "docs-file-info":
          case "roles-add":
          case "roles-edit":
          case "inspections-add":
          case "inspections-templates-edit":
          case "drawings-review-and-publish":
          case "drawings-recycle":
          case "daily-reports-add":
          case "reports-view-work-progress":
          case "reports-view-problem":
          case "reports-view-delay":
          case "daily-reports-recycle":
          case "photos-recycle":
          case "meetings-add":
          case "meetings-recycle":
          case "rfis-add":
          case "rfi-edit":
          case "rfi-view":
          case "rfi-recycle":
          case "tasks-add":
          case "tasks-view":
          case "tasks-edit":
          case "tasks-recycle":
            navigate(-1);
            break;
          case "inspections-add-template":
            navigate(-2);
            break;
          default:
            break;
        }
      }

      if (type === "step:after" && action === "prev") {
        if (isStep2) {
          switch (currentGuideMe) {
            case "directory-users-add":
            case "directory-users-edit":
            case "directory-users-view":
            case "drawings-view":
              run(setState);
              break;
            case "rfi-view":
            case "rfis-add":
            case "rfi-edit":
            case "announcement-add":
            case "announcement-view":
            case "announcement-edit":
            // setState({ run: false, stepIndex: 0, tourActive: false });
            case "meetings-add":
            case "meetings-view":
            case "meetings-edit":
            case "roles-add":
            case "roles-edit":
            case "inspections-add":
            case "inspections-templates-edit":
            case "daily-reports-add":
            case "daily-reports-view":
            case "reports-view-work-progress":
            case "reports-view-problem":
            case "reports-view-delay":
            case "daily-reports-edit":
            case "tasks-add":
            case "tasks-view":
              run(setState);
              navigate(-1);
              break;
            case "tasks-edit":
              run(setState);
              dispatch(resetSelectedTasks());
              navigate(-1);
              break;
            default:
              break;
          }
        }
        if (isStep3) {
          switch (currentGuideMe) {
            case "announcement-view":
            case "docs-add-file":
            case "docs-add-folder":
            case "docs-move-to":
            case "docs-settings":
            case "meetings-settings":
            case "drawings-export":
            case "drawings-settings":
            case "daily-reports-settings":
            case "photos-settings":
            case "rfi-settings":
            case "tasks-settings":
              run(setState);
              break;
            case "docs-file-info":
            case "announcement-recycle":
            case "meetings-recycle":
            case "inspections-add-template":
            case "drawings-review-and-publish":
            case "drawings-recycle":
            case "daily-reports-recycle":
            case "photos-recycle":
            case "rfi-recycle":
            case "tasks-recycle":
              run(setState);
              navigate(-1);
              break;
            default:
              break;
          }
        }

        if (isStep4) {
          switch (currentGuideMe) {
            case "docs-file-info":
            case "inspections-add-template":
              run(setState);
              navigate(-1);
              break;
            case "rfi-export":
              run(setState);
            default:
              break;
          }
        }
        if (isStep5) {
          switch (currentGuideMe) {
            case "docs-move-to":
            case "drawings-export":
              run(setState);
              break;
            default:
              break;
          }
        }

        if (isStep6) {
          switch (currentGuideMe) {
            case "drawings-review-and-publish":
              run(setState);
              break;
            default:
              break;
          }
        }

        if (isStep7) {
          switch (currentGuideMe) {
            case "drawings-review-and-publish":
              run(setState);
              break;
            default:
              break;
          }
        }

        if (isStep11) {
          switch (currentGuideMe) {
            case "meetings-add":
            case "meetings-edit":
              run(setState);
              break;
            default:
              break;
          }
        }

        if (size - 1 === index) {
          switch (currentGuideMe) {
            case "docs-add-file":
            case "docs-add-folder":
            case "docs-actions":
            case "docs-file-info":
            case "docs-settings":
            case "meetings-settings":
            case "external-contact-add":
            case "external-contact-edit":
            case "external-contact-delete":
            case "directory-users-add":
            case "directory-users-edit":
            case "directory-users-view":
            case "roles-settings":
            case "roles-delete":
            case "drawings-add":
            case "drawings-review-and-publish":
            case "drawings-edit":
            case "drawings-settings":
            case "daily-reports-settings":
            case "photos-add":
            case "photos-edit":
            case "photos-view":
            case "photos-settings":
            case "drawings-view":
            case "rfi-settings":
            case "tasks-settings":
              run(setState);
              break;
            default:
              break;
          }
        }
      }

      if (action === "update" && lifecycle === "beacon" && type === "beacon") {
        run(setState);
      }

      if (["step:after", "error:target_not_found"].includes(type)) {
        // Update state to advance the tour
        setState({ stepIndex: index + (action === "prev" ? -1 : 1) });
      } else if (["finished", "skipped"].includes(status)) {
        // Need to set our running state to false, so we can restart if we click start again.
        setState({ run: false, stepIndex: 0, tourActive: false });
      }
      /* if (action === 'reset' || lifecycle === 'complete') {
      setState({ run: false, stepIndex: 0, tourActive: false });
    } */
    },
    [rfi, announcement, meeting, currentGuideMe, setState, pathname],
  );

  return (
    <ReactJoyride
      callback={handleTourCallback}
      continuous
      run={state.run}
      stepIndex={state.stepIndex}
      steps={state.steps}
      styles={{
        options: {
          arrowColor: "white",
          backgroundColor: "white",
          primaryColor: "#FBCB04",
          textColor: "black",
          zIndex: 15000,
        },
      }}
      tooltipComponent={Tooltip}
    />
  );
}

function App() {
  const darkMode = useSelector(selectTheme);
  const theme = darkMode ? darkTheme : defaultTheme;
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);

  const { data: user } = useGetUserQuery({}, { skip: currentUser });

  useEffect(() => {
    if (!currentUser && user) {
      dispatch(setCurrentUser(user));
    }
  }, [user, currentUser, dispatch]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <ToastProvider>
          <CssBaseline />
          <AppTourProvider>
            {/* Public Routes */}
            <Routes>
              <Route path="/*" element={<PublicRoutes />} />
            </Routes>

            {/* Enterprise Routes */}
            <Routes>
              <Route path="/*" element={<EnterpriseRoutes />} />
            </Routes>

            {/* User Routes */}
            <Routes>
              <Route path="/*" element={<UserRoutes />} />
            </Routes>
            <AppTourWrapper />
          </AppTourProvider>
        </ToastProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

const testSteps = [
  {
    selector: "[data-tour='first-step']",
    content: "This is step 1",
  },
];

export default App;
