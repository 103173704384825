import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
// MUI
import CircleSharp from "@mui/icons-material/CircleSharp";
import Lock from "@mui/icons-material/Lock";
import LockOpen from "@mui/icons-material/LockOpen";
import {
  Box,
  Card,
  CardActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
// Custom imports
import { useTour } from "@reactour/tour";
import moment from "moment";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { PopUpAlert } from "../../../components/PopUpAlert";
import { CreateFormUsersSide, FormHeader } from "../../../components/shared";
import { useAppTourContext } from "../../../components/supportButton/context/context";
import { DISTRIBUTION_LIST_NAMES } from "../../../constants";
import { selectCurrentEnterprise } from "../../../features/enterprise/enterpriseSlice";
import { useUploadFilesMutation } from "../../../features/files";
import {
  useCreateReportMutation,
  useEditReportMutation,
  useGetReportByIdQuery,
} from "../../../features/project/modules/dailyReports/dailyReportsApiSlice";
import {
  selectCurrentGuideMe,
  selectCurrentProject,
} from "../../../features/project/projectSlice";
import { selectCurrentUser } from "../../../features/userSettings/userSettingsSlice";
import { timeZoneOffset } from "../../../utils/timeZoneOffset";
import { DelayReport } from "./components/DelayReport";
import { Reports } from "./components/Reports";
import { Manpower } from "./components/Manpower";

function DailyReportFormContent({
  category,
  agendas,
  setDeletedAgendas,
  setDeletedTopics,
}) {
  const { watch, setValue } = useFormContext();
  if (category === 4) {
    return (
      <Box width="100%">
        <DelayReport
          category={category}
          delayReport={watch("delayReport")}
          updateReport={(delayReport) => {
            setValue("delayReport", delayReport);
          }}
        />
      </Box>
    );
  }

  if (category === 5) {
    return <Manpower />;
  }

  return (
    <Reports
      category={category}
      agendas={agendas}
      updateAgendas={(value) => setValue("agendas", value)}
      deleteAgenda={(value) => setDeletedAgendas((prev) => [...prev, value])}
      deleteTopic={(value) => setDeletedTopics((prev) => [...prev, value])}
    />
  );
}

function DailyReportsForm() {
  const { idReport, idProject } = useParams();

  const { isOpen, currentStep } = useTour();
  // Hooks
  const navigate = useNavigate();
  const { t: tDailyReports } = useTranslation("dailyReports");
  const { t: tGeneral } = useTranslation("general");

  const currentProject = useSelector(selectCurrentProject);
  const currentEnterprise = useSelector(selectCurrentEnterprise);
  const currentUser = useSelector(selectCurrentUser);
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const validationSchema = yup.object({
    title: yup.string("Enter a valid title").required("This field is required"),
    dueDate: yup
      .string("Enter a valid date")
      .required("This field is required"),
    assignedTo: yup.array().of(yup.string()).min(1, "This field is required"),
    distributionList: yup.array().of(yup.string()).notRequired(),
    creators: yup.array().of(yup.string()),
  });

  const form = useForm({
    defaultValues: {
      title: "",
      dueDate: moment(new Date()).format("YYYY-MM-DD"),
      status: "open",
      bPrivate: false,
      assignedTo: [],
      distributionList: [],
      creators: [currentUser?.id],
      agendas: [],
      category: 1,
      delayReport: {},
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();

  const { reset, watch, handleSubmit, register, setValue } = form;

  const { data: report } = useGetReportByIdQuery(idReport, { skip: !idReport });
  // Mutations
  const [createReport, { isSuccess, isLoading, isError }] =
    useCreateReportMutation();
  const [
    editReport,
    {
      isSuccess: isEditSuccess,
      isLoading: isEditLoading,
      isError: isEditError,
    },
  ] = useEditReportMutation();
  const [uploadFiles] = useUploadFilesMutation();
  const statusOptions = [
    {
      value: "open",
      label: tGeneral("open_action").toUpperCase(),
      color: "#5ce381",
    },
    {
      value: "draft",
      label: tGeneral("draft").toUpperCase(),
      color: "#ffc900",
    },
  ];
  const categoryOptions = [
    {
      value: 1,
      label: tDailyReports("basic"),
      color: "#BFBFBF",
    },
    {
      value: 2,
      label: tDailyReports("workProgress"),
      color: "#5FC5F4",
    },
    {
      value: 3,
      label: tDailyReports("problem"),
      color: "#ED8B23",
    },
    {
      value: 4,
      label: tDailyReports("delay"),
      color: "#E9569F",
    },
    {
      value: 5,
      label: tDailyReports("manpower"),
      color: "#CB6CE6",
    },
  ];
  const [isModuleLoading, setIsModuleLoading] = useState(false);
  const [deletedTopics, setDeletedTopics] = useState([]);
  const [deletedAgendas, setDeletedAgendas] = useState([]);

  const category = watch("category");
  const delayReport = watch("delayReport");

  const goBack = () => {
    if (idReport) {
      navigate("./../..");
      return;
    }
    navigate("./..");
  };

  useEffect(() => {
    if (isSuccess || isEditSuccess) {
      goBack();
      setIsModuleLoading(false);
    } else if (isError || isEditError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      setIsModuleLoading(false);
    }
  }, [isSuccess, isError, isEditSuccess, isEditError, goBack, tGeneral]);

  useEffect(() => {
    if (
      (!idReport || report?.agendas?.length === 0) &&
      (!isOpen || currentStep === 9)
    ) {
      setValue("agendas", [
        {
          idLocation: null,
          idAux: Date.now().toString(),
          topics: [],
          order: 1,
          advance: 0,
          idUnit: null,
          cost: 0,
          currency: "",
          risk: "",
        },
      ]);
    }
  }, [idReport, report, setValue, isOpen, currentStep, category]);

  useEffect(() => {
    if (!idReport || !report?.delayReport) {
      setValue("delayReport", {
        delayDays: 0,
        delayHours: 0,
        files: [],
        createDate: new Date(),
        idTopic: uuidv4(),
        description: "",
      });
    }
  }, [idReport, report, setValue, category]);

  const onSubmit = async (values) => {
    setIsModuleLoading(true);
    const [year, month, day] = values.dueDate.split("-");
    const body = {
      idProject: currentProject?.id,
      title: values?.title,
      dueDate: `${month}-${day}-${year}`,
      bPrivate: values?.bPrivate,
      status: values?.status,
      assignedTo: values?.assignedTo,
      distributionList: values?.distributionList,
      creators: values?.creators,
      category: values?.category,
    };

    const agendas = [...values.agendas];
    const uploadFilesPromises = [];
    if (values?.category < 4) {
      agendas.forEach((agenda) => {
        const topics = [...agenda.topics];
        topics.forEach((topic) => {
          const files = [...topic.files];
          const filesFormData = new FormData();
          files.forEach((file) => {
            filesFormData.append("files", file);
          });
          uploadFilesPromises.push(
            uploadFiles({
              idObject: topic.id,
              moduleName: "dailyReports",
              body: filesFormData,
            }),
          );
        });
      });
    } else if (values?.category === 4) {
      const files = [...(values?.delayReport.files || [])];
      const filesFormData = new FormData();
      files.forEach((file) => {
        filesFormData.append("files", file);
      });
      uploadFilesPromises.push(
        uploadFiles({
          idObject: values?.delayReport.idTopic,
          moduleName: "dailyReports",
          body: filesFormData,
        }),
      );
      body.delayReport = { ...values?.delayReport };
    } else if (values?.category === 5) {
      body.manpower = values.manpower;
    }
    await Promise.all(uploadFilesPromises);

    body.agendas = agendas;

    if (idReport) {
      body.id = idReport;
      body.idEnterprise = currentEnterprise?.id;
      body.deletedAgendas = deletedAgendas;
      body.deletedTopics = deletedTopics;
      editReport(body);
    } else {
      createReport(body);
    }
  };

  useEffect(() => {
    if (report) {
      reset({
        title: report?.title,
        dueDate: moment(timeZoneOffset(new Date(report?.dueDate))).format(
          "YYYY-MM-DD",
        ),
        status: report?.status,
        bPrivate: report?.bPrivate,
        assignedTo: report?.assignedTo || [],
        distributionList: report?.distributionList || [],
        creators: report?.creators,
        agendas: report?.agendas,
        category: report?.category,
        ...(report?.delayReport && {
          delayReport: {
            id: report?.delayReport.id,
            delayDays: report?.delayReport.delayDays,
            delayHours: report?.delayReport.delayHours,
            files: [...(report?.delayReport.topic.files || [])],
            createDate: report?.delayReport.topic.createDate,
            idTopic: report?.delayReport.topic.id,
            description: report?.delayReport.topic.title,
            idLocation: report?.delayReport.idLocation,
          },
        }),
        manpower: report?.manpower,
      });
    }
  }, [report, reset]);

  const agendas = watch("agendas");

  const hasNoAgendas = useMemo(() => {
    if (agendas) {
      if (agendas.length === 0) {
        return true;
      }
      let disabled = false;

      agendas.forEach((agenda) => {
        if (agenda.topics.length === 0) {
          disabled = true;
        }
      });

      if (disabled) return disabled;

      return agendas?.findIndex((agenda) => agenda.idLocation === null) > -1;
    }
    return true;
  }, [agendas]);

  const hasNoDelayReport = useMemo(() => {
    if (delayReport) {
      if (
        delayReport.description &&
        delayReport.idLocation &&
        (delayReport.delayDays > 0 || delayReport.delayHours > 0)
      ) {
        return false;
      }
    }
    return true;
  }, [delayReport]);

  const bPrivate = watch("bPrivate");

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 1 &&
      (currentGuideMe === "daily-reports-add" ||
        currentGuideMe === "daily-reports-edit" ||
        currentGuideMe === "reports-edit-work-progress" ||
        currentGuideMe === "reports-edit-problem" ||
        currentGuideMe === "reports-edit-delay")
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe, setState]);

  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: "100%",
          height: "95vh",
          overflow: "hidden",
        }}
      >
        <FormHeader
          isEditing={Boolean(idReport)}
          onBack={goBack}
          title={
            idReport ? tDailyReports("editReport") : tDailyReports("addReport")
          }
          isSubmit
          isDoneDisabled={
            (category < 4 && hasNoAgendas) ||
            (category === 4 && hasNoDelayReport)
          }
          dataTourNameButton={
            idReport ? "edit-dailyReport-14" : "add-dailyReport-14"
          }
        />
        <Box
          display="flex"
          columnGap="10px"
          overflow="hidden"
          height="90vh"
          maxHeight="90vh"
        >
          {/* Left side of content: Form */}
          <Box flex={2} display="flex" flexDirection="column" overflow="hidden">
            {/* First 4 inputs */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                columnGap: "10px",
                rowGap: "20px",
                p: "10px",
              }}
            >
              {/* Title */}
              <TextField
                required
                fullWidth
                name="title"
                label={tGeneral("title")}
                {...register("title")}
                InputLabelProps={{ shrink: watch("title") !== "" }}
                data-tour="add-dailyReport-2"
              />
              {/* Due Date */}
              <TextField
                required
                fullWidth
                name="dueDate"
                label={tDailyReports("dueDate")}
                type="date"
                {...register("dueDate")}
                data-tour="add-dailyReport-3"
              />
              {/* Status */}
              <FormControl fullWidth data-tour="add-dailyReport-4">
                <InputLabel id="status-label">{tGeneral("status")}</InputLabel>
                <Select
                  required
                  labelId="status-label"
                  name="status"
                  variant="outlined"
                  type="text"
                  label={tGeneral("status")}
                  select
                  {...register("status")}
                  value={watch("status")}
                  data-tour="add-dailyReport-4"
                  MenuProps={{
                    disableScrollLock: true,
                    MenuListProps: {
                      "data-tour": "highlighted-add-dailyReport-4",
                    },
                  }}
                  {...(isOpen && currentStep < 4 && { open: true })}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Box display="flex" alignItems="center">
                        <CircleSharp sx={{ color: option.color, mr: 2 }} />
                        {option.label}
                      </Box>
                    </MenuItem>
                  ))}
                  {report?.status === "close" && (
                    <MenuItem value="close">
                      <Box display="flex" alignItems="center">
                        <CircleSharp sx={{ color: "#B0B7C1", mr: 2 }} />
                        {tGeneral("close").toUpperCase()}
                      </Box>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              {/* Private / Public */}
              <Card sx={{ width: "100%", height: "100%" }}>
                <CardActions
                  sx={{ justifyContent: "space-between", alignItems: "center" }}
                  data-tour="add-dailyReport-5"
                >
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    marginLeft={1}
                  >
                    {bPrivate ? <Lock /> : <LockOpen />}
                    <Typography marginLeft={2}>
                      {bPrivate ? tGeneral("private") : tGeneral("public")}
                    </Typography>
                  </Box>
                  <Switch
                    name="bPrivate"
                    checked={bPrivate}
                    onChange={(e) => setValue("bPrivate", e.target.checked)}
                    color="primary"
                  />
                </CardActions>
              </Card>
            </Box>
            <Box
              sx={{
                borderBottom: "1px solid #E1E1E1",
                p: "10px",
              }}
            >
              {/* Category */}
              <FormControl fullWidth data-tour="add-dailyReport-9">
                <InputLabel id="category-label">
                  {tGeneral("category")}
                </InputLabel>
                <Select
                  required
                  labelId="category-label"
                  name="category"
                  variant="outlined"
                  type="text"
                  label={tGeneral("category")}
                  select
                  {...register("category")}
                  value={category}
                  data-tour="add-dailyReport-9"
                  MenuProps={{
                    disableScrollLock: true,
                    MenuListProps: {
                      "data-tour": "highlighted-add-dailyReport-9",
                    },
                  }}
                  disabled={Boolean(idReport)}
                  // {...(isOpen && currentStep < 4 && { open: true })}
                >
                  {categoryOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Box display="flex" alignItems="center">
                        <CircleSharp sx={{ color: option.color, mr: 2 }} />
                        {option.label}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {/* Report Items Form */}
            <Box marginY="10px" pl="10px" flex={1} sx={{ overflowY: "auto" }}>
              <DailyReportFormContent
                agendas={agendas}
                category={category}
                setDeletedAgendas={setDeletedAgendas}
                setDeletedTopics={setDeletedTopics}
              />
            </Box>
          </Box>
          <CreateFormUsersSide
            distributionListName={DISTRIBUTION_LIST_NAMES.dailyReports}
            lists={[
              {
                label: tGeneral("creators"),
                name: "creators",
                canRemoveSelf: false,
                currentUserId: currentUser.id,
                dataTourName: "add-dailyReport-6",
              },
              {
                label: tGeneral("assigned"),
                name: "assignedTo",
                dataTourName: "add-dailyReport-7",
              },
            ]}
            distributionListDataTour="add-dailyReport-8"
            height="89vh"
          />
        </Box>
      </form>
      <LoadingOverlay open={isLoading || isEditLoading || isModuleLoading} />
    </FormProvider>
  );
}

export default DailyReportsForm;
