import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import WebViewer from "@pdftron/webviewer";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  useAddDisciplineMutation,
  useAddSetMutation,
  useAssignIdAuxMutation,
  useEditDisciplineMutation,
  useGetDisciplinesQuery,
  useGetDrawingIdsQuery,
  useGetRevisionQuery,
  useGetSetsQuery,
  useUpdateDrawingMutation,
  useUpdateSetMutation,
} from "../../features/drawings";
import { AutocompleteCell } from "../../views/projectDashboard/Drawings/UploadDrawings/DrawingRow/AutocompleteCell";
import { UpdateTextFieldModal } from "../shared";
import { PopUpAlert } from "../PopUpAlert";
import extractFileName from "../../utils/text";

function ModalApproveDrawing({ isOpen, onClose, idRevision }) {
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editing, setEditing] = useState(null);

  const { data: revision } = useGetRevisionQuery(idRevision, {
    skip: !isOpen || !idRevision,
  });

  const viewerRef = useRef(null);
  const { register, watch, setValue, handleSubmit, control, reset, getValues } =
    useForm({
      defaultValues: {
        number: 0,
        drawingName: null,
        idDiscipline: "",
        idDrawing: null,
        idSet: null,
        date: null,
      },
    });
  const { data: disciplines } = useGetDisciplinesQuery(idProject);
  const { data: drawingIds } = useGetDrawingIdsQuery(idProject);
  const { data: sets } = useGetSetsQuery(idProject);

  const [addSet, { isLoading: isAddSetLoading }] = useAddSetMutation();
  const [addDiscipline, { isLoading: isAddDisciplineLoading }] =
    useAddDisciplineMutation();
  const [editDiscipline, { isLoading: isEditDisciplineLoading }] =
    useEditDisciplineMutation();
  const [updateSet, { isLoading: isUpdateSetLoading }] = useUpdateSetMutation();
  const [assignIdAux] = useAssignIdAuxMutation();

  const [
    updateDrawing,
    {
      isLoading: isUpdateDrawingLoading,
      isSuccess: isUpdateDrawingSuccess,
      isError: isUpdateDrawingError,
      eror: updateDrawingError,
    },
  ] = useUpdateDrawingMutation();

  useEffect(() => {
    if (isUpdateDrawingSuccess) {
      PopUpAlert(
        "success",
        tGeneral("success"),
        tDrawings("editedDrawingMessage"),
      );
      onClose();
    }
    if (isUpdateDrawingError) {
      PopUpAlert("error", "Error", updateDrawingError);
    }
  });

  const onSubmit = (data) => {
    updateDrawing({
      idDrawing: revision?.drawing?.id,
      idRevision,
      idSet: data.idSet,
      idDiscipline: data.idDiscipline,
      date: data.date,
      name: data.drawingName,
    });
  };

  useEffect(() => {
    if (viewerRef.current && revision && isModalOpen) {
      WebViewer(
        {
          licenseKey: process.env.REACT_APP_APRYSE_LICENSE_KEY,
          path: "/pdftron",
          enableMeasurement: true,
          disabledElements: ["header", "toolsHeader"],
          css: "/pdfTron.css",
          initialDoc: revision.pdfFile,
        },
        viewerRef.current,
      ).then((instance) => {
        const { documentViewer, Tools } = instance.Core;
        const newTool = documentViewer.getTool(Tools.ToolNames.PAN);
        documentViewer.setToolMode(newTool);

        documentViewer.addEventListener("documentLoaded", () => {
          const height = documentViewer.getPageHeight(1);
          const width = documentViewer.getPageWidth(1);
          const zoomLevel = instance.UI.getZoomLevel();
          documentViewer.zoomTo(zoomLevel + 1.2, width * 1.17, height * 1.18);
        });
      });
    }
  }, [viewerRef, revision, isModalOpen]);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const handleAddDiscipline = async ({ newValue: name }) => {
    await addDiscipline({ idProject, name });
  };

  const disciplineObjects = useMemo(() => {
    if (disciplines) {
      return disciplines.map((discipline) => ({
        value: discipline.id,
        label: discipline.name,
      }));
    }
    return [];
  }, [disciplines]);

  const handleUpdate = (newValue) => {
    if (editing.type === "idAux") {
      updateDrawing({ idDrawing: revision.idDrawing, idAux: newValue });
    } else if (editing.type === "idDiscipline") {
      editDiscipline({ id: editing.idObject, name: newValue });
    } else if (editing.type === "idSet") {
      updateSet({ id: editing.idObject, name: newValue });
    }
  };

  const fileName = useMemo(() => {
    if (revision) {
      const urlSplitted = revision.pdfFile.split("/");
      const fileNameFormatted = urlSplitted[urlSplitted.length - 1];
      return extractFileName(fileNameFormatted);
    }
    return "";
  }, [revision]);

  useEffect(() => {
    if (revision) {
      reset({
        number: revision?.number,
        drawingName: revision?.drawing?.name ?? fileName.replace(".pdf", ""),
        idDiscipline: revision?.drawing?.idDiscipline,
        bPrivate: revision?.drawing?.bPrivate,
        idDrawing: revision?.drawing?.id,
        idSet: revision?.idSet,
      });
    }
  }, [revision, reset, fileName]);

  const handleChangeIdAux = (newValue) => {
    const selectedDrawing = drawingIds.find((d) => d.id === newValue);
    setValue("idDrawing", newValue);
    setValue("drawingName", selectedDrawing?.name);
  };

  const handleAddIdAux = (idAux) => {
    const name = getValues("drawingName");
    assignIdAux({
      idAux,
      name,
      idRevision,
      idProject,
    });
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card
          sx={{
            borderRadius: "20px",
            width: "60%",
            minHeight: "70%",
            p: "20px 40px",
          }}
        >
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            display="flex"
            flexDirection="column"
          >
            <Box display="flex" alignItems="center">
              <Typography fontWeight="bold" fontSize="1.563rem" flex={1}>
                {tDrawings("drawingViewer")}
              </Typography>
              <IconButton onClick={onClose} disabled={isUpdateDrawingLoading}>
                <Close />
              </IconButton>
            </Box>
            <Divider sx={{ my: "20px" }} />
            <Box display="flex" flex={1} columnGap="10px">
              <Box flex={3}>
                <Box
                  ref={viewerRef}
                  className="webviewer"
                  sx={{ width: "100%", height: "100%" }}
                />
              </Box>
              <Box
                flex={2}
                display="flex"
                flexDirection="column"
                rowGap="30px"
                data-tour="review-and-publish-drawings-4"
              >
                <Box display="flex" columnGap="9px">
                  <Box flex={1}>
                    <Typography>ID</Typography>
                    <AutocompleteCell
                      onChange={handleChangeIdAux}
                      options={
                        drawingIds?.map((d) => ({
                          label: d.idAux,
                          value: d.id,
                        })) ?? []
                      }
                      value={watch("idDrawing") ?? null}
                      onEdit={(value, label) =>
                        setEditing({
                          type: "idAux",
                          value: label,
                          idObject: value,
                          title: "ID Aux",
                          label: "ID Aux",
                        })
                      }
                      onCreate={handleAddIdAux}
                    />
                  </Box>
                  <Box flex={1}>
                    <Typography>{tDrawings("# Rev")}</Typography>
                    <TextField
                      disabled
                      size="small"
                      required
                      sx={{
                        width: "auto",
                        backgroundColor: "#F0F2F4",
                        flex: 1,
                      }}
                      {...register("number")}
                    />
                  </Box>
                </Box>
                <Box width="100%">
                  <Typography>{tDrawings("drawingName")}</Typography>
                  <Controller
                    name="drawingName"
                    control={control}
                    disabled={!watch("idDrawing")}
                    render={({ field }) => (
                      <TextField fullWidth {...field} size="small" />
                    )}
                  />
                </Box>
                <Box>
                  <Typography>{tDrawings("discipline")}</Typography>
                  <AutocompleteCell
                    isLoading={isAddDisciplineLoading}
                    options={disciplineObjects}
                    onChange={(newVal) => setValue("idDiscipline", newVal)}
                    value={watch("idDiscipline") ?? null}
                    onCreate={(name) => handleAddDiscipline({ newValue: name })}
                    onEdit={(value, label) =>
                      setEditing({
                        type: "idDiscipline",
                        value: label,
                        title: tDrawings("discipline"),
                        label: tDrawings("discipline"),
                        idObject: value,
                      })
                    }
                  />
                </Box>
                <Box>
                  <Typography>Set</Typography>
                  <AutocompleteCell
                    options={
                      sets?.map((set) => ({
                        label: set.name,
                        value: set.id,
                      })) ?? []
                    }
                    isLoading={isAddSetLoading}
                    onChange={(newVal) => setValue("idSet", newVal)}
                    value={watch("idSet") ?? null}
                    onCreate={(name) => addSet({ idProject, name })}
                    onEdit={(value, label) =>
                      setEditing({
                        type: "idSet",
                        value: label,
                        title: "Set",
                        label: "Set",
                        idObject: value,
                      })
                    }
                  />
                </Box>
                <Box>
                  <Typography>{tGeneral("date")}</Typography>
                  <DatePicker
                    sx={{ width: "100%" }}
                    value={watch("date")}
                    onChange={(newVal) => setValue("date", newVal)}
                    slotProps={{ textField: { size: "small" } }}
                  />
                </Box>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" mt="1rem">
              <Button
                variant="contained"
                sx={{ textTransform: "none", borderRadius: "1rem" }}
                type="submit"
                disabled={isUpdateDrawingLoading || !watch("idDrawing")}
              >
                {isUpdateDrawingLoading ? (
                  <CircularProgress size="1rem" />
                ) : (
                  tGeneral("saveChanges")
                )}
              </Button>
            </Box>
          </Box>
        </Card>
      </Modal>

      {editing && (
        <UpdateTextFieldModal
          isOpen={Boolean(editing)}
          label={editing.label}
          onClose={() => setEditing(null)}
          onUpdate={handleUpdate}
          value={editing.value}
          title={editing.title}
          isLoading={isEditDisciplineLoading || isUpdateSetLoading}
        />
      )}
    </>
  );
}

export { ModalApproveDrawing };
