import { Send } from "@mui/icons-material";
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { MentionsInput, Mention } from "react-mentions";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import * as DOMPurify from "dompurify";
import {
  useCommentImageMutation,
  useGetChatQuery,
} from "../../../../features/project/imageApiSlice";
import { useGetProjectUsersQuery } from "../../../../features/project/projectApiSlice";
import { MessageBox } from "../../../question/MessageBox";

const forbidTags = [
  "p",
  "a",
  "form",
  "button",
  "code",
  "dialog",
  "iframe",
  "img",
  "input",
  "kbd",
  "noscript",
  "samp",
  "script",
  "source",
  "textarea",
  "var",
  "video",
];

const mentionInputStyle = {
  input: {
    minHeight: 140,
  },
  highlighter: {
    minHeight: 140,
  },
  control: {
    backgroundColor: "#fff",
  },
  "&multiLine": {
    control: {
      minHeight: 140,
    },
    input: {
      border: "1px solid transparent",
      outline: "none",
      minHeight: 140,
    },
  },
  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      maxHeight: 150,
      overflow: "scroll",
      minWidth: 200,
      maxWidth: 300,
    },
    item: {
      padding: "5px 7px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#FFF5DB",
      },
    },
  },
};

function PhotoChat({ idImage }) {
  const { t: tGeneral } = useTranslation("general");
  const { idProject } = useParams();
  const [newComment, setNewComment] = useState("");
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [onFocus, setOnFocus] = useState(false);
  const { data: projectUsers } = useGetProjectUsersQuery(idProject);
  const [commentImage, { isSuccess, isLoading, isError, error }] =
    useCommentImageMutation();
  const { data: comments } = useGetChatQuery(idImage);

  useEffect(() => {
    if (isSuccess) {
      setNewComment("");
      setTaggedUsers([]);
      setOnFocus(false);
    }
    if (isError) {
      console.error(error);
    }
  }, [isSuccess, isError, error]);

  const handleChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleComment = async () => {
    const users = (projectUsers ?? [])
      .filter(function (obj) {
        return taggedUsers.includes(obj.user.id); // only tagged users
      })
      .map((projectUser) => ({
        id: projectUser.user.id,
        label: `@[${projectUser.user.firstName} ${projectUser.user.lastName}](${projectUser.user.id})`,
        name: `@${projectUser.user.firstName} ${projectUser.user.lastName}`,
      }));

    let updatedComment = newComment;
    const taggedUsersIds = [];
    for (let index = 0; index < users.length; index++) {
      updatedComment = updatedComment.replaceAll(
        `${users[index].label}`,
        function (matched) {
          if (taggedUsersIds.indexOf(users[index].id) === -1)
            taggedUsersIds.push(users[index].id);

          return `${users[index].name}`;
        },
      );
    }

    // Sanitize message
    const cleanMessage = DOMPurify.sanitize(updatedComment, {
      FORBID_CONTENTS: forbidTags,
      FORBID_TAGS: forbidTags,
    });
    if (cleanMessage.length === 0) return;

    await commentImage({
      idObject: idImage,
      comment: cleanMessage,
      files: [],
      module: "photos",
      taggedUsers: taggedUsersIds,
    });
  };

  return (
    <Box mb="20px">
      <Box
        width="100%"
        minHeight="170px"
        sx={{
          borderRadius: 1,
          display: "flex",
          flexDirection: "row",
          border: `1.5px solid ${onFocus ? "#FBCB04" : "lightgray"}`,
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onBlur={(e) => {
          setOnFocus(false);
        }}
      >
        <Box sx={{ width: "95%", minHeight: "170px", padding: 1 }}>
          <MentionsInput
            value={newComment}
            onClick={(e) => {
              setOnFocus(true);
            }}
            onChange={handleChange}
            placeholder={tGeneral("sendSomeoneComment")}
            style={mentionInputStyle}
          >
            <Mention
              trigger="@"
              appendSpaceOnAdd
              allowSpaceInQuery
              data={(projectUsers ?? []).map((projectUser) => ({
                display: `${projectUser.user.firstName} ${projectUser.user.lastName}`,
                urlAvatar: projectUser.user.urlAvatar,
                id: projectUser.user.id,
              }))}
              markup="@[__display__](__id__)"
              displayTransform={(id, display) => {
                return `@${display}`;
              }}
              renderSuggestion={(suggestion, search, highlightedDisplay) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      src={suggestion.urlAvatar}
                      sx={{ width: "30px", height: "30px", mr: "5px" }}
                    />
                    <Typography color="secondary">
                      {suggestion.display}
                    </Typography>
                  </Box>
                );
              }}
              onAdd={(id, display, startPos, endPos) => {
                // To send the ids of the tagged users
                const prevTaggedUsers = [...taggedUsers];
                if (prevTaggedUsers.indexOf(id) === -1)
                  prevTaggedUsers.push(id);
                setTaggedUsers(prevTaggedUsers);
              }}
            />
          </MentionsInput>
        </Box>
        <IconButton
          disabled={isLoading}
          onClick={handleComment}
          color="primary"
          sx={{ marginRight: 1, height: 50 }}
        >
          {isLoading ? <CircularProgress color="primary" /> : <Send />}
        </IconButton>
      </Box>
      {comments?.map((comment) => (
        <Box display="flex" position="relative" mt="20px">
          <Avatar
            src={comment.urlAvatar}
            sx={{ opacity: comment.bActive ? 1 : 0.5 }}
          />
          <MessageBox
            createdBy={`${comment.firstName} ${comment.lastName}`}
            createdDate={comment.createdDate}
            files={comment.files}
            text={comment.comment}
            question
            width="90%"
          />
        </Box>
      ))}
    </Box>
  );
}

export { PhotoChat };
