import TextField from "@mui/material/TextField";
import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "../../../hooks";

const INPUT_DELAY = 250;

function TextFieldWrapper(props) {
  const {
    maxChars = 248,
    showCharCount = false,
    value,
    onChange,
    label,
    ...rest
  } = props;

  const [innerValue, setInnerValue] = useState("");
  const [innerLabel, setInnerLabel] = useState(label);

  useEffect(() => {
    if (showCharCount) {
      setInnerLabel(`${label} (${maxChars - innerValue.length})`);
    }
  }, [innerValue, label, maxChars, showCharCount]);

  useEffect(() => {
    if (value) {
      setInnerValue(value);
    } else {
      setInnerValue("");
    }
  }, [value]);

  const debouncedHandleOnChange = useDebounce(
    // eslint-disable-next-line no-undef
    () => {
      if (onChange) {
        onChange(innerValue);
      }
    },
    INPUT_DELAY,
  );

  const handleOnChange = useCallback((event) => {
    if (event.persist) event.persist();

    const newValue = event.currentTarget.value;

    if (newValue.length <= maxChars) {
      setInnerValue(newValue);
      debouncedHandleOnChange(event);
    }
  }, []);

  return (
    <TextField
      {...rest}
      label={innerLabel}
      value={innerValue}
      onChange={handleOnChange}
    />
  );
}

export default TextFieldWrapper;
