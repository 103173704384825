export const bim = {
  addBim: "BIM 추가",
  progressHelp:
    "파일을 보려면 처리해야 합니다. 이 과정은 몇 분이 소요될 수 있습니다.",
  errorUpload: "파일 업로드 중 오류가 발생했습니다. 지원팀에 문의하십시오.",
  pendingUpload:
    "파일이 업로드 중입니다. 이 작업은 몇 분이 걸릴 수 있으니 나중에 다시 시도하십시오.",
  successUpload: "파일이 성공적으로 업로드되었습니다.",
  deleteBim: "BIM 삭제",
  deleteBimConfirmation: "BIM을 삭제하시겠습니까?",
};
