// @ts-check
/* eslint react/prop-types: 0 */

import { Box, Button, Dialog, DialogContent, TextField, Typography } from "@mui/material";
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import TransitionComponent from "../TransitionComponent";

export default function Import(props) {
  const { client, open, setDataSource, setOpen } = props;

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  const upload = async (file) => {
    setLoading(true);
    try {
      const data = new FormData();
      data.append("file", file);
      // eslint-disable-next-line react/prop-types
      const response = await client.request({
        data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: "/import",
      });
      setDataSource(response.data);
      const Toast = Swal.mixin({
        position: "center",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        toast: true,
      });
      Toast.fire({
        icon: "success",
        title: tProjectSchedule("Success"),
      });
    } catch (error) {
      const Toast = Swal.mixin({
        position: "center",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        toast: true,
      });

      const originalFileName = file.name.split(".")[0];
      const extension = file.name.split(".").pop();

      const errorFileName = `${originalFileName}-error.${extension}`;

      if (error.response && error.response.data.file) {
        const base64File = error.response.data.file;

        const byteCharacters = atob(base64File);
        const byteNumbers = new Array(byteCharacters.length).fill(null).map((_, i) => byteCharacters.charCodeAt(i));
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileURL = URL.createObjectURL(blob);

        Swal.fire({
          icon: "error",
          title: tProjectSchedule("Your file was not imported due to errors."),
          html: `${tProjectSchedule("Please")} <a href="${fileURL}" download="${errorFileName}">${tProjectSchedule(
            "Click here",
          )}</a> ${tProjectSchedule("to download the file and review the errors in the last column.")}`,
          showConfirmButton: false,
          position: "center",
          timerProgressBar: true,
          timer: 5000,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: tProjectSchedule(error.response?.data?.error ? error.response.data.error : error.message),
        });
      }
    }
    setLoading(false);
    setOpen(false);
  };

  const downloadSampleFile = async () => {
    try {
      const response = await client.request({
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET",
        responseType: "blob",
        url: "/download-sample-file",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "project-schedule.xlsx");

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  const populateSampleData = async () => {
    setLoading(true);
    try {
      const response = await client.request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "GET",
        url: "/populate-sample-data",
      });

      setDataSource(response.data);
      const Toast = Swal.mixin({
        position: "center",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        toast: true,
      });
      Toast.fire({
        icon: "success",
        title: tProjectSchedule("Success"),
      });

      setOpen(false);
    } catch (error) {
      console.error("Error sending request to populate sample data", error);

      const Toast = Swal.mixin({
        position: "center",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        toast: true,
      });
      Toast.fire({
        icon: "error",
        title: tProjectSchedule(error.response.data.error ? error.response.data.error : error),
      });
    }
    setLoading(false);
  };

  return (
    <Dialog
      fullWidth
      onClose={() => setOpen(false)}
      open={open}
      PaperProps={{
        className: "project-schedule",
      }}
      scroll="body"
      // @ts-ignore
      TransitionComponent={TransitionComponent}
    >
      <DialogContent
        dividers
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Typography
          fontSize="md"
          fontWeight="bold"
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          {tProjectSchedule("Import from Excel")}
        </Typography>
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            await upload(file);
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              onChange={(event) => {
                // @ts-ignore
                const file = event.target.files[0];
                if (file) {
                  setFile(file);
                }
              }}
              size="small"
              type="file"
            />
            <Button
              color="primary"
              disabled={!file || loading}
              sx={{
                padding: "10px 50px",
              }}
              variant="contained"
              type="submit"
            >
              {loading ? tProjectSchedule("Submitting...") : tProjectSchedule("Submit")}
            </Button>
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <span>
                <a href="#" onClick={() => downloadSampleFile()}>
                  {tProjectSchedule("Click here")}
                </a>{" "}
                {tProjectSchedule(
                  "to download the sample file. Your uploaded file must be based on this template and adhere to the outlined requirements.",
                )}
              </span>
            </Box>
            <Button
              color="primary"
              sx={{
                padding: "10px 50px",
              }}
              variant="contained"
              onClick={() => {
                if (!loading) {
                  populateSampleData();
                }
              }}
            >
              {tProjectSchedule("POPULATE SAMPLE DATA")}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}
