const objectToQueryString = (object) => {
  const queryString = Object.keys(object)
    .map((key) => {
      let value = object[key];

      // We want to avoid the empty values in the params
      if (typeof value === "undefined" || value === null) {
        return "";
      }

      if (typeof value === "object") {
        if (value.length === 0) {
          return "";
        }

        if (Array.isArray(value)) {
          value = value.join(",");
        }
      }

      return `${key}=${value}`;
    })
    .filter((param) => param.length)
    .join("&");

  return queryString.length ? `?${queryString}` : "";
};

export { objectToQueryString };
