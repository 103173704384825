import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { MuiTelInput } from "mui-tel-input";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import {
  useCreateContactMutation,
  useUpdateContactMutation,
} from "../../../features/enterprise/contact/contactApiSlice";
import { selectCurrentEnterprise } from "../../../features/enterprise/enterpriseSlice";
import stringUtil from "../../../utils/string";
import { PopUpAlert } from "../../PopUpAlert";

const ContactFormModal = ({ editingContact, open, setOpen }) => {
  const { t: tGeneral } = useTranslation("general");
  const { t: tContacts } = useTranslation("contacts");
  const { t: tValidation } = useTranslation("validation");
  const currentEnterprise = useSelector(selectCurrentEnterprise);
  const [phoneNumber, setPhoneNumber] = useState("");

  const [
    createContact,
    {
      isLoading: isCreateContactLoading,
      isSuccess: isCreateContactSuccess,
      isError: isCreateContactError,
    },
  ] = useCreateContactMutation();

  const [
    updateContact,
    {
      isLoading: isUpdateContactLoading,
      isSuccess: isUpdateContactSuccess,
      isError: isUpdateContactError,
    },
  ] = useUpdateContactMutation();

  const validationSchema = yup
    .object({
      firstName: yup.string().required(tValidation("required")),
      lastName: yup.string().required(tValidation("required")),
      email: yup.string().email().required(tValidation("required")),
      countryCode: yup
        .number()
        .typeError(tValidation("number"))
        .positive(tValidation("numberPositive"))
        .integer(tValidation("numberInteger"))
        .required(tValidation("required")),
      phone: yup
        .number()
        .typeError(tValidation("number"))
        .positive(tValidation("numberPositive"))
        .integer(tValidation("numberInteger"))
        .required(tValidation("required")),
      company: yup.string().required(tValidation("required")),
    })
    .required();
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    setValue,
  } = useForm({
    defaultValues: {
      firstName:
        stringUtil.capitalizeFirstLetter(editingContact?.firstName) || "",
      lastName:
        stringUtil.capitalizeFirstLetter(editingContact?.lastName) || "",
      email: editingContact?.email || "",
      phone: editingContact?.phone || "",
      countryCode: editingContact?.countryCode || "",
      company: stringUtil.capitalizeFirstLetter(editingContact?.company) || "",
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    if (editingContact) {
      const body = {
        id: editingContact.id,
        ...(isDirty && dirtyFields.firstName && { firstName: data.firstName }),
        ...(isDirty && dirtyFields.lastName && { lastName: data.lastName }),
        ...(isDirty && dirtyFields.email && { email: data.email }),
        ...(isDirty &&
          dirtyFields.countryCode && { countryCode: data.countryCode }),
        ...(isDirty && dirtyFields.phone && { phone: data.phone }),
        ...(isDirty && dirtyFields.company && { company: data.company }),
      };
      // TODO: Figure out why this returns an error
      // It does update but returns error
      await updateContact(body);
    } else {
      const body = {
        idEnterprise: currentEnterprise?.id,
        ...data,
      };
      await createContact(body);
    }
  };

  function handlePhoneNumberChange(newValue) {
    setPhoneNumber(newValue);
    const newCountryCode = newValue.split(" ")[0].slice(1);
    const newPhone = newValue.split(" ").slice(1).join("");
    setValue("countryCode", newCountryCode, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("phone", newPhone, { shouldDirty: true, shouldValidate: true });
  }

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (editingContact?.phone && editingContact?.countryCode) {
      setPhoneNumber(`+${editingContact.countryCode}${editingContact.phone}`);
    }
  }, []);

  useEffect(() => {
    if (isCreateContactError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("generalErrorMessage"));
    }
    if (isCreateContactSuccess) {
      PopUpAlert(
        "success",
        tGeneral("success"),
        tContacts("createContactSuccess"),
      );
      setOpen(false);
    }
  }, [isCreateContactError, isCreateContactSuccess]);

  useEffect(() => {
    if (isUpdateContactError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("generalErrorMessage"));
    }
    if (isUpdateContactSuccess) {
      PopUpAlert(
        "success",
        tGeneral("success"),
        tContacts("updateContactSuccess"),
      );
      setOpen(false);
    }
  }, [isUpdateContactError, isUpdateContactSuccess, isUpdateContactLoading]);

  const isRequestLoading = isCreateContactLoading || isUpdateContactLoading;
  const textFieldSx = {
    mb: 2,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          width: { xs: "90%", md: "40%" },
          padding: "32px 40px",
          borderRadius: "17px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <Typography sx={{ fontSize: "1.5625rem", fontWeight: "600" }}>
            {tContacts(editingContact ? "editContact" : "addContact")}
          </Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label={tGeneral("firstName")}
                sx={textFieldSx}
                error={Boolean(errors?.firstName?.message)}
                helperText={errors?.firstName?.message}
                data-tour="contact-first-name"
                {...field}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label={tGeneral("lastName")}
                sx={textFieldSx}
                error={Boolean(errors?.lastName?.message)}
                helperText={errors?.lastName?.message}
                data-tour="contact-lastname"
                {...field}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label={tGeneral("email")}
                sx={textFieldSx}
                error={Boolean(errors?.email?.message)}
                helperText={errors?.email?.message}
                data-tour="contact-email"
                {...field}
              />
            )}
          />
          <MuiTelInput
            fullWidth
            variant="outlined"
            label={tGeneral("phone")}
            sx={textFieldSx}
            forceCallingCode
            defaultCountry="MX"
            onlyCountries={["MX", "US", "CR", "AR", "CO"]}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            error={Boolean(errors?.phone?.message)}
            helperText={errors?.phone?.message}
            data-tour="contact-phone"
          />
          <Controller
            name="company"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label={tGeneral("company")}
                sx={textFieldSx}
                error={Boolean(errors?.company?.message)}
                helperText={errors?.company?.message}
                data-tour="contact-enterprise"
                {...field}
              />
            )}
          />
          <Box display="flex" justifyContent="space-between" columnGap="16px">
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              sx={{ padding: "16px 0" }}
              onClick={handleClose}
            >
              {tGeneral("cancel")}
            </Button>
            <Button
              fullWidth
              variant="contained"
              sx={{ padding: "16px 0" }}
              type="submit"
              data-tour="contact-add-button"
              disabled={isRequestLoading || !isDirty}
            >
              {isRequestLoading ? (
                <CircularProgress color="primary" />
              ) : (
                tGeneral(editingContact ? "save" : "add")
              )}
            </Button>
          </Box>
        </form>
      </Card>
    </Modal>
  );
};

export default ContactFormModal;
