import React, { useCallback, useEffect } from "react";
import { Route, Routes, useParams } from "react-router-dom";
// Theme
import { AbilityBuilder, createMongoAbility } from "@casl/ability";
import { useAbility } from "@casl/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useDispatch, useSelector } from "react-redux";
import darkTheme from "./assets/theme/darkTheme";
import defaultTheme from "./assets/theme/defaultTheme";
import { selectTheme } from "./features/preferences/preferencesSlice";
// Views
import RequireAuth from "./features/auth/RequireAuth";
// Project Views
import ProjectDashboardLayout from "./components/projectDashboard/ProjectDashboardLayout";
import AdminPanel from "./views/projectDashboard/AdminPanel";
import Analytics from "./views/Analytics";
import AnnouncementForm from "./views/projectDashboard/Announcements/AnnouncementForm";
import AnnouncementRecycle from "./views/projectDashboard/Announcements/AnnouncementRecycle";
import Announcements from "./views/projectDashboard/Announcements/Announcements";
import DailyReportDetails from "./views/projectDashboard/DailyReports/DailyReportDetails";
import DailyReports from "./views/projectDashboard/DailyReports/DailyReports";
import DailyReportsForm from "./views/projectDashboard/DailyReports/DailyReportsForm";
import DailyReportsRecycle from "./views/projectDashboard/DailyReports/DailyReportsRecycle";
import { Dashboard } from "./views/projectDashboard/Dashboard";
import ProjectSchedule from "./views/projectDashboard/ProjectSchedule";
import Delays from "./views/projectDashboard/Delays";
import Deliveries from "./views/projectDashboard/Deliveries";
import Documents from "./views/projectDashboard/Documents/Documents";
import FavoriteDocuments from "./views/projectDashboard/Documents/FavoriteDocuments";
import RecycleDocuments from "./views/projectDashboard/Documents/RecycleDocuments";
import {
  DrawingView,
  Drawings,
  ReviewDrawings,
} from "./views/projectDashboard/Drawings";
import Hours from "./views/projectDashboard/Hours";
import HoursSettings from "./views/projectDashboard/HoursSettings";
import Inspections from "./views/projectDashboard/Inspections";
import InspectionsDetails from "./views/projectDashboard/InspectionsDetails";
import InspectionsForm from "./views/projectDashboard/InspectionsForm";
import InspectionsGraph from "./views/projectDashboard/InspectionsGraph";
import InspectionsRecycle from "./views/projectDashboard/InspectionsRecycle";
import InspectionsTemplates from "./views/projectDashboard/InspectionsTemplates";
import InspectionsTemplatesForm from "./views/projectDashboard/InspectionsTemplatesForm";
import InspectionsTemplatesRecycle from "./views/projectDashboard/InspectionsTemplatesRecycle";
import Issues from "./views/projectDashboard/Issues";
import KPIs from "./views/projectDashboard/KPIs";
import MeetingDetails from "./views/projectDashboard/Meetings/MeetingDetails";
import MeetingForm from "./views/projectDashboard/Meetings/MeetingForm";
import Meetings from "./views/projectDashboard/Meetings/Meetings";
import MeetingsRecycle from "./views/projectDashboard/Meetings/MeetingsRecycle";
import {
  AlbumView,
  PhotoRecycleBin,
  Photos,
} from "./views/projectDashboard/Photos";
import ProjectNotifications from "./views/projectDashboard/ProjectNotifications";
import PunchList from "./views/projectDashboard/PunchList";
import RFIs from "./views/projectDashboard/RFIs";
import RfiForm from "./views/projectDashboard/RfiForm";
import RfiRecycle from "./views/projectDashboard/RfiRecycle";
import { RfiViewDetails } from "./views/projectDashboard/RfiViewDetails";
import SubmittalDetails from "./views/projectDashboard/Submittals/SubmittalDetails";
import SubmittalForm from "./views/projectDashboard/Submittals/SubmittalForm";
import SubmittalRecycle from "./views/projectDashboard/Submittals/SubmittalRecycle";
import Submittals from "./views/projectDashboard/Submittals/Submittals";
import TaskForm from "./views/projectDashboard/Tasks/TaskForm";
import TaskRecycle from "./views/projectDashboard/Tasks/TaskRecycle";
import Tasks from "./views/projectDashboard/Tasks/Tasks";

// Redux selectors
import {
  selectCurrentProject,
  setCurrentProject,
  setCurrentProjectUsers,
  setCurrentRole,
} from "./features/project/projectSlice";

import {
  useGetProjectByIdQuery,
  useGetProjectUsersQuery,
  useGetUserRoleQuery,
} from "./features/project/projectApiSlice";
import { selectCurrentUser } from "./features/userSettings/userSettingsSlice";
// casl

import { selectCurrentEnterprise } from "./features/enterprise/enterpriseSlice";
import CaslContext from "./utils/caslContext";
import { DrawingsRecycleBin } from "./views/projectDashboard/Drawings/DrawingsRecycleBin";
import { ProjectDirectory } from "./views/projectDashboard/ProjectDirectory";
import { Bim } from "./components/projectDashboard/Bim";
import { ProjectNetwork } from "./views/projectDashboard/ProjectNetwork";
import { UploadDrawings } from "./views/projectDashboard/Drawings/UploadDrawings";
import { UploadDrawingsModal } from "./components/drawings/UploadDrawingsModal/UploadDrawingsModal";
import {
  useGetOrderMutation,
  useGetVisibleModulesQuery,
} from "./features/project/projectSettingsApiSlice";
import { setOrder, setVisibles } from "./features/project/projectSettingsSlice";
import { useGetUserQuery } from "./features/enterprise/enterpriseApiSlice";

function ProjectRoutes() {
  const dispatch = useDispatch();

  const { idProject } = useParams();
  const darkMode = useSelector(selectTheme);
  const theme = darkMode ? darkTheme : defaultTheme;

  const ability = useAbility(CaslContext);
  const currentUser = useSelector(selectCurrentUser);
  const currentEnterprise = useSelector(selectCurrentEnterprise);
  // const [getUserRole, { isSuccess: isSuccessRole }] = useGetUserRoleQuery();
  const { data: roleData } = useGetUserRoleQuery({
    idUser: currentUser?.id,
    idProject,
  });

  const [getOrderModules, { isSuccessOrder }] = useGetOrderMutation();

  const currentProject = useSelector(selectCurrentProject);

  const createPermissionRules = useCallback(
    (modules, roles) => {
      const { can, rules, cannot } = new AbilityBuilder(createMongoAbility);
      // TODO: define if planType is going to be used
      if (currentEnterprise?.planType === "user") {
        can("be", "per user");
      } else {
        can("be", "per enterprise");
      }

      modules.forEach((module) => {
        // remove unnecessary modules
        if (
          module !== "id" &&
          module !== "name" &&
          module !== "bActive" &&
          module !== "tableData" &&
          module !== "schedules" &&
          module !== "approvals" &&
          module !== "security" &&
          module !== "functionalReport" &&
          module !== "guarantees" &&
          module !== "orderChanges" &&
          module !== "contracts" &&
          module !== "estimations" &&
          module !== "specifications"
        ) {
          const role = roles[module];

          if (role === 3) {
            // super admin
            can("superAdmin", module);
            can("view", module);
            if (module === "directory") {
              can("editUser", module);
            }
            if (module === "rfis") {
              can("editDraft", module);
              can("editRfi", module);
              can("closeAll", module);
            }
            if (module === "submittals") {
              can("editDraft", module);
              can("editSubmittal", module);
              can("closeAll", module);
            }
            if (module === "documents") {
              can("move", module);
              can("copy", module);
              can("edit", module);
            } else {
              can("edit", module);
            }
            if (module === "drawings") {
              can("annotate", module);
              can("review", module);

              can("reviewAndPublish", module);
              can("add", module);
              can("view", module);
              can("edit", module);
              can("restore", module);
              can("export", module);
              can("settings", module);
            }
            if (module === "meetings") {
              can("add", module);
              can("view", module);
              can("edit", module);
              can("restore", module);
              can("settings", module);
              can("close", module);
            }
            if (module === "inspections") {
              can("add", "inspectionsTemplates");
              can("edit", "inspectionsTemplates");
              can("restore", "inspectionsTemplates");
            }
            if (module === "photos") {
              can("renameAlbum", module);
              can("photoOptions", module);
            }
            if (module === "dailyReports") {
              can("view", module);
              can("edit", module);
              can("export", module);
            }
            if (module === "analytics") {
              can("save", module);
              can("edit", module);
            }
            can("delete", module);
            can("add", module);
            can("restore", module);
            can("settings", module);
            can("share", module);
          } else if (role === 2) {
            // admin
            can("view", module);
            if (module === "directory") {
              can("editUser", module);
            }
            if (module === "rfis") {
              can("editDraft", module);
              can("close", module);
            } else if (module === "directory") {
              can("delete", "directory");
              can("edit", "directory");
              can("add", "directory");
            } else if (module === "submittals") {
              can("editDraft", module);
              can("close", module);
            }
            if (module === "documents") {
              can("delete", module);
              can("move", module);
              can("copy", module);
              can("edit", module);
            } else {
              can("edit", module);
            }
            if (module === "drawings") {
              can("settings", module);
              can("review", module);
            }
            if (module === "photos") {
              can("delete", module);
              can("renameAlbum", module);
              can("photoOptions", module);
            }
            if (module === "inspections") {
              can("add", "inspectionsTemplates");
            }
            if (module === "analytics") {
              can("save", module);
              can("delete", module);
              can("edit", module);
            }
            can("add", module);
            can("settings", module);
          } else if (role === 1) {
            // view only
            can("view", module);
            can("onlyView", module);
          } else if (role === 0) {
            // none
            cannot("view", module);
            can("none", module);
          }

          // USAGE: 'I' is the action and 'a' is the module
          // <Can I="view" a="adminPanel">
          //   <p>I can look at adminPanel</p>
          // </Can>

          // ALL POSSIBLE MODULES ARE: drawings, punchlist, directory, rfis,
          // submittals, deliveries, meetings, photos, tasks, issues,
          // dailyReports, documents, delays, announcements, dashboard, adminPanel
        }
      });
      return rules;
    },
    [currentEnterprise],
  );

  const { data: project } = useGetProjectByIdQuery(idProject);
  const { data: projectUsers } = useGetProjectUsersQuery(idProject);

  const setProjectUsers = async () => {
    if ((projectUsers || []).length > 0) {
      const usersList = projectUsers
        .filter(function (obj) {
          return !obj.bExternal; // only intern users
        })
        .map((user) => ({
          id: user.user.id,
          name: `${user.user.firstName} ${user.user.lastName}`,
          urlAvatar: user.user.urlAvatar,
        }));
      dispatch(setCurrentProjectUsers(usersList));
    }
  };

  const getVisiblesFromDatabase = async (project) => {
    const modulesV = [
      { id: "1", name: "Drawings", visible: true },
      { id: "2", name: "Punch List", visible: true },
      { id: "3", name: "Directory", visible: true },
      { id: "4", name: "RFIs", visible: true },
      { id: "5", name: "Submittals", visible: true },
      { id: "6", name: "Deliveries", visible: true },
      { id: "7", name: "Hours", visible: true },
      { id: "8", name: "Meetings", visible: true },
      { id: "9", name: "Photos", visible: true },
      { id: "10", name: "Tasks", visible: true },
      { id: "11", name: "Inspections", visible: true },
      { id: "12", name: "Issues", visible: true },
      { id: "13", name: "Daily Reports", visible: true },
      { id: "14", name: "Documents", visible: true },
      { id: "15", name: "Delays", visible: true },
      { id: "16", name: "Announcements", visible: true },
      { id: "17", name: "KPIs", visible: true },
    ];

    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const enterpriseUser = await useGetUserQuery().unwrap();

      const body = {
        idProject: project?.id,
        idUser: enterpriseUser?.id,
      };

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const response = await useGetVisibleModulesQuery(body).unwrap();

      if (response) {
        if (response[0].visibleModules.visibleModules.length > 0) {
          dispatch(setVisibles(response[0].visibleModules.visibleModules));
        } else {
          dispatch(setVisibles(modulesV));
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getOrderFromDatabase = async (project) => {
    const modulesO = [
      { id: "1", name: "Drawings", order: 0 },
      { id: "2", name: "Punch List", order: 1 },
      { id: "3", name: "Directory", order: 2 },
      { id: "4", name: "RFIs", order: 3 },
      { id: "5", name: "Submittals", order: 4 },
      { id: "6", name: "Deliveries", order: 5 },
      { id: "7", name: "Hours", order: 6 },
      { id: "8", name: "Meetings", order: 7 },
      { id: "9", name: "Photos", order: 8 },
      { id: "10", name: "Tasks", order: 9 },
      { id: "11", name: "Inspections", order: 9.1 },
      { id: "12", name: "Issues", order: 11 },
      { id: "13", name: "Daily Reports", order: 12 },
      { id: "14", name: "Documents", order: 13 },
      { id: "15", name: "Delays", order: 14 },
      { id: "16", name: "Announcements", order: 15 },
      { id: "17", name: "KPIs", order: 16 },
    ];

    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const enterpriseUser = await useGetUserQuery().unwrap();

      const body = {
        idProject: project?.id,
        idUser: enterpriseUser?.id,
      };

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const response = await getOrderModules(body).unwrap();

      if (response) {
        if (response[0].orderModules.orderModules.length > 0) {
          dispatch(setOrder(response[0].orderModules.orderModules));
        } else {
          dispatch(setOrder(modulesO));
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getRole = async () => {
    try {
      const body = {
        idProject: project?.id,
        idUser: currentUser?.id,
      };

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const response = await useGetUserRoleQuery(body).unwrap();

      if (response) {
        dispatch(setCurrentRole(response[0].role));
      }
    } catch (err) {
      console.error(err);
    }
  };

  // If the user entered a route via direct navigation (browser URL), we need to set the url projectId
  useEffect(() => {
    if (currentProject && project && currentProject?.id !== project?.id) {
      // Set project
      dispatch(setCurrentProject(project));

      // Set project data
      getVisiblesFromDatabase(project);
      getOrderFromDatabase(project);
      getRole();

      // Set project users
      setProjectUsers();
    }
  }, [currentProject, idProject, project, dispatch]);

  useEffect(() => {
    if (idProject && currentUser && roleData) {
      const modules = Object.keys(roleData[0].role);
      const newRules = createPermissionRules(modules, roleData[0].role);
      ability.update(newRules);
    }
  }, [idProject, currentUser, roleData, createPermissionRules, ability]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        {/* CaslContext - for user roles and perimissions */}
        <Routes>
          {/* protected routes */}
          <Route element={<RequireAuth />}>
            {/* project dashboard routes */}

            <Route path="/*" element={<ProjectDashboardLayout />}>
              <Route path="analytics" element={<Analytics />} />
              <Route index path="dashboard" element={<Dashboard />} />
              <Route path="project-schedule" element={<ProjectSchedule />} />
              <Route path="drawings" element={<Drawings />} />
              <Route path="drawings/upload" element={<UploadDrawings />} />
              <Route path="drawings/:idDrawing" element={<DrawingView />} />
              <Route path="drawings/review" element={<ReviewDrawings />} />
              <Route path="drawings/restore" element={<DrawingsRecycleBin />} />
              <Route path="punchList" element={<PunchList />} />
              <Route path="directory" element={<ProjectDirectory />} />
              <Route path="rfi" element={<RFIs />} />
              <Route path="rfi/:idRfi" element={<RfiViewDetails />} />
              <Route path="rfi/restore" element={<RfiRecycle />} />
              <Route path="rfi/restore/:idRfi" element={<RfiViewDetails />} />
              <Route path="rfi/form" element={<RfiForm />} />
              <Route path="rfi/form/:idRfi" element={<RfiForm />} />
              <Route path="submittal" element={<Submittals />} />
              <Route
                path="submittal/:idSubmittal"
                element={<SubmittalDetails />}
              />
              <Route path="submittal/restore" element={<SubmittalRecycle />} />
              <Route
                path="submittal/restore/:idSubmittal"
                element={<SubmittalDetails />}
              />
              <Route path="submittal/form" element={<SubmittalForm />} />
              <Route
                path="submittal/form/:idSubmittal"
                element={<SubmittalForm />}
              />
              <Route path="kpi" element={<KPIs />} />
              <Route path="deliveries" element={<Deliveries />} />
              <Route path="hours" element={<Hours />} />
              <Route path="meetings" element={<Meetings />} />
              <Route path="meetings/restore" element={<MeetingsRecycle />} />
              <Route path="meetings/form" element={<MeetingForm />} />
              <Route
                path="meetings/form/:idMeeting"
                element={<MeetingForm />}
              />
              <Route path="meetings/:idMeeting" element={<MeetingDetails />} />
              <Route path="photos" element={<Photos />} />
              <Route path="photos/:idPhoto" element={<Photos />} />
              <Route path="photos/restore" element={<PhotoRecycleBin />} />
              <Route path="albums/recycle" element={<PhotoRecycleBin />} />
              <Route path="photos/albums/:idAlbum" element={<AlbumView />} />
              <Route
                path="photos/albums/:idAlbum/restore"
                element={<PhotoRecycleBin />}
              />
              <Route path="tasks" element={<Tasks />} />
              <Route path="tasks/:idTask" element={<Tasks />} />
              <Route path="tasks/restore" element={<TaskRecycle />} />
              <Route path="tasks/restore/:idTask" element={<TaskRecycle />} />
              <Route path="tasks/form" element={<TaskForm />} />
              <Route path="inspections" element={<Inspections />} />
              <Route
                path="inspections/restore"
                element={<InspectionsRecycle />}
              />
              <Route path="inspections/form" element={<InspectionsForm />} />
              <Route
                path="inspections/templates"
                element={<InspectionsTemplates />}
              />
              <Route
                path="inspections/templates/restore"
                element={<InspectionsTemplatesRecycle />}
              />
              <Route
                path="inspections/templates/form"
                element={<InspectionsTemplatesForm />}
              />
              <Route
                path="inspections/:idInspection/graph"
                element={<InspectionsGraph />}
              />
              <Route
                path="inspections/:idInspection"
                element={<InspectionsDetails />}
              />
              <Route path="issues" element={<Issues />} />
              <Route path="dailyReports" element={<DailyReports />} />
              <Route
                path="dailyReports/:idReport"
                element={<DailyReportDetails />}
              />
              <Route
                path="dailyReports/restore/:idReport"
                element={<DailyReportDetails />}
              />
              <Route
                path="dailyReports/restore"
                element={<DailyReportsRecycle />}
              />
              <Route
                path="dailyReports/form/:idReport"
                element={<DailyReportsForm />}
              />
              <Route path="dailyReports/form" element={<DailyReportsForm />} />
              <Route path="documents" element={<Documents />} />
              <Route path="documents/:idDocument" element={<Documents />} />
              <Route
                path="documents/favorites"
                element={<FavoriteDocuments />}
              />
              <Route path="documents/restore" element={<RecycleDocuments />} />
              <Route path="delays" element={<Delays />} />
              <Route path="announcements" element={<Announcements />} />
              <Route
                path="announcements/:idAnnouncement"
                element={<Announcements />}
              />
              <Route
                path="announcements/restore"
                element={<AnnouncementRecycle />}
              />
              <Route path="announcements/form" element={<AnnouncementForm />} />
              <Route
                path="announcements/form/:idAnnouncement"
                element={<AnnouncementForm />}
              />
              <Route path="adminPanel" element={<AdminPanel />} />
              <Route
                path="projectNotifications"
                element={<ProjectNotifications />}
              />
              <Route path="hoursSettings" element={<HoursSettings />} />
              <Route path="bim" element={<Bim />} />
              <Route path="bim/:idBim" element={<Bim />} />
              <Route path="projectNetwork" element={<ProjectNetwork />} />
            </Route>
          </Route>
        </Routes>
        <UploadDrawingsModal />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default ProjectRoutes;
