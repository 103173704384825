import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
// MUI Components
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
// MUI Icons
import {
  Apartment,
  Check,
  EditNote,
  LocationOn,
  RemoveRedEye,
  Share,
} from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useSelector } from "react-redux";

import Calendar from "../../../assets/calendar.png";
import Delivery from "../../../assets/delivery.svg";
import Star from "../../../assets/star.svg";
import Time from "../../../assets/rfi/time.svg";
// Custom imports
import LoadingOverlay from "../../../components/LoadingOverlay";
import { PopUpAlert } from "../../../components/PopUpAlert";
import {
  useGetReportByIdQuery,
  useSetReportStatusMutation,
  useShareDailyReportMutation,
} from "../../../features/project/modules/dailyReports/dailyReportsApiSlice";
import ReportLocationDetailsTable from "./components/ReportLocationDetailsTable";
import dailyReportsUtil from "./utils/dailyReports";

import {
  DetailsHeader,
  ModalLastEdit,
  ModalSeenBy,
  ModalShare,
  TooltipUsers,
} from "../../../components/shared";
import { selectCurrentUser } from "../../../features/userSettings/userSettingsSlice";
import { selectCurrentToken } from "../../../features/auth/authSlice";
import { getIcon } from "../Dashboard/Weather/icons";
import CaslContext from "../../../utils/caslContext";
import DelayReportDetails from "./components/DelayReportDetails";
import { useAppTourContext } from "../../../components/supportButton/context/context";
import { selectCurrentGuideMe } from "../../../features/project/projectSlice";
import SkeletonLoading from "../../../components/shared/SkeletonLoading";
import { ManpowerDetails } from "./components/ManpowerDetails";
import pdf from "../../../utils/pdf";

function ReportDetail({ report }) {
  if (report?.category === 4) {
    return <DelayReportDetails report={report} />;
  }
  if (report?.category === 5) {
    return <ManpowerDetails report={report} />;
  }
  return <ReportLocationDetailsTable report={report} />;
}

function DailyReportDetails() {
  const navigate = useNavigate();
  const { idEnterprise, idProject, idReport } = useParams();
  const { t: tDailyReports } = useTranslation("dailyReports");
  const { t: tGeneral } = useTranslation("general");
  const { t: tWeather } = useTranslation("weather");
  // State
  const [isLastSeenOpen, setIsLastSeenOpen] = useState(false);
  const [isLastEditOpen, setIsLastEditOpen] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const token = useSelector(selectCurrentToken);
  const ability = useContext(CaslContext);
  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();
  const currentGuideMe = useSelector(selectCurrentGuideMe);
  //   Queries
  const {
    data: report,
    isLoading,
    isSuccess,
    isError,
    refetch,
  } = useGetReportByIdQuery(idReport);
  // Mutations
  const [
    setReportStatus,
    {
      isSuccess: isSetReportStatusSuccess,
      isLoading: isSetReportStatusLoading,
      isError: isSetReportStatusError,
    },
  ] = useSetReportStatusMutation();

  const isShared = useMemo(() => {
    if (report && idProject) {
      return report.sharedWith.indexOf(idProject) > -1;
    }
    return false;
  }, [report, idProject]);

  const [
    shareDailyReport,
    {
      isSuccess: isShareSuccess,
      error: shareError,
      isError: isShareError,
      isLoading: isShareLoading,
    },
  ] = useShareDailyReportMutation();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (isSetReportStatusError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
    }
    if (isSetReportStatusSuccess) {
      PopUpAlert(
        "success",
        tGeneral("approved"),
        tDailyReports("approvedReport"),
      );
      goBack();
    }
  }, [isSetReportStatusError, isSetReportStatusSuccess, tGeneral, goBack]);

  const approveReport = async () => {
    setReportStatus({
      id: report?.id,
      status: "close",
    }).unwrap();
  };

  const reportClosedDateStyle = dailyReportsUtil.getCloseDatePillStyle(
    report?.status,
    report?.dueDate,
  );

  const exportPDF = async () => {
    await pdf({
      data: {},
      token,
      url: `${process.env.REACT_APP_BACKEND_URL}pdf/${idEnterprise}/${idProject}/daily-reports/${idReport}`,
      download: report.title,
      module: "dailyReport",
      object: report,
      tGeneral,
    });
  };

  const { icon, label } = useMemo(() => {
    if (report && report.weatherInfo) {
      if (report.weatherInfo.translationCode && report.weatherInfo.icon) {
        return {
          icon: `https://openweathermap.org/img/wn/${report.weatherInfo.icon}@2x.png`,
          label: tWeather(report.weatherInfo.translationCode.toLowerCase()),
        };
      }
      return getIcon(report.weatherInfo.weatherCode);
    }
    return { icon: "", label: "" };
  }, [report, tWeather]);

  const showApprove = useMemo(() => {
    if (report) {
      return (
        report.status === "open" &&
        (ability.can("superAdmin", "dailyReports") ||
          report.assignedTo.indexOf(currentUser.id) > -1)
      );
    }
    return false;
  }, [report, currentUser]);

  const category = useMemo(() => {
    if (report) {
      const categoryStyle = dailyReportsUtil.getCategory(report.category);
      return {
        ...categoryStyle,
        label: tDailyReports(categoryStyle.label),
      };
    }
    return dailyReportsUtil.getCategory(1);
  }, [report]);

  const handleShareDailyReport = (idProjects) => {
    shareDailyReport({ idDailyReport: idReport, shareWith: idProjects });
  };

  useEffect(() => {
    if (isShareSuccess) {
      PopUpAlert("success", tGeneral("shared"), tGeneral("sharedSuccessfully"));
      setIsShareOpen(false);
    }
    if (isShareError) {
      PopUpAlert("error", "Error", shareError.message);
      setIsShareOpen(false);
    }
  }, [isShareSuccess, isShareError, shareError, tGeneral]);

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 1 &&
      (currentGuideMe === "daily-reports-view" ||
        currentGuideMe === "reports-view-work-progress" ||
        currentGuideMe === "reports-view-problem" ||
        currentGuideMe === "reports-view-delay") &&
      isSuccess &&
      report
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe, isSuccess, report, setState]);

  if (isLoading) {
    return <SkeletonLoading />;
  }

  return (
    <Box>
      {isSuccess && (
        <>
          {/* Top bar */}
          <DetailsHeader
            status={report?.status}
            bPrivate={report.bPrivate}
            number={report.number}
            onBack={goBack}
            categoryObject={category}
            title={report.title}
            showMainAction={!isShared && showApprove}
            showMore={!isShared}
            mainAction={{
              icon: <Check />,
              label:
                report?.status === "open"
                  ? tDailyReports("approveReport")
                  : tGeneral("edit"),
              onClick: () => approveReport(),
            }}
            mainActionDataTour="view-dailyReport-7"
            actions={[
              {
                onClick: () => {
                  exportPDF();
                },
                icon: <PictureAsPdfIcon />,
                label: tDailyReports("exportPdf"),
                dataTour: "export-pdf",
              },
              {
                onClick: () => setIsLastEditOpen(true),
                icon: <EditNote />,
                label: tGeneral("lastEdit"),
                dataTour: "last-edit",
              },
              {
                onClick: () => setIsLastSeenOpen(true),
                icon: <RemoveRedEye />,
                label: tGeneral("lastSeen"),
                dataTour: "last-seen",
              },
              {
                onClick: () => setIsShareOpen(true),
                icon: <Share />,
                label: tGeneral("share"),
                disabled: ability.cannot("share", "dailyReports"),
              },
            ]}
          />
          <Box
            maxHeight="88vh"
            width="100%"
            overflow="hidden"
            display="flex"
            flexDirection="column"
          >
            {/* Detail cards */}
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              width={1}
              columnGap="10px"
              borderBottom="1px solid #E1E1E1"
              data-tour="view-dailyReport-2"
              p="10px"
            >
              {/* Assignees & creator */}
              <Paper
                elevation={2}
                sx={{
                  flex: 2,
                  display: "flex",
                  gap: 6,
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "150px",
                }}
              >
                {/* Creators */}
                <TooltipUsers users={report.creatorsUsers}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <AvatarGroup
                      max={2}
                      sx={{
                        justifyContent: "flex-end",
                        "& .MuiAvatar-root": {
                          width: 50,
                          height: 50,
                          fontSize: 16,
                        },
                        "& .MuiTooltip-tooltip": {
                          border: "solid skyblue 1px",
                          color: "deepskyblue",
                        },
                        "& .MuiAvatarGroup-root": {
                          marginX: "auto",
                          textAlign: "center",
                        },
                      }}
                    >
                      {report.creatorsUsers.map((user) => (
                        <Avatar
                          key={user?.id}
                          alt={user?.name}
                          src={user?.urlAvatar}
                          sx={{
                            bgcolor: "#ffc900",
                            width: 50,
                            height: 50,
                            fontSize: 16,
                            boxShadow: "0 0 4px 0 rgba(200, 200, 200, 1)",
                            opacity: user?.bActive ? 1 : 0.5,
                          }}
                          style={{
                            border: "0.8px solid white",
                          }}
                        >{`${user?.firstName[0]}${user?.lastName[0]}`}</Avatar>
                      ))}
                    </AvatarGroup>
                    <Box display="flex">
                      <Typography
                        textAlign="center"
                        fontSize="14px"
                        color={
                          report.creatorsUsers[0]?.bActive ? "initial" : "gray"
                        }
                      >{`${report.creatorsUsers[0]?.name} ${
                        report.creatorsUsers[0]?.bActive
                          ? ""
                          : `(${tGeneral("inactive")})`
                      }`}</Typography>
                      {report.creatorsUsers?.length > 1 && (
                        <Typography textAlign="center" fontSize="14px">
                          &nbsp;
                          {`${tGeneral("and")} +${
                            report.creatorsUsers.length - 1
                          }`}
                        </Typography>
                      )}
                    </Box>
                    <Typography fontSize="14px" color="#7a7a7a">
                      {tDailyReports("creators")}
                    </Typography>
                  </Box>
                </TooltipUsers>
                {/* Assignees */}
                <TooltipUsers users={report.assignedToUsers}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <AvatarGroup
                      max={2}
                      sx={{
                        justifyContent: "flex-end",
                        "& .MuiAvatar-root": {
                          width: 50,
                          height: 50,
                          fontSize: 16,
                        },
                        "& .MuiTooltip-tooltip": {
                          border: "solid skyblue 1px",
                          color: "deepskyblue",
                        },
                        "& .MuiAvatarGroup-root": {
                          marginX: "auto",
                          textAlign: "center",
                        },
                      }}
                    >
                      {report.assignedToUsers.map((user) => (
                        <Avatar
                          key={user?.id}
                          alt={user?.name}
                          src={user?.urlAvatar}
                          sx={{
                            bgcolor: "#ffc900",
                            width: 50,
                            height: 50,
                            fontSize: 16,
                            boxShadow: "0 0 4px 0 rgba(200, 200, 200, 1)",
                            opacity: user?.bActive ? 1 : 0.5,
                          }}
                          style={{
                            border: "0.8px solid white",
                          }}
                        >{`${user?.firstName[0]}${user?.lastName[0]}`}</Avatar>
                      ))}
                    </AvatarGroup>
                    <Box display="flex">
                      <Typography
                        textAlign="center"
                        fontSize="14px"
                        color={
                          report.assignedToUsers[0]?.bActive
                            ? "initial"
                            : "gray"
                        }
                      >
                        {`${report.assignedToUsers[0]?.name}`}{" "}
                        {report.assignedToUsers[0]?.bActive
                          ? ""
                          : `(${tGeneral("inactive")})`}
                      </Typography>
                      {report.assignedToUsers?.length > 1 && (
                        <Typography textAlign="center" fontSize="14px">
                          &nbsp;
                          {`${tGeneral("and")} +${
                            report.assignedToUsers.length - 1
                          }`}
                        </Typography>
                      )}
                    </Box>
                    <Typography fontSize="14px" color="#7a7a7a">
                      {tDailyReports("assigned")}
                    </Typography>
                  </Box>
                </TooltipUsers>
              </Paper>
              {/* Weather */}
              {report?.category !== 4 && report?.weatherInfo && (
                <Paper
                  elevation={2}
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    justifyContent: "center",
                    alignItems: "center",
                    px: 2,
                    minHeight: "150px",
                  }}
                >
                  <Box display="flex" columnGap="5px">
                    <LocationOn sx={{ color: "#7A7A7A" }} />
                    <Typography
                      fontSize="0.875rem"
                      color="#7A7A7A"
                      sx={{ textOverflow: "ellipsis" }}
                    >
                      {report?.weatherInfo?.name.split(",")[0]}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" columnGap="10px">
                    <img src={icon} alt="weather" style={{ height: 50 }} />
                    <Typography fontSize="3rem">
                      {Math.round(report?.weatherInfo?.temperature) || "N/A"}°
                    </Typography>
                    <Box>
                      <Typography fontWeight="600" textAlign="center">
                        {label}
                      </Typography>
                      <Typography textAlign="center">
                        {`${tWeather("sensation")} ${Math.round(
                          report?.weatherInfo?.temperatureApparent,
                        )}°`}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              )}
              {/* Delay */}
              {report?.category === 4 && (
                <Paper
                  elevation={2}
                  sx={{
                    flex: 1,
                    display: "flex",
                    gap: 2,
                    justifyContent: "center",
                    alignItems: "center",
                    px: 2,
                    minHeight: "150px",
                  }}
                >
                  <img src={Time} alt="Delay" style={{ height: 50 }} />
                  <Box>
                    <Typography fontSize="14px" color="#707070">
                      {tDailyReports("delay")}
                    </Typography>
                    <Typography fontSize="14px" fontWeight="medium">
                      {(report?.delayReport?.delayDays > 0
                        ? `${report?.delayReport.delayDays} ${tGeneral(
                            "days",
                          )} `
                        : "") +
                        (report?.delayReport?.delayHours > 0
                          ? `${report?.delayReport.delayHours} ${tGeneral(
                              "hours",
                            )}`
                          : "")}
                    </Typography>
                  </Box>
                </Paper>
              )}
              {/* Creation Date */}
              <Paper
                elevation={2}
                sx={{
                  flex: 1,
                  display: "flex",
                  gap: 2,
                  justifyContent: "center",
                  alignItems: "center",
                  px: 2,
                  minHeight: "150px",
                }}
              >
                <img
                  src={Calendar}
                  alt="Creation Date"
                  style={{ height: 50 }}
                />
                <Box>
                  <Typography fontSize="14px" color="#707070">
                    {tDailyReports("createdDate")}
                  </Typography>
                  <Typography fontSize="14px" fontWeight="medium">
                    {moment(report?.createdDate).format("D MMMM YYYY")}
                  </Typography>
                  <Typography fontSize="14px" fontWeight="medium">
                    {`${tDailyReports("atHour")} ${moment(
                      report?.createdDate,
                    ).format("h:mmA")}`}
                  </Typography>
                </Box>
              </Paper>
              {/* Due Date */}
              {report?.category !== 3 && (
                <Paper
                  elevation={2}
                  sx={{
                    flex: 1,
                    display: "flex",
                    gap: 2,
                    justifyContent: "center",
                    alignItems: "center",
                    px: 2,
                    minHeight: "150px",
                  }}
                >
                  <img src={Delivery} alt="Due Date" style={{ height: 50 }} />
                  <Box>
                    <Typography fontSize="14px" color="#707070">
                      {tDailyReports("dueDate")}
                    </Typography>
                    <Typography fontSize="14px" fontWeight="medium">
                      {moment.utc(report?.dueDate).format("D MMMM YYYY")}
                    </Typography>
                  </Box>
                </Paper>
              )}
              {/* Closed Date */}
              <Paper
                elevation={2}
                sx={{
                  flex: 1,
                  display: "flex",
                  gap: 2,
                  justifyContent: "center",
                  alignItems: "center",
                  px: 2,
                  minHeight: "150px",
                }}
              >
                <img src={Star} alt="Closed Date" style={{ height: 50 }} />
                <Box>
                  <Typography
                    fontSize="14px"
                    textAlign="center"
                    color="#707070"
                  >
                    {tDailyReports("closedDate")}
                  </Typography>
                  {report?.closeDate && report?.status !== "open" && (
                    <Typography fontSize="14px" fontWeight="medium">
                      {moment.utc(report?.closeDate).format("D MMMM YYYY")}
                    </Typography>
                  )}
                  {(!report?.closeDate || report?.status === "open") && (
                    <Typography
                      fontSize="14px"
                      textAlign="center"
                      fontWeight="medium"
                      color="white"
                      bgcolor={reportClosedDateStyle.color}
                      px={2}
                      py="4px"
                      borderRadius={10}
                    >
                      {tGeneral([reportClosedDateStyle.value]).toUpperCase()}
                    </Typography>
                  )}
                </Box>
              </Paper>
            </Box>
            <Box flex={1} sx={{ overflowY: "hidden" }} p="1rem">
              {/* Report location items */}
              <Box display="flex" alignItems="center" columnGap="0.5rem">
                {report?.category === 5 && (
                  <Apartment sx={{ color: "#E6B60C" }} />
                )}
                <Typography fontSize="1.25rem" fontWeight="bold">
                  {report?.category === 5
                    ? tGeneral("enterprises")
                    : tDailyReports("reports")}
                </Typography>
              </Box>
              <Box overflow="hidden">
                <ReportDetail report={report} />
              </Box>
              {/* Distribution List */}
            </Box>
            <Box px="10px" borderTop="1px solid #E1E1E1">
              <Typography fontSize={18} fontWeight="bold">
                {tDailyReports("distributionList")}
              </Typography>
              <Box
                display="flex"
                columnGap="10px"
                maxWidth="100%"
                sx={{ overflowX: "auto" }}
              >
                {report.distributionListUsers?.length ? (
                  report.distributionListUsers?.map((user) => (
                    <Box
                      key={user?.id}
                      display="flex"
                      gap={1}
                      bgcolor="#FFEEC1"
                      borderRadius={10}
                      py={1}
                      px={3}
                      sx={{ minWidth: "max-content" }}
                    >
                      <Avatar
                        alt={user?.name}
                        src={user?.urlAvatar}
                        sx={{
                          bgcolor: "#ffc900",
                          width: 30,
                          height: 30,
                          fontSize: 10,
                          boxShadow: "0 0 4px 0 rgba(200, 200, 200, 1)",
                        }}
                        style={{
                          border: "0.8px solid white",
                        }}
                      >{`${user?.firstName[0]}${user?.lastName[0]}`}</Avatar>
                      <Typography>{user.name}</Typography>
                    </Box>
                  ))
                ) : (
                  <Typography fontSize="18px" fontWeight="medium">
                    N/A
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
      {isError && (
        <>
          {/* Top bar */}
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            width={1}
            paddingBottom={3}
          >
            <IconButton title="Back" onClick={goBack}>
              <ArrowBackIcon />
            </IconButton>
          </Box>
          <Box
            width={1}
            display="flex"
            flexDirection="column"
            gap={1}
            alignItems="center"
          >
            <Typography fontSize={24} fontWeight="bold" textAlign="center">
              {tDailyReports("fetchReportIssue")}
            </Typography>
            <Button
              variant="contained"
              startIcon={<RefreshIcon />}
              onClick={() => refetch()}
            >
              {tDailyReports("retry")}
            </Button>
          </Box>
        </>
      )}
      <LoadingOverlay open={isSetReportStatusLoading} />
      <ModalSeenBy
        isOpen={isLastSeenOpen}
        onClose={() => setIsLastSeenOpen(false)}
        idObject={idReport}
      />
      <ModalLastEdit
        isOpen={isLastEditOpen}
        onClose={() => setIsLastEditOpen(false)}
        idObject={idReport}
      />
      <ModalShare
        isOpen={isShareOpen}
        onClose={() => setIsShareOpen(false)}
        moduleName="dailyReport"
        onShare={handleShareDailyReport}
        objectName={report?.title}
        sharedWith={report?.sharedWith ?? []}
        isLoading={isShareLoading}
      />
    </Box>
  );
}

export default DailyReportDetails;
