import { datadogRum } from "@datadog/browser-rum";
import { Box, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  AssignedUsersTooltip,
  StyledMaterialReactTable,
  TitleWidgets,
} from "../../components/shared";
import { selectTheme } from "../../features/preferences/preferencesSlice";
import {
  useGetInactiveInspectionsQuery,
  useRecoverInspectionMutation,
} from "../../features/project/modules/inspections/apiSlice";
import { selectCurrentProject } from "../../features/project/projectSlice";
import { capitalizeFirstLetterOfSentence } from "../../services/helpers/capitalizeFirstLetterOfSentence";
import { formatDate } from "../../services/helpers/dateFormat";
import Can from "../../utils/can";

function InspectionsRecycle() {
  const darkMode = useSelector(selectTheme);
  const { idProject } = useParams();
  const currentProject = useSelector(selectCurrentProject);

  // Filters

  const [selectedInspections, setSelectedInspections] = useState({});

  // Translations
  const { t: tInspections } = useTranslation("inspections");
  const { t: tGeneral } = useTranslation("general");

  const {
    data: inspectionsData,
    isLoading: isInspectionsLoading,
    isFetching: isInspectionsFetching,
  } = useGetInactiveInspectionsQuery({
    idProject,
  });

  const [recoverInspections, { isSuccess: isSuccessRecover }] =
    useRecoverInspectionMutation();

  const body = {
    ids: [],
  };

  const dateNow = new Date();

  // Restore inspection
  const restoreInspection = async (e) => {
    try {
      const response = await recoverInspections({
        ids: Object.keys(selectedInspections),
        idProject,
      }).unwrap();
      if (response) {
        body.ids = [];
        showAlert(`Inspection ${tGeneral("recoveredSuccessfully")}`, "success");
      }
    } catch (err) {
      showAlert(tGeneral("cantRestore"), "error");
      if (datadogRum?.addError) {
        datadogRum.addError({
          message: "Inspections - Restore - Error",
          stack: err.stack ? err.stack : "invalid stack",
          custom: {
            "app": "buildpeer-web",
            "enterprise": currentProject?.idEnterprise,
            "module": "Inspections",
            "project": currentProject?.id,
          },
          timestamp: Date.now(),
        });
      }
    }
  };

  function showAlert(message, type) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: type,
      title: message,
    });
  }

  const statusProps = {
    pending: {
      label: tGeneral("open_action").toUpperCase(),
      styles: {
        backgroundColor: "#5ce381",
        color: "white",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    open_past_due: {
      label: tGeneral("open_action").toUpperCase(),
      styles: {
        backgroundColor: "#e15452",
        color: "white",
        fontSize: "0.6rem",
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    closed: {
      label: tGeneral("closed").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#c2c3c3",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    draft: {
      label: tGeneral("draft").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#ffc900",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    in_revision: {
      label: tGeneral("in_revision").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#03cafc",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    stillOpen: {
      label: tGeneral("still_open").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#ffc900",
        fontSize: "0.6rem",
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    draft_closed_date: {
      label: tGeneral("draft").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#ffc900",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    notDefined: {
      label: tGeneral("not_defined").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#2196f3",
        fontSize: 12,
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
    open: {
      label: tGeneral("open").toUpperCase(),
      styles: {
        color: "white",
        backgroundColor: "#ffc900",
        fontSize: "0.6rem",
        width: "100px",
        fontWeight: "medium",
        textOverflow: "break-line",
      },
    },
  };

  const [columns, setColumns] = useState([
    // Num
    {
      accessorKey: "num",
      header: "#",
      Cell: ({ cell, row }) => `${cell.getValue()}.${row.original?.revision}`,
      size: 20,
    },
    // title
    {
      accessorKey: "title",
      header: tGeneral("title"),
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ cell }) => (
        <Typography fontSize="1rem">
          {capitalizeFirstLetterOfSentence(cell.getValue()).substring(0, 38)}
          {cell.getValue().length > 38 ? "..." : ""}
        </Typography>
      ),
      size: 120,
    },

    // status
    {
      accessorKey: "status",
      header: tGeneral("status"),
      Cell: ({ cell }) => (
        <Chip
          sx={statusProps[cell.getValue()].styles}
          label={statusProps[cell.getValue()].label}
        />
      ),
      size: 80,
    },

    // Assigned
    {
      accessorKey: "assignedToUsers",
      header: tGeneral("assigned"),
      Cell: ({ cell }) => <AssignedUsersTooltip users={cell.getValue()} />,
      size: 80,
    },

    // Delivery Date
    {
      accessorKey: "deliveryDate",
      header: tGeneral("Delivery Date"),
      Cell: ({ cell, row }) =>
        new Date(cell.getValue()) < dateNow &&
        row.original?.status !== "closed" ? (
          <Typography fontSize="1rem">{cell.getValue()}</Typography>
        ) : (
          <Typography fontSize="1rem">{cell.getValue()}</Typography>
        ),
      size: 80,
    },

    // close date
    {
      accessorKey: "closedDate",
      header: tGeneral("closed_date"),
      Cell: ({ cell, row }) => {
        // eslint-disable-next-line no-nested-ternary
        if (
          new Date(row.original?.deliveryDate) < dateNow &&
          row.original?.status !== "closed" &&
          !row.original?.status === "draft"
        ) {
          return (
            <Chip
              sx={[statusProps.open_past_due.styles, { width: "100px" }]}
              label={statusProps.open.label}
            />
          );
        }
        if (row.original?.status === "draft") {
          return (
            <Chip
              sx={[
                statusProps[
                  row.original?.status === "draft"
                    ? "draft_closed_date"
                    : row.original?.status
                ].styles,
                { width: "100px" },
              ]}
              label={
                statusProps[
                  row.original?.status === "draft"
                    ? "draft_closed_date"
                    : row.original?.status
                ].label
              }
            />
          );
        }
        if (row.original?.status === "in_revision") {
          return (
            <Chip
              sx={[statusProps.in_revision.styles, { width: "100px" }]}
              label={statusProps.in_revision.label}
            />
          );
        }
        if (row.original?.status === "open") {
          return (
            <Chip
              sx={[statusProps.open.styles, { width: "100px" }]}
              label={statusProps.open.label}
            />
          );
        }
        return (
          <Typography sx={{ fontSize: "1rem" }}>{cell.getValue()}</Typography>
        );
      },
      size: 80,
    },
  ]);

  const data = useMemo(() => {
    if (inspectionsData) {
      return inspectionsData.map((inspection) => ({
        ...inspection,
        id: inspection.id,
        bPrivate: inspection.bPrivate,
        action: "admin", // get user permissions
        num: inspection.number,
        deliveryDate: formatDate(inspection.deliveryDate),
        closedDate: formatDate(inspection.closedDate),
        createdDate: formatDate(inspection.createDate),
      }));
    }
    return [];
  }, [inspectionsData]);

  return (
    <Can I="edit" a="inspections">
      <Box sx={{ height: 500, width: "100%" }}>
        <TitleWidgets
          title={tInspections("inspection_recycle_bin")}
          isRecycle
          backEnabled
          onRestore={() => restoreInspection()}
          restoreDisabled={!Object.keys(selectedInspections).length}
        />
        <StyledMaterialReactTable
          columns={columns}
          data={data}
          enableRowSelection
          enableRowActions={false}
          selectedRows={selectedInspections}
          setSelectedRows={setSelectedInspections}
          isLoading={isInspectionsFetching || isInspectionsLoading}
        />
      </Box>
    </Can>
  );
}

export default InspectionsRecycle;
