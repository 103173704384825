import moment from "moment";

export function formatDate(date) {
  return new Date(date).toLocaleDateString("us-EN", {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  });
}

export function formatDateYYYmmDD(date) {
  const myDate = date ? new Date(date) : new Date();
  const year = myDate.getFullYear();
  let month = myDate.getMonth() + 1;
  if (month?.toString()?.length === 1) month = `0${month}`;
  let day = myDate.getDate();
  if (day?.toString()?.length === 1) day = `0${day}`;
  return `${year}-${month}-${day}`;
}

export function customFormatDate(date, format) {
  const momentDate = moment.utc(date);

  if (momentDate.isValid()) {
    return momentDate.format(format);
  }

  return "";
}
