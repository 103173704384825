// @ts-check
/* eslint react/prop-types: 0 */

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, ClickAwayListener, Grow, Paper, Popper } from "@mui/material";
// eslint-disable-next-line no-unused-vars
import React, { useRef, useState } from "react";

export default function Colors(props) {
  const { caption, color, options, setColor, zIndex } = props;

  const ref = useRef(null);

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        color="secondary"
        disableElevation
        endIcon={<KeyboardArrowDownIcon />}
        onClick={() => {
          setOpen((open) => !open);
        }}
        ref={ref}
        sx={{
          backgroundColor: "#fff",
          minWidth: "0",
          textTransform: "none",
        }}
        variant="text"
      >
        <Box
          sx={{
            backgroundColor: color.light,
            borderColor: color.dark,
            borderRadius: "5px",
            borderStyle: "solid",
            borderWidth: "1px",
            display: "block",
            height: "20px",
            width: "20px",
          }}
        />
      </Button>
      <Popper
        anchorEl={ref.current}
        disablePortal
        open={open}
        placement="bottom-start"
        role={undefined}
        sx={{
          margin: "5px 0 0 10px",
          zIndex: zIndex ?? 1201,
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "left top",
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={(event) => {
                  // @ts-ignore
                  if (ref.current && ref.current.contains(event.target)) {
                    return;
                  }
                  setOpen(false);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    padding: "15px",
                  }}
                >
                  <strong>
                    <small>{caption}</small>
                  </strong>
                  <Box
                    sx={{
                      display: "grid",
                      gap: "5px",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr",
                      gridTemplateows: "1fr 1fr 1fr 1fr",
                    }}
                  >
                    {options.map((option) => {
                      return (
                        <Box
                          key={option.name}
                          onClick={() => {
                            setColor(option);
                            setOpen(false);
                          }}
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: option.light,
                              borderColor: option.dark,
                              borderRadius: "5px",
                              borderStyle: "solid",
                              borderWidth: "1px",
                              display: "block",
                              height: "20px",
                              width: "20px",
                            }}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
