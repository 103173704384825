// @ts-check
/* eslint react/prop-types: 0 */

import { Alert, Avatar, Box, Card } from "@mui/material";
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import { MessageBox } from "../../question/MessageBox";
import { MessageReply } from "../../question/MessageReply";

const buildBase64 = async (file) => {
  // eslint-disable-next-line no-return-await
  return (
    await new Response(file)
      .blob()
      .then((b) => URL.createObjectURL(b))
      .then((url) => fetch(url))
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          }),
      )
  ).replace(/^.*?base64,/, "");
};

export default function Comments(props) {
  const { activity, client, comment, comments, level, readOnly, setDataSource, dateFormat } = props;

  const [inProcess, setInProcess] = useState(false);

  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  return (
    <Box
      sx={{
        marginLeft: `${(level > 0 ? 50 : 0) + 20 * level}px`,
      }}
    >
      {level === 0 && comments.length === 0 ? (
        <Box
          sx={{
            marginBottom: "20px",
          }}
        >
          <Alert severity="info">{tProjectSchedule("There are no comments in this group.")}</Alert>
        </Box>
      ) : null}
      {comments.map((comment) => {
        return (
          <Box key={comment.id}>
            <Box
              display="flex"
              position="relative"
              sx={{
                paddingRight: "20px",
                paddingTop: "15px",
                width: "100%",
              }}
            >
              <Box alignItems="center" display="flex" flexDirection="column">
                <Card
                  sx={{
                    borderRadius: "100%",
                    p: "2px",
                    width: "100%",
                  }}
                >
                  <Avatar src={comment.createdBy.urlAvatar} />
                </Card>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  marginBottom: "-5px",
                  width: "100%",
                }}
              >
                <MessageBox
                  // @ts-ignore
                  bgColor="#fff9eb"
                  createdDate={comment.createdDate}
                  createdBy={comment.createdBy.name}
                  dateFormat={dateFormat}
                  files={comment.files}
                  text={comment.contents}
                  id={comment.id}
                  module="project-schedule"
                  width="100%"
                />
              </Box>
            </Box>
            <Comments
              activity={activity}
              client={client}
              comment={comment}
              comments={comment.children}
              dateFormat={dateFormat}
              level={level + 1}
              readOnly={readOnly}
              setDataSource={setDataSource}
            />
          </Box>
        );
      })}
      {level === 0 && !readOnly && (
        <Box
          sx={{
            marginBottom: "-10px",
            marginLeft: "15px",
          }}
        >
          <MessageReply
            isLoading={inProcess}
            placeholder={tProjectSchedule("Comments")}
            reply={async (contents, files) => {
              setInProcess(true);
              try {
                const objects = [];
                // eslint-disable-next-line no-restricted-syntax
                for await (const file of files) {
                  const extension = file.name.split(".").pop();
                  objects.push({
                    extension,
                    contentType: file.type,
                    contents: await buildBase64(file),
                    name: file.name,
                    type: ["gif", "jpg", "jpeg", "png"].includes(extension) ? "image" : "file",
                  });
                }
                const response = await client.request({
                  data: {
                    idComment: comment ? comment.id : null,
                    idActivity: activity.id,
                    contents,
                    files: objects,
                  },
                  method: "POST",
                  url: "/comments",
                });
                setDataSource(response.data);
                const Toast = Swal.mixin({
                  position: "center",
                  showConfirmButton: false,
                  timer: 1500,
                  timerProgressBar: true,
                  toast: true,
                });
                Toast.fire({
                  icon: "success",
                  title: tProjectSchedule("Success"),
                });
              } catch (error) {
                const Toast = Swal.mixin({
                  position: "center",
                  showConfirmButton: false,
                  timer: 1500,
                  timerProgressBar: true,
                  toast: true,
                });
                Toast.fire({
                  icon: "error",
                  title: tProjectSchedule(error.response.data.error ? error.response.data.error : error),
                });
                // eslint-disable-next-line no-console
                console.error(error);
              }
              setInProcess(false);
            }}
            requiredText
            showConnection={false}
          />
        </Box>
      )}
    </Box>
  );
}
