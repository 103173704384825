export const bim = {
  addBim: "Add BIM",
  progressHelp:
    "Files must be processed in order to view, this process may take a few minutes.",
  errorUpload: "There was an error uploading the file, please contact support.",
  pendingUpload: "The file is being uploaded, this can take a few minutes, please come back later.",
  successUpload: "The file was uploaded successfully.",
  deleteBim: "Delete BIM",
  deleteBimConfirmation: "Are you sure you want to delete the BIM?",
};
