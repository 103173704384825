// Third-party library imports
import { useDraggable } from "@dnd-kit/core";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import RemoveIcon from "@mui/icons-material/Remove";
import { Backdrop, Box, Fab, IconButton } from "@mui/material";
import { PiEyeLight } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";

// Project-specific absolute imports
import Can from "../../../utils/can";

// Relative imports
import {
  deleteWidget,
  selectActiveWidgetId,
  selectDraggedWidget,
  selectIsEditing,
  setActiveWidget,
  setEditing,
} from "../../../features/project/analyticsSlice";
import {
  RFI,
  Submittals,
  Tasks,
  Inspections,
  Drawings,
  ProjectSchedule,
  Reports,
} from "./ModuleWidgets";

function Widget({
  id,
  size,
  mainIndex,
  type,
  draggedStyle, // receive the style prop here
}) {
  const dispatch = useDispatch();

  // Get active widget ID and editing state from Redux store
  const activeWidgetId = useSelector(selectActiveWidgetId);
  const isEditing = useSelector(selectIsEditing);
  const isDragging = useSelector(selectDraggedWidget);

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
    data: { id, size, mainIndex, type },
    disabled: !(activeWidgetId === id),
  });

  // Get the size of the widget based on its size prop
  const getSize = () => {
    switch (size) {
      case "sm":
        return { width: "100%", height: "100%" };
      case "md":
        return { width: "calc(200% + 16px)", height: "100%" };
      case "lg":
        return { width: "calc(400% + 48px)", height: "100%" };
      default:
        return { width: "100%", height: "100%" };
    }
  };

  // Handle click to set the widget as active
  const handleExpandClick = () => {
    dispatch(setActiveWidget(activeWidgetId === id ? null : id));
    dispatch(setEditing(false));
  };

  // Handle deleting a widget
  const handleDeleteWidget = (id) => {
    dispatch(deleteWidget(id));
  };

  // Render the content based on the type of widget
  const renderContent = () => {
    switch (type) {
      case "rfi":
        return <RFI size={size} isMock={false} />;
      case "tasks":
        return <Tasks size={size} isMock={false} />;
      case "submittals":
        return <Submittals size={size} isMock={false} />;
      case "inspections":
        return <Inspections size={size} isMock={false} />;
      case "drawings":
        return <Drawings size={size} isMock={false} />;
      case "reports":
        return <Reports size={size} isMock={false} />;
      case "projectSchedule":
        return <ProjectSchedule size={size} isMock={false} />;
      default:
        return null;
    }
  };

  // Container styles for the widget
  const containerStyles = {
    ...getSize(),
    ...draggedStyle,
    position: "absolute",
    top: "0px",
    left: "0px",
    backgroundColor: isEditing ? "#EEEEEE" : "white",
    opacity: 1,
    zIndex: activeWidgetId === id || isDragging === id ? 1400 : 1,
    display: "flex",
    flexDirection: "column",
    borderRadius: "15px",
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  };

  // Styles for the icon container
  const iconContainerStyles = {
    position: "absolute",
    top: "10px",
    right: "24px",
    display: "flex",
    gap: "8px",
    zIndex: 2,
  };

  // Styles for the content container
  const contentContainerStyles = {
    position: "absolute",
    padding: "8px 16px",
    width: "100%",
    height: "240px",
  };

  return (
    <>
      {/* Backdrop to indicate the active state */}
      {activeWidgetId === id && !isDragging && (
        <Backdrop
          open={activeWidgetId === id}
          sx={{
            color: "#fff",
            zIndex: 1399, // Ensure backdrop is behind the active widget and DragOverlay
          }}
          onClick={() => dispatch(setActiveWidget(null))}
        />
      )}
      <Box ref={setNodeRef} style={containerStyles} {...attributes}>
        {/* Render delete button if in editing mode */}
        {isEditing && (
          <Fab
            color="primary"
            style={{
              position: "absolute",
              top: -4,
              left: -20,
              color: "#F6D336",
              backgroundColor: "white",
              zIndex: 2,
              width: "32px",
              height: "15px",
            }}
            onClick={() => handleDeleteWidget(id)}
          >
            <RemoveIcon />
          </Fab>
        )}
        {/* Render drag handle if widget is active */}
        {activeWidgetId === id && (
          <IconButton
            {...listeners}
            {...attributes}
            style={{
              position: "absolute",
              top: 0,
              left: -30,
              color: "gray",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 2,
              width: "27px",
              height: "32px",
              borderRadius: "8px",
              padding: "4px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              cursor: "move",
            }}
          >
            <DragIndicatorIcon />
          </IconButton>
        )}
        {/* Icon container */}
        <Can I="edit" a="analytics">
          <Box style={iconContainerStyles}>
            <PiEyeLight
              style={{
                height: "17px",
                width: "17px",
                flexShrink: 0,
              }}
            />
            <IconButton
              onClick={handleExpandClick}
              style={{
                height: "17px",
                width: "17px",
                flexShrink: 0,
                color: activeWidgetId === id ? "#F6D336" : "inherit",
              }}
            >
              <OpenInFullIcon sx={{ height: "20px", width: "20px" }} />
            </IconButton>
          </Box>
        </Can>
        {/* Render the content based on the type prop */}
        <Box style={contentContainerStyles}>{renderContent()}</Box>
      </Box>
    </>
  );
}

export default Widget;
