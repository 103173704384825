export const photos = {
  addPhoto: "사진 추가",
  timeline: "타임라인",
  albums: "앨범",
  album: "앨범",
  photos: "사진",
  addAlbum: "앨범 추가",
  photosPerPage: "페이지당 사진 수",
  deleteImagesTitle: "이미지 삭제",
  deleteImagesContent: "이미지를 삭제하시겠습니까?",
  deleteAlbumsContent: "앨범을 삭제하시겠습니까?",
  deleteImagetTitle: "이미지 삭제",
  deleteImageContent: "이 이미지를 삭제하시겠습니까?",
  setLocation: "위치 설정",
  setAlbumCover: "앨범 커버로 설정",
  setAlbumCoverConfirm: "이 이미지를 앨범 커버로 설정하시겠습니까?",
  movePhotoAlbumTitle: "앨범 변경",
  movePhotoLocationTitle: "위치 변경",
  movePhotoLocationSubtitle: "사진을 위치로 이동",
  movePhotoAlbumSubtitle: "사진을 앨범으로 이동",
  movePhotoLocationSuccess: "이미지 위치가 성공적으로 업데이트되었습니다",
  movePhotoAlbumSuccess: "이미지 앨범이 성공적으로 업데이트되었습니다",
  selectedPhotos: "선택한 사진",
  perPage: "페이지당",
  noImagesCreated: "현재 생성된 이미지가 없습니다",
  noAlbumsCreated: "현재 생성된 앨범이 없습니다",
  addImagesValidation:
    "추가하려면 이미지 추가 버튼을 클릭하여 프로세스를 시작하십시오",
  addAlbumsValidation:
    "추가하려면 앨범 추가 버튼을 클릭하여 프로세스를 시작하십시오",
  projectConfigurationUpdate: "프로젝트 구성이 성공적으로 업데이트되었습니다",
  newPhotos: "새로운 사진",
  images: "이미지",
  youHaveUploaded: "업로드한 이미지 수",
  restoreAlbumsQuestion: "이 앨범을 복원하시겠습니까?",
  restoreImagesQuestion: "이 이미지를 복원하시겠습니까?",
  deletePermanentlyQuestion: "이것을 영구적으로 삭제하시겠습니까?",
  albumName: "앨범 이름",
  imagesDeletedSuccessfully: "이미지가 성공적으로 삭제되었습니다",
  imageCoverSetSuccessfully: "이미지 커버가 성공적으로 설정되었습니다",
  albumDeletedSuccessfully: "앨범이 성공적으로 삭제되었습니다",
  imagesRestoredSuccessfully: "이미지가 성공적으로 복원되었습니다",
  // ADD Photo - guide me steps
  step1_addPhotos_title: "1. 사진 추가",
  step1_addPhotos_text:
    "타임라인 보기에서 여기를 클릭하여 하나 또는 여러 미디어 파일을 추가합니다",
  step2_addPhotos_title: "2. 위치",
  step2_addPhotos_text: "미리 설정된 위치를 선택합니다",
  step3_addPhotos_title: "3. 앨범",
  step3_addPhotos_text: "파일에 대한 앨범을 선택합니다",
  step4_addPhotos_title: "4. 파일 추가",
  step4_addPhotos_text:
    "사진 및 비디오 파일을 첨부하거나 점선 상자 안에 끌어다 놓습니다",
  step5_addPhotos_title: "5. 확인",
  step5_addPhotos_text:
    "업로드된 미디어 파일의 정보를 확인하고 게시하기 위해 여기를 클릭합니다",
  stepFinal_addPhotos: "사진 추가하는 방법을 배웠습니다",
  // ADD Album - guide me steps
  step1_addAlbums_title: "1. 앨범 추가",
  step1_addAlbums_text: "앨범 보기에서 여기를 클릭하여 앨범을 추가합니다.",
  step2_addAlbums_title: "2. 앨범 이름",
  step2_addAlbums_text: "앨범에 이름을 지정합니다",
  step3_addAlbums_title: "3. 추가",
  step3_addAlbums_text: "앨범을 앨범 대시보드에 추가하려면 여기를 클릭합니다",
  stepFinal_addAlbums: "앨범 추가하는 방법을 배웠습니다",
  // VIEW - guide me steps
  step1_viewPhotos_title: "1. 미디어 파일 보기",
  step1_viewPhotos_text: "미디어 파일이나 비디오를 클릭하여 시각화합니다",
  step2_viewPhotos_title: "2. 미디어 파일 시각화",
  step2_viewPhotos_text: "파일을 클릭하면 전체 화면으로 표시됩니다",
  step3_viewPhotos_title: "3. 정보",
  step3_viewPhotos_text:
    "미디어 파일 미리보기의 오른쪽 상단에는 촬영 날짜, 업로드 날짜 및 업로드한 사람, 위치가 표시됩니다",
  step4_viewPhotos_title: "4. 댓글",
  step4_viewPhotos_text:
    "다른 사람이 옵저버로서 미디어 파일에 대한 댓글을 작성합니다",
  step5_viewPhotos_title: "5. 사진/비디오 다운로드",
  step5_viewPhotos_text:
    "선택한 미디어 파일을 기기로 다운로드하려면 여기를 클릭합니다",
  step6_viewPhotos_title: "6. 이미지/비디오 삭제",
  step6_viewPhotos_text:
    "슈퍼 관리자 또는 관리자인 경우 업로드한 미디어 파일을 삭제할 수 있습니다",
  step7_viewPhotos_title: "7. 추가 옵션",
  step7_viewPhotos_text:
    "세 점에 커서를 올리면 팝업 메뉴가 나타나며 다음 옵션이 포함됩니다:",
  step7_viewPhotos_text1_1: "앨범 변경",
  step7_viewPhotos_text1_2: "선택한 미디어 파일을 다른 앨범으로 이동합니다",
  step7_viewPhotos_text2_1: "위치 변경",
  step7_viewPhotos_text2_2: "미디어 파일이 참조하는 위치를 변경합니다",
  step7_viewPhotos_text3_1: "앨범 커버로 설정",
  step7_viewPhotos_text3_2:
    "선택한 사진을 해당하는 앨범 커버로 설정합니다 (이 기능은 사진에만 작동합니다)",
  stepFinal_viewPhotos: "사진 보는 방법을 배웠습니다",
  // RECYCLE - guide me steps
  step1_recyclePhotos_title: "1. 휴지통",
  step1_recyclePhotos_text:
    "여기를 클릭하여 휴지통 메뉴를 엽니다. 현재 보기에 따라 사진/앨범 메뉴로 이동합니다.",
  step2_recyclePhotos_title: "2. 복원할 파일/앨범 선택",
  step2_recyclePhotos_text: "휴지통 메뉴입니다",
  step3_recyclePhotos_title: "3. 복원",
  step3_recyclePhotos_text: "선택한 파일/앨범을 복원하려면 여기를 클릭합니다",
  step4_recyclePhotos_title: "4. 삭제",
  step4_recyclePhotos_text:
    "선택한 파일/앨범을 영구적으로 삭제하려면 여기를 클릭합니다",
  stepFinal_recyclePhotos: "휴지통과 상호 작용하는 방법을 배웠습니다",
  // EXPORT - guide me steps
  step1_exportPhotos_title: "1. 미디어 파일 선택",
  step1_exportPhotos_text:
    "내보낼 모든 미디어 파일의 확인란을 선택합니다. 필요한 경우 하루, 주 또는 월 단위로 파일을 선택할 수도 있습니다",
    step2_exportPhotos_title: "2. 미디어 파일 내보내기",
  step2_exportPhotos_text:
    "여기를 클릭하면 팝업 메뉴가 나타나며 PDF 형식으로 미디어 파일을 내보내는 옵션이 표시됩니다 (페이지당 1, 2 또는 4개)",
  stepFinal_exportPhotos: "미디어 파일을 내보내는 방법을 배웠습니다",
  // SETTINGS - guide me steps
  step1_settingsPhotos_title: "1. 설정",
  step1_settingsPhotos_text: "여기를 클릭하여 설정 팝업 메뉴를 엽니다",
  step2_settingsPhotos_title: "2. 배포 목록",
  step2_settingsPhotos_text:
    "모든 미디어 파일과 앨범을 볼 수 있는 권한을 가진 사람을 선택합니다",
  step3_settingsPhotos_title: "3. 저장",
  step3_settingsPhotos_text: "구성을 저장합니다",
  stepFinal_settingsPhotos: "모듈 설정과 상호 작용하는 방법을 배웠습니다",
  // other
  obligatory: "의무적",
  public: "공개",
  private: "비공개",
  congratulations: "축하합니다!",
  rfi_title_module: "RFIs",
  no_rfis_title: "현재 RFI가 생성되지 않았습니다",
  no_rfis_text: "하나를 추가하려면 RFI 추가 버튼을 클릭하십시오",
  meeting: "회의",
  workflow: "워크플로우",
  guideMe: "가이드",
  support: "지원",
  days: "일",
  rfi_recycle_bin: "RFI 휴지통",
  addSpec: "사양 추가",
  imageUploadedMessage: "이미지가 성공적으로 업로드되었습니다.",
  captureSite: "현장 캡처",
  dateUploaded: "업로드 날짜",
  dateTaken: "촬영 날짜",
  coordinates: "좌표",
};
