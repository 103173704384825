import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
// MUI
import { Box, CircularProgress } from "@mui/material";

// Custom imports
import { useTranslation } from "react-i18next";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { PopUpAlert } from "../../../components/PopUpAlert";
import TaskBoard from "../../../components/projectDashboard/Tasks/TaskBoard";
import { useGetLocationsQuery } from "../../../features/locations/locationApiSlice";
import { useRestoreTaskMutation } from "../../../features/project/modules/tasks/tasksApiSlice";
import {
  resetSelectedTasks,
  selectSelectedTasks,
} from "../../../features/project/modules/tasks/tasksSlice";
import { useDistributionList } from "../../../hooks";
import TaskDetails from "../../../components/projectDashboard/Tasks/TaskDetails";
import { TitleWidgets } from "../../../components/shared";
import { useAppTourContext } from "../../../components/supportButton/context/context";
import {
  selectCurrentGuideMe,
  selectCurrentProject,
} from "../../../features/project/projectSlice";
import { usePaginatedTasks } from "../../../utils/usePaginatedTasks";

function TaskRecycle() {
  // Translation
  const { t: tGeneral } = useTranslation("general");
  const { t: tTasks } = useTranslation("tasks");
  const { idProject } = useParams();

  // Selectors
  const selectedTasks = useSelector(selectSelectedTasks);
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const currentProject = useSelector(selectCurrentProject);

  const {
    total: backlogTotal,
    paginated: backlogTasks,
    refetch: refetchBacklog,
    isLoading: isLoadingBacklog,
    isFetching: isFetchingBacklog,
    noResults: noResultsBacklog,
    noMoreResults: noMoreResultsBacklog,
    onEndReach: onEndReachBacklog,
  } = usePaginatedTasks({
    idProject,
    bActive: false,
    status: "backlog",
  });

  const {
    total: doingTotal,
    paginated: doingTasks,
    refetch: refetchDoing,
    isLoading: isLoadingDoing,
    isFetching: isFetchingDoing,
    noResults: noResultsDoing,
    noMoreResults: noMoreResultsDoing,
    onEndReach: onEndReachDoing,
  } = usePaginatedTasks({
    idProject,
    bActive: false,
    status: "doing",
  });

  const {
    total: doneTotal,
    paginated: doneTasks,
    refetch: refetchDone,
    isLoading: isLoadingDone,
    isFetching: isFetchingDone,
    noResults: noResultsDone,
    noMoreResults: noMoreResultsDone,
    onEndReach: onEndReachDone,
  } = usePaginatedTasks({
    idProject,
    bActive: false,
    status: "done",
  });

  const { data: locations } = useGetLocationsQuery(idProject, {
    skip: !idProject,
    refetchOnMountOrArgChange: true,
  });

  const [
    restoreTask,
    {
      isSuccess: isRestoreTaskSuccess,
      isError: isRestoreTaskError,
      isLoading: isRestoreTaskLoading,
    },
  ] = useRestoreTaskMutation();

  const tasksLoading = useMemo(() => {
    return isLoadingBacklog || isLoadingDoing || isLoadingDone;
  }, [isLoadingBacklog, isLoadingDoing, isLoadingDone]);

  const totalTasks = useMemo(() => {
    if (!backlogTasks && !doingTasks && !doneTasks) return [];
    return [...backlogTasks, ...doingTasks, ...doneTasks];
  }, [backlogTasks, doingTasks, doneTasks]);

  // Hooks
  const { projectUsers } = useDistributionList(idProject);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Search
  const [searchValue, setSearchValue] = useState("");
  const customSearchFunction = (value) => {
    setSearchValue(value);
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // Reset selected tasks on component unmount
    return () => {
      dispatch(resetSelectedTasks());
    };
  }, []);

  useEffect(() => {
    if (isRestoreTaskSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tTasks("taskRestoredSuccessfully"),
      );
      dispatch(resetSelectedTasks());
    }
  }, [isRestoreTaskSuccess]);

  useEffect(() => {
    if (isRestoreTaskError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      dispatch(resetSelectedTasks());
    }
  }, [isRestoreTaskError]);

  const handleRestoreTasks = async () => {
    await restoreTask({
      ids: selectedTasks,
    }).unwrap();

    const backlogTaskIds = backlogTasks.map((task) => task.id);
    const selectedBacklogTasks = selectedTasks.filter((taskId) =>
      backlogTaskIds.includes(taskId),
    );

    const doingTaskIds = doingTasks.map((task) => task.id);
    const selectedDoingTasks = selectedTasks.filter((taskId) =>
      doingTaskIds.includes(taskId),
    );

    const doneTaskIds = doneTasks.map((task) => task.id);
    const selectedDoneTasks = selectedTasks.filter((taskId) =>
      doneTaskIds.includes(taskId),
    );

    if (selectedBacklogTasks.length > 0) {
      refetchBacklog();
    }
    if (selectedDoingTasks.length > 0) {
      refetchDoing();
    }
    if (selectedDoneTasks.length > 0) {
      refetchDone();
    }
  };

  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();
  useEffect(() => {
    if (
      totalTasks.length > 0 &&
      tourActive &&
      stepIndex === 2 &&
      currentGuideMe === "tasks-recycle"
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe, totalTasks]);

  return (
    <>
      <TitleWidgets
        title={tTasks("tasksRecycleBin")}
        showAdd={false}
        backEnabled
        restoreDisabled={!selectedTasks.length}
        onRestore={handleRestoreTasks}
        isRecycle
      />
      {/* Loading */}
      {tasksLoading && (
        <Box display="flex" justifyContent="center" width="100%" marginTop={10}>
          <CircularProgress size={60} />
        </Box>
      )}
      {/* Tasks available */}
      {totalTasks.length > 0 && (
        <TaskBoard
          backlogTasksProps={{
            total: backlogTotal,
            tasks: backlogTasks,
            refetch: refetchBacklog,
            isLoading: isLoadingBacklog || isFetchingBacklog,
            noResults: noResultsBacklog,
            noMoreResults: noMoreResultsBacklog,
            onEndReach: onEndReachBacklog,
          }}
          doingTasksProps={{
            total: doingTotal,
            tasks: doingTasks,
            refetch: refetchDoing,
            isLoading: isLoadingDoing || isFetchingDoing,
            noResults: noResultsDoing,
            noMoreResults: noMoreResultsDoing,
            onEndReach: onEndReachDoing,
          }}
          doneTasksProps={{
            total: doneTotal,
            tasks: doneTasks,
            refetch: refetchDone,
            isLoading: isLoadingDone || isFetchingDone,
            noResults: noResultsDone,
            noMoreResults: noMoreResultsDone,
            onEndReach: onEndReachDone,
          }}
          users={projectUsers}
          locations={locations}
        />
      )}
      <LoadingOverlay open={isRestoreTaskLoading} />
      <TaskDetails
        refetchBacklog={refetchBacklog}
        refetchDoing={refetchDoing}
        refetchDone={refetchDone}
      />
    </>
  );
}

export default TaskRecycle;
