import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DragDropContext } from "react-beautiful-dnd";
import { useEffect, useState } from "react";
import { ModalWithAction } from "../../shared";
import TaskBoardColumn from "./TaskBoardColumn";
import {
  useSetBacklogTaskMutation,
  useSetDoingTaskMutation,
  useSetDoneTaskMutation,
} from "../../../features/project/modules/tasks/tasksApiSlice";
import { PopUpAlert } from "../../PopUpAlert";

function TaskBoard({
  backlogTasksProps = {},
  doingTasksProps = {},
  doneTasksProps = {},
  users = [],
  locations = [],
  height = "90vh",
}) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tTasks } = useTranslation("tasks");

  const { tasks: backlogTasks, refetch: refetchBacklog } = backlogTasksProps;
  const { tasks: doingTasks, refetch: refetchDoing } = doingTasksProps;
  const { tasks: doneTasks, refetch: refetchDone } = doneTasksProps;

  const [closingTask, setClosingTask] = useState(null);

  const [
    setBacklogTask,
    { isLoading: isBacklogLoading, isError: isBacklogError },
  ] = useSetBacklogTaskMutation();
  const [setDoingTask, { isLoading: isDoingLoading, isError: isDoingError }] =
    useSetDoingTaskMutation();
  const [setDoneTask, { isLoading: isDoneLoading, isError: isDoneError }] =
    useSetDoneTaskMutation();

  useEffect(() => {
    if (isBacklogError || isDoingError || isDoneError) {
      PopUpAlert("error", "Error", tTasks("generalErrorMessage"));
    }
  }, [isBacklogError, isDoingError, isDoneError]);

  const handleOnDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      switch (source.droppableId) {
        case "backlog":
          if (destination.droppableId === "doing") {
            const itemBacklog = backlogTasks.splice(source.index, 1)[0];
            try {
              doingTasks.splice(source.index, 0, itemBacklog);
              await setDoingTask({ id: itemBacklog?.id });

              refetchBacklog();
              refetchDoing();
            } catch (e) {
              doingTasks.splice(source.index, 0, itemBacklog);
            }
          }
          break;
        case "doing":
          if (destination.droppableId === "backlog") {
            const itemDoing = doingTasks.splice(source.index, 1)[0];
            try {
              backlogTasks.splice(source.index, 0, itemDoing);
              await setBacklogTask({ id: itemDoing?.id });

              refetchBacklog();
              refetchDoing();
            } catch (e) {
              backlogTasks.splice(source.index, 0, itemDoing);
            }
          }
          if (destination.droppableId === "done") {
            const itemDoing = doingTasks[source.index];
            setClosingTask(itemDoing?.id);
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        width: "100%",
        paddingX: 1,
        paddingY: 1,
        height,
        overflow: "hidden",
      }}
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <TaskBoardColumn
          title={tTasks("backlog")}
          status="backlog"
          users={users}
          locations={locations}
          droppableId="backlog"
          {...backlogTasksProps}
          isLoading={isBacklogLoading || backlogTasksProps.isLoading}
        />
        <TaskBoardColumn
          title={tTasks("doing")}
          status="doing"
          users={users}
          locations={locations}
          borders
          droppableId="doing"
          {...doingTasksProps}
          isLoading={isDoingLoading || doingTasksProps.isLoading}
        />
        <TaskBoardColumn
          title={tTasks("done")}
          status="done"
          users={users}
          locations={locations}
          droppableId="done"
          {...doneTasksProps}
          isLoading={isDoneLoading || doneTasksProps.isLoading}
        />
      </DragDropContext>
      <ModalWithAction
        actionLabel={tGeneral("accept")}
        action={async () => {
          await setDoneTask({ id: closingTask });

          refetchDoing();
          refetchDone();

          setClosingTask(null);
        }}
        isOpen={Boolean(closingTask)}
        onClose={() => setClosingTask(null)}
        title={tTasks("closeTask")}
        content={tTasks("closeTaskMessage")}
      />
    </Box>
  );
}

export default TaskBoard;
