// Third-party library imports
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// Icon imports
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import CircleIcon from "@mui/icons-material/Circle";
import RefreshIcon from "@mui/icons-material/Refresh";

// Project-specific absolute imports
import { useGetProjectReportsQuery } from "../../../../features/project/analyticsApiSlice";
import { selectCurrentProject } from "../../../../features/project/projectSlice";

function Reports({ size, isMock }) {
  const { t: tAnalytics } = useTranslation("analytics");
  const currentProject = useSelector(selectCurrentProject);

  const {
    data: reportsData,
    isError,
    isLoading,
    refetch,
  } = useGetProjectReportsQuery(currentProject?.id, {
    skip: isMock,
  });

  const handleRefetch = () => {
    refetch(); // Call refetch to reload data
  };

  // Function to transform reportsData into the desired format
  const chartPieData = [
    {
      id: tAnalytics("open"), // Unique identifier for each slice
      label: tAnalytics("open"),
      value: isMock ? 33 : reportsData?.openCount || 0,
      color: "#6BC497",
    },
    {
      id: tAnalytics("closed"),
      label: tAnalytics("closed"),
      value: isMock ? 34 : reportsData?.closedCount || 0,
      color: "#CACACA",
    },
  ];

  const chartBarData = [
    {
      category: tAnalytics("basic"),
      openCount: isMock ? 12 : reportsData?.basic?.openCount || 0,
      closedCount: isMock ? 1 : reportsData?.basic?.closedCount || 0,
    },
    {
      category: tAnalytics("manpower"),
      openCount: isMock ? 1 : reportsData?.manpower?.openCount || 0,
      closedCount: isMock ? 0 : reportsData?.manpower?.closedCount || 0,
    },
    {
      category: tAnalytics("workProgress"),
      openCount: isMock ? 5 : reportsData?.workProgress?.openCount || 0,
      closedCount: isMock ? 2 : reportsData?.workProgress?.closedCount || 0,
    },
    {
      category: tAnalytics("problem"),
      openCount: isMock ? 20 : reportsData?.problem?.openCount || 0,
      closedCount: isMock ? 1 : reportsData?.problem?.closedCount || 0,
    },
    {
      category: tAnalytics("delay"),
      openCount: isMock ? 15 : reportsData?.delay?.openCount || 0,
      closedCount: isMock ? 5 : reportsData?.delay?.closedCount || 0,
    },
  ];

  // Colors for the bars
  const barColors = { openCount: "#6BC497", closedCount: "#CACACA" };

  // Get the total reports
  const total = isMock ? 70 : reportsData?.openCount + reportsData?.closedCount;

  let content;
  switch (size) {
    case "sm":
    case "md":
    case "lg":
      content = (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "215px",
            justifyContent: "space-between",
          }}
        >
          {/* Left Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "50%", // Each section takes 50% of the width
              padding: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "100%", // Make sure the content stretches to the full height
              }}
            >
              {/* Total report Count */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start", // Align items to the start to get them closer to the left edge
                  justifyContent: "center", // Center vertically within the available height
                  width: "50%", // Takes half of the left section width
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#1F1F1F",
                  }}
                >
                  {tAnalytics("total")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" }, // Stack vertically on small screens, horizontally on larger screens
                    alignItems: { xs: "flex-start", sm: "center" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "35px", lg: "45px" },
                      fontWeight: 700,
                      color: "#1F1F1F",
                    }}
                  >
                    {total}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#4B4B4B",
                      ml: { sm: "8px" }, // Add margin only when in row layout
                      mt: { xs: "8px", sm: "0" }, // Add top margin only when in column layout
                    }}
                  >
                    {tAnalytics("reportCount", { count: total })}
                  </Typography>
                </Box>
              </Box>

              {/* Nivo Pie Chart */}
              <Box
                sx={{
                  width: "50%",
                  height: "96%", // Ensure the height of the pie chart fits within the container
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <ResponsivePie
                    data={chartPieData}
                    margin={{ top: 0, right: 0, bottom: 0, left: 90 }}
                    innerRadius={0.7}
                    startAngle={-115}
                    padAngle={0}
                    cornerRadius={0}
                    borderWidth={0}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 0.2]],
                    }}
                    colors={{ datum: "data.color" }}
                    enableArcLabels={false}
                    enableArcLinkLabels={false}
                  />
                </Box>
              </Box>
            </Box>

            {/* Custom Legends (below pie chart and total) */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <CircleIcon sx={{ color: "#6BC497", fontSize: "10px" }} />
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#4B4B4B",
                  }}
                >
                  {chartPieData[0].value} {tAnalytics("open")}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <CircleIcon sx={{ color: "#CACACA", fontSize: "10px" }} />
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#4B4B4B",
                  }}
                >
                  {chartPieData[1].value} {tAnalytics("closed")}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Vertical Divider */}
          <Box
            sx={{
              width: "2px",
              backgroundColor: "#EEE",
            }}
          />

          {/* Right Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "50%", // Each section takes 50% of the width
              justifyContent: "center", // Center content vertically within the available height
              alignItems: "flex-start",
            }}
          >
            {/* Title */}
            <Typography
              sx={{
                color: "var(--Neutral-Neutral-500, #8E8E8E)",
                fontSize: "12px",
                fontWeight: 500,
                ml: "8px",
                mt: "14px",
              }}
            >
              {tAnalytics("categoryReports")}
            </Typography>

            {/* Nivo Bar Chart */}
            <Box sx={{ width: "100%", height: "100%" }}>
              <ResponsiveBar
                data={chartBarData}
                keys={["openCount", "closedCount"]}
                indexBy="category"
                margin={{ top: 50, right: 0, bottom: 40, left: 0 }}
                padding={0.2}
                innerPadding={8}
                borderRadius={8}
                enableLabel={false}
                enableGridY={false}
                groupMode="grouped"
                colors={({ id }) => barColors[id]} // Apply colors based on the keys
                axisTop={null}
                axisRight={null}
                layers={[
                  "grid",
                  "axes", // Ensure axes layer is included
                  "bars",
                  ({ bars, yScale }) =>
                    bars.map(({ key, width, height, x, color }) => (
                      <g key={key}>
                        <rect
                          x={x}
                          y={yScale(0) - 2} // Adjust y position
                          width={width}
                          height={height > 0 ? 0 : 2} // Minimum height for visibility
                          fill={color}
                        />
                      </g>
                    )),
                ]}
                axisBottom={{
                  tickSize: 0,
                  tickPadding: 10,
                  tickRotation: 0,
                  legendPosition: "middle",
                  legendOffset: 5,
                }}
                theme={{
                  axis: {
                    ticks: {
                      text: {
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fill: "var(--Neutral-Neutral-600, #4B4B4B)", // Aplica el color aquí
                      },
                    },
                  },
                }}
                tooltip={({ id, value }) => (
                  <Box
                    sx={{
                      padding: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "16px",
                      background: "rgba(255, 255, 255, 0.80)",
                      boxShadow: "0px 4px 12px 0px rgba(46, 43, 43, 0.10)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        color: "#4B4B4B",
                      }}
                    >
                      {value}{" "}
                      {id === "openCount"
                        ? tAnalytics("open")
                        : tAnalytics("closed")}
                    </Typography>
                  </Box>
                )}
              />
            </Box>
          </Box>
        </Box>
      );
      break;
    default:
      content = <></>;
  }

  if (isLoading && !isError && !isMock)
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (isError && !isLoading && !isMock)
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography
          sx={{
            color: "#8E8E8E",
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22px",
          }}
        >
          {tAnalytics("somethingWentWrong")}
        </Typography>
        <IconButton onClick={handleRefetch}>
          <RefreshIcon />
        </IconButton>
      </Box>
    );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <PlaylistAddCheckIcon sx={{ color: "#FA9928", fontSize: 30 }} />

        <Typography
          sx={{ fontSize: "16px", fontStyle: "normal", fontWeight: "500" }}
        >
          {tAnalytics("reports")}
        </Typography>
      </Box>
      {content}
    </Box>
  );
}

export { Reports };
