import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Icon,
} from "@mui/material";
// Icons
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
  Add,
  ArrowBack,
  Delete,
  Download,
  Menu as MenuIcon,
  PictureAsPdf,
  Restore,
  Settings,
  SaveOutlined,
} from "@mui/icons-material";
import { useCallback, useEffect, useRef, useState, forwardRef } from "react";
import { useSelector } from "react-redux";
import ExcelIcon from "../../assets/icons/sharedModules/excel.svg";

import { DISTRIBUTION_LIST_NAMES } from "../../constants";
import Can from "../../utils/can";
import { buildAndExportExcel } from "../../utils/excel";
import { ModalSettingsDrawings } from "../drawings/ModalSettingsDrawings";
import { ModalSettingsRfis } from "../projectDashboard/Rfis/ModalSettingsRfis";
import { ModalSettingsSubmittals } from "../projectDashboard/Submittals/ModalSettingsSubmittals";
import { SupportButtonAnnouncements } from "../supportButton/SupportButtonAnnouncements";
import SupportButtonDailyReports from "../supportButton/SupportButtonDailyReports";
import { SupportButtonDirectory } from "../supportButton/SupportButtonDirectory";
import { SupportButtonDocuments } from "../supportButton/SupportButtonDocuments";
import { SupportButtonDrawings } from "../supportButton/SupportButtonDrawings";
import { SupportButtonInspections } from "../supportButton/SupportButtonInspections";
import { SupportButtonInspectionsTemplates } from "../supportButton/SupportButtonInspectionsTemplates";
import { SupportButtonMeetings } from "../supportButton/SupportButtonMeetings";
import SupportButtonProjectDirectory from "../supportButton/SupportButtonProjectDirectory";
import { SupportButtonRFI } from "../supportButton/SupportButtonRfi";
import { SupportButtonPhotos } from "../supportButton/SupportButtonPhotos";
import { SupportButtonTasks } from "../supportButton/SupportButtonTasks";
import { useAppTourContext } from "../supportButton/context/context";
import { selectCurrentGuideMe } from "../../features/project/projectSlice";
import { SupportButtonGeneralDirectory } from "../supportButton/SupportButtonGeneralDirectory";
import { ModalDistributionList } from "./ModalDistributionList";
import { TooltipIconButton } from "./TooltipIconButton";

import { useToast } from "../../contexts/ToastContext";

// eslint-disable-next-line no-underscore-dangle
function _TitleWidgets(props, ref) {
  const {
    title,
    customActions = [],
    backEnabled = false,
    isRecycle = false,
    distributionListName,
    customBackFunction,
    moduleName,
    addFunction,
    onRestore,
    restoreDisabled = true,
    showDelete = false,
    onDelete,
    isRestoreLoading = false,
    customElement = null,
    showAdd = true,
    showMoreOptions = true,
    titleAdornment = null,
    enableSupport = false,
    addNameTour = "",
    showDownload = false,
    onDownload = () => {},
    showRestore = true,
    showExports = true,
    showSave = false,
    onSave,
    buildExcelData,
  } = props;

  const { t: tGeneral } = useTranslation("general");
  const navigate = useNavigate();
  const toast = useToast();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const {
    state: { tourActive, stepIndex, steps, run },
    setState,
  } = useAppTourContext();
  const currentGuideMe = useSelector(selectCurrentGuideMe);
  const [isTour, setIsTour] = useState(false);
  const moreRef = useRef();

  useEffect(() => {
    if (!tourActive || !steps || !moreRef.current) return;

    if (
      tourActive &&
      stepIndex === 2 &&
      (currentGuideMe === "rfi-export" ||
        currentGuideMe === "daily-reports-export")
    ) {
      setAnchorEl(moreRef.current);
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }

    if (
      (((stepIndex === 0 || stepIndex === 1) &&
        (currentGuideMe === "rfi-recycle" ||
          currentGuideMe === "announcement-recycle" ||
          currentGuideMe === "docs-settings")) ||
        ((currentGuideMe === "rfi-export" ||
          currentGuideMe === "daily-reports-export") &&
          stepIndex >= 0 &&
          stepIndex <= 2)) &&
      tourActive
    ) {
      setAnchorEl(moreRef.current);
    } else if (
      tourActive &&
      (stepIndex === 0 || stepIndex === 1) &&
      (currentGuideMe === "meetings-recycle" ||
        currentGuideMe === "meetings-settings" ||
        currentGuideMe === "daily-reports-settings" ||
        currentGuideMe === "rfi-settings")
    ) {
      setAnchorEl(moreRef.current);
    } else {
      setAnchorEl(null);
    }

    if (
      tourActive &&
      stepIndex === 1 &&
      (currentGuideMe === "inspections-add-template" ||
        currentGuideMe === "drawings-review-and-publish" ||
        currentGuideMe === "drawings-settings" ||
        currentGuideMe === "daily-reports-recycle" ||
        currentGuideMe === "daily-reports-settings")
    ) {
      setTimeout(() => {
        setAnchorEl(moreRef.current);
        setTimeout(() => {
          setState({ run: true });
        }, 500);
      }, 500);
    }

    if (
      tourActive &&
      stepIndex === 1 &&
      (currentGuideMe === "drawings-recycle" ||
        currentGuideMe === "tasks-recycle")
    ) {
      setTimeout(() => {
        setAnchorEl(moreRef.current);
        setTimeout(() => {
          setState({ run: true });
        }, 500);
      }, 500);
    }
  }, [stepIndex, tourActive, steps, currentGuideMe, setState]);

  useEffect(() => {
    if (!tourActive || !steps || !moreRef.current) return;

    if (
      stepIndex === 1 &&
      (currentGuideMe === "docs-settings" ||
        currentGuideMe === "meetings-settings") &&
      !run
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }

    if (
      (stepIndex === 2 || stepIndex === 3) &&
      (currentGuideMe === "docs-settings" ||
        currentGuideMe === "meetings-settings") &&
      !run
    ) {
      setIsSettingsOpen(true);
      setIsTour(true);
    }

    if (
      (stepIndex === 2 || stepIndex === steps.length - 2) &&
      (currentGuideMe === "drawings-settings" ||
        currentGuideMe === "daily-reports-settings" ||
        currentGuideMe === "photos-settings" ||
        currentGuideMe === "rfi-settings" ||
        currentGuideMe === "tasks-settings") &&
      !run
    ) {
      setIsSettingsOpen(true);
      setIsTour(true);
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }

    if (
      (stepIndex === steps.length - 1 || stepIndex < 2) &&
      (currentGuideMe === "docs-settings" ||
        currentGuideMe === "meetings-settings" ||
        currentGuideMe === "drawings-settings" ||
        currentGuideMe === "daily-reports-settings" ||
        currentGuideMe === "photos-settings" ||
        currentGuideMe === "rfi-settings" ||
        currentGuideMe === "tasks-settings")
    ) {
      setIsSettingsOpen(false);
      setIsTour(false);
    }

    if (
      tourActive &&
      stepIndex === 1 &&
      (currentGuideMe === "photos-recycle" ||
        currentGuideMe === "photos-settings" ||
        currentGuideMe === "rfi-recycle" ||
        currentGuideMe === "rfi-settings" ||
        currentGuideMe === "tasks-settings")
    ) {
      setAnchorEl(moreRef.current);
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [stepIndex, tourActive, anchorEl, steps, run, currentGuideMe, setState]);

  const renderSupportButton = () => {
    // Directory
    if (moduleName === "directory") {
      return <SupportButtonDirectory />;
    }

    // RFI
    if (moduleName === "rfis") {
      return <SupportButtonRFI />;
    }

    // Inspections
    if (moduleName === "inspections") {
      return <SupportButtonInspections />;
    }

    // Inspections Templates
    if (moduleName === "inspectionsTemplates") {
      return <SupportButtonInspectionsTemplates />;
    }

    // Drawings
    if (moduleName === "drawings") {
      return <SupportButtonDrawings />;
    }

    // Project Directory
    if (moduleName === "projectDirectory") {
      return <SupportButtonProjectDirectory />;
    }

    // Meetings
    if (moduleName === "meetings") {
      return <SupportButtonMeetings />;
    }

    // Daily Reports
    if (moduleName === "dailyReports") {
      return <SupportButtonDailyReports />;
    }

    // Documents
    if (moduleName === "documents") {
      return <SupportButtonDocuments />;
    }

    // Announcements
    if (moduleName === "announcements") {
      return <SupportButtonAnnouncements />;
    }

    if (moduleName === "photos") {
      return <SupportButtonPhotos />;
    }

    if (moduleName === "tasks") {
      return <SupportButtonTasks />;
    }

    if (moduleName === "externalContact") {
      return <SupportButtonGeneralDirectory />;
    }

    return null;
  };

  const renderCustomActions = () => {
    if (!customActions.length) return null;
    return customActions.map(({ onClick, icon, label, dataTour }) => (
      <MenuItem onClick={onClick} data-tour={dataTour}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>{label}</ListItemText>
      </MenuItem>
    ));
  };

  const handleCloseSettings = () => {
    setIsSettingsOpen(false);
  };

  const handleOpenSettings = () => {
    setIsSettingsOpen(true);
  };

  const handleAdd = (e) => {
    if (addFunction) {
      addFunction(e);
    } else {
      navigate("form");
    }
  };

  const renderModalSettings = useCallback(() => {
    if (!isSettingsOpen) return null;
    if (distributionListName === DISTRIBUTION_LIST_NAMES.drawings) {
      return (
        <ModalSettingsDrawings
          isOpen={isSettingsOpen}
          onClose={handleCloseSettings}
        />
      );
    }

    if (distributionListName === DISTRIBUTION_LIST_NAMES.rfis) {
      return (
        <ModalSettingsRfis
          isOpen={isSettingsOpen}
          onClose={handleCloseSettings}
        />
      );
    }

    if (distributionListName === DISTRIBUTION_LIST_NAMES.submittals) {
      return (
        <ModalSettingsSubmittals
          isOpen={isSettingsOpen}
          onClose={handleCloseSettings}
        />
      );
    }

    return (
      <ModalDistributionList
        distributionListName={distributionListName}
        isOpen={isSettingsOpen}
        onClose={handleCloseSettings}
        isTour={isTour}
      />
    );
  }, [isSettingsOpen, distributionListName, isTour]);

  const exportToExcel = () => {
    const { fileName, data } = buildExcelData();
    if (data.length) {
      buildAndExportExcel(fileName, data);
      toast.success(tGeneral("excelSuccessfullyDownloaded"));
    }
  };

  return (
    <Box
      display="flex"
      width="100%"
      borderBottom="1px solid #FBCB04"
      alignItems="center"
      columnGap="10px"
      minHeight="60px"
    >
      {backEnabled && (
        <IconButton
          onClick={() =>
            customBackFunction ? customBackFunction() : navigate(-1)
          }
        >
          <ArrowBack />
        </IconButton>
      )}
      <Typography variant="h4" sx={{ ml: "10px" }}>
        {title}
      </Typography>
      {titleAdornment}
      {enableSupport && renderSupportButton()}
      <Box flex={1} />
      {customElement}
      <Box display="flex" columnGap="10px" mr="10px">
        {moduleName === "photos" && showDownload && (
          <TooltipIconButton
            onClick={onDownload}
            label={tGeneral("download")}
            icon={<Download sx={{ fontSize: "2rem" }} />}
            color="#FBCB04"
          />
        )}
        {showDelete && (
          <TooltipIconButton
            onClick={onDelete}
            label={tGeneral("delete")}
            icon={<Delete sx={{ fontSize: "2rem" }} />}
            color="#FBCB04"
          />
        )}
        {isRecycle && (
          <TooltipIconButton
            onClick={onRestore}
            disabled={restoreDisabled || isRestoreLoading}
            label={tGeneral("restore")}
            icon={<Restore sx={{ fontSize: "2rem" }} />}
            color="#FBCB04"
            data-tour="confirm-recycle"
          />
        )}
        {showAdd && !isRecycle && (
          <Can I="add" a={moduleName}>
            <TooltipIconButton
              label={tGeneral("add")}
              onClick={handleAdd}
              icon={<Add sx={{ fontSize: "2rem" }} />}
              color="#FBCB04"
              data-tour={addNameTour}
              ref={ref}
            />
          </Can>
        )}
        {showMoreOptions && (
          <TooltipIconButton
            onClick={(e) => setAnchorEl(e.currentTarget)}
            label={tGeneral("more")}
            icon={<MenuIcon sx={{ fontSize: "2rem" }} />}
            color="#FBCB04"
            data-tour="more"
            ref={moreRef}
          />
        )}
        {showSave && (
          <Can I="add" a={moduleName}>
            <TooltipIconButton
              onClick={onSave}
              label={tGeneral("save")}
              icon={<SaveOutlined sx={{ fontSize: "2rem" }} />}
              color="#FBCB04"
              data-tour="save"
              ref={moreRef}
            />
          </Can>
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {distributionListName && (
          <Can I="settings" a={moduleName}>
            <MenuItem onClick={handleOpenSettings} data-tour="settings">
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText>{tGeneral("settings")}</ListItemText>
            </MenuItem>
          </Can>
        )}
        {showRestore && (
          <Can I="restore" a={moduleName}>
            <MenuItem onClick={() => navigate("./restore")} data-tour="recycle">
              <ListItemIcon>
                <Restore />
              </ListItemIcon>
              <ListItemText>{tGeneral("restore")}</ListItemText>
            </MenuItem>
          </Can>
        )}
        {renderCustomActions()}
        {moduleName !== "photos" && showExports && (
          <>
            <MenuItem disabled data-tour="export-pdf">
              <ListItemIcon>
                <PictureAsPdf />
              </ListItemIcon>
              <ListItemText>{tGeneral("exportPdf")}</ListItemText>
            </MenuItem>
            <MenuItem disabled data-tour="export-csv">
              <ListItemIcon>
                <Download />
              </ListItemIcon>
              <ListItemText>{tGeneral("exportCsv")}</ListItemText>
            </MenuItem>
          </>
        )}
        {moduleName !== "photos" && Boolean(buildExcelData) && (
          <MenuItem data-tour="export-excel" onClick={exportToExcel}>
            <ListItemIcon>
              <Icon sx={{ height: "20px" }}>
                <img alt="excel-icon" src={ExcelIcon} />
              </Icon>
            </ListItemIcon>
            <ListItemText>{tGeneral("exportExcel")}</ListItemText>
          </MenuItem>
        )}
      </Menu>
      {renderModalSettings()}
    </Box>
  );
}

const TitleWidgets = forwardRef(_TitleWidgets);

export { TitleWidgets };
