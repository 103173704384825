import { AttachFile, Close, Edit, ExpandMore } from "@mui/icons-material";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import moment from "moment";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectCurrentProject } from "../../../../../features/project/projectSlice";
import { FilePreviewHorizontalScroll } from "../../../../../components/shared";

function ReportTopicItem({ topic, onEditTopic, onDeleteTopic }) {
  const { t: tGeneral } = useTranslation("general");
  const currentProject = useSelector(selectCurrentProject);

  const [expanded, setExpanded] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(topic.title);

  const handleExpand = (e) => {
    e.stopPropagation();
    setExpanded((prev) => !prev);
  };

  const handleEditingTitle = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleCancelEdit = (e) => {
    e.stopPropagation();
    setIsEditing(false);
    setNewTitle(topic.title);
  };

  const handleEditTopicTtile = (e) => {
    e.stopPropagation();
    const newTopic = { ...topic, title: newTitle };
    onEditTopic(newTopic);
    setIsEditing(false);
  };

  const handleEditTopicFiles = (files) => {
    const newTopic = { ...topic, files };
    onEditTopic(newTopic);
  };

  const showingFiles = useMemo(() => {
    if (topic) {
      return topic.files.map((file) => {
        if (typeof file === "string") {
          const fileNameIndex = file.lastIndexOf("/");
          return {
            src: file,
            name: file.substring(fileNameIndex + 1),
          };
        }
        return file;
      });
    }
    return [];
  }, [topic]);
  const handleDeleteFile = (file, i) => {
    const newTopic = { ...topic };
    const newFiles = [...newTopic.files];
    newFiles.splice(i, 1);
    newTopic.files = newFiles;
    if (file.id) {
      newTopic.deletedFiles = [...(newTopic.deletedFiles || []), file.id];
    }
    onEditTopic(newTopic);
  };

  const handleDeleteTopic = (e) => {
    e.stopPropagation();
    onDeleteTopic(topic);
  };

  const handleSetEditTopic = (e) => {
    const { value } = e.target;
    setNewTitle(value);
  };

  return (
    <Box
      component="div"
      sx={{
        backgroundColor: "#FFEBBC",
        borderRadius: "20px",
        p: "10px 20px",
        maxHeight: expanded ? "1000px" : "45px",
        cursor: "pointer",
        transition: expanded
          ? "max-height 1s ease-out"
          : "max-height 1s ease-in",
        mb: "8px",
      }}
    >
      {isEditing ? (
        <TextField
          size="small"
          fullWidth
          value={newTitle}
          onChange={handleSetEditTopic}
          onClick={(e) => e.stopPropagation()}
          multiline
          maxRows={8}
          InputProps={{
            sx: { backgroundColor: "white" },
            endAdornment: (
              <Box display="flex" columnGap="5px">
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancelEdit}
                >
                  {tGeneral("cancel")}
                </Button>
                <Button variant="contained" onClick={handleEditTopicTtile}>
                  {tGeneral("save")}
                </Button>
              </Box>
            ),
          }}
        />
      ) : (
        <Box display="flex" columnGap="10px" alignItems="center">
          <Typography
            sx={{
              color: "#B48100",
            }}
          >
            {topic.order}.
          </Typography>
          <Box display="flex" maxHeight="300px" sx={{ overflowY: "auto", maxWidth:"90%", flexDirection:"row", alignItems:"center" }}>
            <Typography
              flex={1}
              component="div"
              onClick={(e) => e.stopPropagation()}
              onDoubleClick={handleEditingTitle}
              sx={[
                {
                  color: "#B48100",
                  flex: 1,
                  wordWrap: "break-word",
                  whiteSpace: "pre-wrap",
                },
                !expanded && {
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: expanded ? "normal" : "nowrap",
                },
              ]}
            >
              {topic.title}
            </Typography>
            <IconButton onClick={handleEditingTitle} sx={{ py: 0, px: "1px", m: 0, maxHeight: "25px" }}>
              <Edit />
            </IconButton>
          </Box>
          {topic.files.length > 0 && <AttachFile />}
          <IconButton onClick={handleDeleteTopic} sx={{ p: 0, m: 0 }}>
            <Close />
          </IconButton>
          <IconButton
            onClick={handleExpand}
            sx={{
              p: 0,
              m: 0,
              height: "20px",
              transform: expanded ? "rotate(180deg)" : "none",
              transition: "transform 0.2s ease",
            }}
          >
            <ExpandMore />
          </IconButton>
        </Box>
      )}
      {expanded && (
        <FilePreviewHorizontalScroll
          files={showingFiles}
          onAddPhotos={handleEditTopicFiles}
          onDelete={handleDeleteFile}
        />
      )}
      {expanded && (
        <Box display="flex" justifyContent="flex-end" columnGap="30px">
          <Typography fontSize="0.875rem" sx={{ color: "#B48303" }}>
            {moment(topic.createDate)
              .tz(currentProject?.projectTimezone)
              .format("D MMMM YYYY")}
          </Typography>
          <Typography fontSize="0.875rem" sx={{ color: "#B48303" }}>
            |
          </Typography>
          <Typography fontSize="0.875rem" sx={{ color: "#B48303" }}>
            {moment(topic.createDate)
              .tz(currentProject?.projectTimezone)
              .format("h:mm A")}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export { ReportTopicItem };
