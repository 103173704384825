import { apiSlice } from "../../../../api/apiSlice";

const bimApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    uploadBim: builder.mutation({
      query: (body) => ({
        url: "/project/bim/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["BIM"],
    }),
    getBimsByProject: builder.query({
      query: (idProject) => `/project/bim/all/${idProject}`,
      providesTags: ["BIM"],
    }),
    getBim: builder.query({
      query: (idBim) => `/project/bim/byId/${idBim}`,
      providesTags: ["BIM"],
    }),
    getAPSPublicToken: builder.query({
      query: () => "/project/bim/publicToken",
      keepUnusedDataFor: 10,
    }),
    deleteBim: builder.mutation({
      query: (body) => ({
        url: "/project/bim/delete",
        method: "POST",
        body,
      }),
      invalidatesTags: ["BIM"],
    }),
  }),
});

export const {
  useUploadBimMutation,
  useGetBimQuery,
  useGetBimsByProjectQuery,
  useLazyGetAPSPublicTokenQuery,
  useGetAPSPublicTokenQuery,
  useDeleteBimMutation,
} = bimApiSlice;
