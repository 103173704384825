import * as React from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
// MUI
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
// Icons
import {
  Add,
  ArrowBack,
  ChevronRight,
  Clear,
  Delete,
  Restore,
  Search,
  Settings,
} from "@mui/icons-material";
import { set } from "react-hook-form";
import Can from "../../../utils/can";
// Custom imports
import LoadingOverlay from "../../../components/LoadingOverlay";
import { PopUpAlert } from "../../../components/PopUpAlert";
import SupportButtonTasks from "../../../components/supportButton/SupportButtonTasks";
import TaskBoard from "../../../components/projectDashboard/Tasks/TaskBoard";
import TaskSettingsModal from "../../../components/projectDashboard/Tasks/TaskSettingsModal";
import {
  EmptyState,
  TasksSkeletonLoading,
  TitleWidgets,
} from "../../../components/shared";
import BubbleAvatarDeletable from "../../../components/userAvatars/BubbleAvatarDeletable";
import { statusOptions } from "../../../constants/projectDashboard/selectOptions";
import { useGetLocationsQuery } from "../../../features/locations/locationApiSlice";
import { selectTheme } from "../../../features/preferences/preferencesSlice";
import { useDeleteTaskMutation } from "../../../features/project/modules/tasks/tasksApiSlice";
import {
  addSelectedTask,
  CONST_DEFAULT_TASK_FILTERS,
  resetSelectedTasks,
  selectCurrentFiltersTasks,
  selectSelectedTasks,
  setCurrentFiltersTasks,
  setEditingTask,
} from "../../../features/project/modules/tasks/tasksSlice";
import {
  useGetProjectConfigurationQuery,
  useUpdateProjectConfigMutation,
} from "../../../features/project/projectApiSlice";
import {
  selectCurrentGuideMe,
  selectCurrentRole,
} from "../../../features/project/projectSlice";
import { useDistributionList } from "../../../hooks";
import { selectSettingsTasksPopupOpen } from "../../../features/project/modules/rfiSlice";

import { useExportTasksMutation } from "../../../features/exportPdf";
import { ModalFileViewer } from "../../../components/shared/ModalFileViewer";
import TaskDetails from "../../../components/projectDashboard/Tasks/TaskDetails";
import { DISTRIBUTION_LIST_NAMES } from "../../../constants";
import CaslContext from "../../../utils/caslContext";
import { useAppTourContext } from "../../../components/supportButton/context/context";
import { SearchUserFilter } from "../../../components/shared/SearchUserFilter";
import { SearchBasicFilter } from "../../../components/shared/SearchBasicFilter";
import { SearchLocationFilter } from "../../../components/shared/SearchLocationFilter";
import { DateRangeFilter } from "../../../components/shared/DateRangeFilter";
import { usePaginatedTasks } from "../../../utils/usePaginatedTasks";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Tasks() {
  // Translation
  const { t: tGeneral } = useTranslation("general");
  const { t: tTasks } = useTranslation("tasks");

  const { idProject } = useParams();

  const ability = useContext(CaslContext);
  // Local state
  const [openAddFilter, setOpenAddFilter] = useState(false);
  // Filters
  const [userList, setUserList] = useState([]);
  const [currentFilter, setCurrentFilter] = useState();

  // Selectors
  const selectedTasks = useSelector(selectSelectedTasks);
  const darkMode = useSelector(selectTheme);

  const {
    total: backlogTotal,
    paginated: backlogTasks,
    refetch: refetchBacklog,
    isLoading: isLoadingBacklog,
    isFetching: isFetchingBacklog,
    noResults: noResultsBacklog,
    noMoreResults: noMoreResultsBacklog,
    onEndReach: onEndReachBacklog,
  } = usePaginatedTasks({
    idProject,
    bActive: true,
    status: "backlog",
  });

  const {
    total: doingTotal,
    paginated: doingTasks,
    refetch: refetchDoing,
    isLoading: isLoadingDoing,
    isFetching: isFetchingDoing,
    noResults: noResultsDoing,
    noMoreResults: noMoreResultsDoing,
    onEndReach: onEndReachDoing,
  } = usePaginatedTasks({
    idProject,
    bActive: true,
    status: "doing",
  });

  const {
    total: doneTotal,
    paginated: doneTasks,
    refetch: refetchDone,
    isLoading: isLoadingDone,
    isFetching: isFetchingDone,
    noResults: noResultsDone,
    noMoreResults: noMoreResultsDone,
    onEndReach: onEndReachDone,
  } = usePaginatedTasks({
    idProject,
    bActive: true,
    status: "done",
  });

  const tasksLoading = useMemo(() => {
    return isLoadingBacklog || isLoadingDoing || isLoadingDone;
  }, [isLoadingBacklog, isLoadingDoing, isLoadingDone]);

  const tasksFetching = useMemo(() => {
    return isFetchingBacklog || isFetchingDoing || isFetchingDone;
  }, [isFetchingBacklog, isFetchingDoing, isFetchingDone]);

  const totalTasks = useMemo(() => {
    if (!backlogTasks && !doingTasks && !doneTasks) return [];
    return [...backlogTasks, ...doingTasks, ...doneTasks];
  }, [backlogTasks, doingTasks, doneTasks]);

  const { data: locations } = useGetLocationsQuery(idProject, {
    skip: !idProject,
    refetchOnMountOrArgChange: true,
  });

  const { data: projectConfig } = useGetProjectConfigurationQuery(idProject);
  const [
    updateConfig,
    { isSuccess: isUpdateConfigSuccess, isError: isUpdateConfigError },
  ] = useUpdateProjectConfigMutation();
  const [
    deleteTask,
    {
      isSuccess: isDeleteTaskSuccess,
      isError: isDeleteTaskError,
      isLoading: isDeleteTaskLoading,
    },
  ] = useDeleteTaskMutation();

  // Hooks
  const {
    getSelectedUsers,
    projectUsers,
    handleCloseDistributionList,
    handleOpenDistributionList,
    isDistributionListOpen,
  } = useDistributionList(idProject);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filterOptions = [
    {
      value: "assigned",
      label: tGeneral("assignees"),
    },
    {
      value: "creator",
      label: tGeneral("createdBy"),
    },
    {
      value: "status",
      label: tGeneral("status"),
    },
    {
      value: "category",
      label: tGeneral("category"),
    },
    {
      value: "priority",
      label: tGeneral("priority"),
    },
    {
      value: "location",
      label: tGeneral("location"),
    },
    {
      value: "createdOn",
      label: tGeneral("createdOn"),
    },
  ];

  const priorityOptions = [
    {
      value: "high",
      label: tTasks("high"),
    },
    {
      value: "medium",
      label: tTasks("medium"),
    },
    {
      value: "low",
      label: tTasks("low"),
    },
  ];

  const styles = {
    secondaryFilter: {
      ml: 1,
      minWidth: "200px",
      verticalAlign: "center",
    },
    inputTitle: {
      backgroundColor: darkMode ? "" : "transparent",
      borderRadius: 2,
    },
    selectInput: {
      backgroundColor: darkMode ? "" : "#FFF5DB",
    },
  };

  const handleDeleteTasks = async () => {
    await deleteTask({
      ids: selectedTasks,
    }).unwrap();

    const backlogTaskIds = backlogTasks.map((task) => task.id);
    const selectedBacklogTasks = selectedTasks.filter((taskId) =>
      backlogTaskIds.includes(taskId),
    );

    const doingTaskIds = doingTasks.map((task) => task.id);
    const selectedDoingTasks = selectedTasks.filter((taskId) =>
      doingTaskIds.includes(taskId),
    );

    const doneTaskIds = doneTasks.map((task) => task.id);
    const selectedDoneTasks = selectedTasks.filter((taskId) =>
      doneTaskIds.includes(taskId),
    );

    if (selectedBacklogTasks.length > 0) {
      refetchBacklog();
    }
    if (selectedDoingTasks.length > 0) {
      refetchDoing();
    }
    if (selectedDoneTasks.length > 0) {
      refetchDone();
    }
  };

  function getTaskStatus(status, isDraft) {
    let value = "open";
    if (status === "backlog" && isDraft) {
      value = "draft";
    }
    if (status === "done") {
      value = "close";
    }

    return value;
  }

  const currentTaskFilters = useSelector(selectCurrentFiltersTasks);

  // Filters
  const filterTasks = (value, filter) => {
    let currentFilters = { ...currentTaskFilters };

    const resetCurrentFilters = () => {
      currentFilters = CONST_DEFAULT_TASK_FILTERS;
    };

    if (!value) {
      resetCurrentFilters();
    } else {
      switch (filter) {
        case "assigned":
          currentFilters = { assignees: value };

          if (currentFilter === "assigned") resetCurrentFilters();
          break;
        case "creator":
          currentFilters = { creator: [value] };

          if (currentFilter === "creator") resetCurrentFilters();
          break;
        case "status":
          currentFilters = { status: [getTaskStatus(value)] };

          if (currentFilter === "status") resetCurrentFilters();
          break;
        case "category":
          currentFilters = { category: [value] };

          if (currentFilter === "category") resetCurrentFilters();
          break;
        case "priority":
          currentFilters = { priority: [value] };

          if (currentFilter === "priority") resetCurrentFilters();
          break;
        case "location":
          currentFilters = { location: [value] };

          if (currentFilter === "location") resetCurrentFilters();
          break;
        case "createdOn":
          currentFilters = {
            createdAfter: value[0],
            createdBefore: value[1],
          };

          if (currentFilter === "createdOn") resetCurrentFilters();
          break;
        default:
          break;
      }
    }

    dispatch(setCurrentFiltersTasks(currentFilters));
  };

  useEffect(() => {
    // Reset selected tasks on component unmount
    return () => {
      dispatch(resetSelectedTasks());
    };
  }, []);

  useEffect(() => {
    if (isUpdateConfigSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tGeneral("projectConfigurationUpdateSuccess"),
      );
      handleCloseDistributionList();
    }
    if (isUpdateConfigError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      handleCloseDistributionList();
    }
  }, [isUpdateConfigSuccess, isUpdateConfigError]);

  useEffect(() => {
    if (isDeleteTaskSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tTasks("taskDeletedSuccessfully"),
      );
      dispatch(resetSelectedTasks());
    }
  }, [isDeleteTaskSuccess]);

  useEffect(() => {
    if (isDeleteTaskError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      dispatch(resetSelectedTasks());
    }
  }, [isDeleteTaskError]);

  useEffect(() => {
    if (projectUsers) {
      const usersList = projectUsers
        .filter(function (obj) {
          return !obj.bExternal; // only intern users
        })
        .map((user) => ({
          id: user.user.id,
          name: `${user.user.firstName} ${user.user.lastName}`,
          urlAvatar: user.user.urlAvatar,
          // idUser: user.user.id,
        }));
      setUserList(usersList);
    }
  }, [projectUsers]);

  const tiers = useMemo(() => {
    if (locations) {
      return Object.keys(locations);
    }
    return [];
  }, [locations]);

  const categories = useMemo(() => {
    if (projectConfig?.taskCategoryList) {
      return projectConfig?.taskCategoryList.map((category, index) => ({
        id: index,
        label: category,
      }));
    }
    return [];
  }, [projectConfig?.taskCategoryList]);

  const [pdfUrl, setPdfUrl] = React.useState(null);

  const {
    state: { tourActive, stepIndex, run, steps },
    setState,
  } = useAppTourContext();
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 0 &&
      !run &&
      (currentGuideMe === "tasks-add" ||
        currentGuideMe === "tasks-view" ||
        currentGuideMe === "tasks-edit")
    ) {
      setState({ run: true });
    }
  }, [tourActive, stepIndex, currentGuideMe, run, setState]);

  useEffect(() => {
    if (
      totalTasks &&
      tourActive &&
      stepIndex === 0 &&
      (currentGuideMe === "tasks-view" || currentGuideMe === "tasks-edit")
    ) {
      if (totalTasks.length > 0) {
        const task = totalTasks.find((task) => !task.bDraft && task.bActive);
        dispatch(addSelectedTask(task?.id));
        setTimeout(() => {
          setState({ run: true });
        }, 500);
      }
    }

    if (
      tourActive &&
      stepIndex === steps.length - 1 &&
      (currentGuideMe === "tasks-view" || currentGuideMe === "tasks-edit")
    ) {
      dispatch(resetSelectedTasks());
      dispatch(resetSelectedTasks());
    }
  }, [
    tourActive,
    stepIndex,
    currentGuideMe,
    steps,
    totalTasks,
    dispatch,
    setState,
  ]);

  if (tasksLoading) {
    return <TasksSkeletonLoading />;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="90vh"
      overflow="hidden"
    >
      {/* Title, searchbar & buttons */}
      <TitleWidgets
        title={tTasks("tasks")}
        distributionListName={DISTRIBUTION_LIST_NAMES.tasks}
        moduleName="tasks"
        showDelete={selectedTasks.length > 0 && ability.can("delete", "tasks")}
        onDelete={handleDeleteTasks}
        enableSupport
        addNameTour="add-tasks-1"
        showExports={false}
      />
      {/* Filters & delete button */}
      {totalTasks.length > 0 && (
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          my="10px"
          px="10px"
        >
          {/* Filter dropdown & chips */}
          <Box
            display="flex"
            flex={1}
            justifyContent="flex-start"
            alignItems="center"
          >
            <FormControl sx={{ minWidth: "15%" }} size="small">
              <InputLabel>{tGeneral("addFilter")}</InputLabel>
              <Select
                open={openAddFilter}
                onClose={() => {
                  setOpenAddFilter(false);
                }}
                onOpen={() => {
                  setOpenAddFilter(true);
                }}
                label={tGeneral("addFilter")}
                MenuProps={{ disableScrollLock: true }}
                defaultValue=""
                value={currentFilter === undefined ? "" : currentFilter}
                onChange={(e) => {
                  dispatch(setCurrentFiltersTasks(CONST_DEFAULT_TASK_FILTERS));
                  setCurrentFilter(e.target.value);
                }}
              >
                {filterOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Assigned Select */}
            {(currentFilter === "assigned" ||
              (currentTaskFilters?.assigned ?? []).length > 0) && (
              <SearchUserFilter
                title={tGeneral("assignees")}
                userList={userList}
                selectedUsersList={userList.filter(
                  (user) =>
                    (currentTaskFilters?.assigned ?? []).findIndex(
                      (assignedUser) => assignedUser === user.id,
                    ) > -1,
                )}
                selectedUsersFormatted={currentTaskFilters?.assigned ?? []}
                handleUpdateUsersList={(list) => {
                  filterTasks(
                    list.length > 0 ? list.map((user) => user.id) : [],
                    "assigned",
                  );
                }}
                selectProps={{
                  ml: 1,
                  width: "200px",
                }}
              />
            )}
            {/* Creator Select */}
            {(currentFilter === "creator" ||
              (currentTaskFilters?.creator ?? []).length > 0) && (
              <SearchUserFilter
                title={tGeneral("createdBy")}
                userList={userList}
                selectedUsersList={userList.filter(
                  (user) =>
                    (currentTaskFilters?.creator ?? []).findIndex(
                      (creatorUser) => creatorUser === user.id,
                    ) > -1,
                )}
                selectedUsersFormatted={currentTaskFilters?.creator ?? []}
                handleUpdateUsersList={(list) => {
                  filterTasks(
                    list.length > 0 ? list.map((user) => user.id) : [],
                    "creator",
                  );
                }}
                selectProps={{
                  ml: 1,
                  width: "200px",
                }}
              />
            )}
            {/* Status Select */}
            {(currentFilter === "status" ||
              (currentTaskFilters?.status ?? []).length > 0) && (
              <FormControl size="small" sx={styles.secondaryFilter}>
                <InputLabel id="userTag1" sx={styles.inputTitle}>
                  {(currentTaskFilters?.status ?? []).length > 0
                    ? ""
                    : tGeneral("status")}
                </InputLabel>
                <Select
                  labelId="statusField"
                  id="demo-multiple-checkbox1"
                  multiple
                  onChange={(e) => {
                    filterTasks(e.target.value, "status");
                  }}
                  value={currentTaskFilters?.status ?? []}
                  startAdornment={
                    (currentTaskFilters?.status ?? []).length > 0 ? (
                      <IconButton
                        onClick={(e) => {
                          filterTasks([], "status");
                        }}
                      >
                        <Clear />
                      </IconButton>
                    ) : (
                      ""
                    )
                  }
                  input={
                    <OutlinedInput
                      label={
                        (currentTaskFilters?.status ?? []).length > 0
                          ? ""
                          : tGeneral("status")
                      }
                    />
                  }
                  renderValue={(selected) =>
                    (currentTaskFilters?.status ?? []).length > 0
                      ? `${tGeneral("status")} (${
                          (currentTaskFilters?.status ?? []).length
                        })`
                      : `${tGeneral("status")}`
                  }
                  MenuProps={MenuProps}
                  sx={styles.selectInput}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox
                        checked={
                          (currentTaskFilters?.status ?? []).indexOf(
                            option.value,
                          ) > -1
                        }
                        sx={{
                          color: "black",
                          ":checked": {
                            color: "#FBCB04",
                          },
                          borderColor:
                            (currentTaskFilters?.status ?? []).indexOf(
                              option.value,
                            ) > -1
                              ? "#f7e4b2"
                              : "black",
                        }}
                      />
                      {option.label == "OPEN"
                        ? tGeneral("open_action").toUpperCase()
                        : option.label == "DRAFT"
                          ? tGeneral("draft").toUpperCase()
                          : option.label == "CLOSED"
                            ? tGeneral("closed").toUpperCase()
                            : "Other"}
                      {/* {option.label} */}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {/* Category Select */}
            {(currentFilter === "category" ||
              (currentTaskFilters?.category ?? []).length > 0) && (
              <SearchBasicFilter
                title={tGeneral("category")}
                itemList={categories}
                selectedElementsList={currentTaskFilters?.category ?? []}
                handleUpdateElementsList={(list) => {
                  filterTasks(list.length > 0 ? list : [], "category");
                }}
                selectProps={{
                  ml: 1,
                  width: "200px",
                }}
              />
            )}
            {/* Priority Select */}
            {(currentFilter === "priority" ||
              (currentTaskFilters?.priority ?? []).length > 0) && (
              <FormControl size="small" sx={styles.secondaryFilter}>
                <InputLabel id="userTag1" sx={styles.inputTitle}>
                  {(currentTaskFilters?.priority ?? []).length > 0
                    ? ""
                    : tGeneral("priority")}
                </InputLabel>
                <Select
                  labelId="priorityField"
                  id="demo-multiple-checkbox1"
                  multiple
                  onChange={(e) => {
                    filterTasks(e.target.value, "priority");
                  }}
                  value={currentTaskFilters?.priority ?? []}
                  startAdornment={
                    (currentTaskFilters?.priority ?? []).length > 0 ? (
                      <IconButton
                        onClick={(e) => {
                          filterTasks([], "priority");
                        }}
                      >
                        <Clear />
                      </IconButton>
                    ) : (
                      ""
                    )
                  }
                  input={
                    <OutlinedInput
                      label={
                        (currentTaskFilters?.priority ?? []).length > 0
                          ? ""
                          : tGeneral("priority")
                      }
                    />
                  }
                  renderValue={(selected) =>
                    (currentTaskFilters?.priority ?? []).length > 0
                      ? `${tGeneral("priority")} (${
                          (currentTaskFilters?.priority ?? []).length
                        })`
                      : `${tGeneral("priority")}`
                  }
                  MenuProps={MenuProps}
                  sx={styles.selectInput}
                >
                  {priorityOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox
                        checked={
                          (currentTaskFilters?.priority ?? []).indexOf(
                            option.value,
                          ) > -1
                        }
                        sx={{
                          color: "black",
                          ":checked": {
                            color: "#FBCB04",
                          },
                          borderColor:
                            (currentTaskFilters?.priority ?? []).indexOf(
                              option.value,
                            ) > -1
                              ? "#f7e4b2"
                              : "black",
                        }}
                      />
                      <Box display="flex" alignItems="center">
                        {option.label}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {/* Location Select */}
            {(currentFilter === "location" ||
              (currentTaskFilters?.location ?? []).length > 0) && (
              <SearchLocationFilter
                title={tGeneral("location")}
                selectedLocations={currentTaskFilters?.location ?? []}
                onChange={(list) => {
                  filterTasks(list.length > 0 ? list : [], "location");
                }}
                selectProps={{
                  ml: 1,
                  width: "200px",
                }}
              />
            )}
            {/* Date Range */}
            {(currentFilter === "createdOn" ||
              (currentTaskFilters?.createdAfter !== null &&
                currentTaskFilters?.createdBefore !== null)) && (
              <DateRangeFilter
                onChange={(stringDates) => {
                  filterTasks(stringDates, "createdOn");
                }}
                filterProps={{
                  minWidth: "200px",
                  verticalAlign: "center",
                  ml: 1,
                }}
              />
            )}
          </Box>
        </Box>
      )}
      {/* Tasks available */}
      {totalTasks.length > 0 && (
        <TaskBoard
          backlogTasksProps={{
            total: backlogTotal,
            tasks: backlogTasks,
            refetch: refetchBacklog,
            isLoading: isLoadingBacklog || isFetchingBacklog,
            noResults: noResultsBacklog,
            noMoreResults: noMoreResultsBacklog,
            onEndReach: onEndReachBacklog,
          }}
          doingTasksProps={{
            total: doingTotal,
            tasks: doingTasks,
            refetch: refetchDoing,
            isLoading: isLoadingDoing || isFetchingDoing,
            noResults: noResultsDoing,
            noMoreResults: noMoreResultsDoing,
            onEndReach: onEndReachDoing,
          }}
          doneTasksProps={{
            total: doneTotal,
            tasks: doneTasks,
            refetch: refetchDone,
            isLoading: isLoadingDone || isFetchingDone,
            noResults: noResultsDone,
            noMoreResults: noMoreResultsDone,
            onEndReach: onEndReachDone,
          }}
          users={projectUsers}
          locations={locations}
        />
      )}
      {/* No tasks */}
      {totalTasks.length === 0 && !tasksLoading && !tasksFetching && (
        <EmptyState
          title={tTasks("noTasksCreated")}
          content={tTasks("noTasksContent")}
          module="tasks"
        />
      )}

      {/* TODO: Refactor useDistributionList to be more self-contained */}
      {isDistributionListOpen && (
        <TaskSettingsModal
          isOpen={isDistributionListOpen}
          onClose={handleCloseDistributionList}
          users={projectUsers}
          days={projectConfig?.taskDays}
          selectedUsers={getSelectedUsers("taskDistributionList")}
          updateConfig={async (distributionList, days) => {
            await updateConfig({
              idProject,
              taskDistributionList: distributionList,
              taskDays: days,
            }).unwrap();
          }}
        />
      )}
      <LoadingOverlay open={isDeleteTaskLoading} />
      {Boolean(pdfUrl) && (
        <ModalFileViewer
          file={{ url: pdfUrl }}
          isOpen={Boolean(pdfUrl)}
          onClose={() => setPdfUrl(null)}
        />
      )}
      <TaskDetails
        refetchBacklog={refetchBacklog}
        refetchDoing={refetchDoing}
        refetchDone={refetchDone}
      />
    </Box>
  );
}

export default Tasks;
