import { tour } from "./tour";

const inspections = {
  // general tour
  ...tour,
  moduleElement: "检查",
  subModuleElement: "模板",
  // ADD - guide me steps
  step1_inspectionsAdd_title: "1. 添加",
  step1_inspectionsAdd_text: "点击 + 图标添加新模板。",
  step2_inspectionsAdd_title: "2. 标题",
  step2_inspectionsAdd_text: "输入检查的标题。",
  step3_inspectionsAdd_title: "3. 交付日期",
  step3_inspectionsAdd_text: "选择检查的交付日期。",
  step4_inspectionsAdd_title: "4. 状态",
  step4_inspectionsAdd_text_1_1: "选择状态",
  step4_inspectionsAdd_text_1_2: "对于该状态的检查，选择“修订”。",
  step4_inspectionsAdd_text_2_1: "选择状态",
  step4_inspectionsAdd_text_2_2: "选择“草稿”状态以保存检查的草稿。",
  step5_inspectionsAdd_title: "5. 隐私",
  step5_inspectionsAdd_text: "选择检查的隐私类型。",
  step5_inspectionsAdd_text_1: "任何有权限访问模块的人都可以看到该检查。",
  step5_inspectionsAdd_text_2: "只有参与者才能看到该检查。",
  step6_inspectionsAdd_title: "6. 位置",
  step6_inspectionsAdd_text: "选择检查的位置。",
  step7_inspectionsAdd_title: "7. 选择模板",
  step7_inspectionsAdd_text: "选择要用于检查的模板。",
  step8_inspectionsAdd_title: "8. 创建者",
  step8_inspectionsAdd_text: "选择与您具有相同权限的用户。",
  step9_inspectionsAdd_title: "9. 分配",
  step9_inspectionsAdd_text: "选择将批准该检查的人员。",
  step10_inspectionsAdd_title: "10. 分发列表",
  step10_inspectionsAdd_text: "选择要通知此检查的人员。",
  step11_inspectionsAdd_title: "11. 创建",
  step11_inspectionsAdd_text: "点击此处创建检查。",
  step_final_inspectionsAdd: "您已学会如何创建检查。",
  // ADD TEMPLATES - guide me steps
  step1_inspectionTemplatesAdd_title: "1. 检查模块菜单",
  step1_inspectionTemplatesAdd_text: "点击此处查看选项。",
  step2_inspectionTemplatesAdd_title: "2. 模板",
  step2_inspectionTemplatesAdd_text: "点击此处打开模板部分。",
  step3_inspectionTemplatesAdd_title: "3. 添加",
  step3_inspectionTemplatesAdd_text: "点击 + 图标",
  step4_inspectionTemplatesAdd_title: "4. 标题",
  step4_inspectionTemplatesAdd_text: "输入检查模板标题",
  step5_inspectionTemplatesAdd_title: "5. 代码",
  step5_inspectionTemplatesAdd_text: "输入检查模板代码。",
  step6_inspectionTemplatesAdd_title: "6. 类别",
  step6_inspectionTemplatesAdd_text: "选择类别。",
  step7_inspectionTemplatesAdd_title: "7. 描述",
  step7_inspectionTemplatesAdd_text: "输入描述。",
  step8_inspectionTemplatesAdd_title: "8. 检查列表",
  step8_inspectionTemplatesAdd_text: "点击添加部分以添加要检查的新项目。",
  step8_1_inspectionTemplatesAdd_title: "8.1 标题",
  step8_1_inspectionTemplatesAdd_text: "输入部分的标题。",
  step8_1_1_inspectionTemplatesAdd_title: "8.1.1 添加元素",
  step8_1_1_inspectionTemplatesAdd_text: "点击以添加要检查的元素。",
  step8_1_2_inspectionTemplatesAdd_title: "8.1.2 标题",
  step8_1_2_inspectionTemplatesAdd_text: "输入标题。",
  step8_1_3_inspectionTemplatesAdd_title: "8.1.3 描述",
  step8_1_3_inspectionTemplatesAdd_text: "输入元素的描述。",
  step9_inspectionTemplatesAdd_title: "9. 添加",
  step9_inspectionTemplatesAdd_text: "点击以添加元素。",
  step10_inspectionTemplatesAdd_title: "10. 保存",
  step10_inspectionTemplatesAdd_text: "点击以保存模板。",
  step_final_inspectionTemplatesAdd: "您已学会如何创建模板。",

  add: "添加检查",
  edit: "编辑检查",
  guideMe: "引导我",
  inspection_recycle_bin: "检查 - 回收站",
  inspections: "检查",
  meeting: "会议",
  no_inspections_text: "如果您想添加一个，请点击“添加检查”按钮开始该过程。",
  no_inspections_title: "目前尚未创建任何检查。",
  title: "检查",
  workflow: "工作流程",
  itemsInspected: "已检查项目",
  pass: "通过",
  fail: "失败",
  na: "不适用",
  sections: "部分",
  items: "项目",
  createTask: "创建任务",
  add_button_tour: "创建新检查",
  select_template: "选择模板",
  add_section: "添加部分",
  no_sections: "此检查中没有部分。",
  approve: "批准",
  checkAndSign: "检查并签名",
  createREvision: "创建修订版本",
  observation: "观察",
  addItem: "添加项目",
  deleteInspection: "您确定要删除此检查吗？",
  Revision: "修订",
};

export { inspections };
