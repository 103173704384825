import { Add, Restore } from "@mui/icons-material";
import { Box, Card, IconButton, Skeleton, Typography } from "@mui/material";
import Lottie from "lottie-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import TodoAnimation from "../../../../assets/animations/dashboard/todo.json";
import { useGetTodoListQuery } from "../../../../features/project/projectApiSlice";
import { selectCurrentRole } from "../../../../features/project/projectSlice";
import { ModalAddTodoList } from "./ModalAddTodoList";
import { ModalTodoRecycleBin } from "./ModalTodoRecycleBin";
import { TodoCard } from "./TodoCard";

function TodoList() {
  const { t: tGeneral } = useTranslation("general");
  const { idProject } = useParams();
  const [isAddTodoOpen, setIsAddTotoOpen] = useState(false);
  const handleClose = () => setIsAddTotoOpen(false);
  const handleOpen = () => setIsAddTotoOpen(true);
  const [isTodoRecycleOpen, setIsTodoRecycleOpen] = useState(false);
  const currentRole = useSelector(selectCurrentRole);

  const handleOpenRecycleBin = () => setIsTodoRecycleOpen(true);
  const handleCloseRecycleBin = () => setIsTodoRecycleOpen(false);

  const { data, isLoading } = useGetTodoListQuery(
    { idProject },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const renderContent = () => {
    if (isLoading) {
      return [...Array(10)].map((_val, index) => (
        <Box key={index} display="flex" p="10px" alignItems="center">
          <Box flex={1}>
            <Skeleton width="213px" />
            <Box display="flex" columnGap="6px">
              <Skeleton width="114px" />
              <Skeleton width="60px" />
            </Box>
          </Box>
          <Skeleton variant="rectangular" width="20px" height="20px" />
        </Box>
      ));
    }
    if (data?.length > 0) {
      return data?.map(({ id, name, limitDate }, index) => (
        <TodoCard
          key={id}
          id={id}
          name={name}
          limitDate={limitDate}
          last={index === data.length - 1}
        />
      ));
    }

    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="100%"
      >
        <Lottie
          animationData={TodoAnimation}
          loop
          style={{ height: "200px" }}
        />
        <Typography color="#9F9F9F" fontWeight="600">
          {tGeneral("addItemList")}
        </Typography>
      </Box>
    );
  };

  return (
    <Box flex={1} display="flex" flexDirection="column" overflow="hidden">
      <Box display="flex">
        <Typography fontSize="1.375rem">{tGeneral("todoList")}</Typography>
        <Box flex={1} />
        <IconButton onClick={handleOpenRecycleBin}>
          <Restore sx={{ color: "grey" }} />
        </IconButton>
        <IconButton onClick={handleOpen}>
          <Add sx={{ color: "grey" }} />
        </IconButton>
      </Box>
      <Card sx={{ overflowY: "auto", flex: 1, mx: "5px", mb: "5px" }}>
        {renderContent()}
      </Card>
      <ModalAddTodoList
        idProject={idProject}
        isOpen={isAddTodoOpen}
        order={data ? data.length + 1 : 1}
        onClose={handleClose}
      />
      <ModalTodoRecycleBin
        isOpen={isTodoRecycleOpen}
        onClose={handleCloseRecycleBin}
        idProject={idProject}
      />
    </Box>
  );
}

export { TodoList };
