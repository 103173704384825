import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useContext, useMemo, useState } from "react";

import { useSelector } from "react-redux";
import { selectCurrentProject } from "../../../../features/project/projectSlice";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ReportLocationComments } from "./ReportLocationComments";
import { selectCurrentUser } from "../../../../features/userSettings/userSettingsSlice";
import CaslContext from "../../../../utils/caslContext";

function DelayReportDetails({ report }) {
  const topic = useMemo(() => {
    if (report) {
      return report.delayReport.topic;
    }
    return [];
  }, [report]);

  const locationItems = useMemo(() => {
    if (report) {
      return [report.delayReport.location];
    }
    return [];
  }, [report]);

  const currentProject = useSelector(selectCurrentProject);
  const currentUser = useSelector(selectCurrentUser);
  const ability = useContext(CaslContext);

  const [tabIndex, setTabIndex] = useState(0);

  const disableChat = useMemo(() => {
    if (report && currentUser) {
      const isDisabled = report.status === "close";
      if (!isDisabled && !ability.can("superAdmin", "dailyReports")) {
        const canComment =
          report.creators.indexOf(currentUser?.id) > -1 ||
          report.assignedTo.indexOf(currentUser?.id) > -1;
        if (canComment) {
          return false;
        }
        return true;
      }
      return isDisabled;
    }
    return false;
  }, [report, currentUser]);

  return (
    <Box>
      <Tabs variant="scrollable" scrollButtons="auto" value={tabIndex}>
        {locationItems.map((location, index) => {
          let locationLabel = location?.locationName ?? "N/A";
          if (location?.locationType === "tier 2")
            locationLabel = `>${locationLabel}`;
          if (location?.locationType === "tier 3")
            locationLabel = `>>${locationLabel}`;
          return (
            <Tab
              key={`tab-location-${index}`}
              label={locationLabel}
              sx={{
                textTransform: "none",
                minWidth: 0,
                fontWeight: "regular",
                "&.Mui-selected": {
                  color: "black",
                  fontWeight: "medium",
                },
              }}
            />
          );
        })}
      </Tabs>

      {locationItems.map((location, index) => (
        <div
          key={`tabpanel-location-${index}`}
          role="tabpanel"
          hidden={tabIndex !== index}
        >
          <Paper elevation={3} sx={{ display: "flex", height: 600, mt: 2 }}>
            {/* Left side */}
            <Box flex={1}>
              {/* Description */}
              <Box
                height={525}
                overflow="scroll"
                sx={{
                  msOverflowStyle: "none",
                  "::-webkit-scrollbar": {
                    display: "none",
                  },
                  "::-moz-scrollbar": {
                    display: "none",
                  },
                }}
                data-tour="view-report-delay-description"
              >
                <Box
                  key={topic?.id}
                  bgcolor="#FFEBBD"
                  sx={{
                    ":hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <Box px={4} pt={2}>
                    <Typography
                      fontSize={14}
                      color="#B58101"
                      sx={{
                        whiteSpace: "pre-wrap",
                      }}
                    >{`${topic?.title}`}</Typography>
                    <Typography
                      fontSize={12}
                      color="#B58101"
                      textAlign="end"
                      marginTop={1}
                      pb={1}
                      borderBottom="1px solid #DEDEDE"
                    >
                      {moment(new Date(topic?.createDate))
                        .tz(currentProject?.projectTimezone)
                        .format(
                          "D MMMM YYYY\xa0\xa0\xa0\xa0 |\xa0\xa0\xa0\xa0 h:mmA",
                        )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* Right side */}
            <Box flex={1} bgcolor="#FFEBBC" data-tour="view-dailyReport-5">
              <ReportLocationComments disabled={disableChat} topic={topic} />
            </Box>
          </Paper>
        </div>
      ))}
    </Box>
  );
}

export default DelayReportDetails;
