import { Circle, Edit, Email, RemoveRedEye } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  AssignedUsersTooltip,
  StyledMaterialReactTable,
  TooltipIconButton,
} from "../../../../components/shared";
import { useAppTourContext } from "../../../../components/supportButton/context/context";
import { selectCurrentGuideMe } from "../../../../features/project/projectSlice";
import { selectCurrentUser } from "../../../../features/userSettings/userSettingsSlice";
import Can from "../../../../utils/can";
import CaslContext from "../../../../utils/caslContext";
import dailyReportsUtils from "../utils/dailyReports";
import { SendEmailModal } from "./Modals/SendEmailModal";

const risks = [
  {
    label: "highRisk",
    color: "#EC2D30",
  },
  {
    label: "mediumRisk",
    color: "#FE9B0E",
  },
  {
    label: "lowRisk",
    color: "#0C9D61",
  },
];

const statusColor = {
  open: "#0C9D61",
  closed: "#F64C4C",
  close: "#F64C4C",
  inactive: "#CACACA",
  draft: "#FE9B0E",
};

function DailyReportsTable({
  reports,
  inactive = false,
  category,
  isLoading,
  selectedReports,
  setSelectedReports,
}) {
  const { t: tDailyReports } = useTranslation("dailyReports");
  const { t: tGeneral } = useTranslation("general");
  const { t: tStatus } = useTranslation("status");

  const ability = useContext(CaslContext);
  const currentUser = useSelector(selectCurrentUser);
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const [sendingReports, setSendingReports] = useState(null);

  const navigate = useNavigate();
  const columns = useMemo(() => {
    const cols = [
      {
        header: tGeneral("status"),
        accessorKey: "status",
        Cell: ({ cell, row }) => (
          <Tooltip
            title={tStatus(
              row.original?.bActive ? cell.getValue() : "inactive",
            )}
          >
            <Circle
              sx={{
                color:
                  statusColor[
                    row.original?.bActive ? cell.getValue() : "inactive"
                  ],
              }}
            />
          </Tooltip>
        ),
        size: 40,
        enableSorting: false,
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
          sx: { padding: 0 },
        },
      },
      {
        header: "#",
        accessorKey: "number",
        size: 50,
        muiTableHeadCellProps: {
          sx: { padding: 0 },
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        header: tDailyReports("title"),
        accessorKey: "title",
        muiTableBodyCellProps: {
          align: "left",
        },
        muiTableHeadCellProps: {
          align: "left",
        },
        size: 120,
        Cell: ({ cell }) => (
          <Tooltip title={cell.getValue()}>
            <Typography noWrap textOverflow="ellipsis">
              {cell.getValue()}
            </Typography>
          </Tooltip>
        ),
      },
    ];
    if (category === 5) {
      cols.push({
        header: tGeneral("attendance"),
        accessorKey: "manpower",
        size: 100,
        Cell: ({ cell }) => (
          <Box display="flex" flexDirection="column">
            <Typography>
              {cell.getValue()?.assistants} {tDailyReports("assistants")}
            </Typography>
            <Typography>
              {cell.getValue()?.hours} {tGeneral("hours")}
            </Typography>
          </Box>
        ),
      });
    }
    if (category === 4) {
      cols.push({
        header: tDailyReports("delay"),
        accessorKey: "delayReport",
        size: 100,
        Cell: ({ cell }) => (
          <Box display="flex" flexDirection="column">
            <Typography>
              {cell.getValue()?.delayDays}{" "}
              {cell.getValue()?.delayDays === 1
                ? tGeneral("day")
                : tGeneral("days")}
            </Typography>
            <Typography>
              {cell.getValue()?.delayHours}{" "}
              {cell.getValue()?.delayHours === 1
                ? tGeneral("hour")
                : tGeneral("hours")}
            </Typography>
          </Box>
        ),
      });
    }
    if (category === 3) {
      cols.push({
        header: tDailyReports("risk"),
        accessorKey: "risk",
        size: 100,
        Cell: ({ cell }) => (
          <Chip
            sx={{
              backgroundColor: tDailyReports(risks[cell.getValue()]?.color),
            }}
            label={tDailyReports(risks[cell.getValue()]?.label)}
          />
        ),
      });
    }
    if (category === 1) {
      cols.push({
        header: tGeneral("locations"),
        accessorKey: "agendas",
        size: 100,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue()?.length}{" "}
            {cell.getValue()?.length > 1
              ? tGeneral("locations")
              : tGeneral("location")}
          </Typography>
        ),
      });
    }
    return [
      ...cols,
      {
        header: tDailyReports("assigned"),
        accessorKey: "assignedToUsers",
        enableSorting: false,
        Cell: ({ cell }) => <AssignedUsersTooltip users={cell.getValue()} />,
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
        size: 120,
      },
      {
        accessorKey: "dueDate",
        header: tDailyReports("due"),
        Cell: ({ cell }) => moment.utc(cell.getValue()).format("DD/MM/YYYY"),
        size: 120,
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
      },
    ];
  }, [category, tDailyReports, tGeneral]);

  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 2 &&
      currentGuideMe === "daily-reports-recycle"
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe]);

  return (
    <>
      <StyledMaterialReactTable
        data={reports}
        pageSize={10}
        selectedRows={selectedReports}
        setSelectedRows={setSelectedReports}
        isLoading={isLoading}
        columns={columns}
        enableRowSelection
        muiSelectAllCheckboxProps={{ class: "checkbox-recycle" }}
        renderRowActions={({ row }) => {
          if (inactive) {
            return (
              <TooltipIconButton
                icon={<RemoveRedEye fontSize="small" />}
                label={tGeneral("view")}
                onClick={() => navigate(row.id)}
                data-tour={row.index === 0 ? "recycle-dailyReport-3" : ""}
                color="black"
              />
            );
          }
          if (row.original.status === "draft") {
            return (
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={() => {
                  navigate(`form/${row.original.id}`);
                }}
                data-tour="edit-dailyReport-1"
              >
                {tGeneral("edit")}
              </Button>
            );
          }
          return (
            <ButtonGroup>
              <Can I="edit" a="dailyReports">
                {(ability.can("superAdmin", "dailyReports") ||
                  row.original.creators.indexOf(currentUser.id) > -1) && (
                  <TooltipIconButton
                    icon={<Edit fontSize="small" />}
                    label={tGeneral("edit")}
                    onClick={() => {
                      navigate(`form/${row.original.id}`);
                    }}
                    data-tour="edit-dailyReport-1"
                    color="black"
                  />
                )}
              </Can>
              <TooltipIconButton
                icon={<RemoveRedEye fontSize="small" />}
                label={tGeneral("view")}
                onClick={() => navigate(row.id)}
                color="black"
                data-tour="view-dailyReport-1"
              />
              <TooltipIconButton
                icon={<Email fontSize="small" />}
                label={tGeneral("email")}
                onClick={() => setSendingReports([row.original])}
                color="black"
              />
            </ButtonGroup>
          );
        }}
        emptyStateMessage={tGeneral("noElements", {
          moduleName: tDailyReports("reports").toLowerCase(),
        })}
      />
      {Boolean(sendingReports) && (
        <SendEmailModal
          onClose={() => setSendingReports(null)}
          isOpen={Boolean(sendingReports)}
          reports={sendingReports}
        />
      )}
    </>
  );
}

export { DailyReportsTable };
