import React, { useEffect, useState } from "react";
// MUI Components
import { Close, PersonRemove } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  useDisableAdminUsersMutation,
  useEnableAdminUserMutation,
  useGetAdminUsersQuery,
} from "../../features/enterprise/enterpriseApiSlice";
import { selectCurrentEnterprise } from "../../features/enterprise/enterpriseSlice";
import { selectTheme } from "../../features/preferences/preferencesSlice";
// Validators
import { TitleWidgets } from "../../components/shared/TitleWidgets";
// Alert

import { PopUpAlert } from "../../components/PopUpAlert";
import {
  StyledMaterialReactTable,
  TooltipIconButton,
} from "../../components/shared";

function Admins() {
  const { t: tGeneral } = useTranslation("general");
  const darkMode = useSelector(selectTheme);
  const columns = [
    {
      accessorKey: "email",
      header: tGeneral("email"),
      size: 400,
      muiTableBodyCellProps: {
        align: "left",
      },
      muiTableHeadCellProps: {
        align: "left",
      },
    },
  ];

  const styles = {
    columnNumHeader: {
      textAlign: "left",
      width: "20%",
      maxWidth: "20%",
      fontWeight: "bold",
      fontSize: "1rem",
    },
    columnNumCell: {
      fontSize: "1rem ",
      textAlign: "left",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "20%",
      maxWidth: "20%",
    },
    columnNameHeader: {
      textAlign: "left",
      width: "100%",
      maxWidth: "100%",
      fontWeight: "bold",
      fontSize: "1rem",
    },
    columnNameCell: {
      fontSize: "1rem",
      textAlign: "left",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
      maxWidth: "100%",
    },
    columnActionHeader: {
      textAlign: "center",
      width: "10%",
      maxWidth: "10%",
      fontWeight: "bold",
      fontSize: "1rem",
    },
    columnActionCell: {
      fontSize: 12,
      textAlign: "center",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "10%",
      maxWidth: "10%",
    },
    iconButton: {
      color: darkMode ? "white" : "black",
      ":hover": {
        color: "#FBCB04",
      },
      m: [0, 0, 0, 0],
    },
    boxStyle: {
      height: "100%",
      width: "100%",
      maxHeight: "90vh",
    },
    boxButton: {
      backgroundColor: "#ffc900",
      padding: 0.5,
      paddingX: 0.7,
      borderRadius: 1,
    },
    addButton: {
      padding: [0.4, 0, 0.4, 0],
      marginBottom: -0.5,
      color: "black",
    },
    dialogTitle: {
      textAlign: "left",
      height: "60px",
      fontWeight: "bold",
    },
    iconButtonClose: {
      position: "absolute",
      right: 8,
      top: 8,
    },
    textField: {
      mx: "auto",
      width: "100%",
      marginTop: 1,
      marginBottom: 1,
    },
    cancelButton: {
      width: "50%",
      color: "black",
      backgroundColor: "white",
      borderColor: "black",
      border: 1,
      ":hover": {
        color: "white",
        backgroundColor: "#b4b4b4",
      },
    },
    saveButton: {
      width: "50%",
      color: "black",
      backgroundColor: "#ffc900",
      ":hover": {
        color: "black",
        backgroundColor: "#d9ab00",
      },
    },
    removeButton: {
      width: "50%",
      color: "black",
      backgroundColor: "#ffc900",
      ":hover": {
        color: "black",
        backgroundColor: "#d9ab00",
      },
    },
  };

  const [data, setData] = useState([]);
  const [email, setEmail] = useState("");
  const [deleteEmail, setDeleteEmail] = useState("");
  const currentEnterprise = useSelector(selectCurrentEnterprise);
  const {
    data: adminUsers,
    isLoading,
    isSuccess,
  } = useGetAdminUsersQuery(currentEnterprise?.id);

  const [disableAdminUser, { isLoading: isDisabledLoading }] =
    useDisableAdminUsersMutation();
  const [
    enableAdminUser,
    {
      isLoading: isEnabledLoading,
      isSuccess: isEnabledSuccess,
      isError: isEnabledError,
      error: enabledError,
    },
  ] = useEnableAdminUserMutation();

  var dataBody = {};

  const getAdminUsers = async (id) => {
    try {
      const response = await adminUsers().unwrap();
    } catch (e) {}
    if (isLoading) {
    } else if (isSuccess) {
      var jsonData = [];
      for (let i = 0; i < adminUsers.length; i++) {
        jsonData.push({
          id: adminUsers[i]["id"],
          idUser: adminUsers[i]["idUser"],
          email: adminUsers[i]["user"]["email"],
          bMain: adminUsers[i]["bMain"],
        });
      }
      setData(jsonData);
    }
  };

  useEffect(() => {
    const getData = async () => {
      getAdminUsers(currentEnterprise?.id);
    };
    getData();
  }, [currentEnterprise, adminUsers]);

  // enable admin user
  const enableUser = async (e) => {
    const body = {
      idEnterprise: currentEnterprise?.id,
      email,
    };
    try {
      await enableAdminUser(body).unwrap();
      PopUpAlert("success", tGeneral("adminEnabledSuccessfully"));
    } catch (err) {
      console.error(err.data);
      PopUpAlert("error", tGeneral("cantEnableAdmin"));
    }
    setOpenAdminDialog(false);
  };

  // disable admin user
  const disableUser = async (e) => {
    dataBody.idEnterprise = currentEnterprise?.id;
    dataBody.email = deleteEmail;
    try {
      const response = await disableAdminUser(dataBody).unwrap();
      PopUpAlert("success", tGeneral("adminDisabledSuccessfully"));
    } catch (err) {
      console.error(err);
      PopUpAlert("error", tGeneral("cantDisableAdmin"));
    }
    setDeleteEmail("");
  };

  const theme = useTheme();

  const [openAdminDialog, setOpenAdminDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  return (
    <>
      <Box sx={{ height: 500, width: "100%" }}>
        <TitleWidgets
          title="Super Admins"
          addFunction={() => setOpenAdminDialog(true)}
          showMoreOptions={false}
        />
        <ThemeProvider theme={theme}>
          <StyledMaterialReactTable
            columns={columns}
            data={data}
            enableRowActions
            renderRowActions={({ row }) => (
              <Box display="flex" columnGap="10px">
                <TooltipIconButton
                  icon={<PersonRemove />}
                  color="red"
                  onClick={() => {
                    setDeleteEmail(row.original.email);
                    setOpenDeleteDialog(true);
                  }}
                  label={tGeneral("delete")}
                />
              </Box>
            )}
          />
        </ThemeProvider>
      </Box>

      {/* Modal - Add Admin */}
      <Dialog
        open={openAdminDialog}
        onClose={(e) => setOpenAdminDialog(false)}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ mx: "auto" }}
        PaperProps={{ sx: { borderRadius: "10px", width: "50%" } }}
      >
        {/* <DialogTitle id="scroll-dialog-title-delete" sx={styles.dialogTitle}> */}

        <DialogTitle
          id="scroll-dialog-title"
          sx={[
            styles.dialogTitle,
            { padding: "16px 40px" },
            { marginBottom: "10px" },
          ]}
        >
          {tGeneral("addSuperAdmin")}
          <IconButton
            aria-label="close"
            onClick={(e) => setOpenAdminDialog(false)}
            sx={[styles.iconButtonClose, { marginRight: "20px" }]}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={[{ padding: "0px 40px 10px 40px" }]}>
          <TextField
            fullWidth
            id="defaultNameTextField"
            variant="outlined"
            type="email"
            label={tGeneral("email")}
            required
            sx={styles.textField}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </DialogContent>
        <DialogActions
          sx={[
            { marginBottom: "10px" },
            { marginRight: "30px" },
            { marginLeft: "30px" },
          ]}
        >
          <Button
            sx={styles.cancelButton}
            type="submit"
            variant="contained"
            size="large"
            onClick={(e) => setOpenAdminDialog(false)}
          >
            {tGeneral("cancel")}
          </Button>
          <Button
            sx={styles.saveButton}
            type="submit"
            variant="contained"
            size="large"
            onClick={enableUser}
          >
            {tGeneral("confirm")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal - delete Admin */}
      <Dialog
        open={openDeleteDialog}
        onClose={(e) => setOpenDeleteDialog(false)}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ mx: "auto" }}
        PaperProps={{ sx: { borderRadius: "10px", width: "50%" } }}
      >
        {/* <DialogTitle id="scroll-dialog-title-delete" sx={styles.dialogTitle}> */}

        <DialogTitle
          id="scroll-dialog-title"
          // sx={[
          //   styles.dialogTitle,
          //   { padding: "16px 40px" },
          //   { marginBottom: "10px" },
          // ]}
        >
          <IconButton
            aria-label="close"
            onClick={(e) => setOpenDeleteDialog(false)}
            sx={[styles.iconButtonClose, { marginRight: "20px" }]}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <Box style={styles.closeAlert}>
          <Box
            style={{
              width: "100%",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={require("../../assets/icons/delete.png")}
              style={{ width: 100, height: 100, margin: "auto" }}
              alt=""
            />

            <Typography
              style={{ fontSize: 24, textAlign: "center", fontWeight: "600" }}
            >
              {tGeneral("removeSuperAdminTitle").toUpperCase()}
            </Typography>
          </Box>
        </Box>

        <DialogContent>
          <Typography style={{ fontSize: 16, textAlign: "center" }}>
            {tGeneral("removeSuperAdminDescription")}
          </Typography>
        </DialogContent>

        <DialogActions
          sx={[
            { marginBottom: "10px" },
            { marginRight: "30px" },
            { marginLeft: "30px" },
          ]}
        >
          <Button
            sx={styles.cancelButton}
            type="submit"
            variant="contained"
            size="large"
            onClick={(e) => {
              setOpenDeleteDialog(false);
              dataBody = {};
            }}
          >
            {tGeneral("cancel")}
          </Button>

          <Button
            sx={styles.saveButton}
            type="submit"
            variant="contained"
            size="large"
            onClick={() => {
              disableUser();
              setOpenDeleteDialog(false);
            }}
          >
            {tGeneral("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Admins;
