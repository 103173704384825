import { apiSlice } from "../../../../api/apiSlice";

export const inspectionsTemplatesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInspectionsTemplates: builder.query({
      query: (idProject) => `/project/inspectionsTemplates/${idProject}/get`,
      keepUnusedDataFor: 60,
      providesTags: ["InspectionsTemplates"],
    }),
    getActiveInspectionsTemplates: builder.query({
      query: (params) =>
        `project/inspectionsTemplates/${
          params.idProject
        }/get?bActive=true&category=${encodeURIComponent(params.category)}`,
      providesTags: ["InspectionsTemplates"],
    }),
    getInactiveInspectionsTemplates: builder.query({
      query: (params) =>
        `project/inspectionsTemplates/${params.idProject}/get?bActive=false&assignees=${params.assignees}&creator=${params.creator}&status=${params.status}&privacy=${params.privacy}`,
      keepUnusedDataFor: 10,
      providesTags: ["InspectionsTemplates"],
    }),
    getInspectionTemplateById: builder.query({
      query: (params) =>
        `project/inspectionsTemplates/get/${params.idInspectionTemplate}?idProject=${params.idProject}`,
      providesTags: ["InspectionsTemplates"],
    }),
    createInspectionTemplate: builder.mutation({
      query: (body) => ({
        url: "project/inspectionsTemplates/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["InspectionsTemplates"],
    }),
    deleteInspectionTemplate: builder.mutation({
      query: (body) => ({
        url: "project/inspectionsTemplates/delete",
        method: "POST",
        body,
      }),
      invalidatesTags: ["InspectionsTemplates"],
    }),
    recoverInspectionTemplate: builder.mutation({
      query: (body) => ({
        url: "project/inspectionsTemplates/recover",
        method: "POST",
        body,
      }),
      invalidatesTags: ["InspectionsTemplates"],
    }),
    setPrivateInspectionTemplate: builder.mutation({
      query: (body) => ({
        url: "project/inspectionsTemplates/setPrivate",
        method: "POST",
        body,
      }),
      // providesTags: ["Projects"],
    }),
    setPublicInspectionTemplate: builder.mutation({
      query: (body) => ({
        url: "project/inspectionsTemplates/setPublic",
        method: "POST",
        body,
      }),
      // providesTags: ["Projects"],
    }),
    publishInspectionTemplate: builder.mutation({
      query: (body) => ({
        url: "project/inspectionsTemplates/publish",
        method: "POST",
        body,
      }),
      // providesTags: ["Projects"],
    }),
    setDraftInspectionTemplate: builder.mutation({
      query: (body) => ({
        url: "project/inspectionsTemplates/setDraft",
        method: "POST",
        body,
      }),
      // providesTags: ["Projects"],
    }),
    editInspectionTemplate: builder.mutation({
      query: (body) => ({
        url: "project/inspectionsTemplates/edit",
        method: "POST",
        body,
      }),
      invalidatesTags: ["InspectionsTemplates", "Message"],
    }),
    closeInspectionTemplate: builder.mutation({
      query: (body) => ({
        url: "project/inspectionsTemplates/close",
        method: "POST",
        body,
      }),
      invalidatesTags: ["InspectionsTemplates", "Dashboard"],
    }),
    sendEmailInspectionTemplate: builder.mutation({
      query: (body) => ({
        url: "project/inspectionsTemplates/sendMailInspections",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects"],
    }),
    getInspectionsTemplatesStatus: builder.mutation({
      query: (body) => ({
        url: "project/inspectionsTemplates/getInspections",
        method: "POST",
        body,
      }),
      invalidateTags: ["InspectionsTemplates"],
    }),
    getLastInspectionTemplate: builder.query({
      query: (idProject) =>
        `/project/inspectionsTemplates/lastInspection/${idProject}`,
      keepUnusedDataFor: 10,
      providesTags: ["InspectionsTemplates"],
    }),
  }),
});

export const {
  useGetInspectionsTemplatesQuery,
  useGetActiveInspectionsTemplatesQuery,
  useGetInactiveInspectionsTemplatesQuery,
  useCreateInspectionTemplateMutation,
  useEditInspectionTemplateMutation,
  useDeleteInspectionTemplateMutation,
  useCloseInspectionTemplateMutation,
  useSendEmailInspectionTemplateMutation,
  useGetInspectionsTemplatesStatusMutation,
  useGetLastInspectionTemplateQuery,
  useRecoverInspectionTemplateMutation,
  useGetInspectionTemplateByIdQuery,
} = inspectionsTemplatesApiSlice;
