import { tour } from "./tour";

export const tasks = {
  // general tour
  ...tour,
  moduleElement: "Tarefas",
  // ADD - guide me steps
  task: "Tarefa",
  tasks: "Tarefas",
  high: "Alta",
  medium: "Média",
  low: "Baixa",
  taskDeletedSuccessfully: "Tarefa(s) excluída(s) com sucesso",
  taskRestoredSuccessfully: "Tarefa(s) restaurada(s) com sucesso",
  noTasksCreated: "Nenhuma tarefa criada neste momento",
  noTasksContent:
    "Se você deseja adicionar uma, convidamos você a clicar no botão de adicionar para iniciar o processo",
  addTask: "Adicionar tarefa",
  backlog: "Backlog",
  doing: "Em andamento",
  done: "Concluída",
  creationDate: "Data de criação",
  deliveryDate: "Data de entrega",
  closeDate: "Data de fechamento",
  category: "Categoria",
  priority: "Prioridade",
  location: "Localização",
  startTask: "Iniciar tarefa",
  closeTask: "Fechar tarefa",
  days: "Dias",
  tasksRecycleBin: "Lixeira de tarefas",
  editTask: "Editar tarefa",
  addCategory: "Adicionar categoria",
  // ADD - guide me steps
  step1_addtask_title: "1. Adicionar tarefa",
  step1_addtask_text: "Clique aqui para adicionar uma nova tarefa",
  step2_addtask_title: "2. Título",
  step2_addtask_text: "Escreva um título que possa descrever sua nova tarefa",
  step3_addtask_title: "3. Data de vencimento",
  step3_addtask_text:
    "Escolha a data de vencimento como prazo para concluir a tarefa",
  step4_addtask_title: "4. Status",
  step4_addtask_text1_1: "Escolha o status",
  step4_addtask_text1_2: "ABERTO para publicar a tarefa imediatamente.",
  step4_addtask_text2_1: "Escolha o status",
  step4_addtask_text2_2:
    "RASCUNHO para manter um rascunho da tarefa sem publicá-la.",
  step5_addtask_title: "5. Privacidade",
  step5_addtask_text:
    "Clique aqui para torná-la visível para todos ou apenas para as pessoas envolvidas",
  step5_addtask_text_1: "Escolha o tipo de privacidade da tarefa",
  step5_addtask_text_2: "Qualquer pessoa com acesso ao módulo verá sua tarefa",
  step5_addtask_text_3: "Apenas os participantes poderão ver esta tarefa",
  step6_addtask_title: "6. Atribuídos",
  step6_addtask_text: "Selecione as pessoas que precisam concluir a tarefa",
  step7_addtask_title: "7. Lista de distribuição",
  step7_addtask_text:
    "Selecione as pessoas que você deseja notificar sobre esta tarefa",
  step8_addtask_title: "8. Descrição",
  step8_addtask_text: "Escreva uma descrição sobre o que é a tarefa",
  step9_addtask_title: "9. Carregar arquivos",
  step9_addtask_text:
    "Clique aqui ou arraste e solte arquivos como imagens ou documentos",
  step10_addtask_title: "10. Categoria",
  step10_addtask_text: "Escolha a categoria da tarefa",
  step11_addtask_title: "11. Prioridade",
  step11_addtask_text: "Selecione entre alta, média e baixa prioridade",
  step12_addtask_title: "12. Localização",
  step12_addtask_text:
    "Selecione a localização dentro do projeto onde a tarefa precisa ser feita",
  step13_addtask_title: "13. Publicar",
  step13_addtask_text:
    "Clique aqui para tornar a tarefa pública ou para salvá-la como rascunho, se aplicável",
  stepFinal_addtask: "Você aprendeu como criar uma nova tarefa",
  // VIEW - guide me steps
  step1_viewtask_title: "1. Visualizar tarefa",
  step1_viewtask_text: "Clique em uma tarefa para ver seu conteúdo.",
  step2_viewtask_title: "2. Envolvidos",
  step2_viewtask_text:
    "Nesta seção, você pode ver quem são os atribuídos e o criador.",
  step3_viewtask_title: "3. Dados da tarefa",
  step3_viewtask_text:
    "Nesta seção, você pode ver os principais dados da tarefa, como datas de vencimento, status, prioridade, etc.",
  step4_viewtask_title: "4. Descrição",
  step4_viewtask_text:
    "Aqui você pode ver a descrição da tarefa criada pelo seu proprietário.",
  step6_viewtask_title: "6. Responder",
  step6_viewtask_text:
    "Escreva uma resposta para a descrição principal e anexe arquivos a ela. Diferentes respostas serão exibidas abaixo da descrição.",
  step7_viewtask_title: "7. Comentar",
  step7_viewtask_text:
    "Deixe um comentário em uma resposta que foi feita e anexe arquivos, se necessário. Os comentários serão exibidos abaixo da mensagem respondida.",
  step8_viewtask_title: "8. Iniciar/concluir tarefa",
  step8_viewtask_text:
    "Clique aqui para marcar a tarefa como iniciada; se ela já tiver sido iniciada, clique para concluir a tarefa.",
  stepFinal_viewtask: "Você aprendeu como visualizar uma tarefa",
  // EDIT - guide me steps
  step1_edittask_title: "1. Editar tarefa",
  step1_edittask_text:
    "Clique nos três pontos para abrir diferentes opções: Clique em 'Editar'.",
  step2_edittask_title: "2. Informações",
  step2_edittask_text:
    "Nesta seção, você pode editar o título, a data de vencimento, o status e a privacidade da tarefa.",
  step3_edittask_title: "3. Descrição e arquivos anexados",
  step3_edittask_text:
    "Nesta seção, você pode editar a descrição da tarefa e excluir arquivos anexados.",
  step4_edittask_title: "4. Informações adicionais",
  step4_edittask_text:
    "Nesta seção, você pode editar informações adicionais, como categoria, prioridade e localização.",
  step4_edittask_text1_1: "Escolha o status",
  step4_edittask_text1_2: "ABERTO para publicar a tarefa pendente de resposta.",
  step4_edittask_text2_1: "Escolha o status",
  step4_edittask_text2_2:
    "RASCUNHO para manter um rascunho da tarefa sem publicá-la.",
  step5_edittask_title: "5. Atribuídos",
  step5_edittask_text: "Adicione ou remova atribuídos à tarefa",
  step5_edittask_text_1: "Escolha o tipo de privacidade da tarefa",
  step5_edittask_text_2: "Qualquer pessoa com acesso ao módulo verá sua tarefa",
  step5_edittask_text_3: "Apenas os participantes poderão ver esta tarefa",
  step6_edittask_title: "6. Lista de distribuição",
  step6_edittask_text: "Adicione ou remova pessoas da lista de distribuição",
  step7_edittask_title: "7. Salvar alterações",
  step7_edittask_text:
    "Clique aqui para salvar e atualizar as alterações feitas na tarefa",
  stepFinal_edittask: "Você aprendeu como editar uma tarefa",
  // RECYCLE - guide me steps
  step1_recycletask_title: "2. Tarefas da lixeira",
  step1_recycletask_text:
    "Aqui você pode ver as tarefas que foram excluídas do projeto.",
  step2_recycletask_title: "3. Selecionar tarefas",
  step2_recycletask_text: "Selecione as tarefas que você deseja restaurar.",
  step3_recycletask_title: "4. Restaurar tarefas",
  step3_recycletask_text: "Clique aqui para restaurar as tarefas selecionadas.",
  stepFinal_recycletask: "Você aprendeu como interagir com a lixeira",
  // EXPORT - guide me steps
  step1_exporttask_title:
    "Clique nos três pontos para abrir diferentes opções:",
  step1_exporttask_text1_1: "Exportar para PDF",
  step1_exporttask_text1_2:
    "Clique aqui para exportar a tarefa selecionada para um arquivo PDF.",
  step1_exporttask_text2_1: "Enviar por e-mail",
  step1_exporttask_text2_2:
    "Clique aqui para enviar a tarefa selecionada por e-mail.",
  stepFinal_exporttask: "Você aprendeu como exportar uma tarefa",
  // SETTINGS - guide me steps
  step1_settingstask_title: "2. Configurações da tarefa",
  step1_settingstask_text: "Clique aqui para modificar as configurações",
  step3_settingstask_title: "3. Lista de distribuição",
  step3_settingstask_text:
    "Selecione quem são as pessoas padrão para a lista de distribuição de tarefas criadas.",
  step4_settingstask_title: "4. Salvar configurações",
  step4_settingstask_text: "Clique aqui para salvar as configurações",
  stepFinal_settingstask:
    "Você aprendeu como interagir com as configurações da tarefa",
  // other
  obligatory: "Obrigatório",
  public: "Público",
  private: "Privado",
  congratulations: "Parabéns!",
  rfi_title_module: "RFIs",
  no_rfis_title: "Nenhum RFI foi criado no momento",
  no_rfis_text:
    "Se você deseja adicionar um, convidamos você a clicar no botão de adicionar RFI para iniciar o processo",
  meeting: "Reunião",
  workflow: "Fluxo de trabalho",
  guideMe: "Guie-me",
  support: "Suporte",
  closeTaskMessage: "Tem certeza de que deseja fechar esta tarefa?",
  subtasks: "Subtarefas",
  addSubtask: "Adicionar uma subtarefa",
  noSubtasks: "Nenhuma subtarefa criada",
  inList: "Na lista",
};
