import { apiSlice } from "../../api/apiSlice";

export const analyticsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProjectTasks: builder.query({
      query: (projectId) => `/analytics/project/${projectId}/task`,
      providesTags: ["Tasks"],
    }),
    getProjectSubmittals: builder.query({
      query: (projectId) => `/analytics/project/${projectId}/submittal`,
      providesTags: ["Submittals"],
    }),
    getProjectRfis: builder.query({
      query: (projectId) => `/analytics/project/${projectId}/rfi`,
      providesTags: ["RFIs"],
    }),
    getProjectInspections: builder.query({
      query: (projectId) => `/analytics/project/${projectId}/inspection`,
      providesTags: ["Inspections"],
    }),
    getProjectDrawings: builder.query({
      query: (projectId) => `/analytics/project/${projectId}/drawing`,
      providesTags: ["Drawings"],
    }),
    getProjectReports: builder.query({
      query: (projectId) => `/analytics/project/${projectId}/report`,
      providesTags: ["Reports"],
    }),
    getProjectSchedules: builder.query({
      query: (projectId) => `/analytics/project/${projectId}/projectSchedule`,
      providesTags: ["ProjectSchedule"],
    }),
  }),
});

export const {
  useGetProjectTasksQuery,
  useGetProjectSubmittalsQuery,
  useGetProjectRfisQuery,
  useGetProjectInspectionsQuery,
  useGetProjectDrawingsQuery,
  useGetProjectReportsQuery,
  useGetProjectSchedulesQuery,
} = analyticsApiSlice;
